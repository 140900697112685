import {useEffect, useRef, useState} from "react";
import I18n from "i18next";
import {toast} from "react-toastify";

import global from "../../../../../../global";
import {useAuthorizationContext} from "../../../../../../context";

import {UserMenu} from "../../../common/UserMenu/UserMenu";
import {Section} from "../../../common/Section";
import {Avatar} from "../../../Avatar/Avatar";
import {BorderedWrapper} from "../../RightPanel.style";
import Application from "../../../../../../Application";
import {StyledButton} from "../../../common/StyledButton";
import {PublishPanel} from "./PublishPanels/PublishPanel";
import {getGameUrl} from "../../../../../../v2/pages/services";

type Props = {
    showLoading: (show: boolean) => void;
    showAvatarCreator: () => void;
};

export type PublishAction = "publish" | "unpublish";

export const TopMenu = ({showLoading, showAvatarCreator}: Props) => {
    const {dbUser, googleUser} = useAuthorizationContext();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [publishPanelOpen, setPublishPanelOpen] = useState(false);

    const userMenuButtonRef = useRef<HTMLButtonElement | null>(null);
    const app = global.app as Application;
    const [isPublic, setIsPublic] = useState(!!app.editor?.isPublic);
    const [isPublished, setIsPublished] = useState(!!app.editor?.isPublished);
    const [isCloneable, setIsCloneable] = useState(!!app.editor?.isCloneable);

    const handleOpenMenu = () => {
        (global as any).app.call("removeGunAimer", this, this);
        setIsMenuOpen(!isMenuOpen);
    };

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    };

    const handleSettingsSave = async (action?: PublishAction) => {
        let shouldBePublic = isPublic;
        let shouldBeClonable = isCloneable;
        let shouldBePublished = isPublished;
        if (action === "unpublish") {
            shouldBePublished = false;
            setIsPublished(false);
            shouldBePublic = false;
            setIsPublic(false);
            shouldBeClonable = false;
            setIsCloneable(false);
        } else if (action === "publish") {
            shouldBePublished = true;
            setIsPublished(true);
        }

        if (!app.editor) return;

        const sceneID = app.editor.sceneID;

        if (!sceneID) {
            app.toast(I18n.t("Please open scene first."), "warn");
            return;
        }

        setIsLoading(true);

        app.commitSaveScene("", {
            isPublic: shouldBePublic,
            isCloneable: shouldBeClonable,
            isPublished: shouldBePublished,
            onSuccess: () => {
                if (app.editor) {
                    app.editor.isPublic = shouldBePublic;
                    app.editor.isCloneable = shouldBeClonable;
                    app.editor.isPublished = shouldBePublished;
                }
            },
            onError: () => {
                toast.error("Failed to save settings");
            },
        });
    };

    const handleUndo = () => {
        if (!app || !app.editor) return;
        app.editor.undo();
        app.call("objectUpdated");
    };

    const handleRedo = () => {
        if (!app || !app.editor) return;
        app.editor.redo();
        app.call("objectUpdated");
    };

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 89) {
            event.ctrlKey && handleRedo();
        } else if (event.keyCode === 90) {
            event.ctrlKey && handleUndo();
        }
    };

    useEffect(() => {
        app.on("sceneSaved.TopMenu", () => {
            setIsLoading(false);
            app.toast(I18n.t("Scene Saved"), "success");
            showLoading(false);
        });
        app.on("sceneSaveFailed.TopMenu", () => {
            setIsLoading(false);
            showLoading(false);
        });
        app.on("sceneSaveStart.TopMenu", () => {
            setIsLoading(true);
        });
        app.on(`undo.TopMenu`, handleUndo);
        app.on(`redo.TopMenu`, handleRedo);
        app.on(`keydown.TopMenu`, handleKeyDown);

        return () => {
            app.on("stoppedPlayingGame.TopMenu", null);
            app.on("sceneSaved.TopMenu", null);
            app.on("sceneSaveFailed.TopMenu", null);
            app.on("sceneSaveStart.TopMenu", null);
            app.on(`undo.TopMenu`, null);
            app.on(`redo.TopMenu`, null);
            app.on(`keydown.TopMenu`, null);
        };
    }, []);

    useEffect(() => {
        if (app.editor?.isPublished) {
            setIsCloneable(!!app.editor?.isCloneable);
            setIsPublic(!!app.editor?.isPublic);
            setIsPublished(!!app.editor?.isPublished);
        }
    }, [publishPanelOpen]);

    useEffect(() => {
        !isPublic && setIsCloneable(false);
    }, [isPublic]);

    const handleSaveScene = () => {
        if (!app.editor?.sceneName) {
            return toast.error("Scene name is required.");
        }
        showLoading(true);
        app.editor.scene.userData.lastSaveTime = new Date().toISOString();
        app.saveScene(true);
    };

    return (
        <BorderedWrapper height="40px">
            <Section $gap="9px" $direction="row" $width="auto" $align="center">
                {isMenuOpen && (
                    <UserMenu
                        close={handleCloseMenu}
                        userMenuButtonRef={userMenuButtonRef}
                        showAvatarCreator={showAvatarCreator}
                        isLoading={isLoading}
                    />
                )}
                {publishPanelOpen && !!app.editor?.sceneID && (
                    <PublishPanel
                        handleSettingsSave={handleSettingsSave}
                        closePanel={() => setPublishPanelOpen(false)}
                        fullUrl={getGameUrl(app.editor.sceneID) || ""}
                        isPublic={isPublic}
                        setIsPublic={setIsPublic}
                        isCloneable={isCloneable}
                        setIsCloneable={setIsCloneable}
                        isPublished={isPublished}
                        isLoading={isLoading}
                    />
                )}
                <button onClick={isLoading ? undefined : handleOpenMenu} className="reset-css" ref={userMenuButtonRef}>
                    <Avatar name={dbUser?.username || undefined} image={googleUser?.photoURL || undefined} size={24} />
                </button>
            </Section>
            <Section $gap="8px" $direction="row" $width="auto" $align="center">
                <StyledButton
                    isActive
                    onClick={handleSaveScene}
                    width="auto"
                    style={{height: "24px", fontSize: "11px"}}>
                    Save
                </StyledButton>
                <StyledButton
                    isGreySecondary
                    onClick={() => setPublishPanelOpen(true)}
                    width="auto"
                    style={{height: "24px", fontSize: "11px"}}>
                    Publish
                </StyledButton>
            </Section>
        </BorderedWrapper>
    );
};
