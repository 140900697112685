import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

import {BottomBar, Container, HeaderWrapper, PanelButton, RightSide, SearchSection, Title} from "./TemplatePanel.style";
import {SearchInput} from "../common/SearchInput";
import {BLANK_TEMPLATE_ID, TEMPLATES} from "./constants/templates";
import {useAppGlobalContext} from "../../../../context";
import {StyledButton} from "../common/StyledButton";
import {useOnClickOutside} from "usehooks-ts";
import {TemplateList} from "./TemplateList";
import {ROUTES} from "../../../../AppRouter";

const TemplatePanel = () => {
    const {setProjectPhase} = useAppGlobalContext();
    const [search, setSearch] = useState("");
    const [createBtnDisabled, setCreateBtnDisabled] = useState(true);
    const [selectedTemplateId, setSelectedTemplateId] = useState("");
    const [filteredData, setFilteredData] = useState<any[]>(TEMPLATES);
    const navigate = useNavigate();
    const templateRef = useRef<HTMLDivElement>(null);

    const handleCreate = () => {
        setProjectPhase(3);
    };
    useOnClickOutside(templateRef, handleCreate);

    useEffect(() => {
        setCreateBtnDisabled(selectedTemplateId !== BLANK_TEMPLATE_ID);
    }, [selectedTemplateId]);

    useEffect(() => {
        if (!search) {
            setFilteredData(TEMPLATES);
            return;
        } else {
            const data = TEMPLATES?.filter(n => {
                return n.Name.toLowerCase().indexOf(search.toLowerCase()) > -1;
            });

            if (data.findIndex(n => n.ID === selectedTemplateId) === -1) {
                const template = TEMPLATES.find(n => n.ID === selectedTemplateId);
                if (template) {
                    data.unshift(template);
                }
            }

            if (data.findIndex(n => n.ID === BLANK_TEMPLATE_ID) === -1) {
                data.unshift(TEMPLATES[0]);
            }

            setFilteredData(data);
        }
    }, [search]);

    return (
        <Container ref={templateRef}>
            <HeaderWrapper>
                <Title>Templates</Title>
                <RightSide>
                    <SearchSection>
                        <SearchInput onChange={setSearch} value={search} placeholder="Search" width="237px" />
                    </SearchSection>
                </RightSide>
            </HeaderWrapper>
            {filteredData && filteredData.length > 0 && (
                <div style={{flexGrow: 1}}>
                    <TemplateList
                        data={filteredData}
                        selectedItemsIds={[selectedTemplateId]}
                        onClick={id => setSelectedTemplateId(id)}
                        onDoubleClick={id => {
                            setSelectedTemplateId(id);
                            handleCreate();
                        }}
                    />
                </div>
            )}
            <BottomBar>
                <PanelButton
                    className="reset-css"
                    width={"160px"}
                    onClick={() => {
                        navigate(ROUTES.DASHBOARD);
                        setProjectPhase(1);
                    }}>
                    Cancel
                </PanelButton>
                <StyledButton
                    width={"160px"}
                    onClick={handleCreate}
                    disabled={createBtnDisabled}
                    isBlue
                    style={createBtnDisabled ? {opacity: 0.7, cursor: "not-allowed"} : {}}>
                    Create
                </StyledButton>
            </BottomBar>
        </Container>
    );
};

export default TemplatePanel;
