import * as THREE from "three";
import {Object3D} from "three";
import {HarvestBehaviorInterface, GAME_STATE, COLLISION_TYPE} from "../../types/editor";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import CameraUtils from "../../utils/CameraUtils";

import global from "../../global";
import HarvestFlashImage001 from "../assets/images/harvest-flash-001.png";
import {IInventory} from "../../api/inventory";
import {EVENTS, dispatchCustomInventoryEvent} from "../../api/inventory/inventoryEvents";
import Player from "../../player/Player";
import {ResourcesUtils} from "../../utils/ResourcesUtils";

class HarvestBehaviorUpdater implements BehaviorUpdater {
    app = global.app as Player;
    game?: GameManager;
    target: Object3D;
    behavior: HarvestBehaviorInterface;
    screenPos: {x: number; y: number} | null = null;
    camera: THREE.Camera | null = null;
    harvestIconImgSRC?: string;
    private isHarvesting: boolean = false;
    private harvestInterval: ReturnType<typeof setInterval> | null = null;
    harvestCounter: number = 0;

    constructor(target: Object3D, behavior: HarvestBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        this.harvestIconImgSRC = this.behavior.uiImage;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.camera = this.game!.scene!.userData.camera;
        this.addCollisionListener();
        CameraUtils.disableFromCameraCollision(this.target);
        if (!this.game!.scene!.userData.harvestObjects) {
            this.game!.scene!.userData.harvestObjects = [];
        }
        this.game!.scene!.userData.harvestObjects.push(this.target);
    }

    addCollisionListener() {
        this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollisionWithPlayer.bind(this),
                useBoundingBoxes: true,
                distanceThreshold: 0.25,
            },
            this.target.userData.physics && this.target.userData.physics.enabled,
        );
    }

    onCollisionWithPlayer() {
        if (this.target.userData.stopAtLocation) {
            this.game!.player!.userData.movingToTouchPoint = false;
            this.target.userData.stopAtLocation = false;
            this.screenPos = this.convertToScreenPosition();
            if (!this.isHarvesting) {
                this.startHarvesting(); 
            }
        }
    }

    startHarvesting() {
        if (this.isHarvesting) return;

        this.isHarvesting = true;
        this.harvestInterval = setInterval(() => {
            if (this.screenPos && this.harvestCounter < 5) {
                this.addImageToScreen(this.screenPos.x, this.screenPos.y);
            }
        }, 500);
    }

    stopHarvesting() {
        this.harvestCounter = 0;
        this.isHarvesting = false;
        this.target.userData.harvestObject = false;
        if (this.harvestInterval) {
            clearInterval(this.harvestInterval);
            this.harvestInterval = null;
        }
    }

    async addImageToScreen(x: number, y: number) {
        const resource = await ResourcesUtils.getRandomResourceFromObject("Forest", this.behavior.objectType);

        if (resource) {
            this.harvestIconImgSRC = ResourcesUtils.nameToResourceImage(resource.Name);

            const invObj: IInventory = {
                Amount: this.behavior.materialPerInteraction,
                UUID: resource.ID,
                Name: resource.Name,
                IsHarvesting: true,
            };

            dispatchCustomInventoryEvent(EVENTS.INVENTORY_ADD, invObj);

            const img = document.createElement("img");
            img.src = this.harvestIconImgSRC as string;
            img.style.position = "absolute";
            img.style.left = `${x}px`;
            img.style.top = `${y}px`;
            img.style.width = "100px";
            img.style.height = "100px";
            img.style.pointerEvents = "none";
            img.style.transition = "transform 1s ease-in-out, opacity 1s ease-in-out";

            //TODO add a flash effect property to behavior
            const flashImg = document.createElement("img");
            flashImg.src = HarvestFlashImage001;
            flashImg.style.position = "absolute";
            flashImg.style.left = `${x - 50}px`;
            flashImg.style.top = `${y - 50}px`;
            flashImg.style.width = "200px";
            flashImg.style.height = "200px";
            flashImg.style.pointerEvents = "none";
            flashImg.style.opacity = "1";
            flashImg.style.transform = "scale(0)";
            flashImg.style.transition = "transform 0.2s ease-out, opacity 0.5s ease-in-out";

            const randomRotation = Math.random() * 90 - 45;
            flashImg.style.transform += ` rotate(${randomRotation}deg)`;

            document.body.appendChild(flashImg);
            document.body.appendChild(img);

            setTimeout(() => {
                flashImg.style.transform = `scale(1.5) rotate(${randomRotation}deg)`;
            }, 50);

            setTimeout(() => {
                flashImg.style.transform = `scale(0.8) rotate(${randomRotation}deg)`;
                flashImg.style.opacity = "0";
                setTimeout(() => {
                    flashImg.remove();
                }, 100);
            }, 300);

            const targetX = window.innerWidth - 150;
            const targetY = window.innerHeight - 150;

            setTimeout(() => {
                img.style.transform = `translate(${targetX - x}px, ${targetY - y}px)`;
            }, 100);

            setTimeout(() => {
                img.style.opacity = "0";
            }, 700);

            setTimeout(() => {
                img.remove();
            }, 2000);

            this.harvestCounter += 1;
        }
    }

    convertToScreenPosition(): {x: number; y: number} | null {
        if (!this.camera) return null;

        const width = window.innerWidth;
        const height = window.innerHeight;

        return {x: width / 2, y: height / 2};
    }

    update(clock: THREE.Clock, delta: number): void {
        if (!this.game || !this.target || !this.game.player || this.game.state == GAME_STATE.PAUSED) {
            return;
        }

        if (!this.target.userData.isCollidingWithPlayer || this.app.isGameMenuOpen) {
            this.stopHarvesting();
        }
    }

    reset() {}
}

export default HarvestBehaviorUpdater;
