import * as THREE from "three";
import Ammo from "../assets/js/libs/ammojs3/builds/ammo";
import global from "../global";
import {ConvexGeometry} from "three/examples/jsm/geometries/ConvexGeometry.js";
import {SimplifyModifier} from "three/examples/jsm/modifiers/SimplifyModifier.js";
import {CollisionFlag, IPhysics, SphereData} from "../physics/common/types";
import {PhysicsUtil} from "./PhysicsUtil";
import PhysicsWorld from "./PhysicsWorld";
import {Mesh, Object3D, Scene} from "three";

//This is a class with convex hull examples and has not been fully developed
class ConvexHullGenerator {
    constructor() {}

    //This simplifies the vehicle body to a convex hull shape reduces vertices by 95 percent.
    createVehicleBodyConvexHullShape(
        scene: Scene,
        object: Object3D,
        ammo: any,
        physicsWorld: any,
        DISABLE_DEACTIVATION: number,
    ): void {
        if (object) {
            object.traverse((child: Object3D) => {
                if (
                    child.name.toLocaleLowerCase().includes("body") &&
                    (child as Mesh).isMesh &&
                    (child as Mesh).geometry
                ) {
                    const mesh = child as Mesh;
                    mesh.updateMatrixWorld(true);

                    const simplifyModifier = new SimplifyModifier();
                    const simplificationFactor = 0.95; // (95% reduction)
                    const simplifiedGeometry = simplifyModifier.modify(
                        mesh.geometry.clone(),
                        Math.floor(mesh.geometry.attributes.position.count * simplificationFactor),
                    );

                    const positionAttribute = simplifiedGeometry.attributes.position;
                    const worldMatrix = child.matrixWorld;

                    const worldVertices = [];
                    for (let i = 0; i < positionAttribute.count; i++) {
                        const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
                        vertex.applyMatrix4(worldMatrix);
                        worldVertices.push(vertex);
                    }

                    const boundingBox = new THREE.Box3().setFromObject(object);
                    const center = new THREE.Vector3();

                    if ((global?.app as any)?.storage?.debug) {
                        const convexGeometry = new ConvexGeometry(worldVertices);
                        const convexMaterial = new THREE.MeshBasicMaterial({
                            color: "green",
                            wireframe: false,
                            opacity: 0.5,
                            transparent: true,
                        });
                        const convexHullMesh = new THREE.Mesh(convexGeometry, convexMaterial);

                        const boundingBoxC = new THREE.Box3().setFromObject(child);
                        const centerC = boundingBox.getCenter(new THREE.Vector3());

                        convexHullMesh.position.copy(centerC).negate();
                        convexHullMesh.position.y = center.y;
                        object.add(convexHullMesh);

                        const convexGeometryWireframe = new ConvexGeometry(worldVertices);
                        const convexGeometryWireframeMaterial = new THREE.MeshBasicMaterial({
                            color: "white",
                            wireframe: true,
                            opacity: 0.5,
                            transparent: true,
                        });
                        const convexHullMeshWireframe = new THREE.Mesh(
                            convexGeometryWireframe,
                            convexGeometryWireframeMaterial,
                        );
                        convexHullMeshWireframe.position.copy(centerC).negate();
                        convexHullMeshWireframe.position.y = center.y;
                        object.add(convexHullMeshWireframe);
                    }

                    const convexHullShape = new ammo.btConvexHullShape();

                    //console.log("Model mesh count for mesh in object:____" + child.name + "  " + worldVertices.length);

                    for (let i = 0; i < worldVertices.length; i++) {
                        const vertex = worldVertices[i];
                        convexHullShape.addPoint(new ammo.btVector3(vertex.x, vertex.y + 0.25, vertex.z));
                    }

                    const transform = new ammo.btTransform();
                    transform.setIdentity();
                    const origin = new ammo.btVector3(0, 0, 0);
                    transform.setOrigin(origin);

                    const motionState = new ammo.btDefaultMotionState(transform);
                    const localInertia = new ammo.btVector3(0, 0, 0);
                    const mass = 0;
                    convexHullShape.calculateLocalInertia(mass, localInertia);

                    const rbInfo = new ammo.btRigidBodyConstructionInfo(
                        mass,
                        motionState,
                        convexHullShape,
                        localInertia,
                    );
                    const body = new ammo.btRigidBody(rbInfo);

                    physicsWorld.addRigidBody(body);
                    body.setActivationState(DISABLE_DEACTIVATION);
                }
            });
        }
    }

    createTrackBoundaryConvexHullShape(
        object: THREE.Object3D,
        track_surface: any,
        scene: THREE.Scene,
        ammo: any,
        physicsWorld: any,
        DISABLE_DEACTIVATION: number,
    ): void {
        if (object) {
            object.traverse((child: THREE.Object3D) => {
                if ((child as THREE.Mesh).isMesh && (child as THREE.Mesh).geometry && child.name === track_surface) {
                    const mesh = child as THREE.Mesh;
                    mesh.updateMatrixWorld(true);

                    const geometry = mesh.geometry as THREE.BufferGeometry;
                    const positionAttribute = geometry.attributes.position;
                    const worldMatrix = child.matrixWorld;

                    const worldVertices = [];
                    for (let i = 0; i < positionAttribute.count; i++) {
                        const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
                        vertex.applyMatrix4(worldMatrix); // Convert local to world coordinates
                        worldVertices.push(vertex);
                    }

                    if ((global?.app as any)?.storage?.debug) {
                        //const convexGeometry = new ConvexGeometry(worldVertices);
                        //const convexMaterial = new THREE.MeshBasicMaterial({
                        //    color: 'green',
                        //    wireframe: false,
                        //    opacity: 0.5,
                        //    transparent: true
                        //});
                        //const convexHullMesh = new THREE.Mesh(convexGeometry, convexMaterial);
                        //scene.add(convexHullMesh);

                        const convexGeometryWireframe = new ConvexGeometry(worldVertices);
                        const convexGeometryWireframeMaterial = new THREE.MeshBasicMaterial({
                            color: "white",
                            wireframe: true,
                            opacity: 0.25,
                            transparent: true,
                        });
                        const convexHullMeshWireframe = new THREE.Mesh(
                            convexGeometryWireframe,
                            convexGeometryWireframeMaterial,
                        );
                        scene.add(convexHullMeshWireframe);
                    }

                    const convexHullShape = new ammo.btConvexHullShape();
                    for (let i = 0; i < worldVertices.length; i++) {
                        const vertex = worldVertices[i];
                        convexHullShape.addPoint(new ammo.btVector3(vertex.x, vertex.y, vertex.z));
                    }

                    const transform = new ammo.btTransform();
                    transform.setIdentity();
                    const origin = new ammo.btVector3(0, 0, 0);
                    transform.setOrigin(origin);

                    const motionState = new ammo.btDefaultMotionState(transform);
                    const localInertia = new ammo.btVector3(0, 0, 0);
                    const mass = 0; // Static object
                    convexHullShape.calculateLocalInertia(mass, localInertia);

                    const rbInfo = new ammo.btRigidBodyConstructionInfo(
                        mass,
                        motionState,
                        convexHullShape,
                        localInertia,
                    );
                    const body = new ammo.btRigidBody(rbInfo);

                    physicsWorld.addRigidBody(body);
                    body.setActivationState(DISABLE_DEACTIVATION);
                }
            });
        }
    }

    createLandBoundaryConvexHullShape(
        object: THREE.Object3D,
        scene: THREE.Scene,
        ammo: any,
        physicsWorld: any,
        DISABLE_DEACTIVATION: number,
    ): void {
        if (object) {
            object.traverse((child: THREE.Object3D) => {
                if ((child as THREE.Mesh).isMesh && (child as THREE.Mesh).geometry) {
                    const mesh = child as THREE.Mesh;
                    mesh.updateMatrixWorld(true);

                    const geometry = mesh.geometry as THREE.BufferGeometry;
                    const positionAttribute = geometry.attributes.position;
                    const worldMatrix = child.matrixWorld;

                    const worldVertices = [];
                    for (let i = 0; i < positionAttribute.count; i++) {
                        const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
                        vertex.applyMatrix4(worldMatrix); // Convert local to world coordinates
                        worldVertices.push(vertex);
                    }

                    if ((global?.app as any)?.storage?.debug) {
                        // mesh.visible = false;

                        //const convexGeometry = new ConvexGeometry(worldVertices);
                        //const convexMaterial = new THREE.MeshBasicMaterial({
                        //    color: 'green',
                        //    wireframe: false,
                        //    opacity: 0.5,
                        //    transparent: true
                        //});
                        //const convexHullMesh = new THREE.Mesh(convexGeometry, convexMaterial);
                        //scene.add(convexHullMesh);

                        const convexGeometryWireframe = new ConvexGeometry(worldVertices);
                        const convexGeometryWireframeMaterial = new THREE.MeshBasicMaterial({
                            color: "white",
                            wireframe: true,
                            opacity: 0.5,
                            transparent: true,
                        });
                        const convexHullMeshWireframe = new THREE.Mesh(
                            convexGeometryWireframe,
                            convexGeometryWireframeMaterial,
                        );
                        scene.add(convexHullMeshWireframe);
                    }

                    const convexHullShape = new ammo.btConvexHullShape();
                    for (let i = 0; i < worldVertices.length; i++) {
                        const vertex = worldVertices[i];
                        convexHullShape.addPoint(new ammo.btVector3(vertex.x, vertex.y, vertex.z));
                    }

                    const transform = new ammo.btTransform();
                    transform.setIdentity();
                    const origin = new ammo.btVector3(0, 0, 0);
                    transform.setOrigin(origin);

                    const motionState = new ammo.btDefaultMotionState(transform);
                    const localInertia = new ammo.btVector3(0, 0, 0);
                    const mass = 0; // Static object
                    convexHullShape.calculateLocalInertia(mass, localInertia);

                    const rbInfo = new ammo.btRigidBodyConstructionInfo(
                        mass,
                        motionState,
                        convexHullShape,
                        localInertia,
                    );
                    const body = new ammo.btRigidBody(rbInfo);

                    physicsWorld.addRigidBody(body);
                    body.setActivationState(DISABLE_DEACTIVATION);
                }
            });
        }
    }

    //This generates random hill terrains. It's not fully developed but it's a good
    //start on creating custom hill terrains. You can configure it to create
    //rolling hills or steep mountains as convex hull shapes.  See top methods for mesh simplifying
    createRandomHillsConvexHull(scene: THREE.Scene, ammo: any, physicsWorld: any, DISABLE_DEACTIVATION: number) {
        const width = 300;
        const length = 300;
        const maxHeight = 10;
        const numHills = 50;

        const geometry = new THREE.PlaneGeometry(width, length, width / 3 - 1, length / 3 - 1);

        const vertices = geometry.attributes.position;
        const widthSegments = geometry.parameters.widthSegments;
        const lengthSegments = geometry.parameters.heightSegments;

        const hillPositions = [];
        for (let i = 0; i < numHills; i++) {
            const hillX = Math.random() * width - width / 2;
            const hillY = Math.random() * length - length / 2;
            const hillHeight = Math.random() * maxHeight;
            hillPositions.push({x: hillX, y: hillY, height: hillHeight});
        }

        for (let i = 0; i < vertices.count; i++) {
            const x = vertices.getX(i);
            const y = vertices.getY(i);

            let height = 0;
            for (const hill of hillPositions) {
                const distanceToHill = Math.sqrt((x - hill.x) ** 2 + (y - hill.y) ** 2);
                const hillRadius = width / 5;
                if (distanceToHill < hillRadius) {
                    height += hill.height * (1 - distanceToHill / hillRadius);
                }
            }

            vertices.setZ(i, height);
        }

        geometry.computeVertexNormals();

        const material = new THREE.MeshStandardMaterial({color: 0x888888, wireframe: true});
        const mesh = new THREE.Mesh(geometry, material);
        mesh.rotation.x = -Math.PI / 2;
        mesh.visible = false;
        scene.add(mesh);

        mesh.updateMatrixWorld(true);

        const positionAttribute = geometry.attributes.position;
        const worldMatrix = mesh.matrixWorld;

        const worldVertices = [];
        for (let i = 0; i < positionAttribute.count; i++) {
            const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
            vertex.applyMatrix4(worldMatrix); // Convert local to world coordinates
            worldVertices.push(vertex);
        }

        const convexGeometry = new ConvexGeometry(worldVertices);
        const convexMaterial = new THREE.MeshBasicMaterial({
            color: "green",
            wireframe: false,
            opacity: 1,
            transparent: true,
        });
        const convexHullMesh = new THREE.Mesh(convexGeometry, convexMaterial);
        convexHullMesh.rotation.x = -Math.PI / 2;
        scene.add(convexHullMesh);

        const convexGeometryWireframe = new ConvexGeometry(worldVertices);
        const convexGeometryWireframeMaterial = new THREE.MeshBasicMaterial({
            color: "white",
            wireframe: true,
            opacity: 0.5,
            transparent: true,
        });
        const convexHullMeshWireframe = new THREE.Mesh(convexGeometryWireframe, convexGeometryWireframeMaterial);
        convexHullMeshWireframe.rotation.x = -Math.PI / 2;
        scene.add(convexHullMeshWireframe);

        const convexHullShape = new ammo.btConvexHullShape();
        for (let i = 0; i < worldVertices.length; i++) {
            const vertex = worldVertices[i];
            convexHullShape.addPoint(new ammo.btVector3(vertex.x, vertex.y, vertex.z));
        }

        const transform = new ammo.btTransform();
        transform.setIdentity();
        const origin = new ammo.btVector3(0, -20, 0);
        transform.setOrigin(origin);

        const motionState = new ammo.btDefaultMotionState(transform);
        const localInertia = new ammo.btVector3(0, 0, 0);
        const mass = 0; // Static object
        convexHullShape.calculateLocalInertia(mass, localInertia);

        const rbInfo = new ammo.btRigidBodyConstructionInfo(mass, motionState, convexHullShape, localInertia);
        const body = new ammo.btRigidBody(rbInfo);

        physicsWorld?.addRigidBody(body);
        body.setActivationState(DISABLE_DEACTIVATION);
    }
}

export default ConvexHullGenerator;
