import {ISoundSettings} from "../../../../../types/editor";
import {FileData} from "../../types/file";
import {START_MENU_IDS} from "../HUDEditView/types";

export const getSoundsFromUI = (gameUI: any, isStartMenu: boolean): ISoundSettings[] => {
    const soundsArr: ISoundSettings[] = [];
    for (const key in gameUI) {
        const item = gameUI[key];
        if (item && typeof item !== "string") {
            // Check if it's an object and has hoverSound or clickSound
            if (item && "hoverSound" in item && item.hoverSound) {
                const sound = item.hoverSound;
                soundsArr.push({id: sound.ID, url: sound.Url, loop: false, volume: 0.1, soundType: ""});
            }
            if (item && "clickSound" in item && item.clickSound) {
                const sound = item.clickSound;
                soundsArr.push({id: sound.ID, url: sound.Url, loop: false, volume: 0.1, soundType: ""});
            }
        }
    }

    if (isStartMenu) {
        const menuMusic = gameUI[START_MENU_IDS.MENU_MUSIC] as FileData;
        if (menuMusic) {
            soundsArr.push({
                id: menuMusic.ID,
                url: menuMusic.Url,
                loop: true,
                volume: 0.1,
                soundType: "menu-background",
            });
        }
    }

    // Remove duplicates based on sound ID
    const uniqueSoundsArr = Array.from(new Map(soundsArr.map(sound => [sound.id, sound])).values());

    return uniqueSoundsArr;
};
