import {Camera, Object3D, Scene} from "three";
import {backendUrlFromPath} from "../utils/UrlUtils";
import Ajax from "../utils/Ajax";
import * as THREE from "three";
import {GenerateImageRequest} from "../types/imageGenerator";
import {ImageGeneratorUtils} from "../utils/ImageGeneratorUtils";
import {CharacterBehaviorConverter, NPCBehaviorConverter, SpriteBehaviorConverter} from "../serialization/behaviours";
import Box from "../object/geometry/Box.js";
import Player from "../player/Player";
import trimImageData from "trim-image-data";
import {toast} from "react-toastify";
import {ModelGeneratorUtils} from "../utils/ModelGeneratorUtils";
import JSZip from "jszip";
import ModelLoader from "../assets/js/loaders/ModelLoader";
import Converter from "../serialization/Converter";
import Application from "../Application";
import {GLTFExporter} from "three/examples/jsm/exporters/GLTFExporter";
import {ModelUtils} from "../utils/ModelUtils";

export enum GENERATION_STEPS {
    ENCHANCE_PROMPT = "Enchancing prompt",
    GENERATE_IMAGE = "Generating image",
    REMOVE_BACKGROUND = "Removing background",
    UPLOAD_IMAGE = "Uploading image",
    GENERATING_MODEL = "Generating model",
    ANIMATING_MODEL = "Animating model",
    UPLOADING_MODEL = "Uploading model",
    ADDING_MODEL_TO_SCENE = "Adding model to scene",
}

export enum AI_OBJECT_TYPES {
    NPC = "NPC",
    ENVIROMENT_OBJECT = "ENVIROMENT_OBJECT",
    CHARACTER = "CHARACTER",
}

export enum IMAGE_TYPES {
    CHARACTER = "Character",
    OBJECT = "Object",
    BACKDROP = "Backdrop",
    SKYBOX = "Skybox",
}

export type AIResponse = {
    name: string;
    prompt: string;
    width: number;
    height: number;
    story: string;
    type: AI_OBJECT_TYPES;
};

class AIWorldController {
    private scene: Scene;
    private player: Object3D | undefined;
    private app: Player | Application;
    private camera: Camera;
    private sceneId: string = "";
    private CHARACTERS_MODEL_ID = "model_gHafnTZ4kzGzcN2mvAFdo7BQ";
    private OBJECTS_MODEL_ID = "model_hTRC1xN4YN3mWNDRroV85eCX";
    private playerWidth = "1";
    private playerHeight = "2";
    private authToken = "";

    constructor(app: Player | Application, scene: Scene, camera: Camera, sceneId?: string, model?: Object3D) {
        this.scene = scene;
        this.player = model;
        this.sceneId = sceneId || "";
        this.app = app;
        this.camera = camera;
        this.authToken = app instanceof Player ? app.authToken : "";
        const boundingBox = this.player ? new THREE.Box3().setFromObject(this.player) : null;
        this.playerWidth = boundingBox ? (boundingBox.max.x - boundingBox.min.x).toFixed(2) : "1";
        this.playerHeight = boundingBox ? (boundingBox.max.y - boundingBox.min.y).toFixed(2) : "2";
    }

    enchancePrompt = async (prompt: string) => {
        try {
            const aiResponse = await fetch(backendUrlFromPath("/api/AI/Assistant") || "", {
                method: "POST",
                body: JSON.stringify({
                    systemContent: getEnchancePromptSystemMessage(this.playerWidth, this.playerHeight),
                    userMessage: prompt,
                }),
            });
            if (aiResponse.ok) {
                const response = await aiResponse.json();
                if (response.assistantResponse) {
                    const {assistantResponse} = response;
                    const regex = /\,(?!\s*?[\{\[\"\'\w])/g;
                    const json = assistantResponse.replace(regex, "");

                    const data = JSON.parse(json) as AIResponse;
                    return data;
                }
            } else {
                throw Error("No response from AI.");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    generateAssetFile = async (assetId: string, onError: (message?: string) => void) => {
        const assetResponse = await ImageGeneratorUtils.getAssetById(assetId);
        if (!assetResponse.asset) {
            onError("Failed to load asset.");
            return;
        }

        let trimmedFile = null;

        try {
            const file = await this.urlToFile(assetResponse.asset.url);
            trimmedFile = await this.trimImage(file);
        } catch (error) {
            onError("Failed to upload image.");
            console.error(error);
        }

        return trimmedFile;
    };

    generateAssetUrl = async (asset: {assetId?: string; assetUrl?: string}, onError?: (message?: string) => void) => {
        let url = asset.assetUrl;
        if (asset.assetId) {
            const assetResponse = await ImageGeneratorUtils.getAssetById(asset.assetId);
            if (!assetResponse.asset) {
                onError && onError("Failed to load asset.");
                return;
            }
            url = assetResponse.asset.url;
        }

        if (url) {
            try {
                const file = await this.urlToFile(url);
                const trimmedFile = await this.trimImage(file);
                url = await this.uploadImage(trimmedFile);
            } catch (error) {
                onError && onError("Failed to upload image.");
                console.error(error);
            }
        }

        return url;
    };

    urlToFile = async (url: string) => {
        const res = await fetch(url);
        const blob = await res.blob();
        return new File([blob], "image.png", {type: "image/png"});
    };

    trimImage = async (file: File) => {
        const img = await this.fileToImage(file);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) throw new Error("Canvas context not available");

        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const trimmedData = trimImageData(imageData);

        canvas.width = trimmedData.width;
        canvas.height = trimmedData.height;
        ctx.putImageData(trimmedData, 0, 0);

        return this.canvasToFile(canvas);
    };

    fileToImage = (file: File): Promise<HTMLImageElement> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = reader.result as string;
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    canvasToFile = async (canvas: HTMLCanvasElement): Promise<File> => {
        return new Promise(resolve => {
            canvas.toBlob(blob => {
                if (!blob) throw new Error("Failed to create Blob from canvas");
                resolve(new File([blob], "trimmed_image.png", {type: "image/png"}));
            }, "image/png");
        });
    };

    uploadImage = async (image: File) => {
        let url = "";
        const res = await Ajax.post({
            url: backendUrlFromPath(`/api/Upload/Upload`),
            data: {file: image},
            needAuthorization: false,
        });
        if (res?.data.Code === 200) {
            url = res.data.Data?.url;
        }
        return url;
    };

    generateModelImage = async (
        aiResponse: AIResponse,
        options: GenerateImageRequest,
        additinalPrompt: string,
        imageType: IMAGE_TYPES,
        isFile: boolean,
        onError: (message?: string) => void,
        onSuccess?: () => void,
        onProgress?: (step: GENERATION_STEPS) => void,
    ) => {
        try {
            onProgress && onProgress(GENERATION_STEPS.GENERATE_IMAGE);

            const modelId =
                aiResponse?.type === AI_OBJECT_TYPES.CHARACTER ? this.CHARACTERS_MODEL_ID : this.OBJECTS_MODEL_ID;
            let response;
            if (imageType === IMAGE_TYPES.CHARACTER || imageType === IMAGE_TYPES.OBJECT) {
                options.prompt = options.prompt + " " + additinalPrompt;
                response = await ImageGeneratorUtils.generateImage({...options, modelId: modelId});
            } else if (imageType === IMAGE_TYPES.SKYBOX) {
                response = await ImageGeneratorUtils.generateSkybox({...options, modelId: modelId});
            } else {
                response = await ImageGeneratorUtils.generateTexture({...options, modelId: modelId});
            }

            if (response.assetIds.length === 0) {
                onError();
                return;
            }
            if (imageType === IMAGE_TYPES.CHARACTER || imageType === IMAGE_TYPES.OBJECT) {
                onProgress && onProgress(GENERATION_STEPS.REMOVE_BACKGROUND);
                response = await ImageGeneratorUtils.removeImageBackground({assetId: response.assetIds[0]});
                if (response.assetIds.length === 0) {
                    onError();
                    return;
                }
            }

            onProgress && onProgress(GENERATION_STEPS.UPLOAD_IMAGE);
            if (isFile) {
                const file = await this.generateAssetFile(response.assetIds[0], onError);

                onSuccess && onSuccess();

                return {...aiResponse, file, url: ""};
            }
            const url = await this.generateAssetUrl({assetId: response.assetIds[0]}, onError);

            onSuccess && onSuccess();

            return {...aiResponse, url, file: null};
        } catch (error) {
            console.error(error);
            onError();
        }
    };

    uploadImageFor3dObjectGeneration = async (image: File, onProgress?: (step: GENERATION_STEPS) => void) => {
        try {
            onProgress && onProgress(GENERATION_STEPS.UPLOAD_IMAGE);
            const res = await ModelGeneratorUtils.uploadImage(image);
            if (res?.status !== 200) {
                toast.error("Failed to upload image.");
            }
            return res?.data.data.image_token;
        } catch (error) {
            console.error(error);
        }
    };

    generate3dObject = async (
        generationType: "text_to_model" | "image_to_model",
        prompt: string,
        url?: string,
        fileToken?: string,
        type?: AI_OBJECT_TYPES,
        onProgress?: (step: GENERATION_STEPS) => void,
    ) => {
        url = url ? backendUrlFromPath(url) : "";

        try {
            onProgress && onProgress(GENERATION_STEPS.GENERATING_MODEL);
            const res = await ModelGeneratorUtils.generateModel(generationType, prompt, url, fileToken, "detailed");
            if (res.output) {
                const task_id = res.task_id;
                const pbr_model = res.output.pbr_model;
                const rendered_image = res.output.rendered_image;
                let model = pbr_model;
                return {task_id, model, rendered_image};
            }
        } catch (error) {
            toast.error("Failed to generate model.");
            console.error(error);
        }
    };

    createPlane = (width: number, height: number, name: string, material: any) => {
        const geometry = new THREE.BoxGeometry(width, height, 0.001);
        const plane = new Box(geometry, material);
        plane.name = name;
        plane.userData.isPlane = true;
        return plane;
    };

    addObjectToScene = async (object: THREE.Object3D, is2D: boolean, width?: number, height?: number) => {
        let targetObject = object;

        if (!is2D) {
            let list: any = [];
            const converter = new (Converter as any)();
            converter.traverse(object, object.children, list, {}, true);
            const ojb = await converter.sceneAsGroupFromJson(list, {
                // @ts-ignore
                server: this.app.options.server,
            });
            targetObject = ojb.scene.children[0].clone();
        }

        this.scene.add(targetObject);

        if (width && height && !is2D) {
            const boundingBox = new THREE.Box3().setFromObject(targetObject);
            const objHeight = boundingBox.max.y - boundingBox.min.y;
            const normalizedHeight = 1 / objHeight;
            const scale = height * normalizedHeight;
            targetObject.scale.set(scale, scale, scale);
        }

        const lookAtPoint = this.getLookAtPointOnGround();

        const boundingBox = new THREE.Box3().setFromObject(targetObject);
        const playerBoundingBox = this.player ? new THREE.Box3().setFromObject(this.player) : null;

        const feetPosition = playerBoundingBox?.min.y || 0;
        const halfHeight = (boundingBox.max.y - boundingBox.min.y) / 2;

        const y = feetPosition + halfHeight;
        const x = this.player?.position.x || lookAtPoint?.x || 0;
        const z = this.player?.position.z || lookAtPoint?.z || 0;

        targetObject.position.set(x, y, z);
        const cameraPosition = this.camera.position.clone();
        cameraPosition.y = targetObject.position.y;
        targetObject.lookAt(cameraPosition);

        targetObject.updateMatrixWorld(true);

        if (this.app instanceof Player) {
            this.app.game?.registerNewObjectInGame(targetObject);
            this.app.saveAddedObjectInScene(targetObject, true);
        }
    };

    addSpriteObject = async (
        url: string,
        width: number,
        height: number,
        name: string,
        type?: AI_OBJECT_TYPES,
        onProgress?: (step: GENERATION_STEPS) => void,
    ) => {
        try {
            onProgress && onProgress(GENERATION_STEPS.GENERATING_MODEL);
            new Promise<string>((resolve, reject) => {
                new THREE.TextureLoader().load(url, async texture => {
                    const material = new THREE.MeshBasicMaterial({map: texture, transparent: true});

                    const plane = this.createPlane(width, height, name, material);

                    const behavior = SpriteBehaviorConverter.DEFAULT.getDefaultBehavior(THREE.MathUtils.generateUUID());
                    plane.userData.behaviors = [behavior];

                    if (type === AI_OBJECT_TYPES.NPC) {
                        const npcBehavior = NPCBehaviorConverter.DEFAULT.getDefaultBehavior(
                            THREE.MathUtils.generateUUID(),
                        );
                        plane.userData.behaviors.push(npcBehavior);
                    }

                    if (type === AI_OBJECT_TYPES.CHARACTER && this.app instanceof Application) {
                        const characterBehavior = CharacterBehaviorConverter.DEFAULT.getDefaultBehavior(
                            THREE.MathUtils.generateUUID(),
                        );
                        plane.userData.behaviors.push(characterBehavior);
                    }
                    await this.uploadModel(plane, name, url);
                    await this.addObjectToScene(plane, true);
                    resolve("Success");
                });
            });
        } catch (error) {
            console.error(error);
        }
    };

    addSkybox = async (url: string, name: string, onProgress?: (step: GENERATION_STEPS) => void) => {
        onProgress && onProgress(GENERATION_STEPS.GENERATING_MODEL);
        new Promise<string>((resolve, reject) => {
            new THREE.TextureLoader().load(url, async texture => {
                texture.mapping = THREE.EquirectangularReflectionMapping;
                texture.magFilter = THREE.LinearFilter;
                texture.minFilter = THREE.LinearMipMapLinearFilter;

                const geometry = new THREE.SphereGeometry(500, 60, 40);
                geometry.scale(-1, 1, 1);
                const material = new THREE.MeshBasicMaterial({map: texture});
                const skybox = new THREE.Mesh(geometry, material);
                skybox.name = name;
                await this.uploadModel(skybox, name, url);
                await this.addObjectToScene(skybox, true);
                resolve("Success");
            });
        });
    };

    addBackdrop = async (url: string, name: string, onProgress?: (step: GENERATION_STEPS) => void) => {
        onProgress && onProgress(GENERATION_STEPS.GENERATING_MODEL);
        new Promise<string>((resolve, reject) => {
            new THREE.TextureLoader().load(url, async texture => {
                const material = new THREE.MeshBasicMaterial({map: texture, side: THREE.DoubleSide});
                const plane = this.createPlane(100, 20, name, material);
                await this.uploadModel(plane, name, url);
                await this.addObjectToScene(plane, true);
                resolve("Success");
            });
        });
    };

    addModelToSceneFromServer = async (objData: any, name: string, width: number, height: number) => {
        let loader = new (ModelLoader as any)(this.app);
        let url = backendUrlFromPath(objData.Url);

        try {
            const obj: THREE.Object3D = await loader.load(url, objData, {
                camera: this.camera,
                renderer: this.app instanceof Player ? this.app.renderer : this.app.editor?.renderer,
                clearChildren: true,
            });
            if (!obj) {
                return;
            }
            obj.name = name;
            Object.assign(obj.userData, objData, {
                Server: true,
            });
            await this.addObjectToScene(obj, false, width, height);
        } catch (error) {
            toast.error("Could not load model");
            console.error(error);
        }
    };

    uploadModel = async (model: THREE.Object3D, name: string, imageUrl: string) => {
        try {
            const exporter = new GLTFExporter();

            new Promise<string>((resolve, reject) => {
                exporter.parse(
                    model,
                    async result => {
                        try {
                            let arrayBuffer = result as ArrayBuffer;
                            arrayBuffer = (await ModelUtils.compressModel(arrayBuffer, false, () => {
                                toast.warn("Could not compress model");
                            })) as ArrayBuffer;
                            const blob = new Blob([arrayBuffer], {type: "model/gltf-binary"});
                            const fileUrl = URL.createObjectURL(blob);
                            await this.uploadObjectByUrl(fileUrl, imageUrl, name);
                            resolve(fileUrl);
                        } catch (error) {
                            toast.error("Error processing model");
                            reject(error);
                        }
                    },
                    () => {},
                    //@ts-ignore
                    {trs: true, binary: true, includeCustomExtensions: true},
                );
            });
        } catch (error) {
            toast.error("Failed to upload model");
            console.error(error);
            throw error;
        }
    };

    uploadObjectByUrl = async (
        url: string,
        imageUrl: string,
        name: string,
        onProgress?: (step: GENERATION_STEPS) => void,
    ) => {
        onProgress && onProgress(GENERATION_STEPS.UPLOADING_MODEL);
        const isBlob = url.startsWith("blob:");
        const extension = isBlob ? "glb" : url.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
        const res = await fetch(url);
        const blob = await res.blob();
        const image = await this.generateAssetUrl({assetUrl: imageUrl}, () => {
            toast.error("Failed to upload image.");
        });
        const file = new File([blob], `${name}.${extension || "glb"}`, {type: "model/gltf-binary"});
        const zipper = new JSZip();

        console.log(file);

        zipper.file(file.name, file);

        try {
            const zip = await zipper.generateAsync({type: "blob"});
            const zippedFile = new File([zip], `${name}.zip`);
            const isGuest = this.app instanceof Player && !!this.authToken;
            const response = await Ajax.post({
                url: backendUrlFromPath(isGuest ? `/api/Mesh/Guest/Add` : `/api/Mesh/Add`),
                data: {
                    file: zippedFile,
                    Image: image,
                },
                msgBodyType: "multipart",
                token: isGuest ? null : this.authToken,
                needAuthorization: !isGuest,
            });

            if (response?.data.Code === 200) {
                return response.data.Data;
            } else {
                throw Error("Failed to upload model");
            }
        } catch (error) {
            toast.error(`Request failed. ${error}`);
        } finally {
            this.app.call("fetchModels");
        }
    };

    getLookAtPointOnGround() {
        const direction = new THREE.Vector3();
        this.camera.getWorldDirection(direction);

        const cameraPos = this.camera.position.clone();
        if (direction.y === 0) return null;

        const t = -cameraPos.y / direction.y;
        if (t < 0) return null;

        return new THREE.Vector3(
            cameraPos.x + t * direction.x,
            0, // y = 0
            cameraPos.z + t * direction.z,
        );
    }

    update = (delta: number) => {};

    public dispose() {}
}

export default AIWorldController;

const AI_DATA = `
  export enum AI_OBJECT_TYPES {
    NPC = "NPC",
    ENVIROMENT_OBJECT = "ENVIROMENT_OBJECT",
    CHARACTER = "CHARACTER",
  }
  export type AIResponse = {
    name: string; // name of the object or character
    width: number; // width of the object or character - with should be estimated based on the player model width
    height: number; // height of the object or character - height should be estimated based on the player model height
    story: string; // story about the object or character
    type: AI_OBJECT_TYPES; // type of the object
  };
`;

const OBJECT_GENERATION_RESPONSE_1 = `
 {
    "name": "Veylthar, the Tree of Echoes",
    "width": 5,
    "height": 10,
    "story": "In the heart of the forgotten Eldenwood, where the air hums with ancient magic, stands Veylthar, the Tree of Echoes. Legends say this tree is older than time itself, whispering the memories of the land to those who listen. Its bioluminescent leaves shimmer under the moonlight, pulsing with the stories of lost civilizations. Travelers who rest beneath its vast canopy often claim to hear faint echoes of past voices—wisdom passed down through the ages. Some believe that Veylthar chooses a guardian once every century, granting them a single leaf imbued with immense knowledge and power. Yet, only those pure of heart may receive its gift.",
    "type": "ENVIROMENT_OBJECT"
  }
`;

const OBJECT_GENERATION_RESPONSE_2 = `
{
    "name": "Old Marcos the Vendor",
    "width": 2,
    "height": 4,
    "story": "In the heart of the bustling town of Valleria, where the scent of spices and fresh bread fills the air, old Marcos the Vendor has been a familiar sight for decades. His cart, a simple yet sturdy wooden stand, carries the finest goods—juicy mangoes from the southern groves, warm bread baked by his own hands, and little wooden carvings that he whittles in his spare time.",
    "type": "NPC"
}
`;

const getEnchancePromptSystemMessage = (playerWith: string = "2", playerHeight: string = "4") => `
Your task is to generate data that will be used to generate an image and then open a Three.js object using this image.
You will be given a prompt. Your task is to determine what type of object should be created and estimate its size based on the available data.
Additionally, create a story for this object or character. The story can refer to the appearance of the character or object. Include information to generate only one object or character on a white background so that the image is adapted to remove the background. 
Width and Height of the object should be estimated based on the player model width and height. Currently, the player model width is ${playerWith} and height is ${playerHeight}.
It is very important that you respond in JSON format as shown in the examples. Response can't contain any additional fields and words like "json".

#DATA STRUCTURE
${AI_DATA}

#EXAMPLE REQUEST 1 (assuming that player model width is 2 and player model height is 4)
-Generate beautiful tree 

#EXAMPLE RESPONSE 1
${OBJECT_GENERATION_RESPONSE_1}

#EXAMPLE REQUEST 2 (assuming that player model width is 2 and player model height is 4)
-Generate image of street vendor

#EXAMPLE RESPONSE 2
${OBJECT_GENERATION_RESPONSE_2}

`;
