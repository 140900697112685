import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import ConsumableBehaviorUpdater from "./ConsumableBehaviorUpdater";
import {CONSUMABLE_TYPES, ConsumableBehaviorInterface, OBJECT_TYPES} from "../../types/editor";
import {initialCollisionSettings} from "../../editor/assets/v2/RightPanel/behaviors/helpers/collisionSettings";

class ConsumableBehaviorConverter extends BaseBehaviorConverter<ConsumableBehaviorInterface> {
    public static DEFAULT = new ConsumableBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: ConsumableBehaviorInterface): BehaviorUpdater {
        return new ConsumableBehaviorUpdater(target, behavior);
    }

    getBehavior(target: THREE.Object3D, id: string): ConsumableBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as ConsumableBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have CONSUMABLE behavior: ", target);
            return null;
        }

        return behavior;
    }

    //TODO fix these
    getDefaultBehavior(id: string): ConsumableBehaviorInterface {
        return {
            enabled: true,
            id,
            type: OBJECT_TYPES.CONSUMABLE,
            pointAmount: 200,
            timeAmount: 0,
            healthAmount: 100,
            ammoAmount: 100,
            shieldAmount: 100,
            moneyAmount: 100,
            speedAmount: 100,
            jumpAmount: 100,
            timeForEffect_jump: 10,
            timeForEffect_speed: 10,
            collisionSettings: initialCollisionSettings,
            scaleAmount: 1,
            consumableType: CONSUMABLE_TYPES.INSTANT,
        };
    }
}

export default ConsumableBehaviorConverter;
