import {useState} from "react";
import {toast} from "react-toastify";
import {ColorRing} from "react-loader-spinner";

import global from "../../../../../../../global";
import Player from "../../../../../../../player/Player";

import {Bottom, Checkbox, CheckboxWrapper, GenerateButton, InputWrapper, Prompt, Requirements} from "./Crafting.style";
import {EmptyStateText, Middle, StateWrapper} from "../Menu.style";
import {AI_OBJECT_TYPES, IMAGE_TYPES} from "../../../../../../../controls/AiWorldController";

export const Crafting = ({close}: {close: () => void}) => {
    const [prompt, setPrompt] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string>("Creating!!");
    const [is3dObject, setIs3dObject] = useState(false);
    const app = global.app as Player;
    const options = {
        numSamples: 1,
        width: "1024",
        height: "1024",
        negativePrompt: "",
        defaultPrompt: "Whole single object, front perspective, isolated against white background.",
    };

    const handleGenerateSpriteObject = async () => {
        setIsLoading(true);
        try {
            setLoadingMessage("Enhancing prompt");
            const aiRes = await app.aiWorldControl.control?.enchancePrompt(prompt);
            if (!aiRes) throw new Error("Failed to enchance prompt");

            const res = await app.aiWorldControl.control?.generateModelImage(
                aiRes,
                {
                    ...options,
                    prompt: `${prompt}`,
                },
                options.defaultPrompt,
                IMAGE_TYPES.OBJECT,
                false,
                message => {
                    setIsLoading(false);
                    close();
                    toast.error(message || "Failed to generate object image");
                },
                () => {},
                step => {
                    setLoadingMessage(step);
                },
            );

            if (res) {
                const {name, width, height, url, type} = res;
                if (url) {
                    await app.aiWorldControl.control?.addSpriteObject(
                        url,
                        width || 5,
                        height || 5,
                        name || "Generated Object",
                        type,
                        step => {
                            setLoadingMessage(step);
                        },
                    );
                }
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to generate object");
        } finally {
            setPrompt("");
            setIsLoading(false);
            setLoadingMessage("Creating!!");
            close();
        }
    };

    const handleGenerate3dModel = async () => {
        setIsLoading(true);
        try {
            setLoadingMessage("Enhancing prompt");
            const aiRes = await app.aiWorldControl.control?.enchancePrompt(prompt);
            if (!aiRes) throw new Error("Failed to enchance prompt");

            const {name, width, height, type} = aiRes;
            let url = "";
            let imageToken = "";

            const isEnvObject = type === AI_OBJECT_TYPES.ENVIROMENT_OBJECT;
            if (isEnvObject) {
                const imageRes = await app.aiWorldControl.control?.generateModelImage(
                    aiRes,
                    {
                        ...options,
                        prompt: `${prompt}`,
                    },
                    options.defaultPrompt,
                    IMAGE_TYPES.OBJECT,
                    true,
                    message => {
                        setIsLoading(false);
                        close();
                        toast.error(message || "Failed to generate object image");
                    },
                    () => {},
                    step => {
                        setLoadingMessage(step);
                    },
                );
                if (!imageRes) throw new Error("Failed to generate object image");
                url = imageRes.url;

                if (imageRes.file) {
                    imageToken = await app.aiWorldControl.control?.uploadImageFor3dObjectGeneration(
                        imageRes.file,
                        step => {
                            setLoadingMessage(step);
                        },
                    );
                }
            }

            const generationType = isEnvObject && (imageToken || url) ? "image_to_model" : "text_to_model";

            const res = await app.aiWorldControl.control?.generate3dObject(
                generationType,
                prompt,
                url,
                imageToken,
                type,
                step => {
                    setLoadingMessage(step);
                },
            );
            if (!res) throw new Error("Failed to generate model");

            const objData = await app.aiWorldControl.control?.uploadObjectByUrl(
                res.model,
                res.rendered_image,
                name || "object",
                step => {
                    setLoadingMessage(step);
                },
            );

            await app.aiWorldControl.control?.addModelToSceneFromServer(objData, name, width, height);
        } catch (error) {
            console.error(error);
            toast.error("Failed to generate object");
        } finally {
            setPrompt("");
            setIsLoading(false);
            setLoadingMessage("Creating!!");
            close();
        }
    };

    return (
        <>
            <Middle>
                <StateWrapper>
                    {isLoading && (
                        <ColorRing
                            visible
                            height="100"
                            width="100"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={["#8B6653", "#8B6653", "#8B6653", "#8B6653", "#8B6653"]}
                        />
                    )}
                    <EmptyStateText>
                        {isLoading ? `${loadingMessage}` : "Come back later for saved recipes"}
                    </EmptyStateText>
                </StateWrapper>
            </Middle>
            <Bottom>
                <InputWrapper>
                    <Prompt
                        $loading={isLoading}
                        placeholder="What do you want to build?"
                        value={prompt}
                        onChange={e => setPrompt(e.target.value)}
                        readOnly={isLoading}
                    />
                    <GenerateButton
                        disabled={!prompt || isLoading}
                        onClick={is3dObject ? handleGenerate3dModel : handleGenerateSpriteObject}>
                        CREATE
                    </GenerateButton>
                </InputWrapper>
                <CheckboxWrapper>
                    <Checkbox
                        id="check1"
                        type="checkbox"
                        checked={is3dObject}
                        disabled={isLoading}
                        onChange={() => setIs3dObject(!is3dObject)}
                    />
                    <label htmlFor="check1">Generate 3D object</label>
                </CheckboxWrapper>

                <Requirements>Crafting requires 3 wood</Requirements>
            </Bottom>
        </>
    );
};
