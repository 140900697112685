import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import HarvestBehaviorUpdater from "./HarvestBehaviorUpdater";
import {HarvestBehaviorInterface, HARVEST_TYPES, RESPAWN_TYPES, OBJECT_TYPES} from "../../types/editor";
import {initialCollisionSettings} from "../../editor/assets/v2/RightPanel/behaviors/helpers/collisionSettings";
import {ResourcesUtils} from "../../utils/ResourcesUtils";

class HarvestBehaviorConverter extends BaseBehaviorConverter<HarvestBehaviorInterface> {
    biome = "Forest";
    defaultObjectType = "Pine Tree";
    public static DEFAULT = new HarvestBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
        this.initDefaultObject();
    }

    async initDefaultObject() {
        const objects = await ResourcesUtils.getBiomeObjects(this.biome, "");
        if (objects) {
            this.defaultObjectType = objects[0].Name;
        }
    }

    convert(target: THREE.Object3D, behavior: HarvestBehaviorInterface): BehaviorUpdater {
        return new HarvestBehaviorUpdater(target, behavior);
    }

    getBehavior(target: THREE.Object3D, id: string): HarvestBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as HarvestBehaviorInterface;
        return behavior;
    }

    getDefaultBehavior(id: string): HarvestBehaviorInterface {
        return {
            type: OBJECT_TYPES.HARVEST,
            harvestInitiatorType: HARVEST_TYPES.HIT,
            enabled: true,
            id,
            uiImage: undefined,
            materialPerInteraction: 25,
            timesBeforeDestroyed: 10,
            respawnType: RESPAWN_TYPES.ONCE,
            objectType: this.defaultObjectType,
        };
    }
}

export default HarvestBehaviorConverter;
