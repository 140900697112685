import React, {useEffect, useRef, useState} from "react";
import {useMediaQuery, useOnClickOutside} from "usehooks-ts";
import mapIcon from "./assets/map-icon.svg";
import {IconComponent} from "../../../common/HUDIcon";
import global from "../../../../../../global";
import {isInputActive} from "../../../utils/isInputActive";
import {DraggableMapContainer, MapCell, MapGrid, Title} from "./Map.style";
import Player from "../../../../../../player/Player";
import {HUD_ITEM} from "../HUDView";

interface Props {
    setNoPauseMenu: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<HUD_ITEM | null>>;
}

export const Map = ({setNoPauseMenu, isOpen, setIsOpen}: Props) => {
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key.toLocaleLowerCase() === "m" && !isInputActive()) {
            setIsOpen(prev => (prev === HUD_ITEM.MAP ? null : HUD_ITEM.MAP));
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <>
            <IconComponent
                $left
                style={{left: "140px"}}
                $active={isOpen}
                onClick={() => {
                    setIsOpen(prev => (prev === HUD_ITEM.MAP ? null : HUD_ITEM.MAP));
                }}>
                <img src={mapIcon} alt="" />
            </IconComponent>
            {isOpen && <MapRenderer closeMap={() => setIsOpen(null)} />}
        </>
    );
};

const columns = 20;
const rows = 20;
const ALL_TILES = rows * columns - 3;
const TILE_SIZE = 120;
const TILE_SIZE_MOBILE = 120;

const centerRow = Math.floor(rows / 2) - 1;
const centerColumn = Math.floor(columns / 2) - 1;

const centerIndexSpan = centerRow * columns + centerColumn;

const MapRenderer = ({closeMap}: {closeMap: () => void}) => {
    const app = global.app as Player;
    const isTablet = useMediaQuery("(max-width: 767px)");

    const [offset, setOffset] = useState({x: 0, y: 0});
    const [isDragging, setIsDragging] = useState(false);
    const [start, setStart] = useState({x: 0, y: 0});
    const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);
    const [dragDistance, setDragDistance] = useState(0);
    const mapContainerRef = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(mapContainerRef, () => {
        closeMap();
    });

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setStart({x: e.clientX, y: e.clientY});
        setDragDistance(0);
        if (clickTimeout) {
            clearTimeout(clickTimeout);
            setClickTimeout(null);
        }
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (isDragging) {
            const dx = e.clientX - start.x;
            const dy = e.clientY - start.y;
            setOffset(prev => ({
                x: prev.x + dx,
                y: prev.y + dy,
            }));
            setStart({x: e.clientX, y: e.clientY});
            setDragDistance(prev => prev + Math.sqrt(dx * dx + dy * dy));
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);

        setClickTimeout(
            setTimeout(() => {
                setClickTimeout(null);
            }, 200),
        );
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        setIsDragging(true);
        setStart({
            x: e.touches[0].clientX,
            y: e.touches[0].clientY,
        });
        setDragDistance(0);

        if (clickTimeout) {
            clearTimeout(clickTimeout);
            setClickTimeout(null);
        }
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (isDragging) {
            const dx = e.touches[0].clientX - start.x;
            const dy = e.touches[0].clientY - start.y;
            setOffset(prev => ({
                x: prev.x + dx,
                y: prev.y + dy,
            }));
            setStart({
                x: e.touches[0].clientX,
                y: e.touches[0].clientY,
            });

            setDragDistance(prev => prev + Math.sqrt(dx * dx + dy * dy));
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);

        setClickTimeout(
            setTimeout(() => {
                setClickTimeout(null);
            }, 200),
        );
    };

    const handleMapCellClick = (e: React.MouseEvent, index: number) => {
        if (dragDistance < 10 && !isDragging) {
            console.log(`Clicked on map cell ${index}`);
        }
    };

    const randomAvailableIndexes = (i: number) => {
        return (
            (i > ALL_TILES - 7 && i < ALL_TILES - 3) ||
            i < 5 ||
            i === 22 ||
            i === 34 ||
            i === 35 ||
            i === 53 ||
            i === 59 ||
            i === 85 ||
            i === 86 ||
            i === 87 ||
            i === 117 ||
            i === 187 ||
            i === 203 ||
            i === 207 ||
            i === 230 ||
            i === 231 ||
            i === 251 ||
            i === 175 ||
            i === 194
        );
    };

    const centerMap = () => {
        if (mapContainerRef.current) {
            const mapWidth = mapContainerRef.current.offsetWidth;
            const mapHeight = mapContainerRef.current.offsetHeight;

            const centerOffsetX = centerColumn * TILE_SIZE - mapWidth / (columns / (isTablet ? 0 : 6));
            const centerOffsetY = centerRow * TILE_SIZE - mapHeight / (rows / 4);

            setOffset({x: -centerOffsetX, y: -centerOffsetY});
        }
    };

    useEffect(() => {
        centerMap();

        if (!app.isSpriteCharacter && app.isGameMenuOpen) {
            document.exitPointerLock();
            window.addEventListener("resize", centerMap);
            return () => {
                document.body.requestPointerLock();
                window.removeEventListener("resize", centerMap);
            };
        }
    }, []);

    return (
        <DraggableMapContainer
            ref={mapContainerRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}>
            <Title>World Map</Title>
            <MapGrid
                $cellSize={isTablet ? TILE_SIZE_MOBILE : TILE_SIZE}
                $columns={columns}
                $rows={rows}
                style={{
                    transform: `translate(${offset.x}px, ${offset.y}px)`,
                }}>
                {Array.from({length: ALL_TILES}).map((_, i) => {
                    if (i === centerIndexSpan) {
                        return (
                            <MapCell
                                key={i}
                                className="center"
                                $available={false}
                                onClick={e => handleMapCellClick(e, i)}>
                                Erth Corp
                            </MapCell>
                        );
                    }
                    return (
                        <MapCell key={i} $available={randomAvailableIndexes(i)} onClick={e => handleMapCellClick(e, i)}>
                            {randomAvailableIndexes(i) ? "Available" : `Map ${i}`}
                        </MapCell>
                    );
                })}
            </MapGrid>
        </DraggableMapContainer>
    );
};
