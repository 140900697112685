import {useEffect, useState} from "react";
import styled from "styled-components";
import {HUD_TABS} from "../HUDEditView/types";
import {GameStartView} from "./GameStartView/GameStartView";
import {GameHUDView} from "./GameHUDView/GameHUDView";
import GameManager from "../../../../../behaviors/game/GameManager";
import {InGameData} from "./types";
import {InGameView} from "./InGameView/InGameView";
import global from "../../../../../global";
import {NewPauseMenu} from "./NewPauseMenu";
import EventBus from "../../../../../behaviors/event/EventBus";
import {Ai16zChat} from "./Ai16zChat/Ai16zChat";
import Player from "../../../../../player/Player";
import {Map} from "./Map/Map";
import {Menu} from "./Menu/Menu";
import {Playcoin} from "./Playcoin/Playcoin";
import {ToastContainer} from "react-toastify";
import {isInputActive} from "../../utils/isInputActive";
import {Inventory} from "./Inventory/Inventory";

export enum HUD_ITEM {
    MENU = "menu",
    MAP = "map",
    INVENTORY = "inventory",
}

type Props = {
    emptyHUD: boolean;
    renderStopButton: boolean;
};
export const HUDView = ({emptyHUD, renderStopButton}: Props) => {
    const app = (global as any).app;
    const url = new URL(window.self.location.href);
    const ai16z = url.searchParams.get("ai16z");
    const metaverse = url.searchParams.get("metaverse");
    const isOtherExperience = !!ai16z || !!metaverse;
    const agents = (app as Player).aiNpcControl.control?.aiAgents;
    const showChat = !!agents?.find(el => el.behavior.show_text_chat === true);

    const [view, setView] = useState<HUD_TABS | null>(HUD_TABS.GAME_START_MENU);
    const [noPauseMenu, setNoPauseMenu] = useState<boolean>(false);
    const [hudOpenedItem, setHudOpenedItem] = useState<HUD_ITEM | null>(null);
    const [bgImage, setBgImage] = useState<string>();
    const [otherExperiencePause, setOtherExperiencePause] = useState(false);
    const [gameData, setGameData] = useState<InGameData>({
        score: 0,
        maxScore: 0,
        currentLives: 0,
        totalLives: 0,
        isWinner: false,
        timeRemaining: "00:00:00",
        playerWeapons: [],
        pickedWeaponOrItem: null,
    });

    useEffect(() => {
        const update = (game: GameManager) => {
            const score = game.score;
            const currentLives = game.lives;
            setGameData(prev => ({
                score,
                currentLives,
                totalLives: prev.totalLives,
                maxScore: prev.maxScore,
                isWinner: game.isWinner(),
                timeRemaining: prev.timeRemaining,
                playerWeapons: game.playerWeapons,
                pickedWeaponOrItem: game.pickedWeaponOrItem,
            }));
        };
        app.on("gameUpdated.HUDView", update);
        return () => {
            app.on("gameUpdated.HUDView", null);
        };
    }, []);

    useEffect(() => {
        const setGameDataValues = (game: GameManager) => {
            const score = game.score;
            const maxScore = game.maxScore;
            const currentLives = game.lives;
            const totalLives = game.initialLives;
            const isWinner = game.isWinner();
            const timeRemaining = game.time_remaining || "00:00:00";
            const playerWeapons = game.playerWeapons;
            const pickedWeaponOrItem = game.pickedWeaponOrItem;
            setGameData({
                score,
                maxScore,
                currentLives,
                totalLives,
                isWinner,
                timeRemaining,
                playerWeapons,
                pickedWeaponOrItem,
            });
        };
        app.on("gameCreated.HUDView", (game: GameManager) => {
            setGameDataValues(game);
        });
        app.on("gameStarted.HUDView", (game: GameManager) => {
            setGameDataValues(game);
            setView(null);
        });
        app.on("gameTimerUpdate", setGameDataValues);
        return () => {
            app.on("gameCreated.HUDView", null);
            app.on("gameStarted.HUDView", null);
            app.on("gameTimerUpdate", null);
        };
    }, []);

    useEffect(() => {
        const restart = (game: GameManager) => {
            if (game?.isGameOver()) {
                setView(HUD_TABS.GAME_HUD);
                if (!isOtherExperience) {
                    setTimeout(() => {
                        setView(HUD_TABS.GAME_START_MENU);
                    }, 5000);
                }
                return;
            }

            if (!noPauseMenu) {
                setView(HUD_TABS.IN_GAME_MENU);
            } else {
                EventBus.instance.send("game.resume");
            }
        };
        const lockState = () => {
            setView(null);
        };
        app.on("pauseGame.HUDView", restart);
        app.on("lockEvent.HUDView", lockState);

        return () => {
            app.on("pauseGame.HUDView", null);
            app.on("lockEvent.HUDView", null);
        };
    }, [noPauseMenu]);

    useEffect(() => {
        if (view === null) {
            app.call(`playingGame`);
        } else {
            app.call(`stoppedPlayingGame`);
        }

        if (view === HUD_TABS.IN_GAME_MENU && isOtherExperience) {
            setBgImage(undefined);
            setOtherExperiencePause(true);
        } else {
            setOtherExperiencePause(false);
        }
    }, [view]);

    useEffect(() => {
        if (isOtherExperience) {
            const start = () => {
                EventBus.instance.send("game.start");
                EventBus.instance.send("game.clear_sounds");
                setView(null);
            };

            document.addEventListener("click", start, {once: true});
        }
    }, []);

    useEffect(() => {
        app.isGameMenuOpen = !!hudOpenedItem;
    }, [hudOpenedItem]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key.toLocaleLowerCase() === "i" && !isInputActive()) {
                setHudOpenedItem(null);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [hudOpenedItem]);

    return (
        <>
            <ToastContainer position="top-center" theme="dark" />
            <Wrapper
                $bgImage={
                    !emptyHUD && view !== HUD_TABS.GAME_HUD && view !== null && !noPauseMenu ? bgImage : undefined
                }
                $bgColor={!emptyHUD && view !== null && !otherExperiencePause && !noPauseMenu}
                $disableClick={view === HUD_TABS.GAME_HUD}
                id="hud-wrapper">
                {view === HUD_TABS.GAME_START_MENU &&
                    (isOtherExperience ? null : (
                        <GameStartView
                            setView={setView}
                            setBgImage={setBgImage}
                            emptyHUD={emptyHUD}
                            renderStopButton={renderStopButton}
                        />
                    ))}
                {view === HUD_TABS.IN_GAME_MENU && !isOtherExperience && !noPauseMenu && (
                    <InGameView
                        setView={setView}
                        setBgImage={setBgImage}
                        emptyHUD={emptyHUD}
                        renderStopButton={renderStopButton}
                    />
                )}

                {(view === null ||
                    view === HUD_TABS.GAME_HUD ||
                    (isOtherExperience && view === HUD_TABS.IN_GAME_MENU)) && (
                    <>
                        {showChat && <Ai16zChat />}
                        {isOtherExperience ? (
                            <NewPauseMenu setView={setView} gameData={gameData} />
                        ) : (
                            <GameHUDView
                                gameData={gameData}
                                isGameOver={view === HUD_TABS.GAME_HUD}
                                emptyHUD={emptyHUD}
                            />
                        )}
                    </>
                )}
            </Wrapper>
            {!showChat && !isOtherExperience && view !== HUD_TABS.GAME_START_MENU && (
                <>
                    <Menu
                        setNoPauseMenu={setNoPauseMenu}
                        isOpen={hudOpenedItem === HUD_ITEM.MENU}
                        setIsOpen={setHudOpenedItem}
                    />
                    <Map
                        setNoPauseMenu={setNoPauseMenu}
                        isOpen={hudOpenedItem === HUD_ITEM.MAP}
                        setIsOpen={setHudOpenedItem}
                    />
                    <Inventory
                        isOpen={hudOpenedItem === HUD_ITEM.INVENTORY}
                        setIsOpen={setHudOpenedItem}
                        // setNoPauseMenu={setNoPauseMenu}
                    />
                    <Playcoin />
                </>
            )}
        </>
    );
};

const Wrapper = styled.div<{
    $bgImage?: string;
    $bgColor?: boolean;
    $disableClick?: boolean;
}>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    box-sizing: border-box;
    ${({$bgColor}) => $bgColor && `background: #000000bf;`}

    ${({$bgImage}) =>
        $bgImage &&
        `
  background-image: url('${$bgImage}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  `}

  ${({$disableClick, $bgImage}) =>
        ($disableClick || !$bgImage) &&
        `
  pointer-events: none;
  `}
`;
