import * as THREE from 'three';
import { MuzzleFlash } from './MuzzleFlash';
import { LaserEffect } from './LaserEffect';
import { FootDust } from './FootDust'; // Import the FootDust class
import { Camera, Scene } from "three";

export class EffectsManager {
    private scene: THREE.Scene;
    private camera: THREE.Camera;
    private static instance: EffectsManager | null = null;
    private muzzleFlashEffect: any;
    private laserEffect: any;
    private footDustEffect: FootDust | null = null; // Add footDustEffect

    constructor(scene: THREE.Scene, camera: THREE.Camera) {
        this.scene = scene;
        this.camera = camera;

        this.muzzleFlashEffect = MuzzleFlash.reset(scene, camera);
        this.laserEffect = LaserEffect.reset(scene);
        this.footDustEffect = new FootDust(scene); // Initialize FootDust
    }

    // Reset the singleton instance each time play is pressed
    // to help keep memory and objects optimized
    public static reset(
        scene: Scene,
        camera: Camera,
    ): EffectsManager {
        EffectsManager.instance = new EffectsManager(
            scene,
            camera
        );
        return EffectsManager.instance;
    }

    private createMuzzleFlash(position: THREE.Vector3, camera: THREE.Camera) {
        this.muzzleFlashEffect.createMuzzleFlashEffect(position, camera);
    }

    private createThrowableLaserEffect(particlesPerFrame = 1, particlesPerPosition = 20, throwable: THREE.Mesh[]) {
        this.laserEffect.createThrowableLaserEffect(particlesPerFrame, particlesPerPosition, throwable);
    }

    // Trigger the FootDust effect
    public createFootDustEffect(playerPostion: THREE.Vector3, particleCount: number, fadeOutDuration: number, color: THREE.Color, size: number, opacity: number, playerSize: number) {
        if (this.footDustEffect) {
            this.footDustEffect.createParticles(playerPostion, particleCount, fadeOutDuration, color, size, opacity, playerSize);
        }
    }

    dispose() {
        if (this.muzzleFlashEffect) {
            this.muzzleFlashEffect = null!;
        }
        if (this.laserEffect) {
            this.laserEffect = null!;
        }
        if (this.footDustEffect) {
            this.laserEffect = null!;
        }
        if (this.footDustEffect) {
            this.footDustEffect.dispose(); // Dispose of FootDust resources
            this.footDustEffect = null;
        }

        this.scene = null!;
        this.camera = null!;

        EffectsManager.instance = null;
    }
}
