import styled from "styled-components";
import {flexCenter} from "../../../../../../assets/style";

export const Tabs = styled.div`
    padding: 8px;
    border-bottom: 1px solid var(--theme-container-divider);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
`;

export const Tab = styled.div<{$selected?: boolean}>`
    color: #e4e4e7;
    width: 100%;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    background: ${({$selected}) => ($selected ? "var(--theme-grey-bg)" : "transparent")};
    ${flexCenter};
    cursor: pointer;
    height: 32px;
`;

export const TypesWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const GenerationType = styled.div`
    ${flexCenter};
    flex-direction: column;
    gap: 8px;
    color: #fafafa;
    font-size: 12px;
    font-weight: 500;
`;

export const TypeImageWrapper = styled.div<{$isSelected?: boolean}>`
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background: #27272a;
    cursor: pointer;
    ${({$isSelected}) => `
        border: 2px solid ${$isSelected ? "#0284C7" : "transparent"};
    `};
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    padding-top: 4px;
`;

export const List = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    overflow-y: auto;
    min-height: 142px;
`;

export const ListItem = styled.div`
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 142px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const Menu = styled.div`
    width: 74px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--theme-grey-bg-secondary-button);
    border-radius: 8px;
    padding: 4px 0px;
    z-index: 1;
`;

export const MenuItem = styled.div<{$isDelete?: boolean}>`
    padding: 4px 8px;
    cursor: pointer;
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-bold);
    color: white;
    cursor: pointer;
    &:hover {
        background: var(--theme-grey-bg);
    }

    ${({$isDelete}) =>
        $isDelete &&
        `
          color: red;
    `}
`;

export const Row = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
`;

export const RowTitle = styled.div`
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-regular);
    color: var(--theme-font-unselected-color);
    line-height: 120%;
    text-align: left;
    white-space: nowrap;
`;

export const RowBox = styled.div`
    ${flexCenter};
    width: 100px;
`;

export const Expand = styled.img<{$isExpanded: boolean}>`
    transform: ${({$isExpanded}) => ($isExpanded ? "rotate(0deg)" : "rotate(180deg)")};
`;
