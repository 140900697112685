import * as THREE from "three";
import {Object3D} from "three";
import {OBJECT_TYPES, COLLISION_TYPE, ConsumableBehaviorInterface, CONSUMABLE_TYPES} from "../../types/editor";
import GameManager from "../../behaviors/game/GameManager";
import {PhysicsUtil} from "../../physics/PhysicsUtil";
import EventBus from "../../behaviors/event/EventBus";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import RangeDetector from "../../behaviors/range/RangeDetector";
import CameraUtils from "../../utils/CameraUtils";
import {IDeleteFromInventoryProps, IInventory} from "../../api/inventory";
import {EVENTS, dispatchCustomInventoryEvent} from "../../api/inventory/inventoryEvents";

export default class consumableBehaviorUpdaterBase implements BehaviorUpdater {
    private game?: GameManager;
    public target: Object3D;
    private usingPhysics: boolean = false;
    private isDynamic: boolean = false;
    private behavior: ConsumableBehaviorInterface;
    private removed: boolean = false;
    private currentIndex: number = 0;
    private isKeyListenerAdded: boolean = false;
    private currentSelectedObject: Object3D | null = null;
    private rangeDetector: RangeDetector;

    constructor(target: Object3D, behavior: ConsumableBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        this.rangeDetector = null as unknown as RangeDetector;
        this.target.userData.isInventoryItem = false;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.usingPhysics = PhysicsUtil.isPhysicsEnabled(this.target);
        this.isDynamic = PhysicsUtil.isDynamicObject(this.target);
        this.addCollisionListener();
        this.addKeyListener(); //TODO add new key bindings from input provider
        if (gameManager.scene) {
            this.rangeDetector = new RangeDetector(gameManager);
        }
        this.addRangeDetector();
        CameraUtils.disableFromCameraCollision(this.target);
    }

    addCollisionListener() {
        let collisionType = this.getCollisionType();
        if (collisionType !== COLLISION_TYPE.UNKNOWN) {
            this.game!.behaviorManager?.collisionDetector.addListener(
                this.target,
                {
                    type: collisionType,
                    callback: this.onCollision.bind(this),
                    useBoundingBoxes: this.behavior.useBoundingBoxes,
                },
                this.isDynamic,
            );
        } else {
            console.warn("Collision type is not specified for " + this.target.name);
        }
    }

    getCollisionType(): COLLISION_TYPE {
        if (!this.behavior.collisionSettings) return COLLISION_TYPE.UNKNOWN;
        return this.behavior.collisionSettings.playerCollision
            ? COLLISION_TYPE.WITH_PLAYER
            : this.behavior.collisionSettings.throwableCollision
              ? COLLISION_TYPE.WITH_COLLIDABLE_OBJECTS
              : this.behavior.collisionSettings.enemyCollision
                ? COLLISION_TYPE.WITH_ENEMY
                : COLLISION_TYPE.UNKNOWN;
    }

    updateGameState() {
        if (this.behavior.healthAmount > 0) {
            EventBus.instance.send("game.lives.inc", this.behavior.healthAmount);
        }
        if (this.behavior.pointAmount > 0) {
            EventBus.instance.send("game.score.inc", this.behavior.pointAmount);
        }
        if (this.behavior.timeAmount > 0) {
            EventBus.instance.send("game.time.inc", this.behavior.timeAmount);
        }
    }

    addKeyListener() {
        if (this.isKeyListenerAdded) return;

        //TODO add new key bindings from input provider
        // Key 'F' removes the selected object
        document.body.addEventListener("keydown", event => {
            if (event.key.toLowerCase() === "f") {
                this.removeCollectableFromInventory();
            }
        });

        //TODO add new key bindings from input provider
        // Key 'E' allows collection for 'Button Press' consumables
        document.body.addEventListener("keydown", event => {
            if (event.key.toLowerCase() === "e") {
                if (this.target && this.target.userData.behaviors) {
                    const consumableBehavior = this.target.userData.behaviors.find(
                        (behavior: any) => behavior.type === OBJECT_TYPES.CONSUMABLE,
                    );

                    if (consumableBehavior && consumableBehavior.consumableType === CONSUMABLE_TYPES.BUTTON_PRESS) {
                        this.target.userData.isButtonPressAllowed = true;
                    }
                }
            }
        });

        //Reset key press event
        document.body.addEventListener("keyup", event => {
            this.target.userData.isButtonPressAllowed = false;
        });

        this.isKeyListenerAdded = true;
    }

    onCollision() {
        if (!this.target || !this.target.userData || !this.target.userData.behaviors) return;

        const consumableBehavior = this.target.userData.behaviors.find(
            (behavior: any) => behavior.type === OBJECT_TYPES.CONSUMABLE,
        );

        if (consumableBehavior && consumableBehavior.consumableType === CONSUMABLE_TYPES.BUTTON_PRESS) {
            if (!this.target.userData.isButtonPressAllowed) {
                return;
            }
        }

        if (this.target.userData.isCollected) {
            if (this.target.userData.isDropped) {
                this.target.userData.isDropped = false;
            }
            return;
        }

        this.target.userData.isCollected = true;
        this.target.userData.isInventoryItem = true;

        // This will save item in inventory backend and trigger UI refresh
        const invObj: IInventory = {Amount: 1, UUID: this.target.uuid, Name: this.target.name};
        dispatchCustomInventoryEvent(EVENTS.INVENTORY_ADD, invObj);

        this.target.visible = false;
    }

    addRangeDetector() {
        this.rangeDetector.setPlayer(this.game!.player!);
        this.rangeDetector.addTargets(this.target);
    }

    removeCollectableFromInventory() {
        if (!this.currentSelectedObject) {
            return;
        }

        const object = this.currentSelectedObject;

        if (object.userData.originalPosition) {
            object.position.copy(object.userData.originalPosition);
        }
        object.userData.isDropped = true;
        object.userData.isCollected = false;
        this.target.userData.isInventoryItem = false;
        const invObj: IDeleteFromInventoryProps = {AmountToRemove: 1, InventoryItemUUID: this.target.uuid};
        dispatchCustomInventoryEvent(EVENTS.INVENTORY_DELETE, invObj);
        object.visible = true;

        this.currentSelectedObject = null;
    }

    update(clock: THREE.Clock, delta: number) {
        if (this.target.userData.isCollected) {
            if (this.target.userData.isDropped) {
                this.target.position.copy(this.target.userData.originalPosition);
            } else {
                this.target.position.copy(this.game!.player!.position);
                const offset = new THREE.Vector3(0, 0, 1);
                offset.applyQuaternion(this.game!.player!.quaternion);
                this.target.position.add(offset);
            }
        }
        this.rangeDetector.update();
    }

    reset() {
        if (this.removed) {
            if (this.usingPhysics) {
                this.game!.app.addPhysicsObject(this.target);
            } else {
                this.game!.scene?.add(this.target);
            }
            this.addCollisionListener();
            this.removed = false;
        }
    }
}
