/* eslint-disable react/no-multi-comp */
import {useEffect, useState} from "react";

import {isInputActive} from "../../../utils/isInputActive";
import EventBus from "../../../../../../behaviors/event/EventBus";

import menuIcon from "./assets/menu-icon.svg";
import tasksIcon from "./assets/tasks.svg";
import craftingIcon from "./assets/crafting.svg";
import npcIcon from "./assets/npc.svg";
import youIcon from "./assets/you.svg";

import {IconComponent} from "../../../common/HUDIcon";
import {Crafting} from "./Crafting/Crafting";
import {Tasks} from "./Tasks/Tasks";
import {MenuItem, Modal, Top} from "./Menu.style";
import {NPCitizens} from "./NPCitizens/NPCitizens";
import {You} from "./You/You";
import global from "../../../../../../global";
import Player from "../../../../../../player/Player";
import {HUD_ITEM} from "../HUDView";

enum PAGE_LABELS {
    TASKS = "Tasks",
    CRAFTING = "Crafting",
    NPC = "NPCitizens",
    YOU = "You",
}

const MENU_ITEMS = [
    {label: PAGE_LABELS.TASKS, icon: tasksIcon},
    {label: PAGE_LABELS.CRAFTING, icon: craftingIcon},
    {label: PAGE_LABELS.NPC, icon: npcIcon},
    {label: PAGE_LABELS.YOU, icon: youIcon},
];

interface Props {
    setNoPauseMenu: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<HUD_ITEM | null>>;
}

export const Menu = ({setNoPauseMenu, isOpen, setIsOpen}: Props) => {
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key.toLocaleLowerCase() === "o" && !isInputActive()) {
            setIsOpen(prev => (prev === HUD_ITEM.MENU ? null : HUD_ITEM.MENU));
        }

        if (event.key === "Escape") {
            EventBus.instance.send("game.resume");
            EventBus.instance.send("game.clear_sounds");
            document.body.requestPointerLock();
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <>
            <IconComponent
                $left
                $active={isOpen}
                onClick={() => setIsOpen(prev => (prev === HUD_ITEM.MENU ? null : HUD_ITEM.MENU))}>
                <img src={menuIcon} alt="" />
            </IconComponent>
            {isOpen && <MenuRenderer close={() => setIsOpen(null)} setNoPauseMenu={setNoPauseMenu} />}
        </>
    );
};

const MenuRenderer = ({
    close,
    setNoPauseMenu,
}: {
    close: () => void; 
    setNoPauseMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const app = global.app as Player;
    const [activePage, setActivePage] = useState<PAGE_LABELS>(PAGE_LABELS.CRAFTING);

    useEffect(() => {
        if (!app.isSpriteCharacter && app.isGameMenuOpen) {
            setNoPauseMenu(true);
            document.exitPointerLock();

            return () => {
                setNoPauseMenu(false);
                document.body.requestPointerLock();
            };
        }
    }, []);

    return (
        <Modal>
            <Top>
                {MENU_ITEMS.map(({label, icon}) => (
                    <MenuItem $active={activePage === label} onClick={() => setActivePage(label)} key={label}>
                        <img src={icon} alt="" />
                        {label === activePage && <div className="label">{label}</div>}
                    </MenuItem>
                ))}
            </Top>
            {activePage === PAGE_LABELS.CRAFTING && <Crafting close={close} />}
            {activePage === PAGE_LABELS.TASKS && <Tasks />}
            {activePage === PAGE_LABELS.NPC && <NPCitizens />}
            {activePage === PAGE_LABELS.YOU && <You />}
        </Modal>
    );
};
