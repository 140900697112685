import styled, {css} from "styled-components";
import {InventoryItem} from "../Inventory/Inventory.style";
import {flexCenter} from "../../../../../../assets/style";

export const MapIconComponent = styled(InventoryItem)`
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 1;
    width: 120px;

    ${flexCenter};
    flex-direction: column;
    row-gap: 12px;

    @media (max-width: 767px) {
        width: 90px;
    }
`;

export const MapGrid = styled.div<{$rows: number; $columns: number; $cellSize: number}>`
    display: grid;
    grid-template-columns: repeat(${({$columns}) => $columns}, ${({$cellSize}) => `${$cellSize}px`});
    grid-template-rows: repeat(${({$rows}) => $rows}, ${({$cellSize}) => `${$cellSize}px`});
    gap: 8px;
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: center;
    position: relative;
`;

export const MapCell = styled.div<{$available: boolean}>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 16px;
    background-color: ${({$available}) => ($available ? "#EFEAE166" : "#EFEAE1")};

    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: -0.011em;
    text-align: center;
    color: #000;
    text-transform: uppercase;

    cursor: grab;

    &.center {
        grid-column: span 2;
        grid-row: span 2;
    }
`;

export const DraggableMapContainer = styled.div`
    position: fixed;
    top: calc(50% - 16px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 24px - 24px);
    height: calc(100vh - 122px - 122px);
    background-color: #d4cbb8;
    border-radius: 16px;

    overflow: hidden;
    cursor: grab;
    z-index: 99999;
    pointer-events: all;
`;

export const Title = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #8b6653;
    z-index: 3;
    height: 52px;
    padding: 16px;

    font-family: Lilita One;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.011em;
    text-align: left;
    color: #fff;
`;
