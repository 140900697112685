import {AvatarCreator, AvatarCreatorConfig, AvatarExportedEvent} from "@readyplayerme/react-avatar-creator";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useOnClickOutside} from "usehooks-ts";
import I18n from "i18next";
import JSZip from "jszip";
import * as THREE from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

import FBXLoader from "../../../../assets/js/loaders/FBXLoader";
import ModelLoader from "../../../../assets/js/loaders/ModelLoader";
import Ajax from "../../../../utils/Ajax";
import global from "../../../../global";
import AddObjectCommand from "../../../../command/AddObjectCommand";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import TimeUtils from "../../../../utils/TimeUtils";
import Converter from "../../../../utils/Converter";
import {backendUrlFromPath} from "../../../../utils/UrlUtils";
import loadModel from "../AnimationCombiner/helpers/loadModel";
import {GLTFExporter} from "three/examples/jsm/exporters/GLTFExporter";
import {positionCameraForModel} from "../utils/positionCameraForModel";
import GradientSpinner from "../../../../player/component/GradientSpinner";
import {Container, LoadingContainer, Overlay, Wrapper} from "./AvatarCreatorPanel.style";
import {toast} from "react-toastify";

type Props = {
    onClose: () => void;
    mainAvatar?: boolean;
    setShowLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AvatarCreatorPanel = ({onClose, mainAvatar, setShowLoading}: Props) => {
    const app = (global as any).app;
    const ref = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [topDownCamera, setTopDownCamera] = useState<THREE.PerspectiveCamera>();
    const [renderer, setRenderer] = useState<THREE.WebGLRenderer>();
    const [scene, setScene] = useState<THREE.Scene>();
    const [controls, setControls] = useState<OrbitControls>();
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const animationNames = ["Idle", "Walk", "Run", "Jump"];
    const modelName = mainAvatar ? "Main Avatar" : "My Avatar";

    useEffect(() => {
        setShowLoading && setShowLoading(false);
    }, []);

    const handleClose = () => {
        setIsLoading(false);
        onClose();
        app?.call("fetchModels");
    };

    useOnClickOutside(ref, () => handleClose());

    const handleOnAvatarExported = async (event: AvatarExportedEvent) => {
        setIsLoading(true);
        if (event.data.url) {
            const array = event.data.url.split(".");
            const type = array[array.length - 1].split("?")[0];
            const animations = await loadAnimations();
            await loadModel(event.data.url, {Type: type}, (object: any) => {
                animations?.forEach(animation => {
                    const tracks = animation.tracks;

                    for (let i = 0; i < tracks.length; i++) {
                        const track = tracks[i];

                        if (track.name.endsWith(".position")) {
                            for (let j = 0; j < track.values.length; j += 3) {
                                track.values[j + 2] = 0; // z
                            }
                        }
                    }
                });
                if (animations && animations.length > 0) {
                    Object.assign(object.animations, [...object.animations, ...animations]);
                    Object.assign(object._obj.animations, [...object._obj.animations, ...animations]);
                }

                saveAsGLB(object, (result: ArrayBuffer) => {
                    (async () => {
                        const blob = new Blob([result]);
                        const file = new File([blob], `${modelName}.${type}`);
                        const zipper = new JSZip();

                        zipper.file(file.name, file);

                        await zipper.generateAsync({type: "blob"}).then(async zip => {
                            const zippedFile = new File([zip], `${modelName}.zip`);
                            try {
                                const response = await Ajax.post({
                                    url: backendUrlFromPath(`/api/Mesh/Add`),
                                    data: {
                                        file: zippedFile,
                                    },
                                    msgBodyType: "multipart",
                                });

                                if (response?.data.Code === 200) {
                                    await addModelToScene(response.data.Data, modelName);
                                }
                            } catch (error) {
                                app.toast("Request failed.", "error");
                                handleClose();
                            }
                        });
                    })();
                });
            });

            try {
                const response = await fetch(event.data.url);
                if (!response.ok) {
                    throw new Error(`Response status: ${response.status}`);
                }

                const blob = await response.blob();
                if (blob === null) {
                    throw new Error("Could not export avatar");
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleOnMainAvatarExported = async (event: AvatarExportedEvent) => {
        setShowLoading && setShowLoading(true);
        if (!event.data.url) {
            toast.error("No avatar URL provided");
            return;
        }

        const array = event.data.url.split(".");
        const type = array[array.length - 1].split("?")[0];

        try {
            const animations = await loadAnimations();
            let loader: GLTFLoader | FBXLoader;
            if (type === "glb" || type === "gltf") {
                loader = new GLTFLoader();
            } else if (type === "fbx") {
                loader = new FBXLoader();
            } else {
                throw new Error("Unsupported model type");
            }
            loader.load(
                event.data.url,
                object => {
                    if (animations?.length) {
                        animations.forEach(animation => {
                            const tracks = animation.tracks;
                            for (const track of tracks) {
                                if (track.name.endsWith(".position")) {
                                    for (let j = 0; j < track.values.length; j += 3) {
                                        track.values[j + 2] = 0;
                                    }
                                }
                            }
                        });

                        object.animations = [...(object.animations || []), ...animations];
                    }
                    const modelToExport =
                        object instanceof THREE.Group || object instanceof THREE.Mesh ? object : object.scene;
                    saveAsGLB(modelToExport, async (result: ArrayBuffer) => {
                        const blob = new Blob([result]);
                        const file = new File([blob], `${modelName}.${type}`);
                        const zipper = new JSZip();

                        zipper.file(file.name, file);

                        const zip = await zipper.generateAsync({type: "blob"});
                        const zippedFile = new File([zip], `${modelName}.zip`);

                        try {
                            const response = await Ajax.post({
                                url: backendUrlFromPath(`/api/Mesh/Add`),
                                data: {file: zippedFile, isAvatar: true},
                                msgBodyType: "multipart",
                            });

                            if (response?.data.Code === 200) {
                                await createModelImage(modelToExport, response.data.Data);
                                toast.success("Avatar successfully saved");
                            }
                        } catch (error) {
                            toast.error("Failed to save avatar");
                        }
                    });
                },
                undefined,
                error => {
                    toast.error("Failed to load model");
                    console.error(error);
                },
            );
        } catch (error) {
            toast.error("An error occurred");
            console.error(error);
        }
    };

    const config: AvatarCreatorConfig = {
        clearCache: true,
        bodyType: "fullbody",
        quickStart: false,
        language: "en",
    };

    const handleUploadThumbnail = (file: File, objData: any) => {
        Ajax.post({
            url: backendUrlFromPath(`/api/Upload/Upload`),
            data: {
                file,
            },
            msgBodyType: "multipart",
        })
            .then(response => {
                if (response?.data.Code === 200) {
                    const data = response.data.Data;
                    const payload = {
                        ID: objData.ID,
                        Name: objData.Name,
                        Image: data.url,
                        IsAvatar: !!mainAvatar,
                    };
                    app.editModel(payload);
                    handleClose();
                }
            })
            .catch(() => {
                toast.error("Uploading thumbnail failed.");
            });
    };

    const loadAnimations = async () => {
        const loadedAnimations: THREE.AnimationClip[] = [];
        for (const fileName of animationNames) {
            try {
                let loader = new (ModelLoader as any)(app);
                const obj = await loader.load(
                    `/assets/animations/readyPlayerMe/${fileName}.glb`,
                    {Type: "glb"},
                    {
                        camera: app.editor.camera,
                        renderer: app.editor.renderer,
                        audioListener: app.editor.audioListener,
                        clearChildren: true,
                    },
                );
                if (!obj) {
                    return;
                }
                const animations = obj._obj?.animations || obj.animations;
                if (animations.length > 1) {
                    animations.forEach((anim: any, index: any) => {
                        anim.name = fileName + index;
                    });
                } else {
                    animations[0].name = fileName;
                }
                loadedAnimations.push(...animations);
            } catch (error) {
                app.toast(I18n.t("Could not load animations"), "warn");
            }
        }
        return loadedAnimations;
    };

    const saveAsGLB = (model: any, callback: (result: ArrayBuffer) => void) => {
        try {
            var exporter = new GLTFExporter();
            exporter.parse(
                model.children.length > 0 ? model.children : model,
                function (result) {
                    (async () => {
                        callback(result as ArrayBuffer);
                    })();
                },
                error => {
                    toast.error(I18n.t("Could not save model"));
                    console.error(error);
                },
                {
                    trs: true,
                    binary: true,
                    animations: model._obj?.animations || model.animations,
                },
            );
        } catch (error) {
            toast.error(I18n.t("Could not save model"));
            console.error(error);
        }
    };

    const addModelToScene = (objData: any, name: string) => {
        let loader = new (ModelLoader as any)(app);
        let url = backendUrlFromPath(objData.Url);

        loader
            .load(url, objData, {
                camera: app.editor.camera,
                renderer: app.editor.renderer,
                audioListener: app.editor.audioListener,
                clearChildren: true,
            })
            .then((obj: any) => {
                if (!obj) {
                    return;
                }
                obj.name = name;
                createModelImage(obj, objData);
                Object.assign(obj.userData, objData, {
                    Server: true,
                });

                if (app.storage.addMode === "click") {
                    clickSceneToAdd(obj);
                } else {
                    app.editor.moveObjectToCameraClosestPoint(obj);
                    addToCenter(obj);
                }
            })
            .catch((e: any) => {
                app.toast(I18n.t("Could not load model"), "error");
                handleClose();
                console.log(e);
            });
    };

    const addToCenter = (obj: any) => {
        app.editor.execute(new (AddObjectCommand as any)(obj));

        if (obj.userData.scripts) {
            obj.userData.scripts.forEach((n: any) => {
                app.editor.scripts.push(n);
            });
            app.call("scriptChanged", obj);
        }
    };

    const clickSceneToAdd = (obj: any) => {
        let added = false;
        app.editor.gpuPickNum += 1;
        app.on(`gpuPick.ModelPanel`, (intersect: {point: any}) => {
            if (!intersect.point) {
                return;
            }
            if (!added) {
                added = true;
                app.editor.sceneHelpers.add(obj);
            }
            obj.position.copy(intersect.point);
        });
        app.on(`raycast.ModelPanel`, (intersect: {point: any}) => {
            app.on(`gpuPick.ModelPanel`, null);
            app.on(`raycast.ModelPanel`, null);
            obj.position.copy(intersect.point);
            addToCenter(obj);
            app.editor.gpuPickNum -= 1;
        });
    };

    const createModelImage = async (mesh: any, data: any) => {
        if (scene && renderer && topDownCamera && controls) {
            const light = new THREE.AmbientLight();
            scene.add(mesh);
            scene.add(light);

            positionCameraForModel(mesh, topDownCamera, controls);

            scene.background = app?.editor?.scene.background;
            renderer.clear();
            animate();
            const canvas = renderer.domElement;
            const dataUrl = canvas.toDataURL("image/jpeg", 1.0);
            const file = (Converter as any).dataURLtoFile(dataUrl, (TimeUtils as any).getDateTime());
            await handleUploadThumbnail(file, data);
        }
    };

    const animate = () => {
        requestAnimationFrame(animate);
        if (renderer && scene && topDownCamera && controls) {
            controls.update();
            renderer.render(scene, topDownCamera);
        }
    };

    useLayoutEffect(() => {
        if (wrapperRef.current && !isRendered) {
            const renderer = new THREE.WebGLRenderer({
                antialias: true,
                preserveDrawingBuffer: true,
            });
            const scene = new THREE.Scene();
            const camera = new THREE.PerspectiveCamera(20, 1, 0.1, 1000);
            const controls = new OrbitControls(camera, renderer.domElement);
            camera.position.set(0, 3, 4); // Adjust height for top-down view
            controls.target = new THREE.Vector3(0, 1.2, 0);
            setIsRendered(true);
            setRenderer(renderer);
            setTopDownCamera(camera);
            setControls(controls);
            setScene(scene);
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(wrapperRef.current.offsetWidth || 100, wrapperRef.current.offsetHeight || 100);
            wrapperRef.current.appendChild(renderer.domElement);
        }
    }, [wrapperRef.current, isRendered]);

    return (
        <Overlay>
            <Container ref={ref} $fullScreen={!!mainAvatar}>
                {scene && renderer && (
                    <AvatarCreator
                        subdomain="mochi"
                        config={config}
                        onAvatarExported={event =>
                            mainAvatar ? handleOnMainAvatarExported(event) : handleOnAvatarExported(event)
                        }
                    />
                )}

                {isLoading && (
                    <LoadingContainer $fullScreen={!!mainAvatar}>
                        <GradientSpinner />
                    </LoadingContainer>
                )}
            </Container>
            <Wrapper ref={wrapperRef} />
        </Overlay>
    );
};
