import React, {useEffect, useState} from "react";
import * as THREE from "three";
import styled from "styled-components";

import global from "../../../../../global";
import Application from "../../../../../Application";

import {ColorRow} from "../common/ColorRow";
import {MaterialEditorButton} from "../MainButtons";
import {regularFont} from "../../../../../assets/style";

type Props = {
    color: string;
    setColor: React.Dispatch<React.SetStateAction<string | null>>;
    texture: any;
    showMaterialEditor: () => void;
};

export const TextureSection = ({texture, color, setColor, showMaterialEditor}: Props) => {
    const app = (global?.app as Application) || null;
    const editor = app?.editor;

    const [hasTexture, setHasTexture] = useState(false);

    const handleColorChange = (color: string) => {
        setColor(color);
        if (app?.editor?.selected) {
            const selectedObject = app.editor.selected as any;

            selectedObject.material.color.set(color);
            app.call(`objectChanged`, app.editor.selected, app.editor.selected);
        }
    };

    const updateMaterialSettings = () => {
        const selected = editor?.selected;

        if (!selected || !(selected instanceof THREE.Mesh) || !selected.material) {
            setHasTexture(false);
            return;
        }

        const materials = Array.isArray(selected.material) ? selected.material : [selected.material];
        const textureProps = [
            "map",
            "normalMap",
            "roughnessMap",
            "metalnessMap",
            "bumpMap",
            "alphaMap",
            "displacementMap",
        ];

        const hasAnyTexture = materials.some(material =>
            textureProps.some(prop => {
                const texture = material[prop];
                return texture && texture.isTexture; // Ensure the texture exists and is a valid texture
            }),
        );

        setHasTexture(hasAnyTexture);

        materials.forEach(material => {
            if (hasAnyTexture) {
                material.color.set(0xffffff); // Ensure texture colors are not tinted
            }

            if (material instanceof THREE.MeshStandardMaterial) {
                material.roughness = 0.5;
                material.metalness = 0.1;
            }

            material.needsUpdate = true;
        });
    };

    useEffect(() => {
        app.on(`objectChanged.TextureSection`, updateMaterialSettings);

        return () => {
            app.on(`objectChanged.TextureSection`, null);
        };
    }, []);

    useEffect(() => {
        if (texture) {
            updateMaterialSettings();
        }
    }, [texture]);

    return (
        <>
            <ColorRow color={color} handleColorChange={handleColorChange} disabled={hasTexture} />
            {!!hasTexture && <Info>To apply color, remove texture.</Info>}
            <MaterialEditorButton showMaterialEditor={showMaterialEditor} />
        </>
    );
};

const Info = styled.div`
    ${regularFont("xxs")};
    color: var(--theme-font-unselected-color);
    margin-top: -8px;
`;
