import PlayerComponent from "./PlayerComponent";
import PackageManager from "../../package/PackageManager";
import {PointerLockControls} from "three/examples/jsm/controls/PointerLockControls.js";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls.js";
import {FlyControls} from "three/examples/jsm/controls/FlyControls.js";
import {TrackballControls} from "three/examples/jsm/controls/TrackballControls.js";
import CharacterControls from "../../controls/CharacterControls";
import VehicleControls from "../../controls/VehicleControls";
//TODO this will soon be re factored to camera types
import {CAMERA_TYPES} from "../../types/editor";
import {Input} from "../../controls/input/Bindings";
import {defaultBindings} from "../../controls/input/DefaultBindings";

class PlayerControl extends PlayerComponent {
    constructor(app) {
        super(app);
        this.packageManager = new PackageManager();
        this.require = this.packageManager.require;

        this.control = null;
        this.input = null;
    }

    create(physics, scene, camera, renderer, player) {
        let type = camera.userData.control;
        return this._createControl(type, physics, scene, camera, renderer, player);
    }

    _createControl(type, physics, scene, camera, renderer, player) {
        return new Promise((resolve, reject) => {
            console.log("Control type selectedModelName:", camera.userData);
            switch (type) {
                case "FlyControls": {
                    this.control = new FlyControls(camera, renderer.domElement);
                    if (camera.userData.flyOptions) {
                        Object.assign(this.control, camera.userData.flyOptions);
                    }
                    resolve();
                    break;
                }
                case "PointerLockControls": {
                    this.control = new PointerLockControls(camera, renderer.domElement);
                    if (camera.userData.pointerLockOptions) {
                        Object.assign(this.control, camera.userData.pointerLockOptions);

                        if (this.control.isLocked) {
                            this.control.lock();
                        } else {
                            this.control.unlock();
                        }
                    }
                    resolve();
                    break;
                }
                case "TrackballControls": {
                    this.control = new TrackballControls(camera, renderer.domElement);
                    if (camera.userData.trackballOptions) {
                        Object.assign(this.control, camera.userData.trackballOptions);
                    }
                    resolve();
                    break;
                }
                //TODO CharacterControls will be renamed to accommodate consolidated control code
                case CAMERA_TYPES.FORTNITE:
                case CAMERA_TYPES.FIRST_PERSON:
                case CAMERA_TYPES.THIRD_PERSON: {
                    let selectedModelName = camera.userData.characterOptions.selectedModel;
                    if (selectedModelName !== "none" && scene.getObjectByName(selectedModelName)) {
                        const model = scene.getObjectByName(selectedModelName);
                        if (model) {
                            const keyBindings = defaultBindings();
                            this.input = new Input(keyBindings);
                            this.input.attach(document);
                            new CharacterControls(
                                this.input,
                                physics,
                                scene,
                                camera,
                                renderer,
                                player,
                                renderer.domElement,
                                model,
                                model._obj && model._obj.animations ? model._obj.animations : [],
                            )
                                .create()
                                .then(control => {
                                    this.control = control;
                                    if (camera.userData.characterOptions) {
                                        Object.assign(this.control, camera.userData.characterOptions);
                                    }
                                    resolve();
                                })
                                .catch(e => {
                                    console.error("Error", e);
                                });
                        } else {
                            console.error(
                                "Selected model does not exist or is not valid. Skipping CharacterControls setup.",
                            );
                            reject(
                                "Selected model does not exist or is not valid. Skipping CharacterControls setup.",
                            );
                        }
                    } else {
                        console.error(
                            "Selected model does not exist in the scene. Skipping CharacterControls setup.",
                        );
                        reject("Selected model does not exist in the scene. Skipping CharacterControls setup.");
                    }
                    break;
                }
                case CAMERA_TYPES.VEHICLE: {
                    let selectedModelName = camera.userData.VehicleOptions.selectedModel;
                    if (selectedModelName !== "none" && scene.getObjectByName(selectedModelName)) {
                        const model = scene.getObjectByName(selectedModelName);
                        if (model) {
                            new VehicleControls(
                                physics,
                                scene,
                                camera,
                                renderer.domElement,
                                model,
                                model._obj && model._obj.animations ? model._obj.animations : [],
                            )
                                .create()
                                .then(control => {
                                    this.control = control;
                                    if (camera.userData.VehicleOptions) {
                                        Object.assign(this.control, camera.userData.VehicleOptions);
                                    }
                                    resolve();
                                });
                        } else {
                            console.error(
                                "Selected model does not exist or is not valid. Skipping VehicleControls setup.",
                            );
                            reject("Selected model does not exist or is not valid. Skipping VehicleonControls setup.");
                        }
                    } else {
                        console.error("Selected model does not exist in the scene. Skipping VehicleControls setup.");
                        reject("Selected model does not exist in the scene. Skipping VehicleControls setup.");
                    }
                    break;
                }

                case "OrbitControls": {
                    this.control = new OrbitControls(camera, renderer.domElement);
                    if (camera.userData.orbitOptions) {
                        Object.assign(this.control, camera.userData.orbitOptions);
                    }
                    resolve();
                    break;
                }
                default: {
                    console.error("Unsupported control type: " + type);
                    reject("Unsupported control type: " + type);
                }
            }
        });
    }

    update(clock, deltaTime) {
        if (this.input) {
            this.input.update();
        }

        if (this.control && this.control.update) {
            this.control.update(deltaTime);
        }
    }

    dispose() {
        if (this.control) {
            this.control.dispose();
            this.control = null;
        }

        if (this.input) {
            this.input.dispose();
            this.input = null;
        }
    }
}

export default PlayerControl;
