import PlayerComponent from "./PlayerComponent";
import {CollisionData, CollisionFlag, ICollisionSource, IDispatcher, IPhysics} from "../../physics/common/types";
import {Object3D, Quaternion, Scene, Vector3} from "three";
import PhysicsWorld from "../../physics/PhysicsWorld";
import Application from "../../Application";
import global from "../../global";
import Ammo from "../../assets/js/libs/ammojs3/builds/ammo";
import Player from "../Player";
import MultiplayerProxy from "../../multiplayer/MultiplayerProxy";
import {PhysicsUtil} from "../../physics/PhysicsUtil";

type UpdateData = {
    timestamp: number;
    uuid: string;
    position: {x: number; y: number; z: number};
    rotation: {x: number; y: number; z: number; w: number};
    dt: number;
    extraData: any;
};

type UpdatesData = {
    previous: UpdateData | null;
    current: UpdateData | null;
};

export default class PlayerPhysics2 extends PlayerComponent implements ICollisionSource {
    isMultiplayer: boolean = false;
    useWorker: boolean;
    physics: IPhysics | null;
    ammo: any;
    scene!: Scene;
    updates: Map<string, UpdatesData> = new Map();
    positionAux: Vector3 = new Vector3();
    quaternionAuxA: Quaternion = new Quaternion();
    quaternionAuxB: Quaternion = new Quaternion();
    collisionListener?: (collision: CollisionData) => void;

    constructor(app: Application | Player) {
        super(app);
        this.useWorker =
            typeof Worker !== "undefined" &&
            (global.app as Application).storage &&
            ((global.app as Application).storage as any).debug === false;
        this.physics = null;
        //FIXME: move to a separate PhysicsUtils class
        this.app.addPhysicsObject = (target: Object3D) => {
            this.scene.add(target);
            this.addObject(target);
        };
        this.app.removePhysicsObject = (target: Object3D) => {
            this.scene.remove(target);
            if (PhysicsUtil.isPhysicsEnabled(target)) {
                this.physics?.remove(target.uuid);
            }
        };
        this.app.removePhysicsObjectBody = (target: Object3D) => {
            if (PhysicsUtil.isPhysicsEnabled(target)) {
                this.physics?.remove(target.uuid);
            }
        };
        this.app.addPhysicsObjectBody = (target: Object3D) => {
            if (PhysicsUtil.isPhysicsEnabled(target)) {
                this.addObject(target);
            }
        }
    }

    create(sceneId: string, scene: Scene, isMultiplayer: boolean): Promise<IPhysics> {
        this.scene = scene;
        this.isMultiplayer = isMultiplayer;
        return new Promise((resolve, reject) => {
            this.initPhysics(sceneId, scene)
                .then(physics => {
                    if (!physics) throw Error("initPhysics failed");
                    this.physics = physics;
                    resolve(this.physics);
                })
                .catch(e => {
                    reject(e);
                });
        });
    }

    //ICollisionSource impl

    addCollisionListener(listener: (collision: CollisionData) => void) {
        this.collisionListener = listener;
    }

    //end of ICollisionSource impl

    addObjects() {
        this.addObjectsFromScene();
        if (!this.useWorker) {
            let debugMesh = this.physics?.initDebug();
            if (debugMesh) {
                this.scene.add(debugMesh);
            }
        }
    }

    addObjectsFromScene() {
        this.scene.traverse(object => {
            this.addObject(object);
        });
    }

    addObject(object: Object3D) {
        if (object.userData && object.userData.physics && object.userData.physics.enabled) {
            const physicsConfig = object.userData.physics;
            if (physicsConfig.type === "rigidBody") {
                if (this.isMultiplayer) {
                    //just add objects to the local update cache
                    if (PhysicsUtil.isDynamicObject(object)) {
                        //FIXME: use collision_type when fixed in UI
                        this.physics?.addObject(object.uuid, physicsConfig.mass, CollisionFlag.DYNAMIC, object);
                    }
                    return;
                }
                object.updateMatrixWorld(true);
                PhysicsUtil.addObjectShapeToPhysics(object, this.physics);
            }
        }
    }

    initPhysics(sceneId: string, scene: Scene) {
        return new Promise<IPhysics | null>((resolve, reject) => {
            let dispatcher: IDispatcher = {
                onReady: () => {
                    console.log("PlayerPhysics2: physics engine is ready !");
                },
                onBodyUpdate: (uuid: string, position: Vector3, rotation: Quaternion, dt: number, extraData: any) => {
                    this.pushUpdateData(uuid, position, rotation, dt, extraData);
                },
                onCollision: (uuid: string, listenerId: string) => {
                    if (this.collisionListener) this.collisionListener({uuid, listenerId});
                },
            };

            if (this.isMultiplayer) {
                new MultiplayerProxy(sceneId, scene, dispatcher)
                    .start()
                    .then(proxy => {
                        console.log("MultiplayerProxy: started !");
                        resolve(proxy);
                    })
                    .catch(e => {
                        reject(e);
                    });
            } else if (this.useWorker) {
                import("../../physics/worker/PhysicsProxy").then(PhysicsProxy => {
                    new PhysicsProxy.default(dispatcher)
                        .start()
                        .then(proxy => {
                            console.log("PhysicsProxy: started !");
                            resolve(proxy);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            } else {
                Ammo().then(ammo => {
                    new PhysicsWorld(ammo, dispatcher)
                        .start()
                        .then(world => {
                            console.log("PhysicsWorld: started !");
                            resolve(world);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            }
        });
    }

    update() {
        this.physics?.simulate();

        if (this.isMultiplayer) {
            this.updateObjectsWithInterpolation();
        } else {
            this.updateObjects();
        }

        //update kinematic objects
        this.physics?.getKinematicBodyObjects().forEach((object, uuid) => {
            const currentPosition = PhysicsUtil.calculatePhysicsPositionFromObject(
                object.position,
                object.quaternion,
                object.userData.physics?.anchorOffset,
            );
            this.physics?.setOrigin(uuid, currentPosition);
            this.physics?.setRotation(uuid, object.quaternion);
        });
    }

    private pushUpdateData(uuid: string, position: Vector3, rotation: Quaternion, dt: number, extraData: any) {
        let currentUpdate = this.updates.get(uuid);

        if (!currentUpdate) {
            currentUpdate = {
                previous: null,
                current: null,
            };
        }

        let delta = 0;
        const timestamp = Date.now();

        if (currentUpdate.current) {
            currentUpdate.previous = currentUpdate.current;
        }

        if (currentUpdate.previous) {
            delta = timestamp - currentUpdate.previous.timestamp;
        }

        currentUpdate.current = {
            timestamp: timestamp,
            uuid,
            position: position,
            rotation: rotation,
            dt: delta,
            extraData,
        };

        this.updates.set(uuid, currentUpdate);
    }

    private updateObjectsWithInterpolation() {
        const timestamp = Date.now();

        this.updates.forEach((data, uuid) => {
            const object = this.physics?.getDynamicBodyObject(uuid);
            if (object) {
                const previous = data.previous || data.current;
                const current = data.current || data.previous;

                const delta = current.timestamp - previous.timestamp;
                const currentDelta = timestamp - current.timestamp;
                const progress = Math.min(delta > 0 ? currentDelta / delta : 1, 1);

                this.interpolateObjectPositionAndRotation(object, previous, current, progress);

                // move to previous data if it's older than current timestamp
                if (data.current && data.current.timestamp + delta < timestamp) {
                    data.previous = data.current;
                    data.current = null;
                }
            } else {
                // TODO: check why server try to update object that doesn't exist
                const target = this.scene.getObjectByProperty("uuid", uuid);
                this.updates.delete(uuid);
                console.warn(
                    `PlayerPhysics2.update: object not found uuid=${uuid} name=${target ? target.name : null}`,
                );
            }
        });
    }

    private updateObjects() {
        this.updates.forEach((data, uuid) => {
            const object = this.physics?.getDynamicBodyObject(uuid);
            if (object && data.current) {
                this.positionAux.copy(data.current.position);

                const newPosition = PhysicsUtil.calculateObjectPositionFromPhysics(
                    this.positionAux,
                    data.current.rotation,
                    object.userData.physics?.anchorOffset,
                );

                object.position.copy(newPosition);
                object.quaternion.copy(data.current.rotation);
            }
        });

        this.updates.clear();
    }

    private interpolateObjectPositionAndRotation(
        object: Object3D,
        previous: UpdateData,
        current: UpdateData,
        progress: number,
    ) {
        this.positionAux.copy(previous.position).lerp(current.position, progress);

        this.quaternionAuxB.copy(current.rotation);
        // slerp doesn't work with non quaternion objects
        this.quaternionAuxA.copy(previous.rotation).slerp(this.quaternionAuxB, progress);

        const newPosition = PhysicsUtil.calculateObjectPositionFromPhysics(
            this.positionAux,
            current.rotation,
            object.userData.physics?.anchorOffset,
        );

        object.position.copy(newPosition);
        object.quaternion.copy(this.quaternionAuxA);
    }

    dispose() {
        this.physics?.terminate();
    }
}
