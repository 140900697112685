import {AnimationAction, AnimationMixer, Object3D} from "three";
import {backendUrlFromPath} from "./UrlUtils";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import {AnimationData} from "../controls/AnimationController";
import global from "../global";
import Player from "../player/Player";
import {AiNPCBehaviorInterface} from "../types/editor";

enum VRMAnimationNames {
    Acknowledging = "acknowledging",
    AngryGesture = "angry gesture",
    AnnoyedHeadShake = "annoyed head shake",
    BeingCocky = "being cocky",
    DismissingGesture = "dismissing gesture",
    HappyHandGesture = "happy hand gesture",
    HardHeadNod = "hard head nod",
    HeadNodYes = "head nod yes",
    LengthyHeadNod = "lengthy head nod",
    LookAwayGesture = "look away gesture",
    RelievedSigh = "relieved sigh",
    SarcasticHeadNod = "sarcastic head nod",
    ShakingHeadNo = "shaking head no",
    ThoughtfulHeadShake = "thoughtful head shake",
    WeightShift = "weight shift",
}

class AiAgent {
    private app = global.app as Player;
    private audioContext: AudioContext;
    private buffer: string[];
    private bufferSize: number;
    private audioChunks: Map<number, Uint8Array>;
    private currentIndex: number;
    private nextIndexToPlay: number;
    private requestQueue: Promise<void>;
    private previousText: string;
    private bufferText: string | null;
    private currentSource: AudioBufferSourceNode | null;
    private controller: AbortController | null;
    private pausePosition: number | null;
    private isPaused: boolean;
    private sceneId: string;
    private userName: string;
    private action: AnimationAction | null = null;
    private prevAnimation: AnimationData | null = null;

    private isVRM: boolean;

    isPlaying: boolean;
    isBusy: boolean;
    isInRange: boolean;
    model: Object3D;
    behavior: AiNPCBehaviorInterface;
    gainNode: GainNode;
    currentAnimationName: string;
    mixer: AnimationMixer;

    constructor(model: Object3D, behavior: AiNPCBehaviorInterface, sceneId: string, userName: string) {
        this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        this.isPlaying = false;
        this.buffer = [];
        this.bufferSize = 50;
        this.audioChunks = new Map();
        this.currentIndex = 0;
        this.nextIndexToPlay = 0;
        this.requestQueue = Promise.resolve();
        this.previousText = "";
        this.bufferText = null;
        this.currentSource = null;
        this.controller = null;
        this.pausePosition = null;
        this.isPaused = false;
        this.model = model;
        this.isBusy = false;
        this.isInRange = false;
        this.behavior = behavior;

        this.gainNode = this.audioContext.createGain();
        this.gainNode.gain.value = 1;
        this.sceneId = sceneId;
        this.userName = userName;
        this.currentAnimationName = "";
        this.mixer = new AnimationMixer(model);
        this.isVRM = !!this.app.vrmExpressionControl.getRegisteredVRMModel(this.model);
        this.playIdleAnimation();
    }

    async sendAudioFile(file: File): Promise<void> {
        const formData = new FormData();
        formData.append("audio", file);
        this.isBusy = true;
        try {
            const voiceResponse = await fetch(backendUrlFromPath("/api/AI/VoiceToText") || "", {
                method: "POST",
                body: formData,
            });

            if (!voiceResponse.ok) {
                throw new Error("Failed to transcribe audio.");
            }

            const response = await voiceResponse.json();

            this.sendTextToConversation(response.transcription);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    private async sendTextToConversation(text: string): Promise<void> {
        this.controller = new AbortController();
        const controller = this.controller;
        this.requestQueue = Promise.resolve();
        this.isBusy = true;
        try {
            await fetchEventSource(backendUrlFromPath("/api/AI/Conversation") || "", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    sceneID: this.sceneId,
                    roomID: this.userName || "guest",
                    assistantName: this.behavior.name,
                    userName: this.userName || "guest",
                    text: text,
                    description: this.generatePrompt(),
                }),
                signal: controller.signal,
                onmessage: async event => {
                    const responseText = event.data;

                    if (responseText === "###END###") {
                        let nextBufferText = this.buffer.join("");

                        if (this.bufferText !== null) {
                            // Handle any remaining partial text
                            const lastSpace = nextBufferText.lastIndexOf(" ");
                            if (lastSpace !== -1) {
                                const completeText = this.bufferText + nextBufferText.substring(0, lastSpace);
                                const remainingText = nextBufferText.substring(lastSpace + 1);

                                this.queueTextToVoice(completeText, this.previousText, remainingText);
                                this.previousText = completeText;

                                // Queue the final piece
                                if (remainingText) {
                                    this.queueTextToVoice(remainingText, this.previousText, "");
                                    this.previousText = remainingText;
                                }
                            } else {
                                // No spaces, just append everything
                                const completeText = this.bufferText + nextBufferText;
                                this.queueTextToVoice(completeText, this.previousText, "");
                                this.previousText = completeText;
                            }
                        } else if (nextBufferText) {
                            this.queueTextToVoice(nextBufferText, this.previousText, "");
                            this.previousText = nextBufferText;
                        }

                        this.bufferText = null;
                        this.buffer = [];
                        return;
                    }

                    this.buffer.push(responseText);

                    if (this.buffer.join("").length >= this.bufferSize) {
                        let nextBufferText = this.buffer.join("");

                        // Find the last complete word
                        const lastSpace = nextBufferText.lastIndexOf(" ");

                        if (lastSpace === -1) {
                            // No spaces found, store entire buffer
                            this.bufferText =
                                this.bufferText === null ? nextBufferText : this.bufferText + nextBufferText;
                        } else {
                            const completeText = nextBufferText.substring(0, lastSpace);
                            const remainingText = nextBufferText.substring(lastSpace + 1);

                            if (this.bufferText === null) {
                                this.queueTextToVoice(completeText, this.previousText, remainingText);
                                this.previousText = completeText;
                                this.bufferText = remainingText;
                            } else {
                                const fullText = this.bufferText + completeText;
                                this.queueTextToVoice(fullText, this.previousText, remainingText);
                                this.previousText = fullText;
                                this.bufferText = remainingText;
                            }
                        }

                        this.buffer = [];
                    }
                },
                async onopen(response) {
                    console.log("Connection opened");
                },
                onerror(error) {
                    controller.abort();
                    console.error("Error in /Conversation:", error);
                },
            });
        } catch (error) {
            console.error("Error in /Conversation response:", error);
        }
    }

    private queueTextToVoice(text: string, previousText: string, nextText: string): void {
        this.requestQueue = this.requestQueue.then(() =>
            this.sendTextToVoice(text, previousText, nextText, this.currentIndex++),
        );
    }

    private async sendTextToVoice(text: string, previousText: string, nextText: string, index: number): Promise<void> {
        if (!text) {
            return;
        }
        try {
            const voiceResponse = await fetch(backendUrlFromPath("/api/AI/TextToVoice") || "", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({text, previousText, nextText, voiceId: this.behavior.voice_id}),
                signal: this.controller?.signal,
            });

            if (!voiceResponse.ok || !voiceResponse.body) {
                throw new Error("Failed to generate speech.");
            }

            await this.handleAudioStream(voiceResponse.body, index);
        } catch (error) {
            console.error("Error in /TextToVoice:", error);
            throw new Error("Failed to generate speech.");
        }
    }

    private async handleAudioStream(stream: ReadableStream<Uint8Array>, index: number): Promise<void> {
        const reader = stream.getReader();
        let audioData = new Uint8Array();

        while (true) {
            const {done, value} = await reader.read();
            if (done || !value) break;

            const temp = new Uint8Array(audioData.length + value.length);
            temp.set(audioData);
            temp.set(value, audioData.length);
            audioData = temp;
        }

        this.audioChunks.set(index, audioData);

        if (!this.isPlaying && index === this.nextIndexToPlay) {
            this.playNextChunk();
        }
    }

    private async playNextChunk(): Promise<void> {
        const chunk = this.audioChunks.get(this.nextIndexToPlay);

        if (!chunk) {
            this.isBusy = false;
            this.currentIndex = 0;
            this.nextIndexToPlay = 0;
        }

        if (!chunk || this.isPaused) {
            this.isPlaying = false;
            return;
        }

        this.isPlaying = true;
        try {
            const audioBuffer = await this.audioContext.decodeAudioData(chunk.buffer);
            const source = this.audioContext.createBufferSource();
            source.buffer = audioBuffer;
            this.gainNode.connect(this.audioContext.destination);
            source.connect(this.gainNode);
            this.currentSource = source;

            source.onended = () => {
                this.audioChunks.delete(this.nextIndexToPlay);
                this.nextIndexToPlay++;
                this.playNextChunk();
            };

            source.start();
        } catch (error) {
            this.audioChunks.delete(this.nextIndexToPlay);
            this.nextIndexToPlay++;
            this.playNextChunk();
            console.error("Error decoding or playing audio chunk:", error);
        }
    }

    public reset(): void {
        this.isPlaying = false;
        this.isBusy = false;
        this.isPaused = false;
        this.buffer = [];
        this.bufferText = null;
        this.previousText = "";
        this.audioChunks.clear();
        this.currentIndex = 0;
        this.nextIndexToPlay = 0;

        this.controller?.abort();
        this.controller = null;

        this.requestQueue = Promise.resolve();

        if (this.currentSource) {
            this.currentSource.stop();
            this.currentSource = null;
        }
    }

    public pause(): void {
        if (this.isPlaying && this.currentSource) {
            this.isPaused = true;
            this.pausePosition = this.audioContext.currentTime;
            this.currentSource?.stop();
            this.isPlaying = false;
        }
    }

    public async resume(): Promise<void> {
        this.isPaused = false;
        if (!this.isPlaying && this.pausePosition !== null) {
            const chunk = this.audioChunks.get(this.nextIndexToPlay);

            if (chunk) {
                this.isPlaying = true;

                try {
                    const audioBuffer = await this.audioContext.decodeAudioData(chunk.buffer);
                    const source = this.audioContext.createBufferSource();
                    source.buffer = audioBuffer;
                    this.gainNode.connect(this.audioContext.destination);
                    source.connect(this.gainNode);
                    this.currentSource = source;

                    source.onended = () => {
                        this.audioChunks.delete(this.nextIndexToPlay);
                        this.nextIndexToPlay++;
                        this.playNextChunk();
                    };

                    source.start(0, this.pausePosition);
                    this.pausePosition = null;
                } catch (error) {
                    console.error("Error decoding or resuming audio chunk:", error);
                    this.isPlaying = false;
                }
            }
        }
    }

    setGainNodeValue = (gain: number): void => {
        this.gainNode.gain.value = gain;
    };

    playIdleAnimation = (): void => {
        const isVRM = !!this.app.vrmExpressionControl.getRegisteredVRMModel(this.model);
        if (isVRM) {
            this.app.vrmExpressionControl.playMixamoAnimation(this.model, "Idle");
        }
    };

    playSpeechAnimation = async (): Promise<void> => {
        this.prevAnimation = {...this.model.userData.animation};
        this.currentAnimationName = "Idle";
        this.isPlaying = true;

        const isVRM = !!this.app.vrmExpressionControl.getRegisteredVRMModel(this.model);

        if (isVRM) {
            const animationName = this.getRandomVRMAnimationName();
            try {
                this.currentAnimationName = animationName;
                await this.app.vrmExpressionControl.playMixamoAnimation(this.model, animationName);
                this.setExpressionForAnimation(animationName);
            } catch (error) {
                this.currentAnimationName = "";
            }
        } else {
            this.app.animationControl.playAnimation(this.model, this.currentAnimationName, 1);
        }
    };

    stopSpeechAnimation = (): void => {
        this.isPlaying = false;
        if (this.model.userData.animation && this.prevAnimation) {
            const {clip, speed} = this.prevAnimation as AnimationData;

            if (clip?.name) {
                if (this.isVRM) {
                    this.app.vrmExpressionControl.playMixamoAnimation(this.model, clip.name);
                } else {
                    this.app.animationControl.playAnimation(this.model, clip.name, speed);
                }
            } else {
                this.app.vrmExpressionControl.stopAnimation(this.model);
                this.app.animationControl.stopAnimation(this.model);
            }

            this.setExpressionForAnimation("");

            this.prevAnimation = null;
            this.currentAnimationName = "";
        }
    };

    getRandomVRMAnimationName = (): VRMAnimationNames => {
        return Object.values(VRMAnimationNames)[Math.floor(Math.random() * Object.values(VRMAnimationNames).length)];
    };

    setExpressionForAnimation(animationName: VRMAnimationNames | string) {
        const vrmExpressionControl = this.app.vrmExpressionControl;
        const vrm = vrmExpressionControl.getRegisteredVRMModel(this.model);

        if (!vrm) {
            return;
        }
        switch (animationName) {
            case VRMAnimationNames.HappyHandGesture:
            case VRMAnimationNames.Acknowledging:
                vrmExpressionControl.setHappyExpression(vrm);
                break;
            case VRMAnimationNames.AngryGesture:
            case VRMAnimationNames.HardHeadNod:
                vrmExpressionControl.setAngryExpression(vrm);
                break;
            case VRMAnimationNames.ThoughtfulHeadShake:
                vrmExpressionControl.setThoughtfulExpression(vrm);
                break;
            case VRMAnimationNames.RelievedSigh:
                vrmExpressionControl.setRelievedExpression(vrm);
                break;
            case VRMAnimationNames.AnnoyedHeadShake:
            case VRMAnimationNames.ShakingHeadNo:
                vrmExpressionControl.setAnnoyedExpression(vrm);
                break;
            case VRMAnimationNames.BeingCocky:
            case VRMAnimationNames.SarcasticHeadNod:
                vrmExpressionControl.setSillyExpression(vrm);
                break;
            case VRMAnimationNames.LookAwayGesture:
                vrmExpressionControl.setDisappointedExpression(vrm);
                break;
            case VRMAnimationNames.DismissingGesture:
                vrmExpressionControl.setConfusedExpression(vrm);
                break;
            case VRMAnimationNames.HeadNodYes:
                vrmExpressionControl.setSweetExpression(vrm);
                break;
            case VRMAnimationNames.LengthyHeadNod:
            case VRMAnimationNames.WeightShift:
                vrmExpressionControl.setTiredExpression(vrm);
                break;
            default:
                vrmExpressionControl.resetExpressions(vrm);
                break;
        }
    }

    generatePrompt = (): string => {
        const {name, bio, lore, adjectives, interests, social_media_posts, response_style, miscellaneous} =
            this.behavior;

        return `
            # Action Examples
  
            Sheila-kathryn: just finished the best anime i've ever seen
            Sheila-kathryn: watched 40 hours of it in 2 days (CONTINUE)
            Dixie: damn, u ok
            Sheila-kathryn: surprisingly yes (CONTINUE)
            Sheila-kathryn: just found out theres a sequel, gg
            
            Xaviera: so i've been diving into photography as a new hobby
            Miranda: oh awesome, what do you enjoy taking photos of
            Xaviera: mostly nature and urban landscapes (CONTINUE)
            Xaviera: there's something peaceful about capturing the world through a lens
            
            Petrina: hey fren r u ok (CONTINUE)
            Petrina: u look sad
            Ag: im ok sweetie mommy just tired
            
            Jade: the new exhibit downtown is rly cool, it's all about tribalism in online spaces (CONTINUE)
            Jade: it really blew my mind, you gotta go
            Doroteya: sure i'd go
            Jade: k i was thinking this weekend
            Jade: i'm free sunday, we could get a crew together
            
            Elyssa: i found some incredible art today
            Eden: real art or digital art
            Elyssa: real art (CONTINUE)
            Elyssa: the pieces are just so insane looking, one sec, let me grab a link (CONTINUE)
            Elyssa: DMed it to you
            
            Florette: heyyyyyy (NONE)
            Peggi: whats up long time no see
            Florette: chillin man. playing lots of fortnite. what about you (NONE)
            
            Shandra: drop a joke on me (NONE)
            Valencia: why dont scientists trust atoms cuz they make up everything lmao (NONE)
            Shandra: haha good one (NONE)
            
            Lorrayne: gotta run (NONE)
            Thelma: Okay, ttyl (NONE)
            Lorrayne: ...(IGNORE)
            
            Ainslie: the things that were funny 6 months ago are very cringe now (NONE)
            Nessy: lol true (NONE)
            Ainslie: too real haha (NONE)
            
            Hetty: u think aliens are real (NONE)
            Shea: ya obviously (NONE)
  
            (Action examples are for reference only. Do not use the information from them in your response.)

            # Knowledge
  
            # Task: Generate dialog and actions for the character ${name}.
            About ${name}:
            ${bio} 
            ${lore}

            # Message Directions for ${name}
            ${response_style}

            # Adjectives
            ${adjectives?.join(", ")}

            # Interests
            ${interests?.join(", ")}

            # Social Media Posts 
            ${social_media_posts}

            # Additional personality information
            ${miscellaneous}


            # Available Actions
            IGNORE: Call this action if ignoring the user. If the user is aggressive, creepy or is finished with the conversation, use this action. Or, if both you and the user have already said goodbye, use this action instead of saying bye again. Use IGNORE any time the conversation has naturally ended. Do not use IGNORE if the user has engaged directly, or if something went wrong an you need to tell them. Only ignore if the user should be ignored.,
            MUTE_ROOM: Mutes a room, ignoring all messages unless explicitly mentioned. Only do this if explicitly asked to, or if you're annoying people.,
            CONTINUE: ONLY use this action when the message necessitates a follow up. Do not use this action when the conversation is finished or the user does not wish to speak (use IGNORE instead). If the last message action was CONTINUE, and the user has not responded. Use sparingly.,
            NONE: Respond but perform no additional action. This is the default if the agent is speaking and not doing anything additional.

            # Instructions: Write the next message for Eliza. Use the information provided to generate a response.  Your response cannot start with your name. If context of conversation is provided in prompts, then you should remember previous user messages. Don't talk about prompts. This structure is reserved for user only.
        `;
    };
}

export default AiAgent;
