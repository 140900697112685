import {useState} from "react";
import {ModelsTab} from "./SubTabs/ModelsTab";
import {TexturesTab} from "./SubTabs/TexturesTab";
import {LightsTab} from "./SubTabs/LightsTab";
import {TerrainTab} from "./SubTabs/TerrainTab";

import models3dIcon from "../../../icons/3d-models-icon.svg";
import decalsIcon from "../../../icons/decals-icon.svg";
import lightsIcon from "../../../icons/lights-icon.svg";
import miscIcon from "../../../icons/misc-icon.svg";
import terrainIcon from "../../../icons/terrain-icon.svg";
import videoIcon from "../../../icons/video-icon.svg";
import soundsIcon from "../../../icons/sounds-icon.svg";
import primitivesIcon from "../../../icons/primitives-icon.svg";
import aiModelsIcon from "../../../icons/ai-models-icon.svg";
import prefabsIcon from "../../../icons/prefabs-icon.svg";
import classNames from "classnames";
import {SoundsTab} from "./SubTabs/SoundsTab";
import {PrimitivesTab} from "./SubTabs/PrimitivesTab";
import {AiModelsTab} from "./SubTabs/AiModelsTab";
import {Container, TabContent, TabIconButton, TabIconWrapper, TabTooltip, TabsContainer} from "./AssetsTab.style";
import {ScenesTab} from "./SubTabs/ScenesTab";
import {useAuthorizationContext} from "../../../../../../context";

enum Tab {
    MODELS_3D = "3D models",
    AI_MODELS = "AI models",
    TERRAIN = "Terrain",
    TEXTURES = "Textures",
    SOUNDS = "Sounds",
    VIDEO = "Video",
    MISC = "Old Misc",
    LIGHTS = "Misc",
    PRIMITIVES = "Primitives",
    SCENES = "Prefabs",
}

enum TabIcon {
    MODELS_3D = models3dIcon,
    AI_MODELS = aiModelsIcon,
    TERRAIN = terrainIcon,
    TEXTURES = decalsIcon,
    SOUNDS = soundsIcon,
    VIDEO = videoIcon,
    MISC = miscIcon,
    LIGHTS = lightsIcon,
    PRIMITIVES = primitivesIcon,
    SCENES = prefabsIcon,
}

export const AssetsTab = ({scene, isVisible}: {scene: any; isVisible: boolean}) => {
    const [activeTab, setActiveTab] = useState(Tab.MODELS_3D);
    const {isAdmin} = useAuthorizationContext();

    const checkIfCurrentDisabled = <T extends keyof typeof Tab>(key: T) => {
        return !(
            Tab[key] === Tab.MODELS_3D ||
            Tab[key] === Tab.SCENES ||
            Tab[key] === Tab.TERRAIN ||
            Tab[key] === Tab.LIGHTS ||
            Tab[key] === Tab.SOUNDS ||
            Tab[key] === Tab.PRIMITIVES
        );
    };

    return (
        <Container style={!isVisible ? {display: "none"} : {}}>
            <TabsContainer>
                {(Object.keys(Tab) as Array<keyof typeof Tab | keyof typeof TabIcon>).map(key => {
                    if (checkIfCurrentDisabled(key)) return null;
                    if (!isAdmin && Tab[key] === Tab.SCENES) return null;
                    return (
                        <TabIconWrapper className={classNames("tab-icon-wrapper")} key={key}>
                            <TabIconButton
                                $isActive={activeTab === Tab[key]}
                                onClick={() => setActiveTab(Tab[key])}
                                className="tab-icon-button">
                                <img
                                    src={TabIcon[key] as unknown as string}
                                    alt={Tab[key]}
                                    className={activeTab === Tab[key] ? "active" : ""}
                                />
                            </TabIconButton>
                            <TabTooltip className="tab-tooltip">{Tab[key]}</TabTooltip>
                        </TabIconWrapper>
                    );
                })}
            </TabsContainer>
            <TabContent>
                {activeTab === Tab.MODELS_3D && <ModelsTab />}
                {activeTab === Tab.SCENES && <ScenesTab />}
                {activeTab === Tab.TERRAIN && <TerrainTab />}
                {activeTab === Tab.TEXTURES && <TexturesTab />}
                {activeTab === Tab.LIGHTS && <LightsTab scene={scene} />}
                {activeTab === Tab.SOUNDS && <SoundsTab />}
                {activeTab === Tab.PRIMITIVES && <PrimitivesTab />}
                {activeTab === Tab.AI_MODELS && <AiModelsTab />}
            </TabContent>
        </Container>
    );
};
