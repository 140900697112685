import {useEffect, useMemo, useState} from "react";
import {Expand, GenerationType, Section, TypeImageWrapper, TypesWrapper} from "../AiImageGenerator.styles";
import {toast} from "react-toastify";

import {IMAGE_TYPES, SKYBOX_STYLES} from "../../../../../../../types/imageGenerator";
import {
    BottomBar,
    StyledTextArea,
    SubmitButton,
    Title,
    Wrapper,
    Separator,
    LoadingWrapper,
} from "../../../AiAssistant.style";
import {NumericInputRow} from "../../../../RightPanel/common/NumericInputRow";
import arrowIcon from "../../../icons/arrow.svg";
import AIWorldController from "../../../../../../../controls/AiWorldController";
import global from "../../../../../../../global";
import Application from "../../../../../../../Application";
import {Oval} from "react-loader-spinner";

type Props = {
    isOpen: boolean;
    modelId: string;
};

const generateSizeOptions = (minValue: number) => {
    const sizeOptions = [];
    for (let value = minValue; value < 2048; value += 8) {
        sizeOptions.push(value);
    }
    return sizeOptions;
};

export const ImageGenerator = ({isOpen, modelId}: Props) => {
    const [loadingAI, setLoadingAI] = useState(false);
    const [prompt, setPrompt] = useState("");
    const [negativePrompt, setNegativePrompt] = useState("");
    const [height, setHeight] = useState(1024);
    const [width, setWidth] = useState(1024);
    const [imageType, setImageType] = useState<IMAGE_TYPES>(IMAGE_TYPES.CHARACTER);
    const [isExpanded, setIsExpanded] = useState(false);
    //const [skyboxStyle, setSkyboxStyle] = useState(SKYBOX_STYLES.RETRO_FANTASY);
    const app = global.app as Application;
    const numSamples = 1;
    const aiWorldController = new AIWorldController(app, app.editor?.scene!, app.editor?.camera!);

    const minValue = useMemo(() => (imageType === IMAGE_TYPES.SKYBOX ? 1024 : 64), [imageType]);
    const sizeOptions = useMemo(() => generateSizeOptions(minValue), [minValue]);

    const options = {
        numSamples: 1,
        width: "1024",
        height: "1024",
        negativePrompt: "",
        defaultPrompt: "Whole single object, front perspective, isolated against white background.",
    };

    const handleGenerateObject = async () => {
        setLoadingAI(true);
        const newWidth = getClosestSize(width).toString();
        const newHeight = getClosestSize(height).toString();
        try {
            const additionalDesc = imageType === IMAGE_TYPES.CHARACTER ? "This has to be a character" : "";
            const aiRes = await aiWorldController.enchancePrompt(prompt + " " + additionalDesc);
            if (!aiRes) throw new Error("Failed to enchance prompt");

            const res = await aiWorldController.generateModelImage(
                aiRes,
                {
                    width: newWidth,
                    height: newHeight,
                    negativePrompt,
                    prompt,
                    numSamples,
                    style: SKYBOX_STYLES.THREE_D_CARTOON,
                },
                options.defaultPrompt,
                imageType,
                false,
                err => {
                    setLoadingAI(false);
                    toast.error(err || "Failed to generate object image");
                },
            );

            if (res) {
                const {name, width, height, url, type} = res;
                if (url) {
                    switch (imageType) {
                        case IMAGE_TYPES.SKYBOX:
                            await aiWorldController.addSkybox(url, name || "Generated Object");
                            break;
                        case IMAGE_TYPES.CHARACTER:
                        case IMAGE_TYPES.OBJECT:
                            await aiWorldController.addSpriteObject(
                                url,
                                width || 5,
                                height || 5,
                                name || "Generated Object",
                                type,
                            );
                            break;
                        case IMAGE_TYPES.BACKDROP:
                            await aiWorldController.addBackdrop(url, name || "Generated Object");
                            break;
                    }
                }
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to generate object");
        } finally {
            setPrompt("");
            setLoadingAI(false);
        }
    };

    const getClosestSize = (value: number) => {
        return sizeOptions.reduce((closest, num) =>
            Math.abs(num - value) < Math.abs(closest - value) ? num : closest,
        );
    };

    useEffect(() => {
        if (imageType === IMAGE_TYPES.SKYBOX) {
            if (width < 1024) setWidth(1024);
        }
    }, [imageType]);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Enter" && isOpen && !loadingAI && prompt) {
                handleGenerateObject();
            }
        };
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen, prompt, loadingAI]);

    if (!isOpen) return null;

    return (
        <>
            <Wrapper style={{padding: "0", gap: "0"}}>
                <Wrapper>
                    <Title>What do you want to make?</Title>
                    <TypesWrapper>
                        {Object.values(IMAGE_TYPES).map(value => (
                            <GenerationType key={value} onClick={() => setImageType(value)}>
                                <TypeImageWrapper $isSelected={imageType === value} />

                                {value}
                            </GenerationType>
                        ))}
                    </TypesWrapper>
                    <Section>
                        <Title>How would you describe it?</Title>
                        <StyledTextArea
                            value={prompt}
                            onChange={e => setPrompt(e.target.value)}
                            placeholder="Write a description..."
                        />
                    </Section>
                </Wrapper>
                <Separator />
                <Wrapper>
                    <Title onClick={() => setIsExpanded(!isExpanded)} style={{cursor: "pointer"}}>
                        Advanced <Expand src={arrowIcon} alt="arrow" $isExpanded={isExpanded} />
                    </Title>
                    {isExpanded && (
                        <>
                            <Section>
                                <Title>Negative Prompt</Title>
                                <StyledTextArea
                                    value={negativePrompt}
                                    onChange={e => setNegativePrompt(e.target.value)}
                                    placeholder="Write what you don’t want.."
                                />
                            </Section>

                            <Section>
                                <NumericInputRow
                                    label="Width"
                                    value={width}
                                    setValue={value => setWidth(value)}
                                    min={minValue}
                                    max={2048}
                                />
                                <NumericInputRow
                                    label="Height"
                                    value={height}
                                    setValue={value => setHeight(value)}
                                    min={minValue}
                                    max={2048}
                                />
                            </Section>
                        </>
                    )}
                </Wrapper>
                {loadingAI && (
                    <LoadingWrapper>
                        <Oval
                            visible
                            height="40"
                            width="40"
                            color="#0284c7"
                            secondaryColor="#333"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass="loaderWrapper"
                        />
                    </LoadingWrapper>
                )}
            </Wrapper>
            <BottomBar>
                <SubmitButton disabled={!prompt || loadingAI} onClick={handleGenerateObject}>
                    Generate
                </SubmitButton>
            </BottomBar>
        </>
    );
};
