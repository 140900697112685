/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
//import {dispatch} from '../third_party';

import EventList from "./EventList";
import PackageManager from "../package/PackageManager";
import PlayerLoader from "./component/PlayerLoader";
import PlayerEvent from "./component/PlayerEvent";
import PlayerControl from "./component/PlayerControl";
import AiNpcControl from "./component/AiNpcControl";
import AiWorldControl from "./component/AiWorldControl";
import PlayerAudio from "./component/PlayerAudio";
import PlayerRenderer from "./component/PlayerRenderer";
import PlayerAnimation from "./component/PlayerAnimation";
import PlayerPhysics2 from "./component/PlayerPhysics2";
import PlayerLoadMask from "./component/PlayerLoadMask";
import Ai16zPlayerLoadMask from "./component/Ai16zPlayerLoadMask";
import WebVR from "./component/WebVR";
import CssUtils from "../utils/CssUtils";
import global from "../global";
import {dispatch} from "d3-dispatch";
import * as THREE from "three";
import GameManager from "../behaviors/game/GameManager";
import Ajax from "../utils/Ajax";
import Converter from "../serialization/Converter";
import ModelLoader from "../assets/js/loaders/ModelLoader";
import {backendUrlFromPath} from "../utils/UrlUtils";
import CharacterBehaviorUpdater from "../serialization/behaviours/CharacterBehaviorUpdater";
import CharacterBehaviorConverter from "../serialization/behaviours/CharacterBehaviorConverter";
import {IFRAME_MESSAGES} from "../types/editor";
import {CSS3DRenderer} from "three/examples/jsm/renderers/CSS3DRenderer";
import {AnimationController} from "../controls/AnimationController";
import Stats from "stats.js";
import RendererStats from "../utils/RendererStats";
import {VRMExpressionController} from "../controls/VRMExpressionController";
import {toast} from "react-toastify";

/**
 * @author mrdoob / http://mrdoob.com/
 *
 * @param {HTMLElement} container 容器
 * @param {Object} options 配置信息
 * @param {String} options.server 服务器信息，例如：http://localhost:2000
 * @param {Boolean} options.enableThrowBall 是否允许扔小球进行物理测试
 * @param {Boolean} options.showStats 是否显示性能控件
 */
class Player {
    constructor(container, options = {}) {
        this.container = container;
        this.options = options;

        this.options.server = this.options.server || window.origin;
        this.options.enablePhysics = false; // 这个配置在场景里
        this.options.enableThrowBall = false;
        this.options.showStats = this.options.showStats || false;
        this.options.useInstancing = this.options.useInstancing || false;

        if (!global.app) {
            global.app = this;
            global.app.storage = {debug: this.options.isDebugMode};
        }

        this.dispatch = dispatch.apply(dispatch, EventList);
        this.call = this.dispatch.call.bind(this.dispatch);
        this.on = this.dispatch.on.bind(this.dispatch);

        this.start = this.start.bind(this);
        this.startAnimationLoop = this.startAnimationLoop.bind(this);
        this.stopAnimationLoop = this.stopAnimationLoop.bind(this);

        window.addEventListener("resize", this.onResize.bind(this));

        var observer = new MutationObserver(this.onResize.bind(this));

        observer.observe(this.container, {
            attributes: true,
            characterData: false,
            childList: false,
        });

        this.scene = null;
        this.sceneId = "";
        this.authToken = "";
        this.camera = null;
        this.renderer = null;
        this.rendererCSS = null;

        this.stats = null;
        this.rendererStats = null;

        this.gis = null;

        this.package = new PackageManager();
        this.require = this.package.require.bind(this.package);

        this.game = new GameManager(this);

        this.event = new PlayerEvent(this);

        this.loader = new PlayerLoader(this);
        this.control = new PlayerControl(this);
        this.aiNpcControl = new AiNpcControl(this);
        this.aiWorldControl = new AiWorldControl(this);
        this.animationControl = new AnimationController(this);
        this.vrmExpressionControl = new VRMExpressionController(this);
        this.audio = new PlayerAudio(this);
        this.playerRenderer = new PlayerRenderer(this);
        this.animation = new PlayerAnimation(this);
        this.physics = new PlayerPhysics2(this);
        this.webvr = new WebVR(this);

        const url = new URL(window.self.location.href);
        const ai16z = url.searchParams.get("ai16z");
        const metaverse = url.searchParams.get("metaverse");
        const useotherMask = !!ai16z || !!metaverse;

        this.mask = useotherMask ? new Ai16zPlayerLoadMask(this) : new PlayerLoadMask(this);

        this.isPlaying = false;
        this.isPaused = false;
        this.isSpriteCharacter = false;
        this.isGameMenuOpen = false;
        this.clock = new THREE.Clock(false);
        this.autoSaveState = false;

        this.editor = this.options.editor;
        this.room = null;
        this.userName = null;
        this.delta = 0;
        this.interval = 1 / 60;

        // 保证播放器在不加载语言包的情况下正常运行
        if (!window._t) {
            window._t = function (data) {
                return data;
            };
        }
    }

    /**
     * @param {String} sceneData
     * @param {boolean} useWebGPU
     * @param {boolean} isMultiplayer
     * @param {boolean} showStats
     * @param {boolean} useAvatar
     * @param {String} sceneId
     * @param {boolean} isEditorMode
     * @param {String} userId
     * @param {String} userName
     * @param {String} authToken
     */
    start(
        sceneId,
        isMultiplayer,
        showStats,
        useInstancing,
        useWebGPU,
        useAvatar,
        sceneData,
        isEditorMode,
        userId,
        userName,
        authToken,
    ) {
        if (isMultiplayer && !sceneId) {
            global.app.toast(_t("Scene ID is missing"));
            console.error(_t("Scene ID is missing"));
            window.parent.postMessage(IFRAME_MESSAGES.GAME_CLOSED, "*");
            return Promise.reject("You need to save the scene to use multiplayer option.");
        }

        this.sceneId = sceneId;
        this.authToken = authToken;

        if (userName) {
            this.userName = userName;
        }

        if (this.isPlaying) {
            return Promise.resolve();
        }

        if (typeof sceneData !== "string") {
            global.app.toast(_t("Scene data of invalid type "));
            console.error(_t("Scene data of invalid type "));
            window.parent.postMessage(IFRAME_MESSAGES.GAME_CLOSED, "*");
            return Promise.reject();
        }

        this.isPlaying = true;
        console.log("Starting Player...");
        return new Promise(async (resolve, reject) => {
            // global.app.setAutoSave(false);
            // this.autoSaveState = global.app.storage
            //     ? global.app.storage.autoSave
            //     : false;

            let jsons = JSON.parse(sceneData);

            if (this.isPaused) {
                const elapsedTime = this.clock.elapsedTime;
                this.clock.start();
                this.clock.elapsedTime = elapsedTime;
                this.isPaused = false;
                resolve();
                return;
            }

            this.container.style.display = "block";
            this.mask.show();

            this.renderer = await this.getRenderer(useWebGPU);
            global.app.renderer = this.renderer;

            //FIXME: remove physics reference from Scene loader and create Terrain physics in addObjects()

            this.loader
                .create(null, jsons, {
                    domWidth: this.container.clientWidth,
                    domHeight: this.container.clientHeight,
                })
                .then(async obj => {
                    if (useAvatar) {
                        await this.replaceCharacterWithAvatar(obj, userId);
                    }

                    await this.initPlayer(obj, useWebGPU, showStats);

                    this.physics
                        .create(sceneId, this.scene, isMultiplayer)
                        .then(async physics => {
                            this.physics.addObjects();

                            this.dispatch.call("init", this);

                            let promise1 = this.event.create(this.scene, this.camera, this.renderer, obj.scripts);
                            let promise2 = this.control.create(physics, this.scene, this.camera, this.renderer, this);
                            let promise3 = this.audio.create(this.scene, this.camera, this.renderer);
                            let promise4 = this.playerRenderer.create(
                                this.scene,
                                this.camera,
                                this.renderer,
                                this.rendererCSS,
                            );
                            let promise5 = this.animation.create(
                                this.scene,
                                this.camera,
                                this.renderer,
                                obj.animations,
                            );
                            //let promise7 = this.webvr.create(this.scene, this.camera, this.renderer);

                            Promise.all([promise1, promise2, promise3, promise4, promise5])
                                .then(() => {
                                    // game manager requires all other components to be initialized
                                    this.aiNpcControl.create(
                                        this.scene,
                                        this.camera,
                                        this.renderer,
                                        sceneId,
                                        this,
                                        this.control.control.player,
                                    );
                                    this.aiWorldControl.create(
                                        this.scene,
                                        this.camera,
                                        this.renderer,
                                        sceneId,
                                        this,
                                        this.control.control.player,
                                    );
                                    this.game
                                        .create(
                                            this.physics.physics,
                                            this.control.control,
                                            this.physics,
                                            this.scene,
                                            this.camera,
                                            obj.behaviors,
                                            isEditorMode,
                                            useInstancing,
                                            this.physics.world,
                                        )
                                        .then(() => {
                                            this.event.init();
                                            this.clock.start();
                                            this.event.start();
                                            this.animationControl.start(this.game);
                                            this.vrmExpressionControl.start(this.game);
                                            this.startAnimationLoop();
                                            this.showStats();
                                            global.app.call("playerStarted", null);
                                            console.log("Player Started");
                                            this.game.hud.create(
                                                !this.game.isEnabled || !this.game.scene,
                                                isEditorMode,
                                            );
                                            this.mask.hide();

                                            resolve();
                                        })
                                        .catch(e => {
                                            window.parent.postMessage(IFRAME_MESSAGES.GAME_CLOSED, "*");
                                            console.error("Player failed to create game manager", e);
                                            this.stop();
                                            reject(e);
                                        });
                                })
                                .catch(e => {
                                    console.error("Player failed to start", e);
                                    this.stop();
                                    reject(e);
                                    window.parent.postMessage(IFRAME_MESSAGES.GAME_CLOSED, "*");
                                });
                        })
                        .catch(err => {
                            console.error("Physics failed to start", err);
                            this.stop();
                            reject(err);
                            window.parent.postMessage(IFRAME_MESSAGES.GAME_CLOSED, "*");
                        });
                })
                .catch(e => {
                    console.error("Player failed to load the project", e);
                    this.stop();
                    reject(e);
                    window.parent.postMessage(IFRAME_MESSAGES.GAME_CLOSED, "*");
                });
        });
    }

    startAnimationLoop() {
        this.delta = 0;
        this.renderer?.setAnimationLoop(this.animate.bind(this));
    }

    stopAnimationLoop() {
        this.renderer?.setAnimationLoop(null);
    }

    /**
     * 停止播放器
     */
    stop() {
        if (!this.isPlaying && !this.isPaused) {
            return;
        }

        this.mask.hide();

        //global.app.setAutoSave(this.autoSaveState);
        this.isPlaying = false;
        this.isPaused = false;
        this.renderer?.setAnimationLoop(null);

        this.event.stop();

        this.loader.dispose();
        this.event.dispose();
        this.control.dispose();
        this.aiNpcControl.dispose();
        this.aiWorldControl.dispose();
        this.animationControl.dispose();
        this.vrmExpressionControl.dispose();
        this.audio.dispose();
        this.animation.dispose();
        this.physics.dispose();
        this.webvr.dispose();

        this.playerRenderer.dispose();

        if (this.rendererCSS) {
            this.container.removeChild(this.rendererCSS.domElement);
        }
        this.container.style.display = "none";
        if (this.scene != null) {
            this.scene.children.length = 0;
        }

        this.renderer.clear(); //clean up renderer memory
        this.rendererCSS.clear();

        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.rendererCSS = null;

        this.clock.stop();

        this.game.reset();

        this.hideStats();

        global.app.call("playerStopped", null);
        window.parent.postMessage(IFRAME_MESSAGES.GAME_ENDED, "*");
    }

    pause() {
        if (!this.isPlaying) {
            return;
        }
        this.isPlaying = false;
        this.isPaused = true;
        this.clock.stop();
    }

    async getRenderer(enableWebGPU) {
        let renderer = null;
        let isAvailable = enableWebGPU && navigator.gpu && (await navigator.gpu.requestAdapter());
        if (!THREE.WebGPURenderer || !isAvailable) {
            console.log("RENDERER: WebGL");
            renderer = new THREE.WebGLRenderer({antialias: true});
        } else {
            console.log("RENDERER: WebGPU");
            renderer = new THREE.WebGPURenderer({antialias: true});
        }
        return renderer;
    }

    /**
     * @param {*} obj
     * @param {boolean} useWebGPU
     */
    async initPlayer(obj, useWebGPU, showStats) {
        var container = this.container;
        // options
        this.options.enablePhysics = obj.options.enablePhysics;
        this.options.enableVR = obj.options.enableVR;
        this.options.vrSetting = obj.options.vrSetting;

        // camera
        this.camera = obj.camera;

        if (!this.camera) {
            console.warn(`Player: Three is no camera in the scene.`);
            this.camera = new THREE.PerspectiveCamera(50, container.clientWidth / container.clientHeight, 0.1, 1000);
        }

        // scene
        this.scene = obj.scene || new THREE.Scene();

        // renderer
        //this.renderer = await this.getRenderer(useWebGPU);
        this.rendererCSS = new CSS3DRenderer();

        this.rendererCSS.setSize(container.clientWidth, container.clientHeight);

        const wrapper = this.rendererCSS.domElement.getElementsByTagName("div")[0];
        wrapper.style.position = "absolute";
        wrapper.style.top = "0";
        wrapper.style.left = "0";
        wrapper.style.zIndex = "2";

        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.domElement.style.position = "absolute";
        this.renderer.domElement.style.top = "0";
        this.renderer.domElement.style.left = "0";

        this.rendererCSS.domElement.appendChild(this.renderer.domElement);
        container.appendChild(this.rendererCSS.domElement);

        this.renderer.setSize(container.clientWidth, container.clientHeight);
        //container.appendChild(this.renderer.domElement);

        this.camera.aspect = this.renderer.domElement.width / this.renderer.domElement.height;
        this.camera.updateProjectionMatrix();

        this.camera.near = 0.1;
        this.camera.far = 10000;
        this.camera.updateProjectionMatrix();

        if (isNaN(this.camera.position.x) || isNaN(this.camera.position.y) || isNaN(this.camera.position.z)) {
            this.camera.position.set(0, 20, 0);
            this.camera.lookAt(new THREE.Vector3(0, 0, 0));
        }

        this.camera.children.forEach(child => {
            const parent = child.parent;
            if (isNaN(parent.position.x) || isNaN(parent.position.y) || isNaN(parent.position.z)) {
                parent.position.set(0, 20, 0);
                parent.rotation.set(0, 0, 0);
            }
        });

        const listener = new THREE.AudioListener();
        this.camera.add(listener);
        console.log("Player: Camera initialized.", this.camera);

        this.renderer.domElement.style.filter = CssUtils.serializeFilter(obj.options);

        this.scene.children = this.scene.children.filter(child => {
            if (child.userData && child.userData.isInfiniteGrid) {
                if (child.material) {
                    child.material.dispose();
                }
                this.scene.remove(child);
                return false;
            }
            return true;
        });

        if (showStats) {
            this.stats = new Stats();
            this.stats.showPanel(0);
            // set it to bottom-left position
            this.stats.dom.style.cssText = "position:fixed;bottom:0;left:0;cursor:pointer;opacity:0.9;z-index:90000";
            document.body.appendChild(this.stats.dom);

            this.rendererStats = new RendererStats();
            this.rendererStats.domElement.style.position = "absolute";
            this.rendererStats.domElement.style.left = "0px";
            this.rendererStats.domElement.style.bottom = "50px";
            document.body.appendChild(this.rendererStats.domElement);
        }

        if (global.app && global.app.objectOutliner) {
            global.app.objectOutliner = null;
        }

        global.app.scene = this.scene;
    }

    animate() {
        if (!this.isPlaying) {
            return;
        }

        if (this.stats) {
            this.stats.begin();
        }

        //this.renderer.clear(true, true, true); // Clear color, depth, and stencil buffers

        //this.clock.getDelta(); // see: ../polyfills.js
        var deltaTime = this.clock.getDelta();
        this.delta += deltaTime;

        if (this.delta > this.interval) {
            this.event.update(this.clock, deltaTime);
            this.control.update(this.clock, deltaTime);
            this.aiNpcControl.update(this.clock, deltaTime);
            this.aiWorldControl.update(this.clock, deltaTime);
            this.animationControl.update(this.clock, deltaTime);
            this.vrmExpressionControl.update(this.clock, deltaTime);
            this.playerRenderer.update(this.clock, deltaTime);
            this.animation.update(this.clock, deltaTime);
            this.physics.update(this.clock, deltaTime);
            this.webvr.update(this.clock, deltaTime);
            this.game.update(this.clock, deltaTime);

            if (this.stats) {
                this.stats.end();
            }

            this.delta = this.delta % this.interval;
        }
        if (this.rendererStats) {
            this.rendererStats.update(this.renderer);
        }
    }

    resize() {
        if (!this.camera || !this.renderer) {
            return;
        }

        var width = this.container.clientWidth;
        var height = this.container.clientHeight;

        var camera = this.camera;
        var renderer = this.renderer;

        camera.aspect = width / height;
        camera.updateProjectionMatrix();

        renderer.domElement;
        renderer.setSize(width, height);
        this.rendererCSS.setSize(width, height);
    }

    onResize() {
        this.resize();
    }

    setAutoSave() {
        //dummy function for compatibility between Application and Player
    }

    showStats() {
        if (global.app?.storage?.showStats) {
            Object.assign(global.app.stats.dom.style, {
                display: "block",
            });
        }
    }

    hideStats() {
        if (!global?.app?.stats?.dom?.style) return;
        Object.assign(global?.app?.stats?.dom?.style, {
            display: "none",
        });
    }

    async replaceCharacterWithAvatar(obj, userId) {
        const scene = obj.scene;
        const behaviors = obj.behaviors;

        let behaviorTarget = null;
        const characterBehavior = behaviors.find(behavior => behavior instanceof CharacterBehaviorUpdater);

        if (characterBehavior) {
            behaviorTarget = characterBehavior.target;
        }

        const character = scene.getObjectByProperty("uuid", behaviorTarget?.uuid);

        if (character) {
            try {
                const response = await Ajax.get({
                    url: backendUrlFromPath(`/api/Mesh/GetAvatar?UserID=${userId}`),
                    needAuthorization: false,
                });

                if (response?.data.Code !== 200 && response?.data.Msg) {
                    return;
                }

                const model = response?.data.Data;
                if (model) {
                    let loader = new ModelLoader();
                    const url = backendUrlFromPath(model.Url);

                    const avatar = await loader.load(url, model, {
                        camera: this.camera,
                        renderer: this.renderer,
                        audioListener: this.audioListener,
                        clearChildren: true,
                    });

                    if (!avatar) {
                        return;
                    }

                    Object.assign(avatar.userData, character.userData, {
                        Server: true,
                        ...model,
                    });
                    Object.assign(avatar.position, character.position);

                    avatar.rotation.x = character.rotation.x;
                    avatar.rotation.y = character.rotation.y;
                    avatar.rotation.z = character.rotation.z;
                    avatar.castShadow = character.castShadow;
                    avatar.uuid = character.uuid;
                    avatar.name = character.name;

                    scene.remove(character);
                    scene.add(avatar);

                    const characterBehaviorConverter = CharacterBehaviorConverter.DEFAULT;
                    characterBehaviorConverter.updateCharacterOptions(avatar, obj.camera);

                    characterBehavior.target = avatar;
                    if (characterBehavior.game) {
                        characterBehavior.game.player = avatar;
                    }
                }
            } catch (e) {
                console.warn("Could not load model", e);
            }
        }
    }

    /**
     * @param {any} objectId
     * @param {boolean} isServerObject
     */
    async saveAddedObjectInScene(object, isServerObject) {
        if (typeof object === "string") {
            object = this.scene.getObjectById(object);
        }

        if (!object) {
            return;
        }
        let list = [];
        new Converter().traverse(object, object.children, list, {}, isServerObject);

        const payload = {
            ID: this.sceneId,
            Object: JSON.stringify(list[0]),
        };

        try {
            Ajax.post({
                url: backendUrlFromPath(`/api/Scene/AddObject`),
                data: payload,
                needAuthorization: false,
                msgBodyType: "multipart",
            });
        } catch (e) {
            toast.error("Failed to save object in scene");
            console.error("Failed to save object in scene", e);
        }
    }

    /**
     * @param {any} object
     * @param {boolean} isServerObject
     */
    async editObjectInScene(object, isServerObject) {
        if (typeof object === "string") {
            object = this.scene.getObjectById(object);
        }

        if (!object) {
            return;
        }
        let list = [];
        new Converter().traverse(object, object.children, list, {}, isServerObject);

        const payload = {
            ID: this.sceneId,
            Object: JSON.stringify(list[0]),
        };

        try {
            Ajax.post({
                url: backendUrlFromPath(`/api/Scene/EditObject`),
                data: payload,
                needAuthorization: false,
                msgBodyType: "multipart",
            });
        } catch (e) {
            console.error("Failed to save object in scene", e);
        }
    }
}

export default Player;
