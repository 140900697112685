import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import ProceduralPlantBehaviorUpdater from "./ProceduralPlantBehaviorUpdater";
import { OBJECT_TYPES, ProceduralPlantBehaviorInterface, PROCEDURAL_PLANT_TYPES } from "../../types/editor";
import alphaImage from "../assets/images/alphaGrassImage.jpg";
import diffuseImage from "../assets/images/diffuseGrassImage.jpg";


class ProceduralPlantBehaviorConverter extends BaseBehaviorConverter<ProceduralPlantBehaviorInterface> {
    public static DEFAULT = new ProceduralPlantBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: ProceduralPlantBehaviorInterface): BehaviorUpdater {
        return new ProceduralPlantBehaviorUpdater(target, behavior);
    }

    getBehavior(target: THREE.Object3D, id: string): ProceduralPlantBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as ProceduralPlantBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have THROWABLE behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): ProceduralPlantBehaviorInterface {
        return {
            type: OBJECT_TYPES.PROCEDURAL_PLANT,
            plantType: PROCEDURAL_PLANT_TYPES.GRASS,
            id,
            enabled: true,
            numberOfPlants: 25000,
            windDirectionX: 0,
            windDirectionY: 3,
            windDirectionZ: 0.05,
            windStrength: 0.2,
            windSpeed: 8.5,
            alphaImage: alphaImage, 
            diffuseImage: diffuseImage,
            plantWidth: 0.07,
            plantHeight: 0.75,
            horizontalSegments: 1,
            verticalSegments: 8,
            isAnimated: true,
        };
    }

}

export default ProceduralPlantBehaviorConverter;
