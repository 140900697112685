/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
/**
 * 自定义事件列表
 *
 */
const EventList = [
    // 播放器事件
    "init",
    "resize",

    // VR事件
    "maskProgress", // 加载器进度
    "vrConnected", // 进入VR
    "vrDisconnected", // 退出VR
    "vrSelectStart", // VR选中开始
    "vrSelectEnd", // VR选中结束
    "gameUpdated",
    "gameCreated",
    "gameStarted",
    "gameEnded",
    "pauseGame",
    "gameResumed",
    "gameTimerUpdate",
    "lockEvent",
    "unlockEvent",
    "playerStarted",
    "removeGunAimer",
    "playingGame",
    "stoppedPlayingGame",
    "playerFallBack",
    "playerDead",
    "enableInfiniteGrid",
    "disableInfiniteGrid",
    "disposeEffectOutliner",
    "startVehicle",
    "stopVehicle",
    "updateVehicle",
    "disposeVehicle",
    "update3RD",
    "chatActivated",
    "chatDeactivated",
];

export default EventList;
