import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import ProceduralTerrainBehaviorUpdater from "./ProceduralTerrainBehaviorUpdater";
import { OBJECT_TYPES, ProceduralTerrainBehaviorInterface, PROCEDURAL_TERRAIN_TYPES } from "../../types/editor";
import perlinNoiseImage from "../assets/images/perlinNoiseImage.png";


class ProceduralTerrainBehaviorConverter extends BaseBehaviorConverter<ProceduralTerrainBehaviorInterface> {
    public static DEFAULT = new ProceduralTerrainBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: ProceduralTerrainBehaviorInterface): BehaviorUpdater {
        return new ProceduralTerrainBehaviorUpdater(target, behavior);
    }

    getBehavior(target: THREE.Object3D, id: string): ProceduralTerrainBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as ProceduralTerrainBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): ProceduralTerrainBehaviorInterface {
        return {
            type: OBJECT_TYPES.PROCEDURAL_TERRAIN,
            terrainType: PROCEDURAL_TERRAIN_TYPES.MOUNTAINS,
            id,
            enabled: true,
            perlinNoiseScale: 2.5, //default elevation scale
            perlinNoiseImage: perlinNoiseImage, //default height map image
            terrainWidth: 50.0,
            terrainLength: 50.0,
            terrainSegments: 25,
        };
    }

}

export default ProceduralTerrainBehaviorConverter;
