import { useEffect, useState } from "react";
import { ProceduralPlantBehaviorInterface, PROCEDURAL_PLANT_TYPES } from "../../../../../types/editor";
import global from "../../../../../global";
import { Separator } from "../common/Separator";
import { SelectionOfButtons } from "../common/SelectionOfButtons";
import { NumericInputRow } from "../common/NumericInputRow";
import { StyledButton } from "../../common/StyledButton";
import { PanelSectionTitleSecondary } from "../RightPanel.style";
import { ContentItem } from "../panels/GameSettings/GameSettings.style";
import { BasicCombobox } from "../../common/BasicCombobox";
import { ProceduralPlantBehaviorConverter } from "../../../../../serialization/behaviours";
import { ImageSection } from "../common/ImageSection";

type Props = {
    behavior: ProceduralPlantBehaviorInterface;
};

export const ProceduralPlantBehavior = ({ behavior: initialBehavior }: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;

    const [behaviorState, setBehaviorState] = useState<ProceduralPlantBehaviorInterface>({
        ...ProceduralPlantBehaviorConverter.DEFAULT.getDefaultBehavior(initialBehavior.id), 
        ...initialBehavior, 
    });

    const getObjectBehavior = () => {
        if (selected) {
            const obj = editor.objectByUuid(selected.uuid);
            return obj.userData.behaviors?.find((userBehavior: any) => userBehavior.id === behaviorState.id);
        }
        return null;
    };

    const handleInputChange = (value: any, name: keyof ProceduralPlantBehaviorInterface) => {
        if (selected) {
            const objBehavior = getObjectBehavior();
            if (objBehavior) {
               
                objBehavior[name] = value;

                const updatedBehavior = {
                    ...ProceduralPlantBehaviorConverter.DEFAULT.getDefaultBehavior(objBehavior.id),
                    ...objBehavior, 
                };

                //console.log("Updated objBehavior:", updatedBehavior);

                const behaviors = selected.userData.behaviors || [];
                const updatedBehaviors = behaviors.map((behavior: ProceduralPlantBehaviorInterface) =>
                    behavior.id === updatedBehavior.id ? updatedBehavior : behavior
                );
           
                selected.userData.behaviors = updatedBehaviors;

                setBehaviorState((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));

                app.call(`objectChanged`, app.editor, app.editor.selected);
                app.call(`objectUpdated`, app.editor, app.editor.selected);
            }
        }
    };

    useEffect(() => {
        if (selected) {
            const objBehavior = getObjectBehavior();
            if (objBehavior) {
                const updatedBehavior = {
                    ...ProceduralPlantBehaviorConverter.DEFAULT.getDefaultBehavior(objBehavior.id),
                    ...objBehavior,
                };

                const behaviors = selected.userData.behaviors || [];
                const updatedBehaviors = behaviors.map((behavior: ProceduralPlantBehaviorInterface) =>
                    behavior.id === updatedBehavior.id ? updatedBehavior : behavior
                );
                selected.userData.behaviors = updatedBehaviors;

                setBehaviorState(updatedBehavior);

                app.call(`objectChanged`, app.editor, app.editor.selected);
                app.call(`objectUpdated`, app.editor, app.editor.selected);

            }
        }
    }, []);

    const proceduralPlantOptions = Object.keys(PROCEDURAL_PLANT_TYPES).map((key) => ({
        key,
        value: PROCEDURAL_PLANT_TYPES[key as keyof typeof PROCEDURAL_PLANT_TYPES],
    }));

    return (
        <>
            <SelectionOfButtons>
                <StyledButton
                    width="calc(50% - 3px)"
                    isBlue={behaviorState.isAnimated}
                    isActive={behaviorState.isAnimated}
                    onClick={() => handleInputChange(true, "isAnimated")}
                >
                    <span>Animated</span>
                </StyledButton>
                <StyledButton
                    width="calc(50% - 3px)"
                    isBlue={!behaviorState.isAnimated}
                    isActive={!behaviorState.isAnimated}
                    onClick={() => handleInputChange(false, "isAnimated")}
                >
                    <span>Static</span>
                </StyledButton>
                <Separator />
            </SelectionOfButtons>

            <ContentItem style={{ marginBottom: "12px" }} $flexDirection="row" $justifyContent="space-between">
                <PanelSectionTitleSecondary>Plant Type</PanelSectionTitleSecondary>
                <BasicCombobox
                    data={proceduralPlantOptions}
                    value={proceduralPlantOptions.find((option) => option.value === behaviorState.plantType) || proceduralPlantOptions[0]}
                    onChange={(option) => handleInputChange(option?.value, "plantType")}
                />
            </ContentItem>
            <NumericInputRow
                width="75px"
                label="Wind Direction X"
                value={behaviorState.windDirectionX || 0}
                setValue={(value) => handleInputChange(value, "windDirectionX")}
            />
            <NumericInputRow
                width="75px"
                label="Wind Direction Y"
                value={behaviorState.windDirectionY || 0}
                setValue={(value) => handleInputChange(value, "windDirectionY")}
            />
            <NumericInputRow
                width="75px"
                label="Wind Direction Z"
                value={behaviorState.windDirectionZ || 0}
                setValue={(value) => handleInputChange(value, "windDirectionZ")}
            />

            <NumericInputRow
                width="75px"
                label="Wind Strength"
                value={behaviorState.windStrength}
                setValue={(value) => handleInputChange(value, "windStrength")}
            />
            <NumericInputRow
                width="75px"
                label="Wind Speed"
                value={behaviorState.windSpeed}
                setValue={(value) => handleInputChange(value, "windSpeed")}
            />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <div style={{ display: "flex", gap: "10px" }}>
                    <ImageSection
                        text="Alpha Texture"
                        uploadedFile={behaviorState.alphaImage || null}
                        setUploadedFile={(value) => handleInputChange(value, "alphaImage")}
                    />
                    <ImageSection
                        text="Diffuse Texture"
                        uploadedFile={behaviorState.diffuseImage || null}
                        setUploadedFile={(value) => handleInputChange(value, "diffuseImage")}
                    />
                </div>
            </div>

            <NumericInputRow
                width="75px"
                label="Number of Plants"
                value={behaviorState.numberOfPlants}
                setValue={(value) => handleInputChange(value, "numberOfPlants")}
            />
            <NumericInputRow
                width="75px"
                label="Plant Width"
                value={behaviorState.plantWidth}
                setValue={(value) => handleInputChange(value, "plantWidth")}
            />
            <NumericInputRow
                width="75px"
                label="Plant Height"
                value={behaviorState.plantHeight}
                setValue={(value) => handleInputChange(value, "plantHeight")}
            />
            <NumericInputRow
                width="75px"
                label="Horizontal Segments"
                value={behaviorState.horizontalSegments}
                setValue={(value) => handleInputChange(value, "horizontalSegments")}
            />
            <NumericInputRow
                width="75px"
                label="Vertical Segments"
                value={behaviorState.verticalSegments}
                setValue={(value) => handleInputChange(value, "verticalSegments")}
            />
        </>
    );
};