import * as THREE from 'three';

export class FootDust {
    private scene: THREE.Scene;
    private particleMaterial: THREE.ShaderMaterial;
    private particles: THREE.Points[] = []; // Store references to particles

    constructor(scene: THREE.Scene) {
        this.scene = scene;
        this.particleMaterial = this.createShaderMaterial();
    }
    private createShaderMaterial(): THREE.ShaderMaterial {
        return new THREE.ShaderMaterial({
            uniforms: {
                uTime: { value: 0 },
                uLifetime: { value: 3.0 }, // Default particle lifetime in seconds
                uSize: { value: 40.0 }, // Initial size of the particles
                uColor: { value: new THREE.Color(1.0, 1.0, 1.0) } // Default white color
            },
            vertexShader: `
            uniform float uSize;
            void main() {
                gl_PointSize = uSize;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `,
            fragmentShader: `
            uniform float uTime;
            uniform float uLifetime;
            uniform vec3 uColor;
            void main() {
                float dist = length(gl_PointCoord - vec2(0.5));
                if (dist > 0.5) discard;

                float alpha = 1.0 - (uTime / uLifetime);
                alpha = clamp(alpha, 0.0, 1.0);

                gl_FragColor = vec4(uColor, alpha); // Use the passed color with fading alpha
            }
        `,
            transparent: true,
        });
    }

    public createParticles(
        playerPosition: THREE.Vector3,
        particleCount: number,
        fadeOutDuration: number,
        color: THREE.Color,
        particleSize: number, 
        startOpacity: number,
        playerSize: number 
    ) {
        const particleGeometry = new THREE.BufferGeometry();
        const vertices = new Float32Array([0, 0, 0]);
        particleGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

        // Use the existing particleMaterial and update its uniforms
        const particleMaterial = this.particleMaterial.clone();
        particleMaterial.uniforms.uLifetime.value = fadeOutDuration;
        particleMaterial.uniforms.uColor.value = color; // Set the color for particles
        particleMaterial.opacity = startOpacity; // Set the initial opacity

        for (let i = 0; i < particleCount; i++) {
            const particle = new THREE.Points(particleGeometry, particleMaterial);

            // Randomize the start position around the player's width
            const randomOffset = new THREE.Vector3(
                (Math.random() - 0.5) * playerSize, // Adjust X range based on player width
                (Math.random() - 0.5) * 0.2,                 // Adjust Y range for slight vertical variation
                (Math.random() - 0.5) * playerSize  // Adjust Z range based on player width
            );

            particle.position.set(
                playerPosition.x + randomOffset.x,
                0, // Ensure correct y positioning
                playerPosition.z + randomOffset.z
            );

            this.scene.add(particle);
            this.particles.push(particle); // Store reference to particle for later disposal

            const startTime = Date.now();
            const fadeOut = () => {
                const elapsedTime = (Date.now() - startTime) / 1000;
                const normalizedTime = Math.min(elapsedTime / fadeOutDuration, 1); // Normalize time

                // Update particle size and transparency
                particle.material.uniforms.uTime.value = elapsedTime;
                const size = particleSize * (1 - normalizedTime); // Reduce size over time
                particle.material.uniforms.uSize.value = size;

                // Update transparency based on elapsed time
                const alpha = startOpacity * (1 - normalizedTime); // Reduce alpha over time
                particle.material.uniforms.uColor.value = color; // Keep the base color
                particle.material.opacity = alpha; // Adjust opacity

                // Move the particle in the opposite direction of the player's movement
                const movementDirection = new THREE.Vector3(
                    playerPosition.x / 2,
                    playerPosition.y, // Ensure correct y positioning
                    playerPosition.z / 2
                ).normalize();
                particle.position.add(movementDirection.multiplyScalar(0.1));

                particle.position.y = playerPosition.y / 10; // Ensure Y positioning stays at the player's height

                if (elapsedTime >= fadeOutDuration) {
                    this.scene.remove(particle);
                    particle.geometry.dispose();
                    particle.material.dispose();
                } else {
                   requestAnimationFrame(fadeOut);
                }
            };
            fadeOut();
        }
    }


    public dispose() {
        // Dispose of all particles
        for (const particle of this.particles) {
            this.scene.remove(particle);
            particle.geometry.dispose();
        }
        this.particles.length = 0; // Clear the particles array

        // Dispose of the particle material
        if (this.particleMaterial) {
            this.particleMaterial.dispose();
        }
    }
}
