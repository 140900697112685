import VoiceRecorder from "../utils/VoiceRecorder";
import AiAgent from "../utils/AiAgent";
import {Camera, Object3D, Scene} from "three";
import global from "../global";
import {AiNPCBehaviorInterface, OBJECT_TYPES} from "../types/editor";
import AiConversationView from "../player/component/AiConversationView";

const keysMapping: Record<number, string> = {
    8: "Backspace",
    69: "E",
};

class AIConversationsController {
    private scene: Scene;
    private player: Object3D;
    private voiceRecorder: VoiceRecorder | null = null;
    aiAgents: AiAgent[] = [];
    private gamePaused: boolean = false;
    private readonly boundHandleKeyDown: (event: KeyboardEvent) => void;
    private readonly boundHandleKeyUp: (event: KeyboardEvent) => void;
    private buttonView: AiConversationView | null = null;
    private sceneId: string = "";
    private app: any;

    constructor(app: any, scene: Scene, camera: Camera, sceneId: string, model: Object3D) {
        this.scene = scene;
        this.player = model;

        global.app!.on("gameStarted.AIConversationController", this.handleGameStarted.bind(this));
        global.app!.on("pauseGame.AIConversationController", this.handleGamePaused.bind(this));
        global.app!.on("gameEnded.AIConversationController", this.handleGameEnded.bind(this));

        this.boundHandleKeyDown = this.handleKeyDown.bind(this);
        this.boundHandleKeyUp = this.handleKeyUp.bind(this);
        this.buttonView = new AiConversationView(app);
        this.sceneId = sceneId;
        this.app = app;
        this.setAiAgents();
    }

    getAIAgentBehavior = (object: Object3D) => {
        return object.userData.behaviors.find((behavior: any) => behavior.type === OBJECT_TYPES.AI_NPC) as
            | AiNPCBehaviorInterface
            | undefined;
    };

    private bindEventListeners(): void {
        document.addEventListener("keydown", this.boundHandleKeyDown);
        document.addEventListener("keyup", this.boundHandleKeyUp);
    }

    private unbindEventListeners(): void {
        document.removeEventListener("keydown", this.boundHandleKeyDown);
        document.removeEventListener("keyup", this.boundHandleKeyUp);
    }

    private handleKeyDown(event: KeyboardEvent): void {
        const key = keysMapping[event.keyCode];

        const aiAgentInRange = this.getAiAgentInRange();

        if (
            key === "E" &&
            !this.gamePaused &&
            aiAgentInRange &&
            !aiAgentInRange.isBusy &&
            !aiAgentInRange.isPlaying &&
            aiAgentInRange.behavior.active_in_voice_chat
        ) {
            aiAgentInRange.isBusy = true;
            this.voiceRecorder?.startRecording(blob => {
                const file = new File([blob], "audio.wav", {type: blob.type});
                aiAgentInRange.sendAudioFile(file);
            });
        }

        if (key === "Backspace" && !this.gamePaused) {
            //this.aiAgent?.reset();
        }
    }

    private playSpeechAnimation(delta: number) {
        for (const aiAgent of this.aiAgents) {
            if (aiAgent.isPlaying) {
                if (!aiAgent.currentAnimationName) {
                    aiAgent.playSpeechAnimation();
                }
            } else {
                aiAgent.stopSpeechAnimation();
            }
        }
    }

    getClosestAiAgent() {
        const playerPosition = this.player?.position;
        let closestAiAgent = null;
        let closestDistance = Number.MAX_VALUE;

        for (const aiAgent of this.aiAgents) {
            const aiAgentPosition = aiAgent.model.position;
            const distance = playerPosition.distanceTo(aiAgentPosition);

            if (distance < closestDistance) {
                closestDistance = distance;
                closestAiAgent = aiAgent;
            }
        }

        return closestAiAgent;
    }

    private checkAiAgentRange() {
        if (this.aiAgents.length === 0) {
            return;
        }

        const playerPosition = this.player?.position;

        for (const aiAgent of this.aiAgents) {
            const aiAgentPosition = aiAgent.model.position;
            const distance = playerPosition.distanceTo(aiAgentPosition);

            aiAgent.isInRange = distance <= aiAgent.behavior.range;

            if (aiAgent.isInRange) {
                const gain = 1 - distance / aiAgent.behavior.range + 0.01;
                aiAgent.setGainNodeValue(gain > 1 ? 1 : gain);
            } else {
                aiAgent.setGainNodeValue(0);
            }
        }
    }

    private getAiAgentInRange() {
        return this.aiAgents.find(aiAgent => aiAgent.isInRange);
    }

    private handleKeyUp(event: KeyboardEvent): void {
        const key = keysMapping[event.keyCode];

        if (key === "E") {
            this.voiceRecorder?.stopRecording();
        }
    }

    private setAiAgents() {
        let activeVoiceRecorder = false;
        this.scene.traverse((object: Object3D) => {
            if (object.userData.behaviors) {
                const aiNpcBehavior = this.getAIAgentBehavior(object);
                if (aiNpcBehavior && aiNpcBehavior.enabled) {
                    if (!activeVoiceRecorder) {
                        activeVoiceRecorder = aiNpcBehavior.active_in_voice_chat;
                    }
                    /*const aiAgent = global.app?.room
                        ? new AiAgentMultiplayer(
                              object,
                              name,
                              description,
                              additional_prompt,
                              range,
                              voice_id,
                              this.sceneId,
                              this.app.userName,
                              global.app.room,
                          )
                        : new AiAgent(
                              object,
                              name,
                              description,
                              additional_prompt,
                              range,
                              voice_id,
                              this.sceneId,
                              this.app.userName,
                          );*/

                    const aiAgent = new AiAgent(object, aiNpcBehavior, this.sceneId, this.app.userName);

                    /*if (aiAgent instanceof AiAgentMultiplayer) {
                        aiAgent.setupWebSocket();
                    }*/
                    this.aiAgents.push(aiAgent);
                }
            }
        });

        if (activeVoiceRecorder) {
            this.voiceRecorder = new VoiceRecorder();
            this.voiceRecorder.init();
        }
    }

    update = (delta: number) => {
        if (this.gamePaused) {
            return;
        }
        this.checkAiAgentRange();
        this.playSpeechAnimation(delta);

        const aiAgentInRange = this.getAiAgentInRange();

        if (aiAgentInRange && aiAgentInRange.behavior.active_in_voice_chat) {
            this.buttonView?.show(aiAgentInRange.behavior.name, aiAgentInRange.isBusy || aiAgentInRange.isPlaying);
        } else {
            this.buttonView?.dispose();
        }
    };

    private handleGamePaused = (): void => {
        this.gamePaused = true;
        //this.aiAgent?.pause();
        this.buttonView?.dispose();
        this.aiAgents.forEach(aiAgent => {
            aiAgent.setGainNodeValue(0);
        });
    };

    private handleGameEnded = (): void => {
        this.gamePaused = true;
        this.unbindEventListeners();
        this.buttonView?.dispose();
        this.aiAgents.forEach(aiAgent => {
            aiAgent.reset();
        });
    };

    private handleGameStarted = (): void => {
        this.gamePaused = false;
        this.bindEventListeners();
        //this.aiAgent?.resume();
    };

    public dispose() {
        this.unbindEventListeners();

        if (global.app) {
            global.app!.on("gameStarted.AIConversationController", null);
            global.app!.on("pauseGame.AIConversationController", null);
            global.app!.on("gameEnded.AIConversationController", null);
        }
    }
}

export default AIConversationsController;
