import {EmptyStateText, Middle} from "../Menu.style";

export const You = () => {
    return (
        <>
            <Middle>
                <EmptyStateText>Come back later for character customization...</EmptyStateText>
            </Middle>
        </>
    );
};
