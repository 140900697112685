import * as THREE from "three";
import global from "../global";
import {CAMERA_TYPES, OBJECT_TYPES, WEAPON_AIMERS, WEAPON_TYPES} from "../types/editor";

class CameraControl {
    private scene: THREE.Scene;
    private camera: THREE.PerspectiveCamera;
    private player: THREE.Object3D;
    private static instance: CameraControl | null = null;
    private nearLimit: number;
    private farLimit: number;
    private cameraLockPosition: boolean;
    private spherical: THREE.Spherical;
    private targetSpherical: THREE.Spherical;
    private lerpFactor: number;
    private sideScroller: boolean;
    private mouseMoveHandler: (event: MouseEvent | TouchEvent) => void;
    public isAimingDownSites: boolean = false;
    private hasMoved: boolean = false;
    private sceneObjects: any[];
    private originalFov: number | 50;
    public weaponScopeZoom: number | null = null;
    private isPointerLocked: boolean;
    private isDebug: boolean;
    private scopeScene: THREE.Scene | null = null;
    private scopeCamera: THREE.PerspectiveCamera | null = null;
    private scopeRenderer: THREE.WebGLRenderer | null = null;
    private scopePlane: THREE.Mesh | null = null;
    public aimerImg: HTMLImageElement | null = null;
    public scopeRenderContainer: HTMLElement | null = null;
    public currentWeapon: THREE.Object3D | null = null;
    public preventMeshPenetration: boolean = true;
    private targetPosition: THREE.Vector3 = new THREE.Vector3();
    private prevDistance: number = 0;
    private lastTouchX: number | null = null;
    private lastTouchY: number | null = null;
    private raycaster: THREE.Raycaster;
    private fov: number;

    constructor(
        scene: THREE.Scene,
        camera: THREE.PerspectiveCamera,
        player: THREE.Object3D,
        cameraLockPosition: boolean = false,
        sideScroller: boolean = false,
        nearLimit: number = 7,
        farLimit: number = 8,
        fov: number = 50,
    ) {
        this.scene = scene;
        this.camera = camera;
        this.player = player;
        this.nearLimit = nearLimit;
        this.farLimit = farLimit;
        this.fov = fov;
        this.camera.fov = fov;
        this.cameraLockPosition = cameraLockPosition;
        this.sideScroller = sideScroller;
        this.spherical = new THREE.Spherical(this.nearLimit, Math.PI / 2, 0);
        this.targetSpherical = new THREE.Spherical(this.nearLimit, Math.PI / 2, 0);
        this.lerpFactor = 0.25;
        this.originalFov = this.camera.fov;
        this.weaponScopeZoom = this.originalFov! / 2.5;
        this.mouseMoveHandler = this.onMouseMove.bind(this);
        this.sceneObjects = this.initSceneObjects(scene);
        this.isPointerLocked = false;
        this.initMouseEvents();
        this.initPointerLockEvents();
        this.isDebug = (global.app as any)?.storage?.debug || false;
        this.scopeRenderContainer = document.getElementById("scope-container");
        this.currentWeapon = null;
        this.initScopeScene();
        this.raycaster = new THREE.Raycaster();
        this.scene.userData.camera = this.camera;
    }

    private initScopeScene() {
        if (!this.scopeRenderContainer) {
            this.scopeRenderContainer = document.createElement("div");
            this.scopeRenderContainer.id = "scope-container";
            this.scopeRenderContainer.style.position = "absolute";
            this.scopeRenderContainer.style.top = "0";
            this.scopeRenderContainer.style.left = "0";
            this.scopeRenderContainer.style.width = "100vw";
            this.scopeRenderContainer.style.height = "100vh";
            this.scopeRenderContainer.style.pointerEvents = "none";
            this.scopeRenderContainer.style.zIndex = WEAPON_AIMERS.AIMER_SCREEN_ZINDEX.toString();
            document.body.appendChild(this.scopeRenderContainer);
        }

        this.scopeRenderer = new THREE.WebGLRenderer({alpha: true});
        this.scopeRenderer.setSize(window.innerWidth, window.innerHeight);
        this.scopeRenderContainer.appendChild(this.scopeRenderer.domElement);

        const aspectRatio = window.innerWidth / window.innerHeight;
        const fov = 75;
        const planeHeight = 2;
        const fovInRadians = (fov * Math.PI) / 180;
        const cameraDistance = planeHeight / 2 / Math.tan(fovInRadians / 2);

        this.scopeScene = new THREE.Scene();
        this.scopeCamera = new THREE.PerspectiveCamera(fov, aspectRatio, 0.1, 1000);
        this.scopeCamera.position.z = cameraDistance;
    }

    private onWindowResize() {
        if (!this.cameraLockPosition && !this.sideScroller && this.scopeRenderer) {
            this.scopeRenderer!.setSize(window.innerWidth, window.innerHeight);
            this.scopeCamera!.aspect = window.innerWidth / window.innerHeight;
            this.scopeCamera!.updateProjectionMatrix();
        }
    }
    createScopePlane(reticleSize: number) {
        const planeSize = 2;
        const aspectRatio = window.innerWidth / window.innerHeight;
        const planeGeometry = new THREE.PlaneGeometry(planeSize * aspectRatio, planeSize);

        const imageSizePx = reticleSize;
        const circleDiameter = (imageSizePx / window.innerWidth) * planeSize * aspectRatio;
        const circleRadius = circleDiameter / 2;

        const vertexShader = `
                    varying vec2 vUv;
                    void main() {
                        vUv = uv;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                    }
                `;

        const fragmentShader = `
            varying vec2 vUv;
            uniform float circleRadius;
            void main() {
                vec2 uv = vUv * 2.0 - 1.0;
                float aspect = ${aspectRatio.toFixed(2)};
                uv.x *= aspect;
                float dist = length(uv);

                // Smooth feathering near the edge of the detected reticle circle
                float edgeFeather = 0.05; // Adjust this value for more or less feathering
                float alpha = smoothstep(circleRadius, circleRadius + edgeFeather, dist);

                // Set the color to black with 85% transparency (15% opacity)
                gl_FragColor = vec4(0.0, 0.0, 0.0, alpha * 0.85);
            }
        `;

        const planeMaterial = new THREE.ShaderMaterial({
            vertexShader,
            fragmentShader,
            side: THREE.DoubleSide,
            transparent: true,
            uniforms: {
                circleRadius: {value: circleRadius},
            },
        });

        this.scopePlane = new THREE.Mesh(planeGeometry, planeMaterial);
        this.scopeScene?.add(this.scopePlane);
    }

    //TODO move this to behavior
    createSciFiScopePlane(reticleSize: number, sides: number) {
        const planeSize = 2;
        const aspectRatio = window.innerWidth / window.innerHeight;
        const planeGeometry = new THREE.PlaneGeometry(planeSize * aspectRatio, planeSize);

        const imageSizePx = reticleSize;
        const circleDiameter = (imageSizePx / window.innerWidth) * planeSize * aspectRatio;
        let shapeRadius = circleDiameter + circleDiameter * 0.25;

        const rotationAngle = Math.PI / sides; // Rotation angle based on number of sides

        const vertexShader = `
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `;

        const fragmentShader = `
        varying vec2 vUv;
        uniform float shapeRadius;
        uniform int sides; // Number of sides

        // Function to determine if the UV coordinates are inside a polygon
        bool insidePolygon(vec2 uv) {
            float angleStep = 2.0 * 3.14159 / float(sides); // Calculate angle step based on number of sides
            vec2 offsetPoint = uv * 2.0 - 1.0; // Normalize to [-1, 1]

            // Adjust UVs for aspect ratio
            float aspect = ${aspectRatio.toFixed(2)};
            offsetPoint.x *= aspect; // Scale x-coordinate by aspect ratio

            // Rotate UV coordinates by the initial rotation angle
            float cosAngle = cos(${rotationAngle});
            float sinAngle = sin(${rotationAngle});
            vec2 rotatedPoint = vec2(
                cosAngle * offsetPoint.x - sinAngle * offsetPoint.y,
                sinAngle * offsetPoint.x + cosAngle * offsetPoint.y
            );

            // Check if point is inside the polygon
            for (int i = 0; i < ${sides}; ++i) {
                float angle = float(i) * angleStep; // Current vertex angle
                vec2 vertex = vec2(cos(angle), sin(angle)) * shapeRadius; // Current vertex position

                // Next vertex
                vec2 nextVertex = vec2(cos(angle + angleStep), sin(angle + angleStep)) * shapeRadius;

                // Check for intersection with the edges of the polygon
                if (dot(rotatedPoint - vertex, nextVertex - vertex) < 0.0) {
                    return false; // Outside polygon
                }
            }
            return true; // Inside polygon
        }

        void main() {
            vec2 uv = vUv; // Use original UV coordinates
            float alpha = insidePolygon(uv) ? 0.0 : 0.80; // Fully transparent inside polygon, 20% outside

            // Set the color to black with 85% transparency (15% opacity)
            gl_FragColor = vec4(0.0, 0.0, 0.0, alpha * 0.85);
        }
    `;

        const planeMaterial = new THREE.ShaderMaterial({
            vertexShader,
            fragmentShader,
            side: THREE.DoubleSide,
            transparent: true,
            uniforms: {
                shapeRadius: {value: shapeRadius}, // Use shape radius
                sides: {value: sides}, // Pass number of sides to the shader
            },
        });

        this.scopePlane = new THREE.Mesh(planeGeometry, planeMaterial);
        this.scopeScene?.add(this.scopePlane);
    }

    removeScopePlane() {
        if (this.scopePlane && this.scopeScene) {
            this.scopeScene.remove(this.scopePlane);
            this.scopePlane.geometry.dispose();
        }
    }

    private initPointerLockEvents() {
        document.addEventListener("pointerlockchange", this.onPointerLockChange);
        document.addEventListener("pointerlockerror", e => {
            console.error("Pointer Lock Error", e);
        });
    }

    private onPointerLockChange() {
        this.isPointerLocked = !!document.pointerLockElement;
        if (!this.isPointerLocked) {
            global.app?.call("unlockEvent");
        }
    }

    private initSceneObjects(scene: THREE.Scene): THREE.Object3D[] {
        const objectsToConsider: THREE.Object3D[] = [];
        scene.traverse((object: THREE.Object3D) => {
            if (object instanceof THREE.Mesh) {
                objectsToConsider.push(object);
            }
        });
        return objectsToConsider;
    }

    public static reset(
        scene: THREE.Scene,
        camera: THREE.PerspectiveCamera,
        player: THREE.Object3D,
        cameraLockPosition: boolean = false,
        sideScroller: boolean = false,
        nearLimit: number = 7,
        farLimit: number = 8,
        fov: number = 50,
    ): CameraControl {
        CameraControl.instance = new CameraControl(
            scene,
            camera,
            player,
            cameraLockPosition,
            sideScroller,
            nearLimit,
            farLimit,
            fov,
        );
        return CameraControl.instance;
    }

    private initMouseEvents() {
        document.addEventListener("mousemove", this.mouseMoveHandler as EventListener);

        document.addEventListener(
            "touchmove",
            (event: TouchEvent) => {
                this.onTouchEnd(event);
                this.mouseMoveHandler(event);
            },
            {passive: false},
        );

        document.addEventListener(
            "touchend",
            (event: TouchEvent) => {
                this.onTouchEnd(event);
            },
            {passive: false},
        );

        document.addEventListener(
            "touchcancel",
            (event: TouchEvent) => {
                this.onTouchEnd(event);
            },
            {passive: false},
        );

        document.addEventListener("mousedown", this.onMouseDown.bind(this));
        document.addEventListener("mouseup", this.onMouseUp.bind(this));
        window.addEventListener("resize", this.onWindowResize.bind(this));
    }

    private onKeyDown(event: KeyboardEvent) {
        if (event.key === "Escape") {
            event.preventDefault();
            this.unlockPointerLock();
        }
    }

    public requestPointerLock() {
        if (document.body.requestPointerLock) {
            document.body.requestPointerLock();
        } else {
            console.error("Pointer Lock is not supported");
        }
    }

    public unlockPointerLock() {
        if (document.exitPointerLock) {
            document.exitPointerLock();
        } else {
            console.error("Pointer Lock is not supported");
        }
    }

    private onMouseDown(event: MouseEvent) {
        if (event.button === 2) {
            // Right mouse button
            event.preventDefault();
            this.zoomIn();
        }
    }

    private zoomIn() {
        this.isAimingDownSites = true;
        this.updateAimerSize();
        this.camera.fov = this.originalFov;
        this.camera.updateProjectionMatrix();
    }

    private onMouseUp(event: MouseEvent) {
        if (event.button === 2) {
            // Right mouse button
            this.resetZoom();
        }
    }

    public resetZoom() {
        this.isAimingDownSites = false;
        this.hasMoved = false;
        this.camera.fov = this.originalFov;
        this.updateAimerSize();
        this.camera.updateProjectionMatrix();
    }

    private updateAimerSize() {
        if (this.scene.userData.gameWeapons && this.scene.userData.gameWeapons.length > 0) {
            for (const weapon of this.scene.userData.gameWeapons) {
                if (weapon) {
                    if (weapon.userData?.behaviors) {
                        const weaponBehavior = weapon.userData.behaviors.find(
                            (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON,
                        );
                        if (weaponBehavior && weapon.userData.isCurrentWeapon) {
                            weapon.userData.scopeZoomAimerSize = window.innerHeight;
                            weapon.userData.isAimingDownSites = this.isAimingDownSites;
                            this.currentWeapon = weapon;
                            this.aimerImg = document.getElementById(weapon.userData.aimerID) as HTMLImageElement;
                            if (this.aimerImg) {
                                if (this.isAimingDownSites && weapon.userData.fpsEnabled) {
                                    if (weapon.userData.weaponType === WEAPON_TYPES.SNIPER_RIFLE) {
                                        this.createScopePlane(weapon.userData.scopeZoomAimerSize);
                                        this.updateScope(weapon, weapon.userData.scopeZoomAimerSize);
                                    } else if (weapon.userData.weaponType === WEAPON_TYPES.SCIFI_SNIPER_RIFLE) {
                                        this.createSciFiScopePlane(weapon.userData.scopeZoomAimerSize, 8);
                                        this.aimerImg!.style.filter =
                                            "drop-shadow(0 0 15px rgba(255, 255, 255, 1)) drop-shadow(0 0 30px rgba(255, 255, 0, 0.5))";
                                        this.updateScope(weapon, weapon.userData.scopeZoomAimerSize);
                                    }
                                } else {
                                    this.removeScopePlane();
                                    this.updateScope(weapon, weapon.userData.weaponHUDAimerSize);
                                }
                            }
                        }
                    }
                }
            }
        } else {
            if (this.isDebug) {
                console.log("No weapons found in gameWeapons.");
            }
        }
    }

    private updateScope(weapon: THREE.Object3D, size: number) {
        this.aimerImg!.style.minWidth = size + "px";
        this.aimerImg!.style.minHeight = size + "px";
        weapon.userData.isAimingDownSites = this.isAimingDownSites;
    }

    private onMouseMove(event: MouseEvent | TouchEvent) {
        if (this.cameraLockPosition || this.sideScroller || this.scene.userData.cameraType == CAMERA_TYPES.FIXED)
            return;

        let deltaX = 0;
        let deltaY = 0;

        if (typeof TouchEvent !== "undefined" && event instanceof TouchEvent) {
            if (event.touches.length > 0) {
                const touch = event.touches[0];

                const screenWidth = window.innerWidth;
                const screenHeight = window.innerHeight;

                const excludedAreaWidth = screenWidth * 0.35;
                const excludedAreaHeight = screenHeight * 0.35;

                const touchX = touch.clientX;
                const touchY = touch.clientY;

                if (
                    touchY > screenHeight - excludedAreaHeight &&
                    (touchX < excludedAreaWidth || touchX > screenWidth - excludedAreaWidth)
                ) {
                    return;
                }

                if (this.lastTouchX === null || this.lastTouchY === null) {
                    this.lastTouchX = touchX;
                    this.lastTouchY = touchY;
                    return;
                }

                deltaX = touchX - this.lastTouchX;
                deltaY = touchY - this.lastTouchY;

                this.lastTouchX = touchX;
                this.lastTouchY = touchY;
            }
        } else if (event instanceof MouseEvent) {
            deltaX = event.movementX;
            deltaY = event.movementY;
        }

        if (deltaX === 0 && deltaY === 0) return;

        const thetaDelta = deltaX * 0.006;
        const phiDelta = deltaY * 0.004;

        this.targetSpherical.theta += thetaDelta;
        this.targetSpherical.phi -= phiDelta;

        this.targetSpherical.phi = THREE.MathUtils.clamp(this.targetSpherical.phi, 0.01, Math.PI - 0.01);
    }

    private onTouchEnd(event: TouchEvent) {
        if (event.touches.length === 0) {
            this.lastTouchX = null;
            this.lastTouchY = null;
        }
    }

    public update(gamePaused: boolean) {
        if (gamePaused) {
            if (this.scopeRenderContainer) {
                this.scopeRenderContainer.remove();
            }
            this.scopeScene = null;
            this.scopeCamera = null;
            this.scopeRenderer = null;
            return;
        }

        if (!this.scopeScene || !this.scopeCamera || !this.scopeRenderer) {
            this.initScopeScene();
        }

        this.updateCameraPosition();

        if (this.scopePlane) {
            this.scopeRenderer!.render(this.scopeScene!, this.scopeCamera!);
        }

        //integrate camera behavior until completely done
        if (this.scene.userData.cameraMinDistance != null && this.scene.userData.cameraMaxDistance != null) {
            this.nearLimit = this.scene.userData.cameraMinDistance;
            this.farLimit = this.scene.userData.cameraMaxDistance;
        }
    }

    // TODO: refactor this, to make it easier to understand,
    // could be universal camera constrols like limits and offsets
    // and we setup this in character controller code to not have dependencies from this class on player object
    // that way we can have any type of camera view for any type of object without changing this class: Open-Closed principle from SOLID
    private updateCameraPosition() {
        this.spherical.theta += (this.targetSpherical.theta - this.spherical.theta) * this.lerpFactor;
        this.spherical.phi += (this.targetSpherical.phi - this.spherical.phi) * this.lerpFactor;

        // TODO: we should make controls for camera target offset and limits
        this.calculateTargetPosition();
        const radius = this.getControlRadius();

        let x, y, z;

        switch (true) {
            // Vehicle view
            case this.cameraLockPosition && !this.sideScroller: {
                const playerDirection = new THREE.Vector3(0, 0, 1).applyQuaternion(this.player.quaternion);
                const cameraOffset = playerDirection
                    .clone()
                    .multiplyScalar(-radius)
                    .add(new THREE.Vector3(0, radius * Math.cos(this.spherical.phi), 0));

                x = THREE.MathUtils.lerp(
                    this.camera.position.x,
                    cameraOffset.x * 0.5 + this.targetPosition.x,
                    this.lerpFactor,
                );
                y = THREE.MathUtils.lerp(
                    this.camera.position.y,
                    cameraOffset.y + this.targetPosition.y,
                    this.lerpFactor,
                );
                z = THREE.MathUtils.lerp(
                    this.camera.position.z,
                    cameraOffset.z * 0.5 + this.targetPosition.z,
                    this.lerpFactor,
                );
                y = Math.max(y, 1);
                this.camera.position.set(x, y, z);
                this.camera.lookAt(this.targetPosition);
                break;
            }

            // Top View and Over-The-Shoulder view
            case !this.cameraLockPosition && !this.sideScroller: {
                x = radius * Math.sin(this.spherical.phi) * Math.cos(this.spherical.theta) + this.targetPosition.x;
                y = radius * Math.cos(this.spherical.phi) + this.targetPosition.y;
                z = radius * Math.sin(this.spherical.phi) * Math.sin(this.spherical.theta) + this.targetPosition.z;

                if (this.isAimingDownSites && !this.hasMoved) {
                    this.camera.fov = 100 / this.weaponScopeZoom!;
                    this.camera.updateProjectionMatrix();
                    this.hasMoved = true;
                }
                this.camera.position.set(x, y, z);
                this.camera.lookAt(this.targetPosition);

                // handle fps no weapon until refactor is complete
                if (this.player.userData.controlType === CAMERA_TYPES.FIRST_PERSON && !this.currentWeapon) {
                    this.camera.position.copy(this.targetPosition);
                } else {
                    y = Math.max(y, 0); // TODO: why is this needed?
                }

                break;
            }

            // Side Scroller view
            case this.cameraLockPosition && this.sideScroller: {
                x = radius * Math.sin(this.spherical.phi) * Math.cos(this.spherical.theta) + this.targetPosition.x;
                y = radius * Math.cos(this.spherical.phi) + this.targetPosition.y;
                z = radius * Math.sin(this.spherical.phi) * Math.sin(this.spherical.theta) + this.targetPosition.z;
                y = Math.max(y, 1);
                this.camera.position.set(x, y, z);
                this.camera.lookAt(this.targetPosition);
                break;
            }
        }

        const hesitationFactor = 0.1;
        this.camera.position.x += ((x as number) - this.camera.position.x) * hesitationFactor;
        this.camera.position.y += ((y as number) - this.camera.position.y) * hesitationFactor;
        this.camera.position.z += ((z as number) - this.camera.position.z) * hesitationFactor;

        ////TODO this will change after re-factoring of camera behaviors
        if (this.scene && this.scene.userData && this.scene.userData.cameraType == CAMERA_TYPES.FIXED) {
            this.camera.position.y = this.player.position.y + this.scene.userData.cameraHeadHeight;
        }

        this.camera.lookAt(this.targetPosition);
    }

    private getControlRadius() {
        if (!this.preventMeshPenetration) {
            return Math.max(this.nearLimit, Math.min(this.farLimit, this.spherical.radius));
        }

        // raycast from the camera to the player to check if there is an object between them
        this.raycaster.far = this.farLimit;

        const direction = new THREE.Vector3();
        this.camera.getWorldDirection(direction);
        direction.negate();

        this.raycaster.set(this.targetPosition, direction);

        this.raycaster.camera = this.camera;
        /* const intersects = this.raycaster.intersectObjects(
            this.scene.children.filter(child => !(child instanceof THREE.Sprite)),
            true,
        );*/

        let distance = Number.POSITIVE_INFINITY;

        /*intersects.forEach(intersect => {
            if (this.isValidIntersect(intersect)) {
                distance = Math.min(distance, intersect.distance);
            }
        });*/

        distance = THREE.MathUtils.clamp(distance - 1, 0.001, this.farLimit);

        // smooth out the distance changes if we zoom out
        if (distance > this.prevDistance) {
            const lerpSpeed = 0.1;
            distance = THREE.MathUtils.lerp(this.prevDistance, distance, lerpSpeed);
        }

        this.prevDistance = distance;

        return distance;
    }

    private isValidIntersect(intersect: any): boolean {
        return (
            intersect.object.uuid !== this.player.uuid &&
            !intersect.object.isSkinnedMesh &&
            intersect.object.userData?.disableCameraCollision !== true
        );
    }

    private calculateTargetPosition() {
        this.targetPosition.copy(this.player.position);

        if (this.cameraLockPosition && !this.sideScroller) {
            // Vehicle view
            this.targetPosition.y += 8;
        } else if (!this.cameraLockPosition && !this.sideScroller) {
            // Top View and Over-The-Shoulder view
            this.targetPosition.y += this.player.userData.playerHeight || 0;
        } else if (this.cameraLockPosition && this.sideScroller) {
            // Side Scroller view
        }
    }

    public dispose() {
        document.removeEventListener("mousemove", this.mouseMoveHandler);
        document.removeEventListener("mousedown", this.onMouseDown.bind(this));
        document.removeEventListener("mouseup", this.onMouseUp.bind(this));
        document.removeEventListener("keydown", this.onKeyDown.bind(this));
        window.removeEventListener("resize", this.onWindowResize.bind(this));

        if (this.scopeRenderContainer) {
            this.scopeRenderContainer.remove();
        }
        this.scopeScene = null;
        this.scopeCamera = null;
        this.scopeRenderer = null;
        this.sceneObjects = [];

        CameraControl.instance = null;
    }
}

export {CameraControl};
