import { Bindings } from "./Bindings";
import { PlayerActions } from "./ActionTypes";

export function defaultBindings() {
	const bindings = new Bindings<PlayerActions>();

	bindings.bindKey("KeyW").toMotion("forward", 1);
	bindings.bindKey("KeyS").toMotion("forward", -1);
	bindings.bindKey("KeyA").toMotion("lateral", -1);
	bindings.bindKey("KeyD").toMotion("lateral", 1);

	bindings.bindKey("KeyR").toAction("reload");
	bindings.bindKey("KeyF").toAction("drop");

	bindings.bindKey("KeyE").toAction("use");

	bindings.bindKey("ShiftLeft").toAction("run");
	bindings.bindKey("Space").toAction("jump");
	bindings.bindKey("ControlLeft").toAction("crouch");

	bindings.bindMouseClick(0).toAction("primary");
	bindings.bindMouseClick(2).toAction("secondary");

	bindings.bindMouseMove("x").toMotion("view_x", 1);
	bindings.bindMouseMove("y").toMotion("view_y", 1);

	return bindings;
}