import {useEffect, useState} from "react";
import * as THREE from "three";
import global from "../../../../../global";
import Application from "../../../../../Application";
import {PanelSectionTitleSecondary} from "../RightPanel.style";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {StyledRange} from "../../common/StyledRange";
import {SelectRow} from "../common/SelectRow";
import {MATERIAL_TYPES} from "../../../../../types/editor";
import {MaterialTileRange} from "../common/MateriaTileRange";
import {MapType, MaterialSection} from "../common/MaterialSection";

export type ImageChangeType = (url: string, type: "map" | "bump" | "normal" | "displacement") => void;

const generateMaterialTypeOptions = () => {
    return Object.entries(MATERIAL_TYPES).map(([key, value], index) => ({
        key: index.toString(),
        value: value,
        label: key.replace(/_/g, " ").replace(/^\w/, c => c.toUpperCase()),
    }));
};

const materialTypeOptions = generateMaterialTypeOptions();

export const MaterialRenderingSection = () => {
    const app = global.app as Application;
    const editor = app.editor;
    const [materialType, setMaterialType] = useState<string>(MATERIAL_TYPES.MESH_STANDARD);
    const [materialMapImage, setMaterialMapImage] = useState<string>("");
    const [materialBumpImage, setMaterialBumpImage] = useState<string>("");
    const [materialNormalImage, setMaterialNormalImage] = useState<string>("");
    const [materialDisplacementImage, setMaterialDisplacementImage] = useState<string>("");
    const [materialSettings, setMaterialSettings] = useState<any>({
        isDoubleSided: false,
        isWireframe: false,
        isTransparent: false,
        opacity: 1,
        roughness: 0.5,
        metalness: 0.5,
        bumpScale: 0.1,
        normalScale: 0.1,
        displacementScale: 0.1,
        isTiling: false,
        tileRepeatX: 1,
        tileRepeatZ: 1,
    });

    const getSelectedObject = (): THREE.Object3D => {
        return editor?.selected as THREE.Object3D;
    };

    const selected = getSelectedObject();

    const getAllChildrenWithMaterials = (object: THREE.Object3D) => {
        const result: THREE.Mesh[] = [];
        object.traverse(child => {
            if (child instanceof THREE.Mesh && child.material) {
                result.push(child);
            }
        });
        return result;
    };

    const handleMaterialTypeChange = (newValue: string) => {
        const selected = getSelectedObject();
        if (!selected) return;

        const validMaterialType = materialTypeOptions.find(option => option.value === newValue);

        if (!validMaterialType) {
            console.error(`Invalid material type selected: ${newValue}`);
            return;
        }

        setMaterialType(newValue);

        updateMaterialProperties(selected, materialSettings);
        app.call("objectChanged", app.editor, selected);
    };

    const handleNormalScaleChange = (value: number) => {
        const selected = getSelectedObject();
        if (!selected) return;

        const material = (selected as any).material;
        if (material && material.normalMap) {
            material.normalScale.set(value, value);
        }

        const materialSettingsHolder = {
            ...materialSettings,
            normalScale: value,
        };

        setMaterialSettings(materialSettingsHolder);
        updateMaterialProperties(selected, materialSettingsHolder);
        app.call("objectChanged", app.editor, selected);
    };

    const updateMaterialSettings = (key: string, value: any) => {
        const selected = getSelectedObject();
        if (!selected) return;

        const materialSettingsHolder = {
            ...materialSettings,
            [key]: value,
        };

        setMaterialSettings(materialSettingsHolder);
        app.call("objectChanged", app.editor, selected);
        updateMaterialProperties(selected, materialSettingsHolder);
    };

    const applyTextureWithTiling = (
        loader: THREE.TextureLoader,
        textureUrl: string,
        material: THREE.Material,
        mapType: MapType,
        materialSettings: any,
        tileRepeatX: number,
        tileRepeatZ: number,
    ) => {
        loader.load(textureUrl, loadedTexture => {
            // Set texture wrapping and repeat based on tiling settings
            if (materialSettings.isTiling) {
                loadedTexture.wrapS = THREE.RepeatWrapping;
                loadedTexture.wrapT = THREE.RepeatWrapping;
                loadedTexture.repeat.set(tileRepeatX, tileRepeatZ);
            } else {
                loadedTexture.wrapS = THREE.ClampToEdgeWrapping;
                loadedTexture.wrapT = THREE.ClampToEdgeWrapping;
            }

            loadedTexture.needsUpdate = true;

            // Check if the material is a type that supports texture maps
            if (
                material instanceof THREE.MeshStandardMaterial ||
                material instanceof THREE.MeshPhongMaterial ||
                material instanceof THREE.MeshLambertMaterial
            ) {
                if (mapType === MapType.BUMP) {
                    material.bumpMap = loadedTexture;
                    material.bumpScale = materialSettings.bumpScale || 0.1;
                } else if (mapType === MapType.DISPLACEMENT) {
                    material.displacementMap = loadedTexture;
                    material.displacementScale = materialSettings.displacementScale || 1;
                } else if (mapType === MapType.MAP) {
                    material.map = loadedTexture;
                } else if (mapType === MapType.NORMAL) {
                    material.normalMap = loadedTexture;
                    material.normalScale = new THREE.Vector2(
                        materialSettings.normalScale,
                        materialSettings.normalScale,
                    );
                }

                material.needsUpdate = true;
            } else {
                console.warn(
                    "This material type does not support texture maps like bumpMap, displacementMap, or normalMap.",
                );
            }

            material.needsUpdate = true;
        });
    };

    const applyMaps = (material: THREE.Material, materialSettings: any) => {
        const tileRepeatX = materialSettings.tileRepeatX || 1;
        const tileRepeatZ = materialSettings.tileRepeatZ || 1;
        const loader = new THREE.TextureLoader();

        Object.keys(MapType).forEach(key => {
            const value = MapType[key as keyof typeof MapType];
            if ((material as any)[value]) {
                applyTextureWithTiling(
                    loader,
                    (material as any)[value]?.source?.data?.currentSrc || "",
                    material,
                    value,
                    materialSettings,
                    tileRepeatX,
                    tileRepeatZ,
                );
            }
        });
    };

    const updateMaterialProperties = (selected: THREE.Object3D, materialSettings: any) => {
        const objectsWithMaterials = getAllChildrenWithMaterials(selected);

        objectsWithMaterials.forEach(object => {
            const material = object.material;

            if (material instanceof THREE.MeshStandardMaterial || material instanceof THREE.MeshPhysicalMaterial) {
                material.side = materialSettings.isDoubleSided ? THREE.DoubleSide : THREE.FrontSide;
                material.wireframe = !!materialSettings.isWireframe;
                material.transparent = !!materialSettings.isTransparent;
                material.opacity = materialSettings.opacity;
                material.roughness = materialSettings.roughness;
                material.metalness = materialSettings.metalness;

                applyMaps(material, materialSettings);

                material.needsUpdate = true;
            } else if (material instanceof THREE.MeshBasicMaterial || material instanceof THREE.MeshPhongMaterial) {
                material.side = materialSettings.isDoubleSided ? THREE.DoubleSide : THREE.FrontSide;
                material.wireframe = !!materialSettings.isWireframe;
                material.transparent = !!materialSettings.isTransparent;
                material.opacity = materialSettings.opacity;

                material.needsUpdate = true;
            } else {
                console.warn(`Material type does not support bump or displacement maps: ${material.type}`);
            }
        });
    };

    const applySettings = (url: string, type: MapType, texture: THREE.Texture | null) => {
        const selected: any = getSelectedObject();
        if (!selected) return;
        switch (type) {
            case MapType.MAP:
                setMaterialMapImage(url);

                break;
            case MapType.BUMP:
                setMaterialBumpImage(url);
                selected.material.bumpScale = 1;

                break;
            case MapType.NORMAL:
                setMaterialNormalImage(url);

                break;
            case MapType.DISPLACEMENT:
                setMaterialDisplacementImage(url);

                break;
        }
        Object.assign(selected.material, selected.material, {
            [type]: texture,
        });

        updateMaterialProperties(selected, materialSettings);

        selected.material.needsUpdate = true;
        app.call("objectChanged", app.editor, selected);
    };

    const handleImageChange = (url: string, type: MapType) => {
        const selected: any = getSelectedObject();
        if (!selected) return;

        new THREE.TextureLoader().load(
            url,
            texture => {
                applySettings(url, type, texture);
            },
            () => {},
            () => {
                applySettings(url, type, null);
            },
        );
        // Update the state and material settings dynamically based on the type
    };

    useEffect(() => {
        const selected = getSelectedObject();

        if (selected) {
            const objectsWithMaterials = getAllChildrenWithMaterials(selected);

            if (objectsWithMaterials.length > 0) {
                const material = objectsWithMaterials[0].material as THREE.MeshStandardMaterial;
                setMaterialSettings({
                    isDoubleSided: material.side === THREE.DoubleSide,
                    isWireframe: material.wireframe === true,
                    isTransparent: material.transparent === true,
                    opacity: material.opacity ?? 1,
                    roughness: material.roughness ?? 0.5,
                    metalness: material.metalness ?? 0.5,
                    bumpScale: material.bumpScale ?? 0.5,
                    normalScale: material.normalScale?.x ?? 0.5,
                    displacementScale: material.displacementScale ?? 0.5,
                    isTiling: materialSettings.isTiling || false,
                    tileRepeatX: materialSettings.tileRepeatX || 1,
                    tileRepeatZ: materialSettings.tileRepeatZ || 1,
                });
                setMaterialType(material.type || MATERIAL_TYPES.MESH_PHONG);
                setMaterialMapImage(material.map?.source?.data?.currentSrc || "");
                setMaterialBumpImage(material.bumpMap?.source?.data?.currentSrc || "");
                setMaterialNormalImage(material.normalMap?.source?.data?.currentSrc || "");
                setMaterialDisplacementImage(material.displacementMap?.source?.data?.currentSrc || "");
            }
        }
    }, [editor?.selected]);

    const geometry = selected?.geometry;
    let isComplexModel = false;
    if (geometry && geometry.attributes && geometry.attributes.position) {
        isComplexModel = geometry.attributes.position.count >= 6;
    }

    return (
        <div className="Section MaterialSection">
            <SelectRow
                $margin="0"
                label="Material Type"
                data={materialTypeOptions}
                value={
                    materialTypeOptions.find(item => item.value === materialType) || {
                        key: "0",
                        value: MATERIAL_TYPES.MESH_BASIC,
                    }
                }
                onChange={item => handleMaterialTypeChange(item?.value || MATERIAL_TYPES.MESH_BASIC)}
            />

            <PanelCheckbox
                v2
                text="Double Sided"
                checked={materialSettings.isDoubleSided}
                isGray
                regular
                onChange={() => updateMaterialSettings("isDoubleSided", !materialSettings.isDoubleSided)}
            />
            <PanelCheckbox
                v2
                text="Transparent"
                checked={materialSettings.isTransparent}
                isGray
                regular
                onChange={() => updateMaterialSettings("isTransparent", !materialSettings.isTransparent)}
            />
            <PanelCheckbox
                v2
                text="Wireframe Preview"
                checked={materialSettings.isWireframe}
                isGray
                regular
                onChange={() => updateMaterialSettings("isWireframe", !materialSettings.isWireframe)}
            />
            {selected && isComplexModel && (
                <PanelCheckbox
                    v2
                    text="Tile Texture"
                    checked={materialSettings.isTiling}
                    isGray
                    regular
                    onChange={() => updateMaterialSettings("isTiling", !materialSettings.isTiling)}
                />
            )}

            {materialSettings.isTiling && isComplexModel && (
                <>
                    <label>
                        <PanelSectionTitleSecondary>
                            Repeat X ({materialSettings.tileRepeatX})
                        </PanelSectionTitleSecondary>
                        <MaterialTileRange
                            volume={materialSettings.tileRepeatX}
                            setVolume={value => updateMaterialSettings("tileRepeatX", value)}
                        />
                    </label>

                    <label>
                        <PanelSectionTitleSecondary>
                            Repeat Z ({materialSettings.tileRepeatZ})
                        </PanelSectionTitleSecondary>
                        <MaterialTileRange
                            volume={materialSettings.tileRepeatZ}
                            setVolume={value => updateMaterialSettings("tileRepeatZ", value)}
                        />
                    </label>
                </>
            )}

            {materialSettings.isTransparent && (
                <label>
                    <PanelSectionTitleSecondary>Opacity ({materialSettings.opacity})</PanelSectionTitleSecondary>
                    <StyledRange
                        volume={materialSettings.opacity}
                        setVolume={value => updateMaterialSettings("opacity", value)}
                    />
                </label>
            )}

            <label>
                <PanelSectionTitleSecondary>Roughness ({materialSettings.roughness})</PanelSectionTitleSecondary>
                <StyledRange
                    volume={materialSettings.roughness}
                    setVolume={value => updateMaterialSettings("roughness", value)}
                />
            </label>

            <label>
                <PanelSectionTitleSecondary>Metalness ({materialSettings.metalness})</PanelSectionTitleSecondary>
                <StyledRange
                    volume={materialSettings.metalness}
                    setVolume={value => updateMaterialSettings("metalness", value)}
                />
            </label>

            {selected && isComplexModel && (
                <>
                    <MaterialSection
                        label="Map"
                        image={materialMapImage}
                        handleImageChange={handleImageChange}
                        selected={selected}
                        type={MapType.MAP}
                    />
                    <MaterialSection
                        label="Normal"
                        image={materialNormalImage}
                        handleImageChange={handleImageChange}
                        selected={selected}
                        type={MapType.NORMAL}
                    />
                    <label>
                        <PanelSectionTitleSecondary>
                            Normal Scale ({materialSettings.normalScale})
                        </PanelSectionTitleSecondary>
                        <StyledRange
                            min={0}
                            max={50}
                            step={1}
                            value={materialSettings.normalScale}
                            setValue={handleNormalScaleChange}
                        />
                    </label>
                    <MaterialSection
                        label="Bump"
                        image={materialBumpImage}
                        handleImageChange={handleImageChange}
                        selected={selected}
                        type={MapType.BUMP}
                    />
                    <label>
                        <PanelSectionTitleSecondary>
                            Bump Scale ({materialSettings.bumpScale})
                        </PanelSectionTitleSecondary>
                        <StyledRange
                            min={0}
                            max={10}
                            step={0.25}
                            value={materialSettings.bumpScale}
                            setValue={value => updateMaterialSettings("bumpScale", value)}
                        />
                    </label>
                    <MaterialSection
                        label="Displacement"
                        image={materialDisplacementImage}
                        handleImageChange={handleImageChange}
                        selected={selected}
                        type={MapType.DISPLACEMENT}
                    />
                    <label>
                        <PanelSectionTitleSecondary>
                            Displacement Scale ({materialSettings.displacementScale})
                        </PanelSectionTitleSecondary>
                        <StyledRange
                            min={0}
                            max={50}
                            step={1}
                            value={materialSettings.displacementScale}
                            setValue={value => updateMaterialSettings("displacementScale", value)}
                        />
                    </label>
                </>
            )}
        </div>
    );
};
