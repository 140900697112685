import * as THREE from "three";
import CollisionDetector from "./collisions/CollisionDetector";
import GameManager from "./game/GameManager";
import {ICollisionSource, IPhysics} from "../physics/common/types";

export interface BehaviorUpdater {
    target: THREE.Object3D;
    init(game: GameManager): void;
    update(clock: THREE.Clock, delta: number): void;
    reset(): void;
}

class BehaviorManager {
    collisionDetector: CollisionDetector;

    behaviors: BehaviorUpdater[];
    game: GameManager | null = null;

    constructor(physics: IPhysics, collisionSource: ICollisionSource, app: any) {
        this.collisionDetector = new CollisionDetector(physics, collisionSource, app);
        this.behaviors = [];
    }

    create(game: GameManager, behaviors: BehaviorUpdater[], world?: Ammo.btDiscreteDynamicsWorld) {
        this.behaviors = behaviors || [];
        this.game = game;
        this.collisionDetector.create(game.player!);
        this.behaviors.forEach(behavior => {
            behavior.init(game);
        });
    }

    update(clock: THREE.Clock, delta: number): void {
        //detect collisions first
        this.collisionDetector.update();
        //no update behaviors
        this.behaviors.forEach(behavior => {
            //TODO: should we delete "done" behaviors ?
            behavior.update(clock, delta);
        });
    }

    addBehaviors(behaviors: BehaviorUpdater[]) {
        behaviors.forEach(behavior => {
            if (this.game) {
                behavior.init(this.game);
            }
        });

        this.behaviors.push(...behaviors);
    }

    getBehaviorsOfType(type: any) {
        return this.behaviors.filter(b => b instanceof type);
    }

    reset() {
        this.behaviors.forEach(behavior => {
            behavior.reset();
        });
    }
}

export default BehaviorManager;
