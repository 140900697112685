import {useEffect, useRef} from "react";
import I18n from "i18next";
import {useNavigate} from "react-router-dom";

import importIcon from "../../../../editor/assets/v2/icons/import-icon.svg";
import plusIcon from "../../../../editor/assets/v2/icons/plus-icon.svg";
import {useAppGlobalContext, useAuthorizationContext, useHomepageContext} from "../../../../context";
import global from "../../../../global";
import {backendUrlFromPath} from "../../../../utils/UrlUtils";
import {SearchInput} from "../../../../editor/assets/v2/common/SearchInput";
import {SideNavigation} from "./SideNavigation/SideNavigation";
import {
    Container,
    HeaderWrapper,
    PanelButton,
    RightSide,
    RightSideContainer,
    SearchSection,
    Title,
} from "./DashboardLayout.style";
import {StyledButton} from "../common/StyledButton";
import Application from "../../../../Application";
import {ROUTES} from "../../../../AppRouter";
import {PAGES} from "../GamesDashboard/constants";
import {generateProjectLink} from "../../../../v2/pages/services";

export const DashboardLayout = ({children}: {children: React.ReactNode}) => {
    const {setProjectPhase, activePage} = useAppGlobalContext();
    const {search, setSearch} = useHomepageContext();
    const app = (global as any).app as Application;
    const ref = useRef<HTMLDivElement>(null);
    const {isAuthorized, initLoading, handleLogOut} = useAuthorizationContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthorized && !initLoading && !location.pathname.includes("/user/")) {
            navigate(ROUTES.LOGIN, {state: {from: location.pathname}});
        }
    }, [isAuthorized, initLoading]);

    const handleImportScene = () => {
        if (!app) return;
        app.upload(backendUrlFromPath(`/api/Scene/Save`) as string, (obj: any) => {
            if (obj.Code === 200) {
                app.call(`sceneSaved`, this);
            }
            app.toast(I18n.t(obj.Msg));
        });
    };

    const handleCreateEmptyScene = () => {
        navigate(generateProjectLink());
        setProjectPhase(2);
    };

    return (
        <>
            <Container ref={ref}>
                <SideNavigation />
                <RightSideContainer>
                    <HeaderWrapper>
                        <Title>{activePage}</Title>

                        <RightSide>
                            {activePage !== PAGES.SETTINGS && activePage !== PAGES.ADMIN_PANEL ? (
                                <>
                                    <SearchSection>
                                        <SearchInput
                                            onChange={setSearch}
                                            value={search}
                                            placeholder="Search"
                                            width="237px"
                                        />
                                    </SearchSection>
                                    <PanelButton width="86px" className="reset-css" onClick={handleImportScene}>
                                        <img src={importIcon} alt="" />
                                        Import
                                    </PanelButton>
                                    <StyledButton width="131px" onClick={handleCreateEmptyScene} isBlue>
                                        <img className="plus-icon" src={plusIcon} alt="" /> New Game
                                    </StyledButton>
                                </>
                            ) : (
                                <StyledButton width="64px" onClick={handleLogOut} isGrey>
                                    <span className="logout">Logout</span>
                                </StyledButton>
                            )}
                        </RightSide>
                    </HeaderWrapper>
                    {children}
                </RightSideContainer>
            </Container>
        </>
    );
};
