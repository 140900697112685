import {useState} from "react";
import {ImageGenerator} from "./Tabs/ImageGenerator";
import {TABS} from "./AiImageGenerator.types";
import {Tab, Tabs} from "./AiImageGenerator.styles";

type Props = {
    isOpen: boolean;
};

export const AiImageGenerator = ({isOpen}: Props) => {
    const [activeTab, setActiveTab] = useState(TABS.TWO_D);
    const modelId = "model_hTRC1xN4YN3mWNDRroV85eCX";

    return (
        <>
            {isOpen && (
                <Tabs>
                    {Object.values(TABS).map(tab => (
                        <Tab key={tab} onClick={() => setActiveTab(tab)} $selected={tab === activeTab}>
                            {tab}
                        </Tab>
                    ))}
                </Tabs>
            )}

            <ImageGenerator isOpen={activeTab === TABS.TWO_D && isOpen} modelId={modelId} />
            <ImageGenerator isOpen={activeTab === TABS.THREE_D && isOpen} modelId={modelId} />
        </>
    );
};
