import {useEffect, useState} from "react";
import {Oval} from "react-loader-spinner";
import {toast} from "react-toastify";
import global from "../../../../../../global";
import Application from "../../../../../../Application";
import {IAiBehaviorsResponse, IAiResponse, IAiTransformResponse} from "../../../../../../types/editor";
import {Vector3} from "three";
import {backendUrlFromPath} from "../../../../../../utils/UrlUtils";
import {
    attachBehavior,
    base64ToFile,
    detachBehavior,
    replaceTexture,
    updateBehavior,
    updatePosition,
    updateRotation,
    updateScale,
} from "./AiCopilot.service";
import * as THREE from "three";
import {t} from "i18next";
import {createAIPrompt} from "../../../../../../types/aiContent";
import Ajax from "../../../../../../utils/Ajax";
import {BottomBar, LoadingWrapper, StyledTextArea, SubmitButton, Title, Wrapper} from "../../AiAssistant.style";

type Props = {
    isOpen: boolean;
};

export const AiCopilot = ({isOpen}: Props) => {
    const app = global.app as Application;
    const editor = app?.editor;
    const [value, setValue] = useState("");
    const [loadingAI, setLoadingAI] = useState(false);

    const handleTextAreaChange = (value: string) => {
        setValue(value);
    };

    const updateState = (object: any) => {
        app.call(`objectChanged`, app.editor, object);
        app.call(`objectUpdated`, app.editor, object);
        app.call(`behaviorAutoUpdate`, app.editor, object);
    };

    const processTransform = (object: THREE.Object3D, transform: IAiTransformResponse) => {
        if (transform.position) {
            const {x, y, z} = transform.position;
            const position = new Vector3(x, y, z);
            updatePosition(object, position);
        }

        if (transform.scale) {
            const {x, y, z} = transform.scale;
            const scale = new Vector3(x, y, z);
            updateScale(object, scale);
        }

        if (transform.rotation) {
            const {x, y, z} = transform.rotation;
            const rotation = new THREE.Euler(
                x * THREE.MathUtils.DEG2RAD,
                y * THREE.MathUtils.DEG2RAD,
                z * THREE.MathUtils.DEG2RAD,
            );
            updateRotation(object, rotation);
        }
    };

    const processBehaviors = (object: THREE.Object3D, behaviors: IAiBehaviorsResponse) => {
        if (behaviors.attach) {
            behaviors.attach.forEach(behavior => {
                attachBehavior(object, behavior);
            });
        }

        if (behaviors.detach) {
            behaviors.detach.forEach(type => {
                detachBehavior(object, type);
            });
        }

        if (behaviors.update) {
            behaviors.update.forEach(behavior => {
                updateBehavior(object, behavior);
            });
        }
    };

    const processTexture = async (prompt: string, items: IAiResponse[]) => {
        if (prompt === "none" || !prompt || !items || items.length === 0) {
            return;
        }
        setLoadingAI(true);
        try {
            const aiImageReposne = await fetch(backendUrlFromPath("/api/AI/GenerateImage") || "", {
                method: "POST",
                body: JSON.stringify({
                    prompt,
                }),
            });
            if (aiImageReposne.ok) {
                const response = await aiImageReposne.json();

                if (response.image) {
                    const {image} = response;
                    const file = base64ToFile(image, "texture.png", "image/png");

                    const res = await Ajax.post({
                        url: backendUrlFromPath(`/api/Upload/Upload`),
                        data: {
                            file,
                        },
                        msgBodyType: "multipart",
                    });

                    if (res?.data.Code === 200) {
                        const url = res.data.Data?.url;
                        items.forEach(item => {
                            if (item.modelUUID) {
                                const object = editor?.objectByUuid(item.modelUUID);
                                if (object) {
                                    replaceTexture(object, url, !!item.texture?.twoSided, !!item.texture?.transparent);
                                    updateState(object);
                                } else {
                                    toast.error(t("Object not found"));
                                }
                            }
                        });
                    }
                }
            } else {
                const res = await aiImageReposne.json();
                throw Error(res.error);
            }
        } catch (error: any) {
            const message = error.message.split("message:")[1] || "Error generating texture";
            toast.error(t(message));
            console.error("Error:", error);
        } finally {
            setLoadingAI(false);
        }
    };

    const agregateResponseBasedOnTexture = (response: IAiResponse[]) => {
        const aggregated = response.reduce(
            (acc, obj) => {
                const prompt = obj.texture?.prompt || "none";
                if (!acc[prompt]) {
                    acc[prompt] = [];
                }
                acc[prompt].push(obj);
                return acc;
            },
            {} as Record<string, IAiResponse[]>,
        );

        const result = Object.entries(aggregated).map(([prompt, items]) => ({
            prompt,
            items,
        }));

        return result;
    };

    const processResponse = async (response: IAiResponse[]) => {
        const aggregated = agregateResponseBasedOnTexture(response);

        response.forEach(response => {
            if (response.modelUUID) {
                const object = editor?.objectByUuid(response.modelUUID);
                if (object) {
                    if (response.transform) {
                        processTransform(object, response.transform);
                    }

                    if (response.behaviors) {
                        processBehaviors(object, response.behaviors);
                    }

                    updateState(object);
                } else {
                    toast.error(t("Object not found"));
                }
            }
        });
        const promises = aggregated.map(async ({prompt, items}) => {
            return processTexture(prompt, items);
        });

        await Promise.all(promises);
    };

    const handleSubmit = async () => {
        const selected = editor?.selected;
        if (!editor) {
            toast.error(t("Editor not available"));
            return;
        }

        setLoadingAI(true);

        try {
            const aiResponse = await fetch(backendUrlFromPath("/api/AI/Assistant") || "", {
                method: "POST",
                body: JSON.stringify({
                    systemContent: createAIPrompt(editor?.scene, selected),
                    userMessage: value,
                }),
            });
            if (aiResponse.ok) {
                const response = await aiResponse.json();
                if (response.assistantResponse) {
                    const {assistantResponse} = response;
                    const regex = /\,(?!\s*?[\{\[\"\'\w])/g;
                    const json = assistantResponse.replace(regex, "");

                    await processResponse(JSON.parse(json) as IAiResponse[]);
                }
                clearInputs();
            } else {
                throw Error("No response from AI.");
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoadingAI(false);
        }
    };

    const clearInputs = () => {
        setValue("");
    };

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Enter" && isOpen && !loadingAI && value) {
                handleSubmit();
            }
        };
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen, value, loadingAI]);

    if (!isOpen) return null;

    return (
        <>
            <Wrapper>
                <Title>What do you want to do?</Title>
                <StyledTextArea
                    value={value}
                    onChange={e => handleTextAreaChange(e.target.value)}
                    placeholder="Try “Make this 3 times bigger”"
                />
                {loadingAI && (
                    <LoadingWrapper>
                        <Oval
                            visible
                            height="40"
                            width="40"
                            color="#0284c7"
                            secondaryColor="#333"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass="loaderWrapper"
                        />
                    </LoadingWrapper>
                )}
            </Wrapper>
            <BottomBar>
                <SubmitButton disabled={!value || loadingAI} type="submit" onClick={handleSubmit}>
                    Submit
                </SubmitButton>
            </BottomBar>
        </>
    );
};
