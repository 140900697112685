import * as THREE from 'three';
import { Camera, Scene } from "three";

export class MuzzleFlash {
    private scene: THREE.Scene;
    private camera: THREE.Camera;
    private particleMaterial: THREE.ShaderMaterial;
    private static instance: MuzzleFlash | null = null;

    constructor(scene: THREE.Scene, camera: THREE.Camera) {
        this.scene = scene;
        this.camera = camera;
        this.particleMaterial = this.createShaderMaterial();
    }

    public static reset(
        scene: Scene,
        camera: Camera
    ): MuzzleFlash {
        MuzzleFlash.instance = new MuzzleFlash(
            scene,
            camera
        );
        return MuzzleFlash.instance;
    }

    private createShaderMaterial(): THREE.ShaderMaterial {
        return new THREE.ShaderMaterial({
            uniforms: {
                color: { value: new THREE.Color(0xffffff) },
                opacity: { value: 1.0 },  // Start fully opaque
                time: { value: 0.0 },
            },
            vertexShader: `
                uniform float time;
                varying vec2 vUv;

                void main() {
                    vUv = uv;

                    // Scale down over time
                    float scale = 1.0 - time * 10.0;
                    scale = max(scale, 0.0); // Prevent scaling below zero

                    vec4 mvPosition = modelViewMatrix * vec4(position * scale, 1.0);
                    gl_Position = projectionMatrix * mvPosition;

                    gl_Position.z = gl_Position.z + 0.01;
                }
            `,
            fragmentShader: `
                varying vec2 vUv;
                uniform vec3 color;
                uniform float opacity;
                uniform float time;

                void main() {
                    // Fade out over time
                    float fade = 1.0 - time * 10.0;
                    fade = max(fade, 0.0); // Prevent fading below zero

                    gl_FragColor = vec4(color, opacity * fade);
                }
            `,
            transparent: true,
            blending: THREE.NormalBlending,
            depthTest: true,
            depthWrite: false,
        });
    }

    createMuzzleFlashEffect(position: THREE.Vector3) {
        const particleGeometry = new THREE.CircleGeometry(1, 32);

        const particleMesh = new THREE.Mesh(particleGeometry, this.particleMaterial.clone());
        particleMesh.position.copy(position);
        particleMesh.scale.set(1, 1, 1);

        const pointLight = new THREE.PointLight(0xffffff, 10, 10);
        pointLight.position.copy(position);
        this.scene.add(pointLight);

        this.scene.add(particleMesh);

        const updateParticleOrientation = () => {
            particleMesh.lookAt(this.camera.position);
        };

        let startTime = Date.now();

        const animateParticle = () => {
            const elapsedTime = (Date.now() - startTime) / 1000;
            particleMesh.material.uniforms.time.value = elapsedTime;

            updateParticleOrientation();

            if (elapsedTime < 0.2) {
                requestAnimationFrame(animateParticle);
            } else {
                this.scene.remove(particleMesh);
                this.scene.remove(pointLight);
            }
        };

        animateParticle();
    }

    dispose() {

        MuzzleFlash.instance = null;

    }
}
