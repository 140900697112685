import {useEffect, useRef, useState} from "react";
import {useOnClickOutside} from "usehooks-ts";
import styled from "styled-components";

import global from "../../../../../global";
import {GameDataType} from "../../../../../context/HUDGameContext";
import EventBus from "../../../../../behaviors/event/EventBus";
import {IFRAME_MESSAGES} from "../../../../../types/editor";
import {GAME_HUD_IDS, HUD_TABS, IBannerInterface} from "../HUDEditView/types";
import GameManager from "../../../../../behaviors/game/GameManager";
import {InGameData} from "./types";

import backButton from "../../../../../ai16z/assets/back-arrow.svg";
import {CustomBanner} from "../HUDEditView/CustomBanner/CustomBanner";

interface Props {
    setView: React.Dispatch<React.SetStateAction<HUD_TABS | null>>;
    gameData: InGameData;
}

export const NewPauseMenu = ({setView, gameData}: Props) => {
    const ref = useRef<HTMLButtonElement>(null);
    const [isGamePaused, setIsGamePaused] = useState(false);
    const [isGameOver, setIsGameOver] = useState(false);
    const [gameUI, setGameUI] = useState<GameDataType>();
    const app = (global as any).app;
    const scene = app.scene || app.editor?.scene;

    const startGame = () => {
        setView(null);
        EventBus.instance.send("game.resume");
        setIsGamePaused(false);
        setIsGameOver(false);
    };

    useOnClickOutside(ref, () => {
        if (isGamePaused) {
            startGame();
        }
    });

    useEffect(() => {
        const pause = (game: GameManager) => {
            document.body.style.cursor = "wait";
            setTimeout(() => {
                setIsGamePaused(true);
                document.body.style.cursor = "auto";
            }, 1500);

            setIsGameOver(game?.isGameOver());
        };

        app.on("pauseGame.Ai16zPauseMenu", pause);

        return () => {
            app.on("pauseGame.Ai16zPauseMenu", null);
        };
    }, []);

    useEffect(() => {
        if (scene?.userData?.gameUI?.gameHUD) {
            const data = scene.userData.gameUI.gameHUD as GameDataType;
            setGameUI(data);
        } else {
            setGameUI(undefined);
        }
    }, [scene]);

    return (
        <>
            <BackButton
                ref={ref}
                className="reset-css"
                onClick={() => window.parent.postMessage(IFRAME_MESSAGES.GAME_CLOSED, "*")}>
                <img src={backButton} alt="go back" />
            </BackButton>
            {isGameOver && (
                <CustomBanner
                    customStyle={gameUI?.[GAME_HUD_IDS.BANNER] as IBannerInterface}
                    text={gameData.isWinner ? "You Won !" : "You Lose"}
                    width={"100%"}
                    height={"162px"}
                    id={(gameUI?.[GAME_HUD_IDS.BANNER] as string) || "game-hud-banner"}
                />
            )}
        </>
    );
};

const BackButton = styled.button`
    pointer-events: all;
    position: absolute;
    top: 24px;
    left: 24px;
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
    }
`;
