import {useRef, useState} from "react";
import {useOnClickOutside} from "usehooks-ts";
import Ajax from "../../../../../utils/Ajax";
import global from "../../../../../global";
import {useAuthorizationContext} from "../../../../../context";
import {getGameUrl} from "../../../../../v2/pages/services";
import dotsMenu from "../../icons/dots-menu.svg";
import {backendUrlFromPath} from "../../../../../utils/UrlUtils";
import {Options, StyledSceneMenu} from "./SceneList.style";
import {FileData} from "../../types/file";
import {toast} from "react-toastify";

interface Props {
    onDoubleClick: ((id: string, newTab?: boolean | undefined) => void) | undefined;
    noOptions: boolean;
    isCloneable: boolean;
    left: boolean;
    scene: FileData;
    setShowLoading?: (arg: boolean) => void;
    onDelete?: (id: string) => void;
    reload?: () => void;
    isCommunityGame: boolean;
    isOwnerSetted: boolean;
    canDuplicate?: boolean;
}

export const SceneMenu = ({
    noOptions,
    onDoubleClick,
    isCloneable,
    scene,
    setShowLoading,
    left,
    onDelete,
    isCommunityGame,
    isOwnerSetted,
    canDuplicate,
    reload,
}: Props) => {
    const {isAdmin} = useAuthorizationContext();
    const app = (global as any).app as any;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleOptionsClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsMenuOpen(true);
    };
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsMenuOpen(false));

    const handleDelete = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        setIsMenuOpen(false);
        onDelete && onDelete(scene.ID);
    };

    const handlePublic = () => {
        Ajax.post({
            url: backendUrlFromPath(`/api/Scene/Edit`),
            data: {
                Name: scene.Name,
                ID: scene.ID,
                IsPublic: true,
            },
            msgBodyType: "multipart",
        }).then(response => {
            if (response?.data.Code === 200) {
                app.call("sceneSaved");
            } else {
                app.toast(response?.data.Msg, "error");
            }
        });
    };

    const handleClone = async () => {
        if (!setShowLoading || !reload) {
            return console.error("Missing props for cloning: setShowLoading, reload");
        }
        setShowLoading(true);
        try {
            const response = await Ajax.post({
                url: backendUrlFromPath(`/api/Scene/Clone`),
                data: {
                    ID: scene.ID,
                },
                msgBodyType: "urlEncoded",
            });

            const statusCode = response?.data.Code;
            if (!response || (statusCode && statusCode !== 200)) {
                throw new Error(`Error ${statusCode}: ${response?.data.Msg}`);
            }

            reload();
            setShowLoading(false);
        } catch (error) {
            setShowLoading(false);
            toast.error("Request failed.");
        }
    };

    const handleDuplicate = async () => {
        if (!setShowLoading || !reload) {
            return console.error("Missing props for duplicate: setShowLoading, reload");
        }
        setShowLoading(true);
        try {
            const response = await Ajax.post({
                url: backendUrlFromPath(`/api/Scene/Duplicate`),
                data: {
                    ID: scene.ID,
                },
                msgBodyType: "urlEncoded",
            });

            const statusCode = response?.data.Code;
            if (!response || (statusCode && statusCode !== 200)) {
                throw new Error(`Error ${statusCode}: ${response?.data.Msg}`);
            }

            reload();
            setShowLoading(false);
        } catch (error) {
            setShowLoading(false);
            app.toast("Request failed.", "error");
        }
    };

    const handleOpenNewTab = () => {
        onDoubleClick && onDoubleClick(scene.ID, true);
    };

    return !noOptions ? (
        <Options className="reset-css" onClick={handleOptionsClick} $active={isMenuOpen}>
            <img src={dotsMenu} alt="options" />
            <StyledSceneMenu ref={ref} open={isMenuOpen} $left={left}>
                {onDoubleClick && (
                    <span className={"option"} onClick={handleOpenNewTab}>
                        Open in New Tab
                    </span>
                )}
                {scene.IsPublished && (
                    <span className={"option"} onClick={() => window.open(getGameUrl(scene.ID), "_blank")}>
                        Play Game
                    </span>
                )}
                {isCloneable && !canDuplicate && isAdmin && (
                    <span className={"option"} onClick={handleClone}>
                        Clone Project
                    </span>
                )}
                {canDuplicate && (
                    <span className={"option"} onClick={handleDuplicate}>
                        Duplicate
                    </span>
                )}
                {(!isCommunityGame || isAdmin || !isOwnerSetted) && scene.IsPublished && !scene.IsPublic && (
                    <span className={"option"} onClick={handlePublic}>
                        Make public
                    </span>
                )}
                {(!isCommunityGame || !isOwnerSetted) && onDelete && (
                    <span className={"option"} onClick={handleDelete}>
                        Delete
                    </span>
                )}
            </StyledSceneMenu>
        </Options>
    ) : null;
};
