import styled from "styled-components";
import {flexCenter} from "../../../../../../../assets/style";

export const Bottom = styled.div`
    ${flexCenter};
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 0 16px 16px;
`;

export const InputWrapper = styled.div`
    ${flexCenter};
    width: 100%;
    height: 56px;
    column-gap: 8px;
    flex-wrap: nowrap;
    @media only screen and (max-width: 767px) {
        height: 46px;
    }
`;

export const CheckboxWrapper = styled.div`
    ${flexCenter};
    justify-content: flex-start;
    width: 100%;
    height: 32px;
    column-gap: 8px;
    label {
        font-family: Lilita One;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.011em;
        color: #00000066;
        cursor: pointer;
    }
`;

export const Checkbox = styled.input`
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 24px;
    height: 24px;
    border: 1px solid #000000;
    background: transparent;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &::before {
        content: "";
        display: none;
        width: 16px;
        height: 16px;
        background: #8b6653;
        border-radius: 4px;
    }
    &:checked::before {
        display: block;
    }
`;

export const Prompt = styled.input<{$loading: boolean}>`
    flex-grow: 1;
    height: 100%;
    padding: 16px;
    border: 1px solid #000000;
    border-radius: 16px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    text-align: left;
    color: ${({$loading}) => ($loading ? "#22222266" : "#222")};

    font-size: 14px;
    outline: none;
    resize: none;
    background: #f5f5f5;
`;

export const GenerateButton = styled.button`
    width: 31.35%;
    height: 100%;
    cursor: pointer;
    font-family: Lilita One;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.011em;
    text-align: center;
    ${flexCenter};
    color: #fff;
    text-transform: uppercase;
    border-radius: 16px;
    border: none;
    background: #63ad51;
    box-shadow: 0px -2px 2px 2px #549444 inset;

    &:disabled {
        box-shadow: 0px -2px 2px 2px #949494 inset;
        background: #adadad;
        color: #ffffff99;
    }

    @media only screen and (max-width: 767px) {
        font-size: 16px;
        padding: 0 12px;
    }
`;

export const Requirements = styled.div`
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    text-align: center;
    color: #22222266;
`;
