import * as THREE from "three";
import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import { COLLISION_TYPE } from "../../types/editor";
import GameManager from "../../behaviors/game/GameManager";
import { IPhysics } from "src/physics/common/types";
import { PhysicsUtil } from "../../physics/PhysicsUtil";
import CameraUtils from "../../utils/CameraUtils";
import { ProceduralPlantBehaviorInterface, OBJECT_TYPES } from "../../types/editor";

class ProceduralPlantBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    game?: GameManager;
    physics?: IPhysics;
    behavior: ProceduralPlantBehaviorInterface;
    scene: THREE.Scene | null = null;

    grassMaterial: THREE.ShaderMaterial | null = null;
    plantGeometry: THREE.PlaneGeometry | null = null;
    numberOfPlants: number = 25000;
    windDirection: THREE.Vector3 = new THREE.Vector3(0, 3, 0.05);
    windStrength: number = 0.2;
    windSpeed: number = 8.5;
    alphaTexture: THREE.Texture | null = null;
    diffuseTexture: THREE.Texture | null = null;
    plantWidth: number = 0.07;
    plantHeight: number = 0.75;
    horizontalSegments: number = 1;
    verticalSegments: number = 8;
    isAnimated: boolean = true;
    rotations: Float32Array | null = null;;
    playerBoxSize = new THREE.Vector3();
    isTerrainGrass: boolean = false;

    constructor(target: THREE.Object3D, behavior: ProceduralPlantBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        CameraUtils.disableFromCameraCollision(this.target);
        this.target.visible = false;

    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        //this.physics = this.game?.behaviorManager?.collisionDetector.physics; //not needed ( yet )
        //this.addCollisionListener(); //not needed ( yet )
        this.target.visible = false;
        this.scene = this.game!.scene!;

        if (!this.game!.scene!.userData.shaders) {
            this.game!.scene!.userData.shaders = {
                grassVertexShader: this.vertexShader(),
                grassFragmentShader: this.fragmentShader(),
            };
        }

        this.numberOfPlants = this.behavior.numberOfPlants;
        this.rotations = new Float32Array(this.behavior.numberOfPlants);

        this.plantWidth = this.behavior.plantWidth;
        this.plantHeight = this.behavior.plantHeight;

        this.horizontalSegments = this.behavior.horizontalSegments;
        this.verticalSegments = this.behavior.verticalSegments;

        const textureLoader = new THREE.TextureLoader();
        this.alphaTexture = textureLoader.load(this.behavior.alphaImage);
        this.diffuseTexture = textureLoader.load(this.behavior.diffuseImage);

        this.windDirection = new THREE.Vector3(
            this.behavior.windDirectionX,
            this.behavior.windDirectionY,
            this.behavior.windDirectionZ);

        this.alphaTexture = textureLoader.load(this.behavior.alphaImage);

        this.grassMaterial = new THREE.ShaderMaterial({
            uniforms: {
                uTime: { value: 0 },
                uWindDirection: { value: this.windDirection },
                uWindStrength: { value: this.behavior.windStrength },
                uWindSpeed: { value: this.behavior.windSpeed },
                alphaTexture: { value: this.alphaTexture },
                diffuseTexture: { value: this.diffuseTexture },
            },
            vertexShader: this.game!.scene!.userData.shaders.grassVertexShader,
            fragmentShader: this.game!.scene!.userData.shaders.grassFragmentShader,
            side: THREE.DoubleSide,
            transparent: true,
            depthTest: true,
            depthWrite: false,
            blending: THREE.NormalBlending,
        });


        this.plantGeometry = new THREE.PlaneGeometry(this.plantWidth, this.plantHeight, this.horizontalSegments, this.verticalSegments);

        const boundingBox = new THREE.Box3().setFromObject(this.target);
        const minY = boundingBox.min.y;
        if (!this.target.userData.isTerrain) {
             this.createGrass(false, minY + (this.plantHeight / 2));
        }

    }

    private createGrassOnTerrain(clumpingEnabled: boolean = true, terrainMesh: THREE.Mesh, positionArray: number[], boundingBox: THREE.Box3, colors: number[]) {
     
        const grassMesh = new THREE.InstancedMesh(this.plantGeometry!, this.grassMaterial!, this.numberOfPlants);
        const dummy = new THREE.Object3D();

        const offsets = new Float32Array(this.numberOfPlants * 3);
        const heights = new Float32Array(this.numberOfPlants);
        const rotations = new Float32Array(this.numberOfPlants);

        let instanceIndex = 0;

        while (instanceIndex < this.numberOfPlants) {

            const x = boundingBox.min.x + Math.random() * (boundingBox.max.x - boundingBox.min.x);
            const z = boundingBox.min.z + Math.random() * (boundingBox.max.z - boundingBox.min.z);

            let closestY = boundingBox.min.y;
            let minDistance = Infinity;

            let vertexColor = new THREE.Color();

            for (let i = 0; i < positionArray.length / 3; i++) {
                const vx = positionArray[i * 3];
                const vz = positionArray[i * 3 + 2];
                const vy = positionArray[i * 3 + 1];

                const distance = Math.pow(vx - x, 2) + Math.pow(vz - z, 2);
                if (distance < minDistance) {
                    minDistance = distance;
                    closestY = vy;
                    vertexColor.setRGB(colors[i * 3], colors[i * 3 + 1], colors[i * 3 + 2]);
                }
            }

            if (vertexColor.g <= vertexColor.r || vertexColor.g <= vertexColor.b) {
                // Color is not green (green is not dominant), skip placing grass here
                continue;
            }

            const offsetX = (Math.random() - 0.5) * 2;
            const offsetZ = (Math.random() - 0.5) * 2;
            const offsetY = Math.random() * 0.1;

            offsets[instanceIndex * 3] = x + offsetX;
            offsets[instanceIndex * 3 + 2] = z + offsetZ;
            offsets[instanceIndex * 3 + 1] = closestY + offsetY;

            heights[instanceIndex] = Math.random() * 0.5 + 0.5;

            rotations[instanceIndex] = Math.random() * Math.PI * 2;

            dummy.position.set(offsets[instanceIndex * 3], offsets[instanceIndex * 3 + 1], offsets[instanceIndex * 3 + 2]);

            dummy.rotation.set(0, rotations[instanceIndex], 0);
            dummy.updateMatrix();
            grassMesh.setMatrixAt(instanceIndex, dummy.matrix);

            instanceIndex++;
        }

        this.plantGeometry!.setAttribute('aOffset', new THREE.InstancedBufferAttribute(offsets, 3));
        this.plantGeometry!.setAttribute('aHeight', new THREE.InstancedBufferAttribute(heights, 1));
        this.plantGeometry!.setAttribute('aRotation', new THREE.InstancedBufferAttribute(rotations, 1));

        if (this.scene) {
            grassMesh.position.copy(this.target.position);
            grassMesh.rotation.copy(this.target.rotation);
            this.scene.add(grassMesh);
        }
    }


    private createGrass(clumpingEnabled: boolean = true, yOffset: number = 0.75) {
        const grassMesh = new THREE.InstancedMesh(this.plantGeometry!, this.grassMaterial!, this.numberOfPlants);
        const dummy = new THREE.Object3D();

        const offsets = new Float32Array(this.numberOfPlants * 3);
        const heights = new Float32Array(this.numberOfPlants);
        const rotations = new Float32Array(this.numberOfPlants);

        const clumpCenters: { x: number, z: number }[] = [];
        const clumpRadius = 5;
        const clumpStrength = 0.4;

        if (clumpingEnabled) {
            for (let i = 0; i < 10; i++) {
                clumpCenters.push({
                    x: (Math.random() - 0.5) * 50,
                    z: (Math.random() - 0.5) * 50
                });
            }
        }

        for (let i = 0; i < this.numberOfPlants; i++) {
            let x = (Math.random() - 0.5) * 50;
            let z = (Math.random() - 0.5) * 50;
            const height = Math.random() * 0.5 + 0.5;

            if (clumpingEnabled && clumpCenters.length > 0) {
                let closestCenter = clumpCenters[0];
                let minDistance = Math.sqrt(Math.pow(x - closestCenter.x, 2) + Math.pow(z - closestCenter.z, 2));

                for (let j = 1; j < clumpCenters.length; j++) {
                    const distance = Math.sqrt(Math.pow(x - clumpCenters[j].x, 2) + Math.pow(z - clumpCenters[j].z, 2));
                    if (distance < minDistance) {
                        closestCenter = clumpCenters[j];
                        minDistance = distance;
                    }
                }

                const clumpFactor = Math.max(0, 1 - (minDistance / clumpRadius));
                x += (closestCenter.x - x) * clumpFactor * clumpStrength;
                z += (closestCenter.z - z) * clumpFactor * clumpStrength;
            }

            offsets[i * 3] = x;
            offsets[i * 3 + 1] = yOffset;
            offsets[i * 3 + 2] = z;
            heights[i] = height;

            rotations[i] = Math.random() * Math.PI * 2;

            dummy.position.set(x, yOffset, z);
            dummy.rotation.set(0, rotations[i], 0);
            dummy.updateMatrix();

            grassMesh.setMatrixAt(i, dummy.matrix);
        }

        this.plantGeometry!.setAttribute('aOffset', new THREE.InstancedBufferAttribute(offsets, 3));
        this.plantGeometry!.setAttribute('aHeight', new THREE.InstancedBufferAttribute(heights, 1));
        this.plantGeometry!.setAttribute('aRotation', new THREE.InstancedBufferAttribute(rotations, 1));  // Pass the rotation to the shader

        if (this.scene) {
            grassMesh.position.copy(this.target.position);
            grassMesh.rotation.copy(this.target.rotation);
            this.scene.add(grassMesh);
        }
    }



    private vertexShader(): string {
        return `
            
            uniform float uTime;
            uniform vec3 uWindDirection;
            uniform float uWindStrength;
            uniform float uWindSpeed;

            attribute vec3 aOffset;  // Position offset (including terrain height if needed)
            attribute float aHeight; // Height of the grass blade (randomized)
            attribute float aRotation;  // Rotation for each blade (Y-axis)

            varying vec2 vUv;
            varying vec3 vNormal;
            varying vec3 vPosition;

            mat4 rotateY(float angle) {
                return mat4(
                    cos(angle), 0.0, sin(angle), 0.0,
                    0.0, 1.0, 0.0, 0.0,
                    -sin(angle), 0.0, cos(angle), 0.0,
                    0.0, 0.0, 0.0, 1.0
                );
            }

            void main() {
                vUv = uv;
                vNormal = normalize(normalMatrix * normal);
                vPosition = position;

                // Apply taper effect to the grass blade based on UV
                float taperFactor = mix(0.5, 2.5, uv.y); 
                vec3 taperedPosition = position;
                taperedPosition.xz *= taperFactor;

                // Apply width stretch based on height (aHeight)
                float widthFactor = mix(1.0, 1.5, aHeight); 
                taperedPosition.x *= widthFactor;
                taperedPosition.z *= widthFactor;

                // Apply rotation using the Y-axis based on aRotation
                mat4 rotationMatrix = rotateY(aRotation);
                taperedPosition = (rotationMatrix * vec4(taperedPosition, 1.0)).xyz;

                // Add bending effect to the grass blade
                float bendFactor = pow(uv.y, 4.0); 
                taperedPosition.x += bendFactor * 0.1 * sin(aOffset.x * 6.0 + uTime); 
                taperedPosition.z += bendFactor * 0.1 * cos(aOffset.z * 6.0 + uTime); 

                // Add curvature to the top of the blade
                float curveAmount = pow(uv.y, 3.0); 
                taperedPosition.x += curveAmount * 0.5 * sin(uTime + aOffset.x * 0.1); 
                taperedPosition.z += curveAmount * 0.5 * cos(uTime + aOffset.z * 0.1); 

                // Apply vertical stretch
                float heightFactor = mix(1.0, 3.5, aHeight);
                taperedPosition.y *= heightFactor;

                // Set final position
                taperedPosition.y += aOffset.y; // Flat terrain: just add 0 (or another terrain height)
                vec3 newPos = taperedPosition + aOffset;

                // Wind sway effect
                float windFactor = sin((uTime * uWindSpeed) + (aOffset.x * 0.1) + (aOffset.z * 2.0)) * uWindStrength;
                windFactor *= (0.5 + aHeight * 0.5);
                newPos += vec3(uWindDirection.x * windFactor, 0.0, uWindDirection.z * windFactor);

                gl_Position = projectionMatrix * modelViewMatrix * vec4(newPos, 1.0);
            }


            `;
    }



    private fragmentShader(): string {
        return `
        uniform sampler2D alphaTexture;  // Grass alpha texture
        uniform sampler2D diffuseTexture;// Diffuse texture (used for color)

        varying vec2 vUv;

        void main() {
            // Sample the alpha texture to control transparency
            float alpha = texture2D(alphaTexture, vUv).r;

            // Apply smooth transparency for the tips (optional)
            alpha *= smoothstep(0.1, 0.9, vUv.y); // Fades the top smoothly

            // Discard fragments with low alpha (eliminates unwanted transparency and outline)
            if (alpha < 0.1) discard;

            // Use only the diffuse texture for color
            vec3 color = texture2D(diffuseTexture, vUv).rgb;

            // Set the final color and alpha output
            gl_FragColor = vec4(color, alpha);
        }`;
    }


    onCollision() { }

    addCollisionListener() {
        this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollision.bind(this),
                useBoundingBoxes: true,
            },
            PhysicsUtil.isDynamicObject(this.target),
        );
    }

    update(clock: THREE.Clock, delta: number): void {
        if (!this.game?.player?.position || !this.target) return;

        const playerPos = this.game.player.position;
        const targetPos = this.target.position;

        if (!this.playerBoxSize.length()) {
            const box = new THREE.Box3().setFromObject(this.game.player);
            box.getSize(this.playerBoxSize);
        }

        const maxDistance = 10 * this.playerBoxSize.y;

        if (
            Math.abs(playerPos.x - targetPos.x) > maxDistance ||
            Math.abs(playerPos.z - targetPos.z) > maxDistance
        ) {
            return;
        }

        if (this.grassMaterial && this.game.scene && this.behavior.isAnimated) {
            this.grassMaterial.uniforms.uTime.value += delta;
        }

        if (this.target.userData && this.target.userData.terrainMeshData && this.target.userData.isTerrain ) {
            const terrainMeshData = this.target.userData.terrainMeshData;
            const positionArray = terrainMeshData.positionArray;
            const boundingBox = terrainMeshData.boundingBox;
            const colors = terrainMeshData.colors;
            this.createGrassOnTerrain(false, this.target as THREE.Mesh, positionArray, boundingBox, colors);
            this.target.userData.terrainMeshData = null;
        }


    }


    reset() { }
}

export default ProceduralPlantBehaviorUpdater;
