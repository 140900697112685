import {useEffect, useRef, useState} from "react";
import {marked} from "marked";
import global from "../../../../../../global";
import {sendTextToAI} from "../../../../../../api/chat/conversation";
import {ChatContainer, ChatInput, InputContainer, Messages, NoMessages} from "./Ai16zChat.style";
import Player from "../../../../../../player/Player";
import AiAgent from "../../../../../../utils/AiAgent";

interface IMessage {
    author: string;
    text: string;
}

export const Ai16zChat = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const username = urlSearchParams.get("username");

    const [messages, setMessages] = useState<IMessage[]>([]);
    const [yourMessage, setYourMessage] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const messagesWrapperRef = useRef<HTMLDivElement>(null);
    const app = global.app as any;
    const agents = (app as Player).aiNpcControl.control?.aiAgents;
    const noChat = !agents || agents.length === 0 || !agents.find(el => el.behavior.show_text_chat === true);

    const processResponse = async (response: string) => {
        let text = response.replace(/\[.*?\]/g, "").trim(); // to remove [ AI Name ] from response
        text = text.replace(/\s+([.,!?;:'”’"])|(\s+['’](?=\w))/g, "$1$2"); // to remove spaces before punctation
        text = text.replace(/\s*\*\*\s*/g, "**").replace(/\s*\*\s*/g, "*"); // remove all spaces after * to ensure correct formatting of bold and italic

        text = await marked(text);
        return text;
    };

    const scrollToBottom = () => {
        messagesWrapperRef?.current?.scrollTo({
            top: messagesWrapperRef.current.scrollHeight,
        });
    };

    const simulateTyping = (agent: AiAgent, onMessageText: string, prevMessages: IMessage[]) => {
        const chars = onMessageText.split("");
        let currentIndex = 0;
        agent.playSpeechAnimation();

        const typeNextChar = () => {
            setMessages(() => {
                const updatedMessages = [...prevMessages];
                updatedMessages.push({
                    author: agent.behavior.name,
                    text: chars.slice(0, currentIndex).join(""),
                });
                return updatedMessages;
            });

            currentIndex++;

            if (currentIndex < chars.length) {
                setTimeout(typeNextChar, 20);
            } else {
                agent.stopSpeechAnimation();
            }
        };

        typeNextChar();
    };

    const handleSendMessage = async () => {
        const trimmedMessage = yourMessage.trim();
        let currentMessages = [...messages];
        if (trimmedMessage) {
            currentMessages = [...messages, {author: "You", text: trimmedMessage}];
            setMessages(currentMessages);
            setYourMessage("");
        }
        setIsFocused(false);
        app?.call("chatDeactivated");
        inputRef.current?.blur();

        const aiAgent = (app as Player).aiNpcControl.control?.getClosestAiAgent();
        const behavior = aiAgent ? (app as Player).aiNpcControl.control?.getAIAgentBehavior(aiAgent.model) : null;

        try {
            if (behavior && app.editor?.scene) {
                const {name, bio, lore, adjectives, interests} = behavior;

                const response = await sendTextToAI({
                    text: yourMessage,
                    sceneId: app.editor.scene._id,
                    userName: username || "guest",
                    assistantName: name,
                    description:
                        aiAgent?.generatePrompt() ||
                        `Your name is ${name} ${bio} ${lore} ${adjectives?.join(", ")} ${interests?.join(", ")}`,
                });
                if (response && aiAgent) {
                    simulateTyping(aiAgent, await processResponse(response), currentMessages);
                } else {
                    throw Error("No response from AI.");
                }
            } else {
                throw Error("No AI NPC Behavior on the scene.");
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            if (isFocused) {
                if (yourMessage.trim() === "") {
                    setIsFocused(false);
                    app?.call("chatDeactivated");
                    inputRef.current?.blur();
                } else {
                    handleSendMessage();
                }
            } else {
                setIsFocused(true);
                app?.call("chatActivated");
                inputRef.current?.focus();
            }
        }
    };

    useEffect(() => {
        if (noChat) {
            return;
        }
        const listener = (e: KeyboardEvent) => handleKeyDown(e);
        window.addEventListener("keydown", listener);

        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, [yourMessage, isFocused]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    if (noChat) {
        return null;
    }

    return (
        <ChatContainer>
            <Messages className="hidden-scroll" ref={messagesWrapperRef}>
                <div className="messagesWrapper">
                    {messages.map(({author, text}, index) => (
                        <div key={index} className="message">
                            <span className="author">{author}: </span>
                            <span className="text" dangerouslySetInnerHTML={{__html: text}} />
                        </div>
                    ))}
                    {messages.length === 0 && (
                        <NoMessages>
                            It’s quiet here... <br /> Let's start chatting!
                        </NoMessages>
                    )}
                </div>
            </Messages>
            <InputContainer>
                <ChatInput
                    ref={inputRef}
                    placeholder="Press ENTER to type a message"
                    value={yourMessage}
                    onChange={e => setYourMessage(e.target.value)}
                    readOnly={!isFocused}
                />
            </InputContainer>
        </ChatContainer>
    );
};
