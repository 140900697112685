import {useCallback, useState} from "react";
import I18n from "i18next";
import AddObjectCommand from "../../../../../../../command/AddObjectCommand";
import ModelLoader from "../../../../../../../assets/js/loaders/ModelLoader";
import global from "../../../../../../../global";
import {debounce} from "lodash";
import {getModelBySearch} from "../../../../../../../api/animateAnything";
import {AiAssetsList} from "../../../../common/AiAsssetsList";
import {IAnythingModel} from "../../../../../../../types/animateAnything";
import AiModelUtils from "../../../../../../../utils/AiModelUtils";
import JSZip from "jszip";
import Ajax from "../../../../../../../utils/Ajax";

import * as THREE from "three";
import {backendUrlFromPath} from "../../../../../../../utils/UrlUtils";

export const AiModelsTab = () => {
    const [search, setSearch] = useState("");
    const [data, setData] = useState<IAnythingModel[]>();
    const [error, setError] = useState("");
    const app = (global as any).app as any;

    const debouncedHandleSearch = useCallback(
        debounce(async search => {
            try {
                const models = await getModelBySearch(search);
                if (models) {
                    setError("");
                    setData(models.filter(n => AiModelUtils.isModelSupported(n)));
                }
            } catch (error: any) {
                setData(undefined);
                setError(error.message);
            }
        }, 500),
        [app],
    );

    const handleSearch = (value: string) => {
        setSearch(value);
        void debouncedHandleSearch(value);
    };

    const commitThumbnail = async (thumbnailUrl: string, data: any) => {
        await Ajax.post({
            url: backendUrlFromPath(`/api/Mesh/Edit`),
            data: {
                ID: data.ID,
                Name: data.Name,
                Image: thumbnailUrl,
                Category: data.CategoryID,
            },
            msgBodyType: "urlEncoded",
        });
    };

    const handleUploadThumbnail = (file: File, objData: any) => {
        Ajax.post({
            url: backendUrlFromPath(`/api/Upload/Upload`),
            data: {
                file,
            },
            msgBodyType: "multipart",
            //@ts-ignore
        })
            .then(response => {
                if (response?.data.Code === 200) {
                    const payload = {
                        ID: objData.ID,
                        Name: objData.Name,
                        Image: response.data.Data.url,
                    };
                    app.editModel(payload);
                }
            })
            .catch(() => {
                app.toast("Request failed.", "error");
            });
    };

    const onModelSelectedFromList = async (model: IAnythingModel) => {
        if (!model) {
            return;
        }
        const url = AiModelUtils.getModelUrl(model);

        if (!url) {
            app.toast(I18n.t("Model is not supported"), "error");
            return;
        }

        const modelName = model.newName || model.searchName;
        const array = url.split(".");
        const type = array[array.length - 1].split("?")[0];

        try {
            let response = await Ajax.get({url, needAuthorization: false});
            let data = await response?.data;
            const file = new File([data], `${modelName}.${type}`);

            const zipper = new JSZip();
            zipper.file(file.name, file);
            zipper.generateAsync({type: "blob"}).then(async zip => {
                const zippedFile = new File([zip], `${modelName}.zip`);
                try {
                    const meshAddResponse = await Ajax.post({
                        url: backendUrlFromPath(`/api/Mesh/Add`),
                        data: {
                            file: zippedFile,
                        },
                        msgBodyType: "multipart",
                    });
                    if (meshAddResponse?.data.Code === 200) {
                        //download the thumbnail for the model
                        const modelThumbnailResponse = await Ajax.get({
                            url: (model.thumbnails.aw_thumbnail || model.thumbnails.aw_reference) as string,
                            needAuthorization: false,
                        });
                        const thumbnailBlob = await modelThumbnailResponse?.data;
                        // eslint-disable-next-line eqeqeq
                        if (thumbnailBlob == null) {
                            //TODO replace with a default thumbnail here
                            throw new Error("Thumbnail not found");
                        }
                        const file = new File(
                            [thumbnailBlob],
                            `${THREE.MathUtils.generateUUID()}.${thumbnailBlob.type.split("/")[1]}`,
                            {type: thumbnailBlob.type},
                        );

                        handleUploadThumbnail(file, meshAddResponse.data.Data);
                        loadModel(meshAddResponse.data.Data, model);
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const loadModel = (objData: any, model: IAnythingModel) => {
        let loader = new (ModelLoader as any)(app);
        let url = backendUrlFromPath(objData.Url);
        loader
            .load(url, objData, {
                camera: app.editor.camera,
                renderer: app.editor.renderer,
                audioListener: app.editor.audioListener,
                clearChildren: true,
            })
            .then((obj: any) => {
                if (!obj) {
                    return;
                }
                obj.name = model.newName || model.searchName;

                Object.assign(obj.userData, objData, {
                    Server: true,
                });

                if (app.storage.addMode === "click") {
                    clickSceneToAdd(obj);
                } else {
                    app.editor.moveObjectToCameraClosestPoint(obj);
                    addToCenter(obj);
                }
            })
            .catch((e: any) => {
                app.toast(I18n.t("Could not load model"), "error");
                console.log(e);
            });
    };

    const addToCenter = (obj: any) => {
        app.editor.execute(new (AddObjectCommand as any)(obj));

        if (obj.userData.scripts) {
            obj.userData.scripts.forEach((n: any) => {
                app.editor.scripts.push(n);
            });
            app.call("scriptChanged", obj);
        }
    };

    const clickSceneToAdd = (obj: any) => {
        let added = false;
        app.editor.gpuPickNum += 1;
        app.on(`gpuPick.ModelPanel`, (intersect: {point: any}) => {
            // 鼠标移动出现预览效果
            if (!intersect.point) {
                return;
            }
            if (!added) {
                added = true;
                app.editor.sceneHelpers.add(obj);
            }
            obj.position.copy(intersect.point);
        });
        app.on(`raycast.ModelPanel`, (intersect: {point: any}) => {
            // 点击鼠标放置模型
            app.on(`gpuPick.ModelPanel`, null);
            app.on(`raycast.ModelPanel`, null);
            obj.position.copy(intersect.point);
            addToCenter(obj);
            app.editor.gpuPickNum -= 1;
        });
    };

    return (
        <>
            <input className="search-input" type="text" value={search} onChange={e => handleSearch(e.target.value)} />

            {data && data.length > 0 ? (
                <AiAssetsList data={data} onClick={onModelSelectedFromList} />
            ) : (
                <div className="no-data" style={{textAlign: "center"}}>
                    {I18n.t(error || "No models found, use the search bar above to search for models")}
                </div>
            )}
        </>
    );
};
