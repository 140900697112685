import styled from "styled-components";

import global from "../../../../../../global";
import Application from "../../../../../../Application";
import {OBJECT_TYPES} from "../../../../../../types/editor";
import goBackIcon from "../../icons/go-back.svg";
import {Container, Header, IconWrapper, RenameInput} from "../../styles/Behaviors.style";

import {CharacterBehaviors} from "../../behaviors/CharacterBehaviors";
import {AnimationBehaviors} from "../../behaviors/AnimationBehaviors";
import {JumpPadBehaviors} from "../../behaviors/JumpPadBehaviors";
import {TeleportBehaviors} from "../../behaviors/TeleportBehaviors";
import {FollowBehaviors} from "../../behaviors/FollowBehaviors";
import {VolumeBehaviors} from "../../behaviors/VolumeBehaviors";
import {GenericSoundBehaviors} from "../../behaviors/GenericSoundBehaviors";
import {ConsumableBehaviors} from "../../behaviors/ConsumableBehaviors";
import {EnemyBehaviors} from "../../behaviors/EnemyBehaviors";
import {PlatformBehaviors} from "../../behaviors/PlatformBehaviors";
import {SpawnPointBehaviors} from "../../behaviors/SpawnPointBehaviors";
import {CharacterSoundsBehavior} from "../../behaviors/CharacterSoundsBehavior";
import {ThrowableBehaviors} from "../../behaviors/ThrowableBehaviors";
import {WeaponBehaviors} from "../../behaviors/WeaponBehavior/WeaponBehaviors";
import {CameraBehaviors} from "../../behaviors/CameraBehaviors";
import {ScriptBehaviors} from "../../behaviors/ScriptBehaviors";
import {WeaponAmmoBehaviors} from "../../behaviors/WeaponAmmoBehaviors";
import {TriggerBehaviors} from "../../behaviors/TriggerBehaviors/TriggerBehaviors";
import {ObjectInteractionBehaviors} from "../../behaviors/ObjectInteractionBebahiors";
import {PropAnimationBehaviors} from "../../behaviors/PropAnimationBehaviors";
import {AiNPCBehaviors} from "../../behaviors/AiNpcBehaviors";
import deleteIcon from "./icons/delete.svg";
import editIcon from "./icons/edit.svg";
import acceptIcon from "./icons/accept.svg";
import {useState} from "react";
import {NPCBehaviors} from "../../behaviors/NPCBehavior";
import {NPCReceiverBehavior} from "../../behaviors/NPCReceiverBehaviors";
import {RandomizeSpawnerBehaviors} from "../../behaviors/RandomizeSpawnerBehaviors";
import { SpriteBehaviors } from "../../behaviors/SpriteBehaviors";
import { InventoryManagerBehavior } from "../../behaviors/Inventory/InventoryManagerBehavior";
import { MobileTouchBehavior } from "../../behaviors/MobileTouchcControlBehavior";
import { HarvestBehavior } from "../../behaviors/HarvestBehavior";
import { ProceduralPlantBehavior } from "../../behaviors/ProceduralPlantBehavior";
import { ProceduralTerrainBehavior } from "../../behaviors/ProceduralTerrainBehavior";

interface Props {
    selectedBehavior: any;
    setSelectedBehavior: React.Dispatch<any>;
}

export const BehaviorDetailsView = ({selectedBehavior, setSelectedBehavior}: Props) => {
    const app = global.app as Application;
    const [showRenameInput, setShowRenameInput] = useState(false);
    const [customName, setCustomName] = useState(selectedBehavior.customName || selectedBehavior.type);

    const renameBehavior = () => {
        const selected = app.editor?.selected;
        if (!selected || selected instanceof Array) return;
        const behaviors = selected.userData.behaviors;
        const behavior = behaviors.find((b: any) => b.id === selectedBehavior.id);
        if (!behavior) return;
        behavior.customName = customName;

        app.call(`objectChanged`, app.editor, selected);
        app.call(`objectUpdated`, app.editor, selected);
    };

    const handleDelete = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedBehavior(null);
        app?.editor?.removeBehavior(selectedBehavior.id);
    };

    const handleNameChange = (e?: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        e?.stopPropagation();
        setSelectedBehavior({...selectedBehavior, customName: customName});
        setShowRenameInput(false);
        renameBehavior();
    };

    return (
        <BehaviorDetailsWrapper className="hidden-scroll">
            <Container>
                <Header>
                    <img src={goBackIcon} alt="go back" className="icon" onClick={() => setSelectedBehavior(null)} />
                    {showRenameInput ? (
                        <form onSubmit={handleNameChange}>
                            <RenameInput type="text" onChange={e => setCustomName(e.target.value)} value={customName} />
                        </form>
                    ) : (
                        <span>{selectedBehavior.customName || selectedBehavior.type}</span>
                    )}

                    <IconWrapper>
                        {showRenameInput ? (
                            <img src={acceptIcon} onClick={() => handleNameChange()} />
                        ) : (
                            <img src={editIcon} onClick={() => setShowRenameInput(true)} />
                        )}
                        <img src={deleteIcon} className="delete-icon" onClick={handleDelete} />
                    </IconWrapper>
                </Header>
                {selectedBehavior?.type === OBJECT_TYPES.CHARACTER && (
                    <CharacterBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.CAMERA && <CameraBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.ANIMATION && (
                    <AnimationBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.PROP_ANIMATION && (
                    <PropAnimationBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.INVENTORY && (
                    <InventoryManagerBehavior behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.VOLUME && <VolumeBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.GENERIC_SOUND && (
                    <GenericSoundBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.CONSUMABLE && (
                    <ConsumableBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.ENEMY && <EnemyBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.PLATFORM && <PlatformBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.SPAWNPOINT && (
                    <SpawnPointBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.JUMPPAD && <JumpPadBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.TELEPORT && <TeleportBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.FOLLOW && <FollowBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.CHARACTER_SOUNDS && (
                    <CharacterSoundsBehavior behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.THROWABLE && (
                    <ThrowableBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.WEAPON && <WeaponBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.WEAPON_AMMO && (
                    <WeaponAmmoBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.MOBILE_TOUCH_CONTROL && (
                    <MobileTouchBehavior behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.HARVEST && (
                    <HarvestBehavior behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.SCRIPT && <ScriptBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.TRIGGER && <TriggerBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.RANDOMIZE_SPAWNER && (
                    <RandomizeSpawnerBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.PROCEDURAL_PLANT && (
                    <ProceduralPlantBehavior behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.PROCEDURAL_TERRAIN && (
                    <ProceduralTerrainBehavior behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.OBJECT_INTERACTIONS && (
                    <ObjectInteractionBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.AI_NPC && <AiNPCBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.NPC && <NPCBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.NPC_RECEIVER && <NPCReceiverBehavior behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.SPRITE && <SpriteBehaviors behavior={selectedBehavior} />}
            </Container>
        </BehaviorDetailsWrapper>
    );
};

const BehaviorDetailsWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 11px;
    box-sizing: border-box;
    padding: 0;
`;
