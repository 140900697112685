import PlayerComponent from "./PlayerComponent";
import AIConversationsController from "../../controls/AiConversationsController";
//TODO this will soon be re factored to camera types

class AiNpcControl extends PlayerComponent {
    constructor(app) {
        super(app);
        this.control = null;
    }

    create(scene, camera, renderer, sceneId, player, playerModel) {
        return this._createControl(scene, camera, renderer, sceneId, player, playerModel);
    }

    _createControl(scene, camera, renderer, sceneId, player, playerModel) {
        return new Promise((resolve, reject) => {
            this.control = new AIConversationsController(player, scene, camera, sceneId, playerModel);

            resolve();
        });
    }

    update(clock, deltaTime) {
        if (this.control && this.control.update) {
            this.control.update(deltaTime);
        }
    }

    dispose() {
        if (this.control) {
            this.control.dispose();
            this.control = null;
        }
    }
}

export default AiNpcControl;
