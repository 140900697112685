import {InputProvider} from "./InputProvider";

export enum TriggerType {
    Keyboard = "/keyboard/",
    MouseClick = "/mouse/click/",
    MouseMove = "/mouse/move/",
    GamepadButton = "/gamepad/button/",
    GamepadAxis = "/gamepad/axis/",
    VirtualButton = "/virtual/button/",
    VirtualAxis = "/virtual/axis/",
}

type Action = string;
type Motion = string;
type Trigger = string;

class ActionBinding {
    name: Action;

    constructor(name: Action) {
        this.name = name;
    }
}

class MotionBinding {
    name: Motion;
    scale: number;

    constructor(name: Motion, scale?: number) {
        this.name = name;
        this.scale = scale ?? 1;
    }
}

class MotionState {
    name: Motion;
    value: number;
    delta: number;

    constructor(name: Motion, value: number, delta: number) {
        this.name = name;
        this.value = value;
        this.delta = delta;
    }
}

export class Bindings<ActionsAndMotions extends string> {
    actions = new Map<Trigger, ActionBinding[]>();
    motions = new Map<Trigger, MotionBinding[]>();

    bindKey(code: string) {
        const trigger = TriggerType.Keyboard + code;
        return {
            toAction: (name: ActionsAndMotions) => {
                const b = new ActionBinding(name);
                let actions = this.actions.get(trigger);
                if (!actions) {
                    actions = [];
                    this.actions.set(trigger, actions);
                }
                actions.push(b);
            },
            toMotion: (name: ActionsAndMotions, scale?: number) => {
                const b = new MotionBinding(name, scale);
                let motions = this.motions.get(trigger);
                if (!motions) {
                    motions = [];
                    this.motions.set(trigger, motions);
                }
                motions.push(b);
            },
        };
    }

    bindMouseClick(button: number) {
        const trigger = TriggerType.MouseClick + button;
        return {
            toAction: (name: ActionsAndMotions) => {
                const b = new ActionBinding(name);
                let actions = this.actions.get(trigger);
                if (!actions) {
                    actions = [];
                    this.actions.set(trigger, actions);
                }
                actions.push(b);
            },
            toMotion: (name: ActionsAndMotions, scale?: number) => {
                const b = new MotionBinding(name, scale);
                let motions = this.motions.get(trigger);
                if (!motions) {
                    motions = [];
                    this.motions.set(trigger, motions);
                }
                motions.push(b);
            },
        };
    }

    bindMouseMove(axis: "x" | "y") {
        const trigger = TriggerType.MouseMove + axis;
        return {
            toMotion: (name: ActionsAndMotions, scale?: number) => {
                const b = new MotionBinding(name, scale);
                let motions = this.motions.get(trigger);
                if (!motions) {
                    motions = [];
                    this.motions.set(trigger, motions);
                }
                motions.push(b);
            },
        };
    }

    bindGamepadButton(button: number) {}

    bindGamepadAxis(axis: "x" | "y") {}

    bindVirtualButton(name: string) {}

    bindVirtualAxis(name: string, axis: "x" | "y") {}
}

export class Input<ActionsAndMotions extends string> implements InputProvider<ActionsAndMotions> {
    private bindings: Bindings<ActionsAndMotions>;
    private actions = new Map<Action, boolean>();
    private motions = new Map<Motion, MotionState>();
    private attachedEventTarget: EventTarget | null = null;
    private onDetachFns: (() => void)[] = [];
    private downKeys = new Set<string>();
    private activeTriggers = new Set<string>();

    private mouseX: number = 0;
    private mouseY: number = 0;
    private movementX: number = 0;
    private movementY: number = 0;

    constructor(bindings: Bindings<ActionsAndMotions>) {
        this.bindings = bindings;
        this.initInputMaps();
    }

    setBindings(bindings: Bindings<ActionsAndMotions>) {
        this.bindings = bindings;
        this.initInputMaps();
    }

    getAction(actionId: Action): boolean {
        return this.actions.get(actionId) ?? false;
    }

    getMotion(motionId: Motion): number {
        const motion = this.motions.get(motionId);
        return motion ? motion.value + motion.delta : 0;
    }

    /** Updated Mouse Touch Position Handling */
    getMouseTouchPosition() {
        if (document.pointerLockElement) {
            return { x: this.movementX, y: this.movementY, isRelative: true };
        } else {
            return { x: this.mouseX, y: this.mouseY, isRelative: false };
        }
    }

    attach(eventTarget: EventTarget, virtualInputDispatcher: VirtualInputDispatcher) {
        this.attachedEventTarget = eventTarget;
        this.downKeys.clear();

        /** Keyboard Input Handling */
        this.listen("keydown", (event: KeyboardEvent) => {
            if (this.downKeys.has(event.code)) return;
            this.downKeys.add(event.code);
            const trigger = TriggerType.Keyboard + event.code;
            this.setActionState(trigger, true);
            this.setMotionState(trigger, 1);
        });

        this.listen("keyup", (event: KeyboardEvent) => {
            if (!this.downKeys.has(event.code)) return;
            this.downKeys.delete(event.code);
            const trigger = TriggerType.Keyboard + event.code;
            this.setActionState(trigger, false);
            this.setMotionState(trigger, 0);
        });

        /** Mouse Input Handling */
        this.listen("mousedown", (event: MouseEvent) => {
            const trigger = TriggerType.MouseClick + event.button;
            this.setActionState(trigger, true);
            this.setMotionState(trigger, 1);
        });

        this.listen("mouseup", (event: MouseEvent) => {
            const trigger = TriggerType.MouseClick + event.button;
            this.setActionState(trigger, false);
            this.setMotionState(trigger, 0);
        });

        this.listen("mousemove", (event: MouseEvent) => {
            const triggerX = TriggerType.MouseMove + "x";
            const triggerY = TriggerType.MouseMove + "y";

            if (document.pointerLockElement) {
                // Pointer lock mode - use movement deltas
                this.movementX = event.movementX;
                this.movementY = event.movementY;
            } else {
                // Normal mode - Calculate movement manually
                this.movementX = event.clientX - this.mouseX;
                this.movementY = event.clientY - this.mouseY;

                this.mouseX = event.clientX;
                this.mouseY = event.clientY;
            }

            // Apply movement to motion states
            this.addMotionDelta(triggerX, this.movementX);
            this.addMotionDelta(triggerY, this.movementY);
        });


        /** Touch Input Handling */
        this.listen("touchstart", (event: TouchEvent) => {
            const touch = event.changedTouches[0];
            const trigger = TriggerType.MouseClick + touch.identifier;
            this.setActionState(trigger, true);
            this.setMotionState(trigger, 1);
            this.mouseX = touch.clientX;
            this.mouseY = touch.clientY;
        });

        this.listen("touchmove", (event: TouchEvent) => {
            const touch = event.changedTouches[0];
            const triggerX = TriggerType.MouseMove + "x";
            const triggerY = TriggerType.MouseMove + "y";

            this.addMotionDelta(triggerX, touch.clientX - this.mouseX);
            this.addMotionDelta(triggerY, touch.clientY - this.mouseY);

            this.mouseX = touch.clientX;
            this.mouseY = touch.clientY;
        });

        this.listen("touchend", (event: TouchEvent) => {
            const trigger = TriggerType.MouseClick + event.changedTouches[0].identifier;
            this.setActionState(trigger, false);
            this.setMotionState(trigger, 0);
        });
    }

    detach() {
        this.onDetachFns.forEach(fn => fn());
        this.onDetachFns = [];
        this.attachedEventTarget = null;
        this.actions.clear();
        this.motions.clear();
    }

    private setActionState(actionId: string, active: boolean) {
        const actionsList = this.bindings.actions.get(actionId);
        if (!actionsList) return;
        for (const action of actionsList) {
            this.actions.set(action.name, active);
        }
    }

    private setMotionState(motionId: string, active: number) {
        if (active) {
            this.activeTriggers.add(motionId);
        } else {
            this.activeTriggers.delete(motionId);
        }

        for (const motion of this.motions.values()) {
            motion.value = 0;
        }

        for (const trigger of this.activeTriggers) {
            for (const binding of this.bindings.motions.get(trigger) || []) {
                const state = this.motions.get(binding.name);
                if (state) {
                    state.value = binding.scale;
                }
            }
        }
    }

    private addMotionDelta(motionId: string, delta: number) {
        const motionsList = this.bindings.motions.get(motionId);
        if (!motionsList) return;
        for (const motion of motionsList) {
            const state = this.motions.get(motion.name);
            if (state) {
                state.delta += delta * motion.scale;
            }
        }
    }

    private initInputMaps() {
        this.actions.clear();
        for (const binding of this.bindings.actions.values()) {
            binding.forEach(action => {
                this.actions.set(action.name, false);
            });
        }

        this.motions.clear();
        for (const binding of this.bindings.motions.values()) {
            binding.forEach(motion => {
                this.motions.set(motion.name, new MotionState(motion.name, 0, 0));
            });
        }
    }

    update() {
        for (const state of this.motions.values()) {
            state.delta = 0;
        }
    }

    dispose() {
        this.detach();
    }

    private listen(name: string, listener: (event: any) => void) {
        if (this.attachedEventTarget) {
            this.attachedEventTarget.addEventListener(name, listener);
            this.onDetachFns.push(() => {
                this.attachedEventTarget?.removeEventListener(name, listener);
            });
        }
    }
}



// TODO: rewrite to axis and buttons
export class VirtualInputDispatcher {
    private listeners: Map<string, (data?: any) => void> = new Map();

    constructor() {}

    setAxisListener(name: string, listener: (data: {x: number; y: number}) => void) {
        this.listeners.set(name, listener);
    }

    setButtonListener(name: string, listener: (data: boolean) => void) {
        this.listeners.set(name, listener);
    }

    dispatchAxis(name: string, data: {x: number; y: number}) {
        const listener = this.listeners.get(name);
        if (listener) {
            listener(data);
        }
    }

    dispatchButton(name: string, data: boolean) {
        const listener = this.listeners.get(name);
        if (listener) {
            listener(data);
        }
    }
}
