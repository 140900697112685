import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 8px;
    width: 160px;
    height: auto;
    border-radius: 16px;
    cursor: default;

    position: absolute;
    top: -8px;
    right: calc(100% + 16px);

    background: var(--theme-container-minor-dark);
    border: 1px solid #ffffff1a;

    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-bold);
    color: var(--theme-font-main-selected-color);
    line-height: 120%;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 16px;
`;

export const MenuItem = styled.li`
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    transition: 0.2s;
    padding: 8px;
    border-radius: 8px;
    height: 32px;
    background-color: transparent;

    &:hover {
        background-color: var(--theme-container-divider);
    }

    .checkbox {
        margin-left: 8px;
    }
`;
