import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {flexCenter, regularFont} from "../../../../assets/style";
import styled from "styled-components";
import {useOnClickOutside} from "usehooks-ts";
import {StyledButton} from "./StyledButton";
import * as THREE from "three";
import ModelLoader from "../../../../assets/js/loaders/ModelLoader";
import JSZip from "jszip";
import Ajax from "../../../../utils/Ajax";
import TimeUtils from "../../../../utils/TimeUtils";
import Converter from "../../../../utils/Converter";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import I18n from "i18next";
import global from "../../../../global";
import {backendUrlFromPath} from "../../../../utils/UrlUtils";
import {Checkbox} from "../../../../ui/common/Checkbox";
import {GLTFExporter} from "three/examples/jsm/exporters/GLTFExporter";

import {positionCameraForModel} from "../utils/positionCameraForModel";
import {ModelUtils} from "../../../../utils/ModelUtils";
import GradientSpinner from "../../../../player/component/GradientSpinner";
import GLTFLoaderExtended from "../../../../assets/js/loaders/GLTFLoaderExtended";
import {toast} from "react-toastify";

export const Container = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 264px;
    height: 618px;

    background: var(--theme-grey-bg-tertiary);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    color: var(--theme-font-main-selected-color);

    ${flexCenter};
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 0px;

    * {
        box-sizing: border-box;
    }
`;

export const CloseBtn = styled.button`
    position: absolute;
    right: 16px;
    top: 8px;
    font-size: 20px;
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`;

const Title = styled.div`
    width: 100%;
    ${regularFont("xxs")};
    font-weight: var(--theme-font-bold);
    padding: 9px;
`;

const CheckboxWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;
    padding: 12px 8px;
    gap: 12px;
    ${regularFont("xxs")};
    color: var(--theme-font-unselected-color);
    > div {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: space-between;
    }
`;

const BottomBar = styled.div`
    margin-top: auto;
    padding: 18px 20px;
    ${flexCenter};
    ${regularFont("xxs")};
    color: var(--theme-font-input-color);
    text-align: center;
    flex-direction: column;
    gap: 12px;
    p {
        margin: 0;
    }
`;

const Wrapper = styled.div`
    height: 264px;
    min-height: 264px;
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-sizing: border-box;
    overflow: hidden;

    canvas {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }
`;

const LottieContainer = styled.div`
    width: 64px;
    height: 64px;
    margin: auto;
`;

type Props = {
    onClose: () => void;
    onApprove?: (
        thumbnailUrl: string,
        isPublic: boolean,
        data?: Blob,
        filename?: string,
        isZipped?: boolean,
    ) => Promise<void>;
    file: File;
};

export const ModelPreview = ({onClose, onApprove, file}: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const app = (global as any).app;
    const [topDownCamera, setTopDownCamera] = useState<THREE.PerspectiveCamera>();
    const [renderer, setRenderer] = useState<THREE.WebGLRenderer>();
    const [scene, setScene] = useState<THREE.Scene>();
    const [controls, setControls] = useState<OrbitControls>();
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const [mesh, setMesh] = useState<any>();
    const [originalMesh, setOriginalMesh] = useState<any>();
    const [thumbnail, setThumbnail] = useState<string | null>(null);
    const [isHumanoid, setIsHumanoid] = useState<boolean>(false);
    const [isOptimized, setIsOptimized] = useState<boolean>(false);
    const [isCompressed, setIsCompressed] = useState<boolean>(false);
    const [isPublic, setIsPublic] = useState<boolean>(false);
    const [polyCount, setPolyCount] = useState<number>(0);
    const [animations, setAnimations] = useState<THREE.AnimationClip[]>();
    const [isMixamoSupported, setIsMixamoSupported] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isGLTF, setIsGLTF] = useState<boolean>(false);
    const [isVRM, setIsVRM] = useState<boolean>(false);
    const maxPolycount = 20000;
    const animationNames = ["Idle", "Walking", "Slow Run", "Jumping"];

    useOnClickOutside(ref, onClose);

    const handleUploadThumbnail = (file: File) => {
        Ajax.post({
            url: backendUrlFromPath(`/api/Upload/Upload`),
            data: {
                file,
            },
            msgBodyType: "multipart",
        })
            .then(response => {
                if (response?.data.Code === 200) {
                    setIsLoading(true);
                    setThumbnail(response.data.Data.url);
                }
            })
            .catch(() => {
                app.unmask();
                app.toast("Request failed.", "error");
                onClose();
            });
    };

    const saveAsGLB = async (thumbnail: string, model: any) => {
        try {
            var exporter = new GLTFExporter();
            exporter.parse(
                model,
                function (result) {
                    (async () => {
                        let arrayBuffer = result as ArrayBuffer;

                        if (isOptimized) {
                            arrayBuffer = (await ModelUtils.simplifyModel(arrayBuffer, false, () => {
                                toast(I18n.t("Could not simplify model"));
                            })) as ArrayBuffer;
                        }

                        if (isCompressed) {
                            arrayBuffer = (await ModelUtils.compressModel(arrayBuffer, false, () => {
                                toast(I18n.t("Could not compress model"));
                            })) as ArrayBuffer;
                        }

                        if (onApprove) {
                            await onApprove(
                                thumbnail,
                                isPublic,
                                new Blob([arrayBuffer as ArrayBuffer]),
                                `${file.name.split(".")[0]}.glb`,
                            );
                        }
                    })();
                },
                error => {
                    app.toast(I18n.t("Could not save model"), "error");
                    onClose();
                },
                {
                    trs: true,
                    binary: true,
                    animations: model._obj?.animations || model.animations,
                },
            );
        } catch (error) {
            app.toast(I18n.t("Could not save model"), "error");
            onClose();
        }
    };

    const handleModelLoad = (objectUrl: string, type: string, callback: (obj: any) => void) => {
        setIsLoading(true);
        let loader = new (ModelLoader as any)(app);
        loader
            .load(
                objectUrl,
                {Type: type},
                {
                    camera: app.editor.camera,
                    renderer: app.editor.renderer,
                    audioListener: app.editor.audioListener,
                    clearChildren: true,
                },
            )
            .then((obj: any) => {
                setIsLoading(false);
                if (!obj) {
                    app.toast(I18n.t("Could not load model"), "error");
                    onClose();
                }
                callback(obj);
            })
            .catch((e: any) => {
                console.error(e);
                setIsLoading(false);
                app.toast(I18n.t("Could not load model"), "error");
                onClose();
            });
    };

    const loadAnimations = async () => {
        const animations: THREE.AnimationClip[] = [];
        animationNames.forEach((fileName: string) => {
            try {
                let loader = new (ModelLoader as any)(app);
                loader
                    .load(
                        `/assets/animations/mixamo/${fileName}.fbx`,
                        {Type: "fbx"},
                        {
                            camera: app.editor.camera,
                            renderer: app.editor.renderer,
                            audioListener: app.editor.audioListener,
                            clearChildren: true,
                        },
                    )
                    .then((obj: any) => {
                        if (!obj) {
                            return;
                        }
                        if (obj.animations.length > 1) {
                            obj.animations.forEach((anim: any, index: any) => {
                                anim.name = fileName + index;
                            });
                        } else {
                            obj.animations[0].name = fileName;
                        }
                        animations.push(...obj.animations);
                    });
            } catch (error) {
                app.toast(I18n.t("Could not load animations"), "warn");
            }
        });
        return animations;
    };

    useEffect(() => {
        (async () => {
            const animations = await loadAnimations();
            setAnimations(animations);
        })();
    }, []);

    const processFile = async (file: File) => {
        setIsLoading(true);
        try {
            const isZip = file.type === "application/zip";

            if (!isZip) {
                console.log(file);
                const isVRM = file.name.includes(".vrm");
                const isGLTF = file.name.includes(".gltf");
                setIsGLTF(isGLTF);
                setIsVRM(isVRM);
                setIsCompressed(!isVRM);
                processNonZipFile(file);
                return;
            }

            const zipper = new JSZip();
            const zip = await zipper.loadAsync(file);
            const isGLTF = Object.keys(zip.files).some(key => zip.files[key].name.includes(".gltf"));
            const isVRM = Object.keys(zip.files).some(key => zip.files[key].name.includes(".vrm"));

            setIsVRM(isVRM);
            setIsGLTF(isGLTF);
            setIsCompressed(!isVRM);

            processZipFiles(zip);
        } catch (error) {
            console.error("Error processing file:", error);
            app.toast("Could not process file", "error");
        }
    };

    const processNonZipFile = (file: File) => {
        const fileURL = URL.createObjectURL(file);
        const type = file.name.split(".").pop()?.split(" ")[0];

        handleModelLoad(fileURL, type || "glb", obj => {
            setOriginalMesh(obj);
            setMesh(obj);
        });
    };

    const processZipFiles = async (zip: JSZip) => {
        setIsLoading(true);
        const fileMap = new Map();
        const filesAmount = Object.keys(zip.files).filter(key => !zip.files[key].dir).length;
        let rootFile: File;
        let rootPath: string;
        zip.forEach(function (relativePath, zipEntry) {
            if (!zipEntry.dir) {
                zipEntry.async("blob").then(function (fileContent) {
                    const file = new File([fileContent], zipEntry.name);
                    fileMap.set(zipEntry.name, file);
                    if (filesAmount === fileMap.size) {
                        Array.from(fileMap).forEach(([path, file]) => {
                            if (file.name.match(/\.(gltf|glb)$/)) {
                                rootFile = file;
                                rootPath = path.replace(file.name, "");
                            }
                        });

                        if (!rootFile) {
                            app.toast("Root file not found", "error");
                            return;
                        }
                        const fileURL = typeof rootFile === "string" ? rootFile : URL.createObjectURL(rootFile);
                        const loader = new GLTFLoaderExtended();

                        loader
                            .load(fileURL, rootPath, fileMap)
                            .then((obj: any) => {
                                setOriginalMesh(obj);
                                setMesh(obj);
                                setIsLoading(false);
                            })
                            .catch((e: any) => {
                                app.toast("Could not load gltf file", "error");
                                onClose();
                                setIsLoading(false);
                            });
                    }
                });
            }
        });
    };

    useEffect(() => {
        (async () => {
            await processFile(file);
        })();
    }, [file]);

    useLayoutEffect(() => {
        if (wrapperRef.current && !isRendered) {
            const renderer = new THREE.WebGLRenderer({
                antialias: true,
                preserveDrawingBuffer: true,
            });
            const scene = new THREE.Scene();
            scene.background = new THREE.Color(0x27272a);
            const camera = new THREE.PerspectiveCamera(20, 1, 0.1, 100000);
            const controls = new OrbitControls(camera, renderer.domElement);
            setIsRendered(true);
            setRenderer(renderer);
            setTopDownCamera(camera);
            setControls(controls);
            setScene(scene);
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(wrapperRef.current.offsetWidth || 100, wrapperRef.current.offsetHeight || 100);
            wrapperRef.current.appendChild(renderer.domElement);
        }
    }, [wrapperRef.current, isRendered]);

    useEffect(() => {
        if (mesh && topDownCamera && scene && controls) {
            renderer?.clear();
            const light = new THREE.AmbientLight();
            light.intensity = 5;
            scene.clear();
            scene.add(mesh);
            scene.add(light);

            positionCameraForModel(mesh, topDownCamera, controls);

            let count = 0;
            let isMixamoSupported = false;
            mesh.traverse((child: any) => {
                if (child.isMesh) {
                    const geometry = child.geometry;
                    count += geometry.index ? geometry.index.count / 3 : geometry.attributes.position.count / 3;
                }

                if (child.type === "Bone" && !isMixamoSupported) {
                    isMixamoSupported = child.name.includes("mixamo");
                }
            });
            setIsMixamoSupported(isMixamoSupported);
            setPolyCount(count);

            let lastTime = 0;
            const interval = 1000 / 60;

            const animate = (time: number) => {
                requestAnimationFrame(animate);

                if (renderer && scene && topDownCamera && controls) {
                    const deltaTime = time - lastTime;

                    if (deltaTime > interval) {
                        lastTime = time - (deltaTime % interval);

                        renderer.render(scene, topDownCamera);
                        controls.update();
                    }
                }
            };

            animate(0);
        }
    }, [topDownCamera, mesh, scene, controls, renderer]);

    const handleSave = async () => {
        if (isRendered && renderer) {
            const canvas = renderer.domElement;
            const dataUrl = canvas.toDataURL("image/jpeg", 1.0);
            const file = (Converter as any).dataURLtoFile(dataUrl, (TimeUtils as any).getDateTime());
            handleUploadThumbnail(file);
        }
    };

    useEffect(() => {
        (async () => {
            if (thumbnail !== null) {
                if (isOptimized || isHumanoid || isGLTF || isCompressed) {
                    let model = originalMesh;

                    if (isHumanoid && animations) {
                        Object.assign(model.animations, [...model.animations, ...animations]);
                    }

                    saveAsGLB(thumbnail, model);
                } else {
                    if (onApprove) {
                        await onApprove(thumbnail, isPublic);
                    }
                }
            }
        })();
    }, [thumbnail, animations]);

    return (
        <Container ref={ref}>
            <Wrapper ref={wrapperRef} />
            <Content>
                <Title>3D model import</Title>
                {isLoading ? (
                    <GradientSpinner />
                ) : (
                    <CheckboxWrapper>
                        <div>
                            Is this a human shaped Model{" "}
                            <Checkbox
                                customId="humanoid"
                                checked={isHumanoid}
                                onChange={e => setIsHumanoid(e.target.checked)}
                            />
                        </div>
                        <div>
                            Optimize model
                            <Checkbox
                                customId="optimize"
                                checked={isOptimized}
                                onChange={e => setIsOptimized(e.target.checked)}
                            />
                        </div>
                        <div>
                            Compress model
                            <Checkbox
                                customId="compress"
                                checked={isCompressed}
                                onChange={e => setIsCompressed(e.target.checked)}
                            />
                        </div>
                        <div>
                            Make model public
                            <Checkbox
                                customId="make public"
                                checked={isPublic}
                                onChange={e => setIsPublic(e.target.checked)}
                            />
                        </div>
                    </CheckboxWrapper>
                )}

                <BottomBar>
                    {isHumanoid && !isMixamoSupported && (
                        <p>Your model does not support mixamo animations. Animations will not be applied</p>
                    )}
                    {polyCount > maxPolycount && !isOptimized && !isCompressed && !isVRM && (
                        <p>
                            3D model polycount is high, consider optimizing or compressing the model for better
                            performance
                        </p>
                    )}
                    {isVRM && (isOptimized || isCompressed) && (
                        <p>
                            VRM models cannot be optimized or compressed. If you continue model will be saved in GLB
                            format
                        </p>
                    )}
                    <p>Current Polycount: {polyCount}</p>
                    {isRendered && (
                        <StyledButton isBlue onClick={handleSave} disabled={isLoading}>
                            Finished
                        </StyledButton>
                    )}
                </BottomBar>
            </Content>
        </Container>
    );
};
