import {useEffect} from "react";
import {Container, Content, Wrapper} from "./TermsAndPolicy.style";
import {Header} from "../../Header/Header";
import {Footer} from "../../Footer/Footer";
import {PrivacyPolicy} from "./PrivacyPolicy";
import {TOS} from "./TOS";

const privacyPolicyDate = "November 7, 2024";
const TOSDate = "November 11, 2024";

export const TermsAndPolicy = ({privacyPolicy}: {privacyPolicy?: boolean}) => {
    useEffect(() => {
        const container = document.getElementById("container");
        if (container) {
            container.style.overflowY = "auto";
            container.style.position = "relative";

            container.scrollTo(0, 0);
        }
    }, []);

    return (
        <Container>
            <Header />
            <Wrapper>
                <Content>
                    <header className="title">{privacyPolicy ? "Privacy Policy" : "Terms of Service"}</header>
                    <div className="updateDate">Last Revised on {privacyPolicy ? privacyPolicyDate : TOSDate}.</div>
                    <div className="text">{privacyPolicy ? <PrivacyPolicy /> : <TOS />}</div>
                </Content>
            </Wrapper>
            <Footer />
        </Container>
    );
};
