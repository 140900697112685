import {toast} from "react-toastify";
import Ajax, {authToken} from "./Ajax";
import {backendUrlFromPath} from "./UrlUtils";

enum GenerateModelStyles {
    CARTOON = "person:person2cartoon",
    CLAY = "object:clay",
    STEAMPUNK = "object:steampunk",
    VENOM = "animal:venom",
    BARBIE = "object:barbie",
    CHRISTMAS = "object:christmas",
    GOLD = "gold",
    ANCIENT_BRONZE = "ancient_bronze",
}

enum Topology {
    BIPED = "bip",
    QUADRUPED = "quad",
}

const bipAnimations = [
    "preset:idle",
    "preset:walk",
    "preset:climb",
    "preset:jump",
    "preset:run",
    "preset:slash",
    "preset:shoot",
    "preset:hurt",
    "preset:fall",
    "preset:turn",
];
const quadAnimations = ["preset:quad_catrun", "preset:quad_catwalk"];
type GenerateModelRequest = {
    url?: string;
    file_token?: string;
    face_limit?: number;
    texture?: boolean;
    pbr?: boolean;
    style?: GenerateModelStyles;
};

const uploadImage = async (image: File) => {
    const res = await Ajax.post({
        url: backendUrlFromPath(`/api/ObjectGeneration/Upload`),
        msgBodyType: "multipart",
        data: {
            file: image,
        },
        needAuthorization: false,
    });
    return res;
};

const generateModel = async (
    type: "text_to_model" | "image_to_model",
    prompt: string,
    url?: string,
    file_token?: string,
    quality?: string,
) => {
    if (!url && !file_token && type === "image_to_model") {
        throw Error("No image provided.");
    }
    const res = await fetch(backendUrlFromPath("/api/ObjectGeneration/Generate") || "", {
        method: "POST",
        body: JSON.stringify({
            type: type,
            prompt: prompt,
            url: url || "",
            file_token: file_token || "",
            texture_quality: quality || "standard",
        }),
    });

    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("No response from AI.");
    }
};

const animateModel = async (task_id: string) => {
    const res = await fetch(backendUrlFromPath("/api/ObjectGeneration/PreRigCheck") || "", {
        method: "POST",
        body: JSON.stringify({
            original_model_task_id: task_id,
        }),
    });
    if (!res.ok) {
        throw Error("Failed to check rigging.");
    }
    const response = await res.json();
    if (!response.output) {
        throw Error("No response from AI.");
    }

    const {topology, riggable} = response.output;
    if (!riggable) {
        throw Error("Model is not riggable.");
    }
    const rigResponse = await fetch(backendUrlFromPath("/api/ObjectGeneration/Rig") || "", {
        method: "POST",
        body: JSON.stringify({
            original_model_task_id: task_id,
            topology: topology,
            spec: "mixamo",
        }),
    });

    if (!rigResponse.ok) {
        throw Error("Failed to rig model.");
    }
    const rigJson = await rigResponse.json();

    const retargetResponse = await fetch(backendUrlFromPath("/api/ObjectGeneration/Retarget") || "", {
        method: "POST",
        body: JSON.stringify({
            original_model_task_id: rigJson.task_id,
            animation: topology === Topology.BIPED ? bipAnimations[1] : quadAnimations[1],
        }),
    });

    if (!retargetResponse.ok) {
        toast.warn("Failed to retarget animation.");
        return rigJson;
    }
    const retargetJson = await retargetResponse.json();

    return retargetJson;
};

export const ModelGeneratorUtils = {uploadImage, generateModel, animateModel};
