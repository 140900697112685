import React, {RefObject, useState} from "react";
import * as THREE from "three";
import {StyledList, TreeWrapV2} from "./Tree.style";
import {TreeItem} from "./TreeItem";
import {ItemMenuText, RightClickMenu} from "../../../ui/common/RightClickMenu/RightClickMenu";
import Application from "../../../Application";
import global from "../../../global";

type Props = {
    onExpand: (value: any, event: any) => void;
    onSelect: (value: any, isShiftPressed: boolean) => void;
    onDoubleClick: (value: any, event: any) => void;
    onDrop: (value: any, newParentValue: any, newBeforeValue: any) => void;
    className: string;
    style: any;
    data: any;
    treeRef: RefObject<HTMLUListElement>;
    selected: string[] | null;
    onLockClick: (id: string) => void;
    lockedItems: string[];
    openGameManager: () => void;
    scrollToSelected: () => void;
};

export const Tree = ({
    onExpand,
    onSelect,
    onDoubleClick,
    onDrop,
    className,
    style,
    data,
    treeRef,
    selected,
    onLockClick,
    lockedItems,
    openGameManager,
    scrollToSelected,
}: Props) => {
    const app = global.app as Application;
    const editor = app?.editor;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuPosition, setMenuPosition] = useState<{x: number; y: number} | null>(null);

    // const calculateVertices = (data: any) => {
    //     let sum = (obj: any) => {
    //         if (obj.hasOwnProperty("vertices") && obj.vertices >= 0) {
    //             return obj.vertices;
    //         } else if (obj.hasOwnProperty("children") && obj.children.length > 0) {
    //             let total = 0;
    //             obj.children.forEach((o: any) => (total += sum(o)));
    //             return total;
    //         }
    //         return 0;
    //     };
    //     return sum(data);
    // };

    const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event.stopPropagation();

        if (event.target instanceof HTMLLIElement) {
            const value = event.target.getAttribute("value");
            if (value) {
                onSelect && onSelect(value, event.shiftKey);
            }
        }
    };

    const createEmptyGroup = () => {
        closeMenu();
        if (!editor) return;

        const group = new THREE.Group();
        group.name = "Empty Group";
        editor.scene.add(group);

        editor.select(group);
        global.app?.call("objectUpdated");
    };

    const closeMenu = () => {
        setMenuPosition(null);
        setIsMenuOpen(false);
    };

    const handleRightClick = (event: any) => {
        event.preventDefault();
        setIsMenuOpen(true);
        const x = event.clientX;
        const y = event.clientY;
        setMenuPosition({x, y});
    };

    const createNode = (data: any, index: number) => {
        if (data.type === "Box3Helper") {
            return null;
        }

        const leaf = (!data.children || data.children.length === 0) && data.leaf !== false;

        const isBehaviors = data.userData?.behaviors && data.userData?.behaviors.length > 0;

        const isGroup = data.type === "Group";

        if (data.text === "DefaultCamera") {
            data.onClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                openGameManager();
                handleClick(e);
            };
        }

        return (
            <TreeItem
                key={`${data.text}${index}`}
                selected={selected}
                data={data}
                index={index}
                leaf={leaf}
                lockedItems={lockedItems}
                isBehaviors={isBehaviors}
                isGroup={isGroup}
                onLockClick={onLockClick}
                handleClick={handleClick}
                onDoubleClick={onDoubleClick}
                onDrop={onDrop}
                onExpand={onExpand}
                scrollToSelected={scrollToSelected}
                createEmptyGroup={createEmptyGroup}
            />
        );
    };

    const list: any[] = [];

    Array.isArray(data) &&
        data.forEach((n, index) => {
            list.push(createNode(n, index + 1));
        });

    return (
        <TreeWrapV2>
            <StyledList
                className={`hidden-scroll ${className}`}
                style={style}
                ref={treeRef}
                onContextMenu={handleRightClick}>
                {isMenuOpen && menuPosition && (
                    <RightClickMenu onClickoutsideCallback={closeMenu} left={menuPosition.x} top={menuPosition.y}>
                        <ItemMenuText onClick={createEmptyGroup}>Create Empty Group</ItemMenuText>
                    </RightClickMenu>
                )}
                {list}
            </StyledList>
        </TreeWrapV2>
    );
};
