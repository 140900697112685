import React, { useEffect, useState } from "react";
import { ButtonsColumn, Grid } from "../../HUDEditView/InGameMenu/InGameMenu.style";
import {
    HUD_TABS,
    IGameButtonInterface,
    IN_GAME_MENU_IDS,
    InGameMenuDataType,
    START_MENU_BUTTON_TYPES,
} from "../../HUDEditView/types";
import { CustomGameButton } from "../../HUDEditView/CustomGameButton/CustomGameButton";
import { Logo } from "../Logo";
import EventBus from "../../../../../../behaviors/event/EventBus";
import global from "../../../../../../global";
import { StopButton } from "../StopButton";
import { getSoundsFromUI } from "../getGameSounds";

interface Props {
    setView: React.Dispatch<React.SetStateAction<HUD_TABS | null>>;
    setBgImage: React.Dispatch<React.SetStateAction<string | undefined>>;
    emptyHUD: boolean;
    renderStopButton: boolean;
}

export const InGameView = ({ setView, setBgImage, emptyHUD, renderStopButton }: Props) => {
    const app = (global as any).app;
    const [inGameUI, setInGameUI] = useState<InGameMenuDataType>();
    const [isReady, setIsReady] = useState(false);
    const [sounds, setSounds] = useState<any[]>();

    const scene = app.scene || app.editor?.scene;

    useEffect(() => {
        if (scene?.userData?.gameUI?.inGameMenu) {
            const data = scene.userData.gameUI.inGameMenu as InGameMenuDataType;
            setInGameUI(data);
        } else {
            setInGameUI(undefined);
        }
    }, [app]);

    useEffect(() => {
        if (inGameUI) {
            const img = inGameUI[IN_GAME_MENU_IDS.MENU_BG] as string;
            const soundsToLoad = getSoundsFromUI(inGameUI, false);
            img && setBgImage(img);
            setSounds(soundsToLoad);
        } else {
            setBgImage(undefined);
        }
    }, [inGameUI]);

    const handleGameButtonClick = (customStyle: IGameButtonInterface) => {
        const clickSound = customStyle.clickSound;
        clickSound && EventBus.instance.send("game.playSound", clickSound.ID);
        if (checkIfStartBtn(customStyle?.UIButtonType)) {
            EventBus.instance.send("game.resume");
            EventBus.instance.send("game.clear_sounds");
            if (!app.isSpriteCharacter) {
                document.body.requestPointerLock();
            }
            setView(null);
        }
    };

    const checkIfStartBtn = (buttonType: string) => {
        return buttonType?.toLowerCase() === START_MENU_BUTTON_TYPES.START_GAME.toLowerCase();
    };

    useEffect(() => {
        if (inGameUI) {
            setTimeout(() => {
                setIsReady(true);
            }, 1000);
        }
    }, [inGameUI]);

    useEffect(() => {
        if (sounds) {
            EventBus.instance.send("game.loadSounds", sounds);
        }
    }, [sounds]);

    useEffect(() => {
        return () => {
            sounds && EventBus.instance.send("game.clear_sounds");
        };
    }, []);

    const handleHover = (customStyle: IGameButtonInterface) => {
        const hoverSound = customStyle.hoverSound;
        hoverSound && EventBus.instance.send("game.playSound", hoverSound.ID);
    };

    return inGameUI ? (
        <Grid>
            {!emptyHUD && (
                <>
                    <ButtonsColumn>
                        {renderStopButton && <StopButton />}

                        {inGameUI["in-game-menu-logo-mid"] && (
                            <Logo
                                width="285px"
                                height="285px"
                                bgImage={(inGameUI[IN_GAME_MENU_IDS.LOGO_MID] as string) || null}
                            />
                        )}
                        {isReady &&
                            Array(5)
                                .fill(5)
                                .map((_, index) => {
                                    const id = `in-game-menu-button-column-mid-${index + 1}` as IN_GAME_MENU_IDS;
                                    const customStyleData = inGameUI[id] as IGameButtonInterface;

                                    return (
                                        <CustomGameButton
                                            key={index}
                                            customText={
                                                customStyleData?.UIButtonType === START_MENU_BUTTON_TYPES.START_GAME
                                                    ? "Resume Game"
                                                    : undefined
                                            }
                                            customStyle={customStyleData ? customStyleData : null}
                                            width="285px"
                                            height="32px"
                                            onClick={() => handleGameButtonClick(customStyleData)}
                                            onHover={() => handleHover(customStyleData)}
                                            pointerEvent
                                            id={
                                                customStyleData
                                                    ? checkIfStartBtn(customStyleData?.UIButtonType)
                                                        ? "startGameBtn"
                                                        : undefined
                                                    : undefined
                                            }
                                        />
                                    );
                                })}
                    </ButtonsColumn>
                </>
            )}
        </Grid>
    ) : null;
};
