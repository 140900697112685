import * as THREE from "three";
import {InventoryManagerBehaviorInterface, INVENTORY_TYPES} from "../../types/editor";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import {EVENTS, dispatchCustomInventoryEvent} from "../../api/inventory/inventoryEvents";

enum GAME_STATE {
    NOT_STARTED,
    STARTED,
    FINISHED,
    PAUSED,
}

class InventoryManagerBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    behavior: InventoryManagerBehaviorInterface;
    game?: GameManager;
    inventoryUIRendered: boolean = false;

    static allBehaviors: any[] = [];
    static rootRendered: boolean = false;

    constructor(target: THREE.Object3D, behavior: InventoryManagerBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;

        InventoryManagerBehaviorUpdater.loadInventory(this.target, this.behavior);
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
    }

    static loadInventory(target: THREE.Object3D, behavior: InventoryManagerBehaviorInterface) {
        if (Array.isArray(target.userData.behaviors)) {
            const behaviors = target.userData.behaviors.filter(behavior =>
                Object.values(INVENTORY_TYPES).includes(behavior.type),
            );
            if (behaviors.length > 0) {
                InventoryManagerBehaviorUpdater.allBehaviors.push({
                    ...behaviors[0],
                    uuid: target.uuid,
                });
            }
        }
    }

    static createInventoryUI(game?: GameManager) {
        if (InventoryManagerBehaviorUpdater.rootRendered) return;
        InventoryManagerBehaviorUpdater.rootRendered = true;
        dispatchCustomInventoryEvent(EVENTS.INVENTORY_INIT, {
            game,
            behaviors: InventoryManagerBehaviorUpdater.allBehaviors,
        });
    }

    update(clock: THREE.Clock, delta: number): void {
        if (!this.game || !this.target || !this.game.player || this.game.state === GAME_STATE.PAUSED) {
            return;
        }

        if (!this.inventoryUIRendered) {
            InventoryManagerBehaviorUpdater.createInventoryUI(this.game);
            this.inventoryUIRendered = true;
        }
    }

    reset() {}
}

export default InventoryManagerBehaviorUpdater;
