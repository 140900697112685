import EventBus from "../event/EventBus";
import HUDManager from "../hud/HUDManager";
import BehaviorManager, {BehaviorUpdater} from "../BehaviorManager";
import * as THREE from "three";
import Ammo from "../../assets/js/libs/ammojs3/builds/ammo";
import I18n from "i18next";
import {Object3D} from "three";
import CharacterBehaviorUpdater from "../../serialization/behaviours/CharacterBehaviorUpdater";
import global from "../../global";
import {ICollisionSource, IPhysics} from "../../physics/common/types";
import {IFRAME_MESSAGES, ISoundSettings} from "../../types/editor";
import Instancer from "../../utils/Instancer";
import {BehaviorConverter} from "../../serialization/behaviours";

enum GAME_STATE {
    NOT_STARTED = 0,
    STARTED,
    FINISHED, //automatically switches to NOT_STARTED
    PAUSED,
}

export interface IControl {
    setInitialPlayerState(pos: THREE.Vector3, rotation: THREE.Vector3): void;
    getCollisionObjects(): Object3D[];
    disposeBullet(bullet: Object3D): void;
    setMaxShots(maxShots: any): void;
    resetState(): void;
    unlockControl(): void;
    setLives(lives: number): void;
    getPlayerObject(): Object3D;
}

/**
 * To start the game:
 *  EventBus.instance.send('game.start');
 * To increment score:
 *  EventBus.instance.send('game.score.inc', 10);
 */
class GameManager {
    static TOPIC = "game";

    app: any;

    //config
    isEnabled = false;
    initialLives = 3;
    maxScore = 500;

    //current session
    state = GAME_STATE.NOT_STARTED;
    score = 0;
    lives = 0;
    pickedWeaponOrItem?: THREE.Object3D;
    playerWeapons: THREE.Object3D[] = [];

    //after init
    physics?: IPhysics;
    player?: THREE.Object3D | null;
    scene?: THREE.Scene;
    camera?: THREE.Camera;
    control?: IControl;
    hud?: HUDManager;
    behaviorManager?: BehaviorManager;
    gameTimer?: number = 0;
    time_remaining?: string = "00:00:00";
    timerRunning? = false;
    timerRemainingTime: number = 0;
    playerStartingPosition?: THREE.Vector3;
    instancer?: Instancer;

    constructor(app: any) {
        this.app = app;
    }

    //API

    isGameOver() {
        return this.state === GAME_STATE.FINISHED;
    }

    isWinner() {
        return this.isGameOver() && this.lives > 0;
    }

    //and of API

    //call to start the new session
    create(
        physics: IPhysics,
        control: IControl,
        collisionSource: ICollisionSource,
        scene: any,
        camera: any,
        behaviors: BehaviorUpdater[],
        isEditorMode: boolean,
        useInstancing: boolean,
        world?: Ammo.btDiscreteDynamicsWorld,
    ) {
        this.isEnabled = scene.userData.game && scene.userData.game.enabled;
        this.hud = new HUDManager(scene);
        this.physics = physics;
        this.instancer = new Instancer();

        // convert static objects to instanced mesh
        if (useInstancing) {
            this.instancer.convertMeshesToInstancedMeshes(scene);
        }

        if (!this.isEnabled) {
            console.log("GameManager: scene is not a game");
            //(global as any).app.toast(I18n.t('GameManager: scene is not a game'), "warn");
            return Promise.resolve();
        }

        return new Promise<void>((resolve, reject) => {
            this.initialLives = scene.userData.game.lives;
            this.maxScore = scene.userData.game.maxScore;
            this.player = control && control.getPlayerObject ? control.getPlayerObject() : null;

            if (!this.player) {
                console.error("GameManager: failed to find player object. Check your settings.");
                window.parent.postMessage(IFRAME_MESSAGES.GAME_PLAYER_ERROR, "*");
                this.isEnabled = false;
                resolve();
                return;
            }

            this.scene = scene;
            this.camera = camera;
            //TODO: pass control directly from the Player (not through the camera object)
            this.control = camera.userData.controlInstance;

            this.behaviorManager = new BehaviorManager(physics, collisionSource, this.app);
            this.behaviorManager.create(this, behaviors, world);

            this.setInitialPlayerState();

            EventBus.instance.unsubscribe(GameManager.TOPIC);
            EventBus.instance.subscribe(GameManager.TOPIC, this.onMessage.bind(this));

            this.state = GAME_STATE.NOT_STARTED;
            this.lives = Number(this.initialLives);
            this.score = 0;
            (global as any).app.call("gameCreated", this, this);
            window.parent.postMessage(IFRAME_MESSAGES.GAME_CREATED, "*");
            console.log("game created");
            resolve();
        });
    }

    registerNewObjectInGame(target: THREE.Object3D) {
        const converter = new BehaviorConverter({behaviors: []});

        const behaviors = target.userData.behaviors;
        if (behaviors) {
            behaviors.forEach((behavior: any) => {
                if (behavior.enabled) {
                    converter.createBehavior(target, behavior);
                }
            });
            const updaters = converter.obj.behaviors;
            this.behaviorManager?.addBehaviors(updaters);
        }
    }

    loadSounds(sounds: ISoundSettings[]) {
        this.hud?.loadSounds(sounds);
    }

    playSound(soundId: string) {
        this.hud?.playSound(soundId);
    }

    stopSound(soundId: string) {
        this.hud?.stopSound(soundId);
    }

    clearSounds() {
        this.hud?.clearSounds();
    }

    //called when Player stops
    reset() {
        this.endGameSession();
        this.hud?.clear();
    }

    //update score, lives, etc and update state as needed
    onMessage(topic: string, data: any) {
        console.log(`GM: onMessage: ${this.state} -> ${topic} -> ${data}`);
        let subs = topic.split(".");
        if (subs.length < 2) {
            console.warn(`GM: invalid message: ${topic}`);
            return;
        }

        let cmd = subs[1];

        if (cmd === "start") {
            this.state = GAME_STATE.STARTED;
            this.lives = this.initialLives;
            this.score = 0;
            this.gameCountDown();
            this.behaviorManager?.reset();
            if (this.control?.resetState) {
                this.control?.resetState();
            }
            (global as any).app.call("gameStarted", this, this);
            window.parent.postMessage(IFRAME_MESSAGES.GAME_STARTED, "*");
        } else if (cmd === "resume") {
            this.state = GAME_STATE.STARTED;
            this.behaviorManager?.reset();
            if (this.control?.resetState) {
                this.control?.resetState();
                window.parent.postMessage(IFRAME_MESSAGES.GAME_RESUMED, "*");
            }
            (global as any).app.call("gameResumed", this, this);
        } else if (cmd === "pause") {
            if (this.state !== GAME_STATE.FINISHED) {
                this.state = GAME_STATE.PAUSED;
                window.parent.postMessage(IFRAME_MESSAGES.GAME_PAUSED, "*");
            }
        } else if (cmd === "stop") {
            this.endGameSession();
        } else if (cmd === "score") {
            if (this.state !== GAME_STATE.STARTED) {
                console.warn(`GM: score update in a wrong state: ${topic} -> ${this.state}`);
                return;
            }
            this.handleScoreUpdate(topic, subs, data);
        } else if (cmd === "lives") {
            if (this.state !== GAME_STATE.STARTED) {
                console.warn(`GM: lives update in a wrong state: ${topic} -> ${this.state}`);
                return;
            }
            this.handleLivesUpdate(topic, subs, data);
        } else if (cmd === "weapon") {
            this.handleWeaponUpdate(topic, subs, data);
        } else if (cmd === "loadSounds") {
            this.loadSounds(data);
        } else if (cmd === "playSound") {
            this.playSound(data);
        } else if (cmd === "stop_sound") {
            this.stopSound(data);
        } else if (cmd === "clear_sounds") {
            this.clearSounds();
        } else {
            console.warn(`GM: unsupported message: ${topic}`);
            return;
        }
        //update UI
        //this.hud?.update(this, 0);
        (global as any).app.call("gameUpdated", this, this);
    }

    update(clock: any, delta: number) {
        if (this.behaviorManager) {
            this.behaviorManager.update(clock, delta);
        }
    }

    handleScoreUpdate(topic: string, subs: string[], data: any) {
        if (subs.length < 3) {
            console.warn(`GM: invalid score message: ${topic}`);
            return;
        }
        if (typeof data !== "number") {
            console.warn(`GM: invalid score data: ${topic} => ${data}`);
            data = Number(data);
            if (Number.isNaN(data)) {
                return;
            }
        }
        if (subs[2] === "inc") {
            console.log(`GM: score update: ${topic} => ${this.score} -> ${this.score + data}`);
            this.score += data;
            if (this.maxScore > 0 && this.score >= this.maxScore) {
                console.log("GM: score reached 200 - game over !");
                this.endGameSession();
            }
        } else {
            console.warn(`GM: unsupported score update operation: ${topic}`);
            return;
        }
    }

    private handleLivesUpdate(topic: string, subs: string[], data: any) {
        if (subs.length < 3) {
            console.warn(`GM: invalid lives message: ${topic}`);
            return;
        }
        if (typeof data !== "number") {
            console.warn(`GM: invalid lives data: ${topic} => ${data}`);
            return;
        }

        if (subs[2] === "dec") {
            console.log(`GM: lives update: ${topic} => ${this.lives} -> ${this.lives - data}`);
            this.lives -= data;
            if (this.initialLives > 0 && this.lives <= 0) {
                console.log("GM: lives reached 0 - game over !");
                this.endGameSession();
            }
        } else if (subs[2] === "inc") {
            console.log(`GM: lives update: ${topic} => ${this.lives} -> ${this.lives + data}`);
            this.lives += data;
        } else {
            console.warn(`GM: unsupported lives update operation: ${topic}`);
            return;
        }
    }

    private handleWeaponUpdate(topic: string, subs: string[], data: any) {
        if (subs.length < 3) {
            console.warn(`GM: invalid weapon update message: ${topic}`);
            return;
        }

        if (subs[2] === "pickup") {
            console.log(data);
            this.handleWeaponPickup(data);
        } else if (subs[2] === "drop") {
            this.handleWeaponDrop(data);
        }
    }

    private handleWeaponPickup(data: any) {
        this.playerWeapons.push(data);
        this.pickedWeaponOrItem = data; // to do add logic to pick current weapon
    }

    private handleWeaponDrop(data: any) {
        this.playerWeapons = this.playerWeapons.filter(w => w.name !== data.name);
    }

    private setInitialPlayerState() {
        if (this.player) {
            this.playerStartingPosition = this.player.position.clone();
        }

        //set initial player position
        if (this.control?.setInitialPlayerState) {
            let characters = this.behaviorManager!.getBehaviorsOfType(CharacterBehaviorUpdater);
            if (characters.length > 0) {
                if (characters.length > 1) {
                    console.warn("Game has more than one character behavior:");
                    characters.forEach(c => console.warn(c.target.name));
                }
                let character = characters[0];
                let lookAt = new THREE.Vector3();
                lookAt.applyEuler(character.target.rotation.clone());
                let pos = character.target.position.clone();
                this.control.setInitialPlayerState(pos, lookAt);
            }
        }
        if (this.control?.setLives) {
            this.control.setLives(this.initialLives);
        }
    }

    private endGameSession() {
        if (!this.isEnabled) return;
        if (!this.isGameOver()) {
            this.time_remaining = "00:00:00";
            this.playerWeapons = [];
            this.pickedWeaponOrItem = undefined;
            this.state = GAME_STATE.FINISHED;
            (global as any).app.call("gameEnded", this, this);
            window.parent.postMessage(IFRAME_MESSAGES.GAME_ENDED, "*");

            if (this.player && this.playerStartingPosition) {
                this.physics?.setPlayerPosition(this.player?.uuid, this.playerStartingPosition);
                this.player.position.copy(this.playerStartingPosition);
                if (this.player.userData && this.player.userData.physics && this.player.userData.physics.body) {
                    this.player.userData.physics.body = null;
                }
            }

            (global as any).app.call("pauseGame", this, this);
        }
        (global as any).app.call("removeGunAimer", this, this);
    }

    private gameCountDown() {
        if (this.timerRunning) {
            return;
        }
        if (!global?.app?.editor) {
            return console.error("Cannot run the timer. Editor is null.");
        }
        const editor = global.app.editor;
        this.gameTimer = editor.scene?.userData?.game?.timer || 0;
        this.timerRemainingTime = this.gameTimer || 0;
        let lives: number = editor?.scene?.userData?.game?.lives || 0;

        if (
            typeof this.gameTimer !== "undefined" &&
            this.gameTimer > 0 &&
            typeof this.lives !== "undefined" &&
            this.lives > 0
        ) {
            //TODO needs lives to end game  - decouple in next version
            const startTime = Date.now();
            this.timerRunning = true;

            const timerInterval = setInterval(() => {
                if (typeof this.gameTimer !== "undefined") {
                    if (this.state === GAME_STATE.STARTED) {
                        this.timerRemainingTime = this.timerRemainingTime - 1;
                        if (this.timerRemainingTime >= 0) {
                            const hours = Math.floor(this.timerRemainingTime / 3600);
                            const minutes = Math.floor((this.timerRemainingTime % 3600) / 60);
                            const seconds = this.timerRemainingTime % 60;
                            const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
                            this.time_remaining = formattedTime;
                            (global as any).app.call("gameTimerUpdate", this, this);
                            // console.log("Timer update", formattedTime);
                            this.scene!.userData.gameTimeRemaining = this.time_remaining;
                        } else {
                            clearInterval(timerInterval);
                            this.timerRunning = false;
                            EventBus.instance.send("game.lives.dec", Number(lives));
                        }
                    } else if (this.state !== GAME_STATE.PAUSED) {
                        clearInterval(timerInterval);
                        this.timerRunning = false;
                    }
                }
            }, 1000);
        }
    }
}

export default GameManager;
