import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import global from "../../../global";
import Application from "../../../Application";
import {
    Background,
    LoadingBar,
    LoadingBarContainer,
    LoadingSectionWrapper,
    LoadingTrack,
    LoadMaskWrapper,
} from "./Ai16zLoadingMask.style";

type Props = {
    style?: React.CSSProperties;
    show: boolean;
    className?: string;
    progressColor?: string;
    isAutoLoading?: boolean;
};

const Ai16zLoadingMask = ({style, show, className, isAutoLoading = true}: Props) => {
    const app = global.app as Application;
    const [showMask, setShowMask] = useState(show);
    const [counter, setCounter] = useState(0);
    const ref = useRef(0);

    const queryParams = new URLSearchParams(window.location.search);
    const logoFromParam = queryParams.get("customLogo");
    const thumbnailParam = queryParams.get("thumbnail");

    const updateCounterState = () => {
        if (ref.current < 100) {
            const result = (ref.current += 1);
            if (result > 100) return setCounter(100);
            setCounter(ref.current);
            ref.current = result;
        }
        setTimeout(updateCounterState, 30);
    };

    useEffect(() => {
        if (show) {
            setShowMask(true);
            ref.current = 0;
            setCounter(0);
            if (isAutoLoading) {
                updateCounterState();
            }
        } else {
            setTimeout(() => {
                setShowMask(false);
            }, 1000);
        }
        app.on(`maskProgress.LoadMask`, (state: number) => {
            if (state > 100) {
                state = 100;
            }
            ref.current = state;
            setCounter(state);
        });

        return () => {
            app.on(`maskProgress.LoadMask`, null);
        };
    }, [show]);

    if (!showMask) return null;

    return (
        <LoadMaskWrapper className={classNames(className, !show && "hide")} style={style} $show={show}>
            <Background $bgImage={thumbnailParam}>
                <LoadingSectionWrapper>
                    {logoFromParam && <div className="logoText">{logoFromParam}</div>}
                    <LoadingBarContainer>
                        <LoadingTrack>
                            <LoadingBar width={!show ? 100 : counter} />
                        </LoadingTrack>
                    </LoadingBarContainer>
                </LoadingSectionWrapper>
            </Background>
        </LoadMaskWrapper>
    );
};

export default Ai16zLoadingMask;
