import * as THREE from 'three';
import { Camera, Scene } from "three";

export class LaserEffect {
    private scene: THREE.Scene;
    private throwables: THREE.Mesh[];
    private particleMaterial: THREE.ShaderMaterial;
    private static instance: LaserEffect | null = null;

    constructor(scene: THREE.Scene) {
        this.scene = scene;
        this.throwables = [];
        this.particleMaterial = this.createShaderMaterial();
    }


    public static reset(
        scene: Scene
    ): LaserEffect {
        LaserEffect.instance = new LaserEffect(
            scene
        );
        return LaserEffect.instance;
    }

    private createShaderMaterial(): THREE.ShaderMaterial {
        const particleLifetime = 75; // Lifetime in milliseconds

        return new THREE.ShaderMaterial({
            uniforms: {
                uTime: { value: 0 },
                uLifetime: { value: particleLifetime / 1000 }, // Convert to seconds
                uSize: { value: 40.0 }, // Initial size of the particles
            },
            vertexShader: `
                uniform float uSize;
                void main() {
                    gl_PointSize = uSize;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }
            `,
            fragmentShader: `
                uniform float uTime;
                uniform float uLifetime;
                void main() {
                    float dist = length(gl_PointCoord - vec2(0.5));
                    if (dist > 0.5) discard;

                    float flashFrequency = 5.0;
                    float flashIntensity = 0.5;
                    float flash = 0.5 + 0.5 * sin(uTime * flashFrequency);

                    float alpha = (1.0 - (uTime / uLifetime)) * flashIntensity + flashIntensity;
                    alpha = clamp(alpha, 0.0, 1.0);

                    gl_FragColor = vec4(1.0, 1.0, 1.0, alpha);
                }
            `,
            transparent: true,
        });
    }

    createThrowableLaserEffect(
        particlesPerFrame = 1,
        particlesPerPosition = 10,
        throwable: THREE.Mesh
    ) {
        const particleLifetime = 100;
        const spreadDistance = 0.25;
        const direction = throwable.userData.direction.normalize();
        const particleGeometry = new THREE.BufferGeometry();
        const vertices = new Float32Array([0, 0, 0]);
        particleGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        const particleMaterial = this.particleMaterial.clone();

        let frameCounter = 0;
        const framesPerParticle = 1000; // Generate one particle every 2 frames

        for (let j = 0; j < particlesPerFrame; j++) {
            if (frameCounter % framesPerParticle === 0) {
                for (let k = 0; k < particlesPerPosition; k++) {
                    const particle = new THREE.Points(particleGeometry, particleMaterial);
                    const offsetPosition = direction.clone().multiplyScalar(k * spreadDistance);
                    const particlePosition = throwable.position.clone().add(offsetPosition);
                    particle.position.copy(particlePosition);
                    this.scene.add(particle);

                    const fadeOutStartTime = Date.now();
                    const fadeOut = () => {
                        const elapsedTime = (Date.now() - fadeOutStartTime) / 1000;
                        particle.material.uniforms.uTime.value = elapsedTime;
                        particle.material.uniforms.uSize.value = Math.max(20.0 * (1 - elapsedTime / (particleLifetime / 1000)), 0);

                        if (elapsedTime >= particleLifetime / 1000) {
                            this.scene.remove(particle);
                            particle.geometry.dispose();
                            particle.material.dispose();
                        } else {
                            requestAnimationFrame(fadeOut);
                        }
                    };
                    fadeOut();
                }
            }
            frameCounter++;
        }
    }

    public dispose() {
      
        for (const throwable of this.throwables) {
            if (throwable) {
                this.scene.remove(throwable);
                if (throwable.geometry) throwable.geometry.dispose();
                if (throwable.material) throwable.material.dispose();
            }
        }
        this.throwables.length = 0; 

        if (this.particleMaterial) {
            this.particleMaterial.dispose();
        }

     
        LaserEffect.instance = null;
    }
}
