import {flexCenter} from "../../../../assets/style";
import styled from "styled-components";

export const Container = styled.div<{$isOpen?: boolean}>`
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 372px;
    min-height: 145px;
    background: var(--theme-grey-bg-tertiary);
    bottom: 72px;
    left: 50%;
    transform: translateX(-50%) scale(0);
    pointer-events: none;
    transition: transform 0.1s ease;
    position: fixed;
    ${({$isOpen}) =>
        $isOpen &&
        `
      transform: translateX(-50%) scale(1);
      pointer-events: all;
    `};

    .loaderWrapper {
        margin: 4px auto 0;
        height: 58px;
        ${flexCenter};
    }
`;

export const Header = styled.div`
    height: 32px;
    border-bottom: 1px solid var(--theme-container-divider);
    width: 100%;
    ${flexCenter};
    justify-content: flex-start;
    position: relative;
    padding: 0 8px;
`;

export const Tab = styled.div<{$selected?: boolean}>`
    padding: 8px 12px 6px;
    text-align: center;
    font-size: var(--theme-font-size-xxs);
    font-weight: 500;
    color: white;
    border-bottom: ${({$selected}) =>
        $selected ? "2px solid var(--theme-container-main-blue)" : "2px solid transparent"};
    cursor: pointer;
`;

export const CloseBtn = styled.div`
    position: absolute;
    top: 4px;
    right: 8px;
    ${flexCenter};
    cursor: pointer;
`;

export const Title = styled.div<{$isError?: boolean}>`
    font-size: var(--theme-font-size-xxs);
    font-weight: 500;
    line-height: 16px;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({$isError}) =>
        $isError &&
        `
          color: red;
    `}
`;

export const StyledTextArea = styled.textarea`
    margin-top: 4px;
    background: var(--theme-grey-bg);
    border: none;
    outline: none;
    resize: none;
    border-radius: 8px;
    padding: 3px 5px;
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-regular);
    color: #aeaeae;
    height: 85px;
    width: 100%;
    padding: 8px;
`;

export const BottomBar = styled.div`
    width: 100%;
    ${flexCenter};
    padding: 8px;
    border-top: 1px solid var(--theme-container-divider);
`;

export const SubmitButton = styled.button<{disabled?: boolean}>`
    border: none;
    width: 100%;
    color: #fafafa;
    font-weight: 500;
    height: 32px;
    background: var(--theme-container-main-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    border-top: 1px solid #0ea5e9;
    outline: none;
    ${({disabled}) =>
        disabled &&
        `
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 8px;
`;

export const Separator = styled.div`
    flex-shrink: 0;
    height: 1px;
    margin: 0px;
    background: var(--theme-container-divider);
    width: 100%;
`;

export const LoadingWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    ${flexCenter};
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
`;
