import * as THREE from "three";
import GameManager from "../behaviors/game/GameManager";
import global from "../global";
import {set} from "lodash";

export type AnimationData = {
    mixer: THREE.AnimationMixer;
    object: THREE.Object3D;
    speed: number;
    clip: THREE.AnimationClip;
    action: THREE.AnimationAction;
};

export class AnimationController {
    game?: GameManager | null;
    animations: AnimationData[];
    requestAnimationFrameId: number;
    clock?: THREE.Clock;
    gameStarted: boolean = false;

    constructor() {
        this.animations = [];
        this.requestAnimationFrameId = -1;
        this.clock = new THREE.Clock();
    }

    start = (gameManager: GameManager) => {
        this.game = gameManager;
        global.app?.on("gameStarted.AnimationController", () => {
            this.gameStarted = true;
        });
    };

    playAnimation = (
        object: THREE.Object3D,
        animationName: string,
        speed: number,
        playOnce?: boolean,
        fadeDuration: number = 0.5,
    ) => {
        if (object) {
            const currentAction = this.getCurrentAction(object);
            const mixer = this.getMixer(object);
            const animations =
                object._obj?.animations.length > 0
                    ? (object._obj.animations as THREE.AnimationClip[])
                    : (object.animations as THREE.AnimationClip[]);

            const clip = animations.find(clip => clip.name === animationName);

            if (clip) {
                const action = mixer.clipAction(clip as THREE.AnimationClip);

                if (currentAction) {
                    currentAction.fadeOut(fadeDuration);
                    setTimeout(() => {
                        currentAction.stop();
                    }, fadeDuration * 1000);
                }

                action.reset().setEffectiveTimeScale(1).setEffectiveWeight(1).fadeIn(fadeDuration).play();
                if (playOnce) action.setLoop(THREE.LoopOnce, 1);
                action.timeScale = speed;

                object.userData.animation = {mixer, speed, clip, action, object} as AnimationData;

                // Update animation list
                const newAnim = this.animations.filter(anim => anim.object.uuid !== object.uuid);
                newAnim.push(object.userData.animation as AnimationData);
                this.animations = newAnim;
            }

            return object.userData.animation as AnimationData | undefined;
        }
        return undefined;
    };

    playCustomAnimation = (
        object: THREE.Object3D,
        clip: THREE.AnimationClip,
        speed: number,
        playOnce?: boolean,
        fadeDuration: number = 0.5,
    ) => {
        if (object) {
            const currentAction = this.getCurrentAction(object);
            const mixer = this.getMixer(object);
            const action = mixer.clipAction(clip);

            // Play with crossfade
            if (currentAction) {
                currentAction.fadeOut(fadeDuration);
                setTimeout(() => {
                    currentAction.stop();
                }, fadeDuration * 1000);
            }

            action.reset().setEffectiveTimeScale(1).setEffectiveWeight(1).fadeIn(fadeDuration).play();
            if (playOnce) action.setLoop(THREE.LoopOnce, 1);
            action.timeScale = speed;

            object.userData.animation = {mixer, speed, clip, action, object} as AnimationData;

            // Update animation list
            const newAnim = this.animations.filter(anim => anim.object.uuid !== object.uuid);
            newAnim.push(object.userData.animation as AnimationData);
            this.animations = newAnim;

            return object.userData.animation as AnimationData | undefined;
        }
        return undefined;
    };

    getMixer = (object: THREE.Object3D): THREE.AnimationMixer => {
        return object.userData.animation?.mixer || new THREE.AnimationMixer(object);
    };

    getCurrentAction = (object: THREE.Object3D): THREE.AnimationAction | null => {
        return (object.userData.animation?.action as THREE.AnimationAction) || null;
    };

    stopAnimation = (object: THREE.Object3D) => {
        const animation = object.userData.animation as AnimationData;
        if (animation) {
            animation.mixer.stopAllAction();
            animation.mixer.uncacheRoot(animation.mixer.getRoot());
        }
        delete object.userData.animation;
    };

    update = (clock: THREE.Clock, passedDelta: number) => {
        if (!this.game || !this.game.scene || !this.gameStarted) {
            return;
        }

        const delta = this.clock?.getDelta() || 0;

        if (this.animations.length > 0) {
            this.animations.forEach(animation => {
                if (animation) {
                    animation.mixer.update(delta * animation.speed);
                }
            });
        }
    };

    stop = () => {
        if (this.requestAnimationFrameId !== -1) {
            cancelAnimationFrame(this.requestAnimationFrameId);
            this.requestAnimationFrameId = -1;
        }
    };

    dispose = () => {
        const scene = this.game?.scene;
        scene?.traverse(object => {
            if (object.userData.animation) {
                const {mixer} = object.userData.animation as AnimationData;
                mixer.stopAllAction();
                mixer.uncacheRoot(mixer.getRoot());
                delete object.userData.animation;
            }
        });
        this.requestAnimationFrameId = -1;
        global.app?.on("gameStarted.AnimationController", null);
    };
}
