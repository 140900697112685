import {useState} from "react";
import {CloseBtn, Container, Header, Tab} from "./AiAssistant.style";
import closeIcon from "./icons/close.svg";
import {AiCopilot} from "./Tabs/AiCopilot/AiCopilot";
import {AiImageGenerator} from "./Tabs/AiImageGenerator/AiImageGenerator";

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

enum Tabs {
    AI_COPILOT = "AI Copilot",
    GENERATIVE_AI = "Generative AI",
}

export const AiAssistant = ({isOpen, onClose}: Props) => {
    const [activeTab, setActiveTab] = useState(Tabs.AI_COPILOT);

    return (
        <Container $isOpen={isOpen}>
            <Header>
                {Object.values(Tabs).map(tab => (
                    <Tab key={tab} onClick={() => setActiveTab(tab)} $selected={tab === activeTab}>
                        {tab}
                    </Tab>
                ))}
                <CloseBtn>
                    <img src={closeIcon} alt="close" onClick={onClose} />
                </CloseBtn>
            </Header>
            <AiCopilot isOpen={activeTab === Tabs.AI_COPILOT} />
            <AiImageGenerator isOpen={activeTab === Tabs.GENERATIVE_AI} />
        </Container>
    );
};
