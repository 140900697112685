//This class integrates the game with 2D screen elements it keeps score
//based on coins, stars and keys quickest solution I could come up with
//with the latest time frame changes for game concepts
//It also implements a small game flow that allows players to win even
//if they do not get all the coins but find the key

import {SoundManager} from "./SoundManager";
import {Root, createRoot} from "react-dom/client";
import {HUDView} from "../../editor/assets/v2/HUD/HUDView/HUDView";
import {ISoundSettings} from "../../types/editor";

class HUDManager {
    scene: any = null;
    containerId: string = "";
    soundManager: SoundManager;
    private hudRoot: Root | null = null;

    constructor(scene: any) {
        this.scene = scene;
        this.containerId = "hud-view-continer";
        this.hudRoot = null;

        //expose clear sounds and clear score divs to playSrc menu
        this.soundManager = new SoundManager(scene);
    }

    create(emptyHUD = false, renderStopButton = false) {
        const hudContainer = document.createElement("div");
        hudContainer.setAttribute("id", this.containerId);
        hudContainer.style.position = "absolute";
        hudContainer.style.width = "100%";
        hudContainer.style.height = "100%";
        hudContainer.style.top = `0px`;
        hudContainer.style.zIndex = "10000";
        hudContainer.style.pointerEvents = "none";

        // Tworzenie root i renderowanie komponentu
        this.hudRoot = createRoot(hudContainer);
        this.hudRoot.render(<HUDView emptyHUD={emptyHUD} renderStopButton={renderStopButton} />);

        document.body.append(hudContainer);

        window.addEventListener("popstate", () => {
            this.clear();
        });
    }

    clear() {
        if (this.hudRoot) {
            this.hudRoot.unmount();
            this.hudRoot = null;
        }

        const hudContainer = document.getElementById(this.containerId);
        if (hudContainer) {
            hudContainer.remove();
        }

        this.soundManager.clearLoadedSounds();
    }
    loadSounds(sounds: ISoundSettings[]) {
        if (sounds) {
            this.soundManager.loadSounds(sounds);
        }
    }

    stopSound(soundId: string) {
        if (soundId) {
            this.soundManager.stopSound(soundId);
        }
    }

    playSound(id: string) {
        this.soundManager.playSound(id);
    }

    clearSounds() {
        this.soundManager.clearLoadedSounds();
    }
}

export default HUDManager;
