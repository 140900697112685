import * as THREE from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { WEAPON_EFFECTS } from '../../types/editor';
import smokeSprite from "./images/smoke512_1.png";


export class GunMuzzleFlashEffect {
    private scene: THREE.Scene;
    private camera: THREE.OrthographicCamera;
    private renderer: THREE.WebGLRenderer;
    private isFading: boolean;
    private planeMesh: THREE.Mesh<THREE.PlaneGeometry, THREE.ShaderMaterial>; // Single plane instance
    private textureLoader: THREE.TextureLoader;
    private smokeTexture: THREE.Texture;
  
    constructor(currentWeapon: THREE.Object3D) {
        this.renderer = new THREE.WebGLRenderer({ alpha: true });
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        document.body.appendChild(this.renderer.domElement);

        this.scene = new THREE.Scene();

        this.renderer.domElement.style.position = 'absolute';
        this.renderer.domElement.style.top = '0';
        this.renderer.domElement.style.left = '0';
        this.renderer.domElement.style.zIndex = '1000';
        this.renderer.domElement.style.pointerEvents = 'none';

        this.textureLoader = new THREE.TextureLoader();
        this.smokeTexture = this.textureLoader.load(smokeSprite, (texture) => { });
        //this.showMuzzleEffectsADS = false;

        const width = window.innerWidth;
        const height = window.innerHeight;
        this.camera = new THREE.OrthographicCamera(
            -width / 2,
            width / 2,
            height / 2,
            -height / 2,
            -1000,
            1000
        );

        this.camera.position.z = 1;

        this.isFading = false;

        const vertexShader = `
        varying vec2 vUv;

        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `;

        const fragmentShader = `
        varying vec2 vUv;
        uniform float uOpacity;

        void main() {
            // Calculate distance from the center of the plane
            float dist = distance(vUv, vec2(0.5, 0.5));
            // Fade from white to transparent as distance increases
            float opacity = uOpacity * (1.0 - smoothstep(0.0, 0.5, dist));
            vec3 color = vec3(1.0, 1.0, 1.0); // White color
            gl_FragColor = vec4(color, opacity);
        }
    `;

        const planeMaterial = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            transparent: true,
            depthTest: false,
            side: THREE.DoubleSide,
            uniforms: {
                uOpacity: { value: 1.0 },
            },
        });

        const planeSize = 2000;
        const planeGeometry = new THREE.PlaneGeometry(planeSize, planeSize);
        this.planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
        this.planeMesh.visible = false;
        this.scene.add(this.planeMesh);

        this.animate();
    }

    /**
     * Displays a primary gun muzzle flash plane facing the camera.
     * The plane fades out and reduces in size over time.
     *
     * @param scene The current scene where the muzzle flash will be displayed.
     * @param camera The camera used to render the scene.
     * @param currentWeapon The current weapon object containing the muzzle flash plane.
     */

    public addGunPrimaryMuzzleFlash(scene: THREE.Scene,
        camera: THREE.Camera,
        currentWeapon: THREE.Object3D,
        flashBrightness: number,
        particleSize: number,
        particelLife: number,
        particleDensity: number,
        particleOpacity: number,
        isADS: boolean
    ) {

        const fadeDuration = 100;
        let gunMuzzleFlashPlane = currentWeapon.getObjectByName(WEAPON_EFFECTS.GUN_MUZZLE_FLASH_PLANE_NAME) as THREE.Mesh;


        if (gunMuzzleFlashPlane) {
            const rotationSpeed = 10;
            gunMuzzleFlashPlane.rotation.z += rotationSpeed;
            gunMuzzleFlashPlane.rotation.z %= (Math.PI * 2);
            gunMuzzleFlashPlane.visible = false;
            gunMuzzleFlashPlane.castShadow = false;

            const material = gunMuzzleFlashPlane.material;
            if (material instanceof THREE.Material) {
                material.transparent = true;
                material.opacity = particleOpacity;
            }

            const pointLights: THREE.PointLight[] = [];
            currentWeapon.traverse((child) => {
                if (child instanceof THREE.PointLight) {
                    pointLights.push(child);
                }
            });

            pointLights.forEach((pointLight) => {
                pointLight.intensity = flashBrightness;
            });

            const startTime = Date.now();

            const fadeInterval = setInterval(() => {
                const elapsed = Date.now() - startTime;
                const opacity = Math.max(0, 1 - elapsed / fadeDuration);

                if (material instanceof THREE.Material) {
                    material.opacity = particleOpacity;
                }


                if (!isADS) {
                  
                    this.createSmokeParticle(scene,
                        camera,
                        currentWeapon,
                        particleSize,
                        particelLife,
                        particleDensity,
                        particleOpacity
                    );

                    this.shootParticles(scene,
                        camera,
                        currentWeapon,
                        particleSize,
                        particelLife,
                        particleDensity,
                        particleOpacity
                    );

                }

                const scaleFactor = Math.max(1, 1 - elapsed / fadeDuration);
                gunMuzzleFlashPlane.scale.set(scaleFactor, scaleFactor, scaleFactor);

                if (opacity <= 0) {
                    clearInterval(fadeInterval);
                    gunMuzzleFlashPlane.visible = false;
                    if (material instanceof THREE.Material) {
                        material.opacity = 0.5;
                    }
                    gunMuzzleFlashPlane.scale.set(1, 1, 1);

                    pointLights.forEach((pointLight) => {
                        pointLight.intensity = 0;
                    });
                }
            }, 16);
        }

        let muzzleFlashEffect = this.getMeshObject(currentWeapon, "muzzle_flash_effect_frames");
        if (muzzleFlashEffect) {

            if (Math.random() > 0.3) {
                muzzleFlashEffect.visible = true;
                const rotationSpeed = 100;

                // muzzleFlashEffect.rotation.y = Math.random() * Math.PI * 2;
                muzzleFlashEffect.castShadow = false;
                muzzleFlashEffect.scale.set(0.007, 0.007, 0.007);

                const material = muzzleFlashEffect.material;
                if (material instanceof THREE.Material) {
                    material.transparent = true;
                    material.opacity = particleOpacity;
                }

                const fadeDuration = 50;
                const effectStartTime = Date.now();

                const effectFadeInterval = setInterval(() => {
                    const elapsed = Date.now() - effectStartTime;
                    const opacity = Math.max(0, 0.30 - elapsed / fadeDuration);

                    if (material instanceof THREE.Material) {
                        material.opacity = opacity;
                    }

                    if (opacity <= 0) {
                        clearInterval(effectFadeInterval);
                        muzzleFlashEffect.visible = false;
                        if (material instanceof THREE.Material) {
                            material.opacity = 1;
                        }
                    }
                }, 16);



            } else {
                muzzleFlashEffect.visible = false;
            }
        }


        if (!isADS) {
            this.addGunSecondaryMuzzleFlash(scene, currentWeapon, flashBrightness);
            //this.addVinceBulletMotionLines(scene, camera, currentWeapon);
        }
    }


    shootParticles(scene: THREE.Scene,
        camera: THREE.Camera,
        currentWeapon: THREE.Object3D,
        particleSize: number,
        particleLife: number,
        particleDensity: number,
        particleOpacity: number
    ) {

        const particleCount = 5;

        for (let i = 0; i < particleCount; i++) {
            const particle = new THREE.Mesh(
                new THREE.CircleGeometry(0.5, 32),
                new THREE.MeshBasicMaterial({
                    transparent: true,
                    depthTest: false,
                    opacity: 0.10,
                })
            );


            const forward = new THREE.Vector3(0, 0, -1).applyQuaternion(currentWeapon.quaternion);

            const randomOffset = new THREE.Vector3(
                (Math.random() - 0.5) * 1.0,
                (Math.random() * 0.5) + 0.5,
                (Math.random() - 0.5) * 1.0
            );

            particle.position.copy(currentWeapon.position).add(forward.clone().multiplyScalar(2)).add(randomOffset);

            const randomSize = Math.random() * 0.5 + 0.2;
            particle.scale.set(randomSize, randomSize, randomSize);
            scene.add(particle);
            const particleStartTime = Date.now();
            const fadeDuration = 5000;

            const particleFadeInterval = setInterval(() => {
                const elapsed = Date.now() - particleStartTime;
                const opacity = Math.max(0, 0.05 - elapsed / fadeDuration);
                particle.material.opacity = opacity;
                particle.position.add(forward.clone().multiplyScalar(5));
                particle.lookAt(camera.position);

                if (opacity <= 0) {
                    clearInterval(particleFadeInterval);
                    scene.remove(particle);
                }
            }, 16);
        }
    }


    createSmokeParticle(scene: THREE.Scene,
        camera: THREE.Camera,
        currentWeapon: THREE.Object3D,
        particleSize: number,
        particleLife: number,
        particleDensity: number,
        particleOpacity: number
    ) {

        const particleCount = particleDensity;

        for (let i = 0; i < particleCount; i++) {
            const particle = new THREE.Mesh(
                new THREE.CircleGeometry(particleSize, 32),
                new THREE.MeshPhongMaterial({
                    map: this.smokeTexture,
                    transparent: true,
                    depthTest: false,
                    opacity: particleOpacity,
                    emissive: new THREE.Color(0.8, 0.8, 0.8),
                    emissiveIntensity: 0.5,
                })
            );

            const forward = new THREE.Vector3(0, 0, -1).applyQuaternion(currentWeapon.quaternion);
            const randomOffset = new THREE.Vector3(
                (Math.random() - 0.5) * 0.5,
                (Math.random() * 0.5) + 0.5,
                (Math.random() - 0.5) * 0.5
            );

            particle.position.copy(currentWeapon.position).add(forward.multiplyScalar(2)).add(randomOffset);

            const randomSize = Math.random() * 0.5 + 0.2;
            particle.scale.set(randomSize, randomSize, randomSize);
            const randomRotationSpeed = (Math.random() * 0.05) - 0.025;
            scene.add(particle);
            const particleStartTime = Date.now();
            const fadeDuration = particleLife;

            const particleFadeInterval = setInterval(() => {
                const elapsed = Date.now() - particleStartTime;
                const opacity = Math.max(0, 0.10 - elapsed / fadeDuration);

                particle.material.opacity = opacity;

                particle.position.y += 0.01;
                particle.rotation.z += randomRotationSpeed;
                particle.lookAt(camera.position);

                if (opacity <= 0) {
                    clearInterval(particleFadeInterval);
                    scene.remove(particle);
                }
            }, 16);
        }
    }

    public checkForFlashEffectObject(currentWeapon: THREE.Object3D) {
        let gunMuzzleFlashPlane = this.getMeshObject(currentWeapon, WEAPON_EFFECTS.GUN_MUZZLE_FLASH_PLANE_NAME);
        if (gunMuzzleFlashPlane) {
            gunMuzzleFlashPlane.castShadow = false;
            gunMuzzleFlashPlane.visible = false;
        }
        //TODO add to props
        let gunMuzzleFlashEffect = this.getMeshObject(currentWeapon, "muzzle_flash_effect_frames");
        if (gunMuzzleFlashEffect) {
            gunMuzzleFlashEffect.castShadow = false;
            gunMuzzleFlashEffect.visible = false;
        }
    }

    private getMeshObject(weapon: THREE.Object3D, objectMesh: string) {
        return weapon.getObjectByName(objectMesh) as THREE.Object3D
    }

    public addGunSecondaryMuzzleFlash(scene: THREE.Scene, currentWeapon: THREE.Object3D, flashBrightness: number) {

        if (this.isFading) return;

        this.planeMesh.visible = true;

        const fadeDuration = 50;
        const startTime = Date.now();
        this.isFading = true;

        const fadeInterval = setInterval(() => {
            const elapsed = Date.now() - startTime;
            const opacity = Math.max(0, flashBrightness - elapsed / fadeDuration);
            (this.planeMesh.material as THREE.ShaderMaterial).uniforms.uOpacity.value = opacity;

            if (opacity <= 0) {
                clearInterval(fadeInterval);
                this.planeMesh.visible = false;
                this.isFading = false;
            }
        }, 16);
    }

    addVinceBulletMotionLines(scene: THREE.Scene, camera: THREE.Camera, currentWeapon: THREE.Object3D) {
        const length = 15;
        const fadeDuration = 500;

        const startPosition = currentWeapon.position.clone();

        const cameraDirection = new THREE.Vector3();
        camera.getWorldDirection(cameraDirection);
        const endPosition = startPosition.clone().add(cameraDirection.multiplyScalar(length));

        const positions = [
            startPosition.x, startPosition.y, startPosition.z,
            endPosition.x, endPosition.y, endPosition.z,
        ];

        const lineGeometry = new LineGeometry();
        lineGeometry.setPositions(positions);

        const lineMaterial = new LineMaterial({
            color: 0xffffff,
            linewidth: 20,
            transparent: true,
            opacity: .35
        });

        lineMaterial.resolution.set(window.innerWidth, window.innerHeight);

        const lineMesh = new Line2(lineGeometry, lineMaterial);
        scene.add(lineMesh);

        const startTime = Date.now();

        const fadeInterval = setInterval(() => {
            const elapsed = Date.now() - startTime;
            const opacity = Math.max(0, .15 - elapsed / fadeDuration);

            lineMaterial.opacity = opacity;

            if (opacity <= 0) {
                clearInterval(fadeInterval);
                scene.remove(lineMesh);
            }
        }, 16);
    }

    public dispose() {

        this.scene.remove(this.planeMesh);
        this.planeMesh.geometry.dispose();
        (this.planeMesh.material as THREE.ShaderMaterial).dispose();

        document.body.removeChild(this.renderer.domElement);
        this.renderer.dispose();
        this.scene = null as any;
        this.camera = null as any;
        this.renderer = null as any;
    }

    private animate() {
        requestAnimationFrame(() => this.animate());
        if (this.renderer) {
            this.renderer.render(this.scene, this.camera);
        }
    }

}
