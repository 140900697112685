import I18n from "i18next";
import Ajax from "../../utils/Ajax";
import {backendUrlFromPath} from "../../utils/UrlUtils";
import global from "../../global";
import Application from "../../Application";

export const generateLiveKitToken = async (room: string, username: string) => {
    try {
        const response = await Ajax.post({
            url: backendUrlFromPath(`/api/livekit/join_token`),
            needAuthorization: false,
            data: {
                room,
                identity: username,
            },
            msgBodyType: "urlEncoded",
        });
        const obj = response?.data;
        if (obj.Code !== 200) {
            (global?.app as Application)?.toast(I18n.t(obj.Msg), "warn");
            throw Error;
        } else {
            return obj.Data.token as string;
        }
    } catch (error) {
        console.error("LiveKit join token: request failed:", error);
    }
};

export const createLiveKitRoom = async (room: string, username: string) => {
    try {
        const response = await Ajax.post({
            url: backendUrlFromPath(`/api/livekit/create_room`),
            data: {
                room,
                identity: username,
            },
            msgBodyType: "urlEncoded",
        });
        const obj = response?.data;
        if (obj.Code !== 200) {
            throw Error;
        } else {
            console.log("LiveKit Room created successfully");
            return obj.Data;
        }
    } catch (error) {
        console.error("LiveKit Room: Request failed:", error);
    }
};
