import {useEffect, useRef, useState} from "react";
import {DEFAULT_FILE_DATA, FileData} from "../../../../types/file";
import {AssetsList} from "../../../../common/AssetsList";
import {UploadButton} from "../../../../../../../editor/assets/v2/common/UploadButton";
import AddObjectCommand from "../../../../../../../command/AddObjectCommand";
import TerrainUtil from "../../../../../../../utils/TerrainUtil";

import grassyImage from "../../../../icons/assetsTab/terrain/grassy.png";
import rockyImage from "../../../../icons/assetsTab/terrain/rocky.png";
import grassyAndRockyImage from "../../../../icons/assetsTab/terrain/grassy&rocky.png";
import searchIconSmall from "../../../../icons/search-icon-small.svg";
import terrainDefaultImage from "../../../../icons/assetsTab/terrain/default.png";
import {SearchInput} from "../../../../../../../editor/assets/v2/common/SearchInput";
import {TitleContainer, TopContainer} from "../AssetsTab.style";
import {generateUniqueName} from "../../../../../../../v2/pages/services";
import global from "../../../../../../../global";

const mockData = [
    {
        id: "0",
        name: "Grassy Hills",
        map: `${window.location.origin}/assets/maps/terrain.png`,
        maxHeight: 2,
        minHeight: -3,
        texture: `${window.location.origin}/assets/textures/terrain/grassv2/BaseColor.png`,
        textureRepeatU: 10,
        textureRepeatV: 10,
        thumbnail: terrainDefaultImage,
    },
    {
        id: "1",
        name: "Rocky Hills",
        map: `${window.location.origin}/assets/maps/terrain4.png`,
        maxHeight: 3,
        minHeight: -4,
        texture: `${window.location.origin}/assets/textures/terrain/rockandsand/BaseColor.png`,
        textureRepeatU: 10,
        textureRepeatV: 10,
        thumbnail: terrainDefaultImage,
    },
    {
        id: "2",
        name: "Grass&Rocks Hills",
        map: `${window.location.origin}/assets/maps/mountaintest.png`,
        maxHeight: 4,
        minHeight: -5,
        texture: `${window.location.origin}/assets/textures/terrain/grassrockt/BaseColor.png`,
        textureRepeatU: 10,
        textureRepeatV: 10,
        thumbnail: terrainDefaultImage,
    },
];

export const TerrainTab = () => {
    const [search, setSearch] = useState("");
    const [data, setData] = useState(mockData);
    const [filteredData, setFilteredData] = useState<typeof data>();
    const [assetsListData, setAssetsListData] = useState<FileData[]>([]);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const app = (global as any).app as any;

    const handleClick = (id: string, callback?: (obj: any) => void) => {
        const terrain = data?.find(item => item.id === id);
        if (terrain) {
            let loader = new TerrainUtil(
                undefined,
                terrain.map,
                terrain.texture,
                terrain.maxHeight,
                terrain.minHeight,
                terrain.textureRepeatU,
                terrain.textureRepeatV,
            );
            const existingNames = new Set<string>();
            app.editor.scene.children.forEach((child: any) => {
                if (child.name) {
                    existingNames.add(child.name);
                }
            });
            loader.buildMesh().then((mesh: any) => {
                mesh.name = generateUniqueName(terrain.name, existingNames);
                mesh.userData.usePhysics = true;
                Object.assign(mesh.userData, terrain, {
                    Terrain: true,
                });
                app.editor.moveObjectToCameraClosestPoint(mesh);
                addToCenter(mesh);
                callback && callback(mesh);
            });
        }
    };

    const addToCenter = (obj: any) => {
        app.editor.execute(new (AddObjectCommand as any)(obj));
    };

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>, id: string) => {
        e.dataTransfer.setData("asset-id", id);
        e.dataTransfer.setData("asset-type", "terrain");
    };

    useEffect(() => {
        if (!search) {
            setFilteredData(data);
            return;
        } else {
            setFilteredData(
                data?.filter(n => {
                    return n.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
                }),
            );
        }
    }, [search, data]);

    useEffect(() => {
        if (filteredData) {
            const obj = filteredData.map(item => {
                return {
                    ...DEFAULT_FILE_DATA,
                    ID: item.id,
                    Name: item.name,
                    Thumbnail: item.thumbnail,
                };
            });
            setAssetsListData(obj);
        }
    }, [filteredData]);

    useEffect(() => {
        app.on(`dragEnd.TerrainTab`, (type: string, id: string, position: any) => {
            if (type === "terrain") {
                handleClick(id, (obj: any) => {
                    obj.position.copy(position);
                });
            }
        });
        return () => {
            app.on(`dragEnd.TerrainTab`, null);
        };
    }, []);

    return (
        <>
            <TopContainer>
                <SearchInput width="224px" placeholder="Search Terrain" onChange={setSearch} value={search} />
            </TopContainer>
            <TitleContainer>
                Terrain
                <UploadButton onClick={() => inputRef.current?.click()} />
            </TitleContainer>

            {!uploadedFile && (
                <input
                    type="file"
                    ref={inputRef}
                    style={{display: "none"}}
                    onChange={e => setUploadedFile(e.target.files ? e.target.files[0] : null)}
                />
            )}
            {filteredData && (
                <AssetsList data={assetsListData} onClick={handleClick} draggable onDragStart={handleDragStart} />
            )}
        </>
    );
};
