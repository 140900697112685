import {EmptyStateText, Middle} from "../Menu.style";

export const Tasks = () => {
    return (
        <>
            <Middle>
                <EmptyStateText>Come back later for missions...</EmptyStateText>
            </Middle>
        </>
    );
};
