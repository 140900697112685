import React from "react";
import styled from "styled-components";
import eButton from "../../editor/assets/v2/icons/e-button.png";

const Container = styled.div`
    font-size: 20px;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

export const EButtonView = ({name}: {name: string}) => {
    return (
        <Container>
            Press <img src={eButton} alt="arrow" width={32} height={32} /> to talk to {name}
        </Container>
    );
};
