import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import EventBus from "../../behaviors/event/EventBus";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import {Object3D} from "three";
import {
    OBJECT_TYPES,
    CharacterBehaviorInterface,
    COLLISION_TYPE,
    CHARACTER_VERTICAL_STATUS_TYPES,
    CAMERA_TYPES,
} from "../../types/editor";
import global from "../../global";
import {MobileTouchCharacterController} from "../../controls/MobileTouchCharacterController";
import LoadSceneUIImages from "../../utils/LoadSceneUIImages";
import CameraUtils from "../../utils/CameraUtils";
import { CollisionFlag, IPhysics } from "../../physics/common/types";
import Player from "../../player/Player";


class CharacterBehaviorUpdater implements BehaviorUpdater {
    app = global.app as Player;
    target: Object3D;
    removed = false;
    removeTarget: boolean;
    game?: GameManager;
    playerCollisionListenerId: string | undefined;
    hasLanded: boolean = false;
    jumping: boolean = false;
    previousIntersectCount: number = 0;
    private previousPlayerY: number | null = null;
    isDebug: boolean;
    public controlType: CAMERA_TYPES = CAMERA_TYPES.THIRD_PERSON;
    private slopeTolerance = 0.25;
    private minFramesInState = 5;
    private currentStateFrames = 0;
    private currentState: CHARACTER_VERTICAL_STATUS_TYPES = CHARACTER_VERTICAL_STATUS_TYPES.STATIONARY;
    private mobileTouchCharacterController: MobileTouchCharacterController;
    private walkSpeed: number = 0;
    private runSpeed: number = 0;
    behavior: CharacterBehaviorInterface;
    physics: IPhysics | null = null;

    constructor(target: Object3D, control: string, behavior: CharacterBehaviorInterface) {
        this.target = target;
        this.removeTarget = control === CAMERA_TYPES.FIRST_PERSON;
        this.removeTarget = false;
        this.isDebug = (global.app as any)?.storage?.debug;
        EventBus.instance.subscribe("CharacterBehavior:setTarget", this.handleSetTargetMessage.bind(this));
        this.mobileTouchCharacterController = new MobileTouchCharacterController(this.target);
        this.behavior = behavior; 
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.addCollisionListener();
        CameraUtils.disableFromCameraCollision(this.target);
        new LoadSceneUIImages(this.game!.scene!);
      
    }

    private addCollisionListener() {
        this.playerCollisionListenerId = this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollisionWithPlayer.bind(this),
                useBoundingBoxes: false,
            },
            false,
        );
    }

    private handleSetTargetMessage(topic: string, data: any) {
        if (data.uuid === this.target.uuid) {
            this.setTarget(data.target);
        }
    }

    private setTarget(target: Object3D) {
        console.log("CharacterBehaviorUpdater: setTarget", target);
        this.target = target;
        this.removeCollisionListener();
        this.addCollisionListener();
        this.mobileTouchCharacterController.setCharacter(target);
    }

    private removeCollisionListener() {
        if (this.playerCollisionListenerId) {
            this.game!.behaviorManager?.collisionDetector.deleteListener(this.target, this.playerCollisionListenerId);
        }
    }

    onCollisionWithPlayer() {}

    update(clock: THREE.Clock, delta: number) {
        if (!this.game || !this.game.scene || !this.target) return;

        this.game!.player!.userData.isGameMenuOpen = this.app.isGameMenuOpen;

        this.mobileTouchCharacterController.handleTouchControlBehavior(this.target);
        this.getPlayerControlType();
        this.setPlayerVerticalState();
        this.getPlayerHeight();
        this.setPlayerVisibility();
        this.setPlayerVelocity();

        if (this.target.userData.behaviors.find((object: any) => object.type === OBJECT_TYPES.SPRITE)) {
            this.target.visible = false;
        }

        if (this.game!.player!.userData.isGameMenuOpen) {
            this.game!.player!.userData.movingToTouchPoint = false;
            this.target.userData.stopAtLocation = false;
        }        

    }

    private getPlayerControlType() {
        const characterBehavior = this.target.userData.behaviors.find(
            (behavior: any) => behavior.type === OBJECT_TYPES.CHARACTER,
        ) as CharacterBehaviorInterface;

        if (characterBehavior) {
            this.controlType = characterBehavior.control;
            this.target.userData.controlType = this.controlType;
        }

        this.slopeTolerance = this.target.userData.slopeTolerance;
    }

    private setPlayerVelocity() {
        const characterBehavior = this.target.userData.behaviors.find(
            (behavior: any) => behavior.type === OBJECT_TYPES.CHARACTER,
        ) as CharacterBehaviorInterface;

        if (characterBehavior) {
            const controlType = characterBehavior.control;
            switch (controlType) {
                case CAMERA_TYPES.FIRST_PERSON:
                case CAMERA_TYPES.THIRD_PERSON:
                case CAMERA_TYPES.FORTNITE:
                    this.walkSpeed = characterBehavior.characterOptions?.walkSpeed || 1;
                    this.runSpeed = characterBehavior.characterOptions?.runSpeed || 2;
                    break;
                case CAMERA_TYPES.SIDE_SCROLLER:
                    this.walkSpeed = characterBehavior.characterOptions?.walkSpeed || 1;
                    this.runSpeed = characterBehavior.characterOptions?.runSpeed || 2;
                    break;
                default:
                    this.walkSpeed = 1;
                    this.runSpeed = 2;
            }
        }

        this.target.userData.walkSpeed = this.walkSpeed;
        this.target.userData.runSpeed = this.runSpeed;

        this.slopeTolerance = this.target.userData.slopeTolerance;


       //this.physics!.setLinearVelocity(this.target.uuid, 1);
    }

    private setPlayerVerticalState() {
        const currentPlayerY = this.target.position.y;

        if (this.previousPlayerY !== null) {
            const yDifference = parseFloat((currentPlayerY - this.previousPlayerY).toFixed(2));
            const horizontalDifference = this.target.position
                .clone()
                .setY(0)
                .distanceTo(new THREE.Vector3(this.previousPlayerY, 0, 0));

            let newState: CHARACTER_VERTICAL_STATUS_TYPES | undefined;

            if (Math.abs(yDifference) <= this.slopeTolerance) {
                newState = CHARACTER_VERTICAL_STATUS_TYPES.STATIONARY;
            } else if (yDifference > this.slopeTolerance) {
                newState = CHARACTER_VERTICAL_STATUS_TYPES.JUMPING_UP;
            } else if (yDifference < -this.slopeTolerance) {
                newState = CHARACTER_VERTICAL_STATUS_TYPES.FALLING_DOWN;
            }

            if (
                this.currentState === CHARACTER_VERTICAL_STATUS_TYPES.FALLING_DOWN &&
                newState === CHARACTER_VERTICAL_STATUS_TYPES.STATIONARY
            ) {
                this.currentState = CHARACTER_VERTICAL_STATUS_TYPES.LANDED;
                this.target.userData.verticalStatus = this.currentState;
                setTimeout(() => {
                    this.currentState = CHARACTER_VERTICAL_STATUS_TYPES.STATIONARY;
                    this.target.userData.verticalStatus = this.currentState;
                }, 500);
            } else if (newState !== undefined && newState !== this.currentState) {
                this.currentState = newState;
                this.currentStateFrames = 1;
            } else {
                this.currentStateFrames++;
            }

            if (this.currentStateFrames >= this.minFramesInState) {
                this.target.userData.verticalStatus = this.currentState;
            }
        }

        this.previousPlayerY = currentPlayerY;
    }

    private getPlayerHeight() {
        const boundingBox = new THREE.Box3().setFromObject(this.target);
        const playerHeight = boundingBox.max.y - boundingBox.min.y;
        this.target.userData.playerHeight = playerHeight / 2 - playerHeight * 0.1;
    }

    private setPlayerVisibility() {
        if (this.target.userData.controlType === CAMERA_TYPES.FIRST_PERSON) {
            this.target.visible = false;
        } else {
            this.target.visible = true;
        }
    }

    reset() {}
}

export default CharacterBehaviorUpdater;
