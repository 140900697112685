import {authToken} from "../utils/Ajax";
import {backendUrlFromPath} from "../utils/UrlUtils";
import {
    Asset,
    GenerateFillRequest,
    GenerateImageRequest,
    PixelateImageRequest,
    RemoveImageBackgroundRequest,
    UpscaleImageRequest,
} from "../types/imageGenerator";

const getModels = async (paginationToken: string, pageSize: string) => {
    const getQuery = () => {
        let query = "";
        if (paginationToken) {
            query += `?paginationToken=${paginationToken}`;
        }
        if (pageSize) {
            query += query ? `&pageSize=${pageSize}` : `?pageSize=${pageSize}`;
        }
        return query;
    };

    const res = await fetch(backendUrlFromPath(`/api/ImageGeneration/Models${getQuery()}`) || "");
    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("Failed to load models.");
    }
};

const getAssets = async (paginationToken: string, pageSize: string, types: string[]) => {
    const getQuery = () => {
        let query = "";
        if (paginationToken) {
            query += `?paginationToken=${paginationToken}`;
        }
        if (pageSize) {
            query += query ? `&pageSize=${pageSize}` : `?pageSize=${pageSize}`;
        }
        if (types && types.length) {
            query += query ? `&types=${types.join(",")}` : `?types=${types.join(",")}`;
        }
        return query;
    };

    const res = await fetch(backendUrlFromPath(`/api/ImageGeneration/Asset/List${getQuery()}`) || "");
    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("Failed to load assets.");
    }
};

const getAssetById = async (id: string) => {
    const res = await fetch(backendUrlFromPath(`/api/ImageGeneration/Asset/Get?assetId=${id}`) || "");
    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("Failed to load asset.");
    }
};

const getBulkAssets = async (assetIds: string[]) => {
    const res = await fetch(backendUrlFromPath(`/api/ImageGeneration/Asset/GetBulk`) || "", {
        method: "POST",
        body: JSON.stringify({
            assetIds,
        }),
    });
    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("Failed to load assets.");
    }
};
const deleteAsset = async (id: string) => {
    const res = await fetch(backendUrlFromPath(`/api/ImageGeneration/Asset/Delete`) || "", {
        method: "DELETE",
        body: JSON.stringify({
            assetIds: [id],
        }),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken,
        },
    });
    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("Failed to delete asset.");
    }
};

const uploadImage = async (image: string, name: string) => {
    const res = await fetch(backendUrlFromPath("/api/ImageGeneration/Asset/Add") || "", {
        method: "POST",
        body: JSON.stringify({
            image,
            name,
        }),
    });
    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("Failed to upload image.");
    }
};

const downloadAsset = async (asset: Asset) => {
    try {
        const response = await fetch(asset.url);
        if (!response.ok) {
            throw new Error(`Failed to fetch the asset: ${response.statusText}`);
        }

        const blob = await response.blob();

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = `${asset.id}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Błąd podczas pobierania zasobu:", error);
    }
};

const generateImage = async (data: GenerateImageRequest) => {
    const endpoint = data.image ? "ImageToImage" : "TextToImage";
    const res = await fetch(backendUrlFromPath(`/api/ImageGeneration/${endpoint}`) || "", {
        method: "POST",
        body: JSON.stringify({
            modelId: data.modelId,
            image: data.image,
            prompt: data.prompt,
            negativePrompt: data.negativePrompt,
            height: data.height,
            width: data.width,
            numSamples: data.numSamples,
        }),
    });
    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("No response from AI.");
    }
};

const generateTexture = async (data: GenerateImageRequest) => {
    const endpoint = data.image ? "ImageToTexture" : "TextToTexture";
    const res = await fetch(backendUrlFromPath(`/api/ImageGeneration/${endpoint}`) || "", {
        method: "POST",
        body: JSON.stringify({
            modelId: data.modelId,
            image: data.image,
            prompt: data.prompt,
            negativePrompt: data.negativePrompt,
            height: data.height,
            width: data.width,
            numSamples: data.numSamples,
        }),
    });
    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("No response from AI.");
    }
};

const generateSkybox = async (data: GenerateImageRequest) => {
    const res = await fetch(backendUrlFromPath(`/api/ImageGeneration/TextToSkybox`) || "", {
        method: "POST",
        body: JSON.stringify({
            prompt: data.prompt,
            negativePrompt: data.negativePrompt,
            width: data.width,
            style: data.style,
            numSamples: data.numSamples,
        }),
    });
    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("No response from AI.");
    }
};

const removeImageBackground = async (data: RemoveImageBackgroundRequest) => {
    const res = await fetch(backendUrlFromPath("/api/ImageGeneration/RemoveBackground") || "", {
        method: "POST",
        body: JSON.stringify({
            assetId: data.assetId,
        }),
    });
    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("No response from AI.");
    }
};

const pixelateImage = async (data: PixelateImageRequest) => {
    const res = await fetch(backendUrlFromPath("/api/ImageGeneration/Pixelate") || "", {
        method: "POST",
        body: JSON.stringify({
            assetId: data.assetId,
            pixelGridSize: data.pixelGridSize,
            removeNoise: data.removeNoise,
            removeBackground: data.removeBackground,
        }),
    });
    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("No response from AI.");
    }
};

const upscaleImage = async (data: UpscaleImageRequest) => {
    const res = await fetch(backendUrlFromPath("/api/ImageGeneration/Upscale") || "", {
        method: "POST",
        body: JSON.stringify({
            assetId: data.assetId,
            scalingFactor: data.scalingFactor,
            style: data.style,
            imageType: data.imageType,
            prompt: data.prompt,
            negativePrompt: data.negativePrompt,
        }),
    });
    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("No response from AI.");
    }
};

const generateFill = async (data: GenerateFillRequest) => {
    const res = await fetch(backendUrlFromPath("/api/ImageGeneration/MaskReplace") || "", {
        method: "POST",
        body: JSON.stringify({
            assetId: data.assetId,
            mask: data.mask,
            prompt: data.prompt,
            negativePrompt: data.negativePrompt,
        }),
    });
    if (res.ok) {
        const response = await res.json();

        return response;
    } else {
        throw Error("No response from AI.");
    }
};

export const ImageGeneratorUtils = {
    getModels,
    getAssets,
    getAssetById,
    getBulkAssets,
    deleteAsset,
    uploadImage,
    downloadAsset,
    generateImage,
    generateTexture,
    generateSkybox,
    removeImageBackground,
    pixelateImage,
    upscaleImage,
    generateFill,
};
