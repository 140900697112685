import {flexCenter} from "../../../../assets/style";
import styled from "styled-components";

export const Container = styled.div`
    box-sizing: border-box;
    position: fixed;
    z-index: 100;
    left: 12px;
    top: 12px;
    width: 244px;
    height: calc(100svh - 24px);
    background: var(--theme-container-main-dark);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--theme-font-main-selected-color);
    z-index: 100;

    div,
    span,
    button {
        box-sizing: border-box;
    }
`;

export const BorderedWrapper = styled.div<{
    height?: string;
    $isHeader?: boolean;
}>`
    display: flex;
    width: 100%;
    padding: 0 8px;
    height: ${({height}) => height || "40px"};
    min-height: ${({height}) => height || "40px"};
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--theme-container-divider);
    gap: 4px;
    font-weight: 600;
    font-size: var(--theme-font-size-xxs);

    ${({$isHeader}) =>
        $isHeader &&
        `
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  `}

    > div {
        > span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            max-width: 150px;
        }
    }

    .go-back-icon {
        padding: 2px;
    }

    .go-back-icon,
    .menuIcon {
        cursor: pointer;
        border-radius: 8px;
        transition: 0.3s;
        &:hover {
            background-color: #262626;
        }
    }
`;

export const TabButton = styled.div<{$isActive?: boolean}>`
    width: 100%;
    height: 32px;
    border-radius: 8px;
    ${flexCenter};
    transition: all 0.2s;
    cursor: pointer;
    font-size: var(--theme-font-size-xxs);
    font-weight: 600;
    color: white;

    &:hover {
        background: var(--theme-container-divider);
    }

    ${({$isActive}) =>
        $isActive &&
        `
    background: var(--theme-container-divider);
  `}
`;

export const BottomBar = styled.div`
    border-top: 1px solid var(--theme-container-divider);
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    height: auto;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
`;

export const BottomItem = styled.div`
    ${flexCenter};
    justify-content: flex-start;
    gap: 8px;
    color: #71717a;
    font-size: var(--theme-font-size-xxs);
    transition: all 0.1s;
    cursor: not-allowed;
    &:hover {
        color: white;
        .icon {
            filter: brightness(10);
        }
    }
`;
