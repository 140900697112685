export const PHYSICS_EVENTS = {
    TERMINATE: 'physics:terminate',
    READY: 'physics:ready',
    START: 'physics:start',
    UPDATE: 'physics:update',

    ADD: {
        BOX: 'physics:add:box',
        VEHICLE: 'physics:add:vehicle',
        MODEL: 'physics:add:model',
        PLAYER: 'physics:add:player',
        SPHERE: 'physics:add:sphere',
        TERRAIN: 'physics:add:terrain',
        CONVEXHULL: 'physics:add:convexhull',
        CONCAVEHULL: 'physics:add:concavehull',
        CAPSULE: 'physics:add:capsule'
    },

    REMOVE: {
        RIGID_BODY: 'physics:remove:rigid_body',
    },

    APPLY: {
        CENTRAL_IMPULSE: 'physics:apply:central_impulse'
    },

    SET: {
        ORIGIN: 'physics:set:origin',
        ROTATION: 'physics:set:rotation',
        LINEAR_VELOCITY: 'physics:set:linear_velocity'
    },

    BODY: {
        UPDATE: 'physics:body:update'
    },

    PLAYER: {
        ADD: 'physics:player:add',
        REMOVE: 'physics:player:remove',
        MOVE: 'physics:player:move',
        APPLY_IMPULSE: 'physics:player:apply_impulse',
        SET_POSITION: 'physics:player:set_position',
    },

    COLLISION: {
        DETECTED: 'physics:collision:detected',
        DETECT: 'physics:collision:detect',
        ADD: {
            OBJECT: 'physics:collision:add:object'
        },
        REMOVE: {
            OBJECT: 'physics:collision:remove:object'
        }
    },

    ANIMATION: {
        SET: 'physics:animation:set'
    }
}