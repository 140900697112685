/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import PlayerComponent from "../component/PlayerComponent";
import Ease from "../../utils/Ease";

/**
 * 补间动画控制器
 * @param {*} app 播放器
 */
class TweenAnimator extends PlayerComponent {
    constructor(app) {
        super(app);
    }

    create(scene, camera, renderer, animations) {
        this.scene = scene;
        this.animations = animations;

        return new Promise(resolve => {
            resolve();
        });
    }

    update(clock, deltaTime, time) {
        this.animations.forEach(n => {
            n.animations.forEach(m => {
                if (m.model && m.model.userData) {
                    if (m.model.userData.triggerMovement === true && m.model.userData.startOnTrigger === true) {
                        this.tweenObject(m, 0);
                        m.model.userData.startOnTrigger = false;
                        m.model.userData.triggerMovement = false;
                    } else if (
                        m.model.userData.triggerMovement === false &&
                        m.model.userData.startOnTrigger === false
                    ) {
                        this.tweenObject(m, time);
                    }
                }
            });
        });
    }

    tweenObject(animation, time) {
        // 条件判断
        if (animation.type !== "Tween" || !animation.target) {
            return;
        }

        let data = animation.data;

        //check if it's a loop
        if (time < animation.beginTime || time > animation.endTime) {
            if (!animation.loop || !animation.loopType) return;
            //TAHIR: depending on the loopType we need to reset the animation
            let duration = animation.endTime - animation.beginTime;
            animation.beginTime = time;
            animation.endTime = time + duration;
            if (animation.loopType === "reflect") {
                //pos
                [data.endPositionX, data.beginPositionX] = [data.beginPositionX, data.endPositionX];
                [data.endPositionY, data.beginPositionY] = [data.beginPositionY, data.endPositionY];
                [data.endPositionZ, data.beginPositionZ] = [data.beginPositionZ, data.endPositionZ];
                //rot
                [data.endRotationX, data.beginRotationX] = [data.beginRotationX, data.endRotationX];
                [data.endRotationY, data.beginRotationY] = [data.beginRotationY, data.endRotationY];
                [data.endRotationZ, data.beginRotationZ] = [data.beginRotationZ, data.endRotationZ];
                //scale
                [data.endScaleX, data.beginScaleX] = [data.beginScaleX, data.endScaleX];
                [data.endScaleY, data.beginScaleY] = [data.beginScaleY, data.endScaleY];
                [data.endScaleZ, data.beginScaleZ] = [data.beginScaleZ, data.endScaleZ];
            }
        }

        // 获取对象
        var target = this.scene.getObjectByProperty("uuid", animation.target);
        if (!target) {
            //console.warn(`Player: There is no object that uuid equals to ${animation.target}.`);
            return;
        }

        // 获取插值函数

        var ease = Ease[data.ease];
        if (!ease) {
            return;
        }

        var result = ease((time - animation.beginTime) / (animation.endTime - animation.beginTime));

        var positionX = data.beginPositionX + (data.endPositionX - data.beginPositionX) * result;
        var positionY = data.beginPositionY + (data.endPositionY - data.beginPositionY) * result;
        var positionZ = data.beginPositionZ + (data.endPositionZ - data.beginPositionZ) * result;

        var rotationX = data.beginRotationX + (data.endRotationX - data.beginRotationX) * result;
        var rotationY = data.beginRotationY + (data.endRotationY - data.beginRotationY) * result;
        var rotationZ = data.beginRotationZ + (data.endRotationZ - data.beginRotationZ) * result;

        var scaleX = data.beginScaleX + (data.endScaleX - data.beginScaleX) * result;
        var scaleY = data.beginScaleY + (data.endScaleY - data.beginScaleY) * result;
        var scaleZ = data.beginScaleZ + (data.endScaleZ - data.beginScaleZ) * result;

        target.position.x = positionX;
        target.position.y = positionY;
        target.position.z = positionZ;

        target.rotation.x = rotationX;
        target.rotation.y = rotationY;
        target.rotation.z = rotationZ;

        target.scale.x = scaleX;
        target.scale.y = scaleY;
        target.scale.z = scaleZ;
    }

    dispose() {
        this.scene = null;
        this.animations = null;
    }
}

export default TweenAnimator;
