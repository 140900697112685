import {fetchEventSource} from "@microsoft/fetch-event-source";
import {backendUrlFromPath} from "../../utils/UrlUtils";

interface IArgs {
    text: string;
    sceneId: string;
    userName: string;
    assistantName: string;
    description: string;
}

export const sendTextToAI = async (args: IArgs) => {
    const {text, sceneId, userName, assistantName, description} = args;
    try {
        const controller = new AbortController();
        const url = backendUrlFromPath("/api/AI/Conversation");
        const headers = {"Content-Type": "application/json"};
        const body = JSON.stringify({
            sceneID: sceneId,
            roomID: userName || "guest",
            assistantName: assistantName,
            userName: userName || "guest",
            text,
            description: description,
        });

        let responseText = "";
        await fetchEventSource(url!, {
            method: "POST",
            headers,
            body,
            signal: controller.signal,
            onmessage: event => {
                if (event.data === "###END###") {
                    console.log("Conversation ended.");
                    controller.abort();
                } else {
                    responseText += event.data;
                }
            },
            onerror: error => {
                console.error("Error in AI response:", error);
                controller.abort();
            },
        });

        return responseText;
    } catch (error) {
        console.error("Error sending text to AI:", error);
        return null;
    }
};
