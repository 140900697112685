import { useEffect, useState } from "react";
import * as THREE from "three";
import { ProceduralTerrainBehaviorInterface, PROCEDURAL_TERRAIN_TYPES } from "../../../../../types/editor";
import global from "../../../../../global";
import { NumericInputRow } from "../common/NumericInputRow";
import { PanelSectionTitleSecondary } from "../RightPanel.style";
import { ContentItem } from "../panels/GameSettings/GameSettings.style";
import { BasicCombobox } from "../../common/BasicCombobox";
import { ProceduralTerrainBehaviorConverter } from "../../../../../serialization/behaviours";
import { ImageSection } from "../common/ImageSection";

type Props = {
    behavior: ProceduralTerrainBehaviorInterface;
};

export const ProceduralTerrainBehavior = ({ behavior: initialBehavior }: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;

    const [behaviorState, setBehaviorState] = useState<ProceduralTerrainBehaviorInterface>({
        ...ProceduralTerrainBehaviorConverter.DEFAULT.getDefaultBehavior(initialBehavior.id),
        ...initialBehavior,
    });

    // Function to get the selected object and calculate the bounding box
    const getObjectBehavior = () => {
        if (selected) {
            const obj = editor.objectByUuid(selected.uuid);
            return obj.userData.behaviors?.find((userBehavior: any) => userBehavior.id === behaviorState.id);
        }
        return null;
    };

    // Handle updates to behavior state
    const handleInputChange = (value: any, name: keyof ProceduralTerrainBehaviorInterface) => {
        if (selected) {
            const objBehavior = getObjectBehavior();
            if (objBehavior) {
                objBehavior[name] = value;

                const updatedBehavior = {
                    ...ProceduralTerrainBehaviorConverter.DEFAULT.getDefaultBehavior(objBehavior.id),
                    ...objBehavior,
                };

                const behaviors = selected.userData.behaviors || [];
                const updatedBehaviors = behaviors.map((behavior: ProceduralTerrainBehaviorInterface) =>
                    behavior.id === updatedBehavior.id ? updatedBehavior : behavior
                );

                selected.userData.behaviors = updatedBehaviors;

                setBehaviorState((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));

                app.call(`objectChanged`, app.editor, app.editor.selected);
                app.call(`objectUpdated`, app.editor, app.editor.selected);
            }
        }
    };

    useEffect(() => {
        if (selected) {
            const objBehavior = getObjectBehavior();
            if (objBehavior) {
                const updatedBehavior = {
                    ...ProceduralTerrainBehaviorConverter.DEFAULT.getDefaultBehavior(objBehavior.id),
                    ...objBehavior,
                };

                const behaviors = selected.userData.behaviors || [];
                const updatedBehaviors = behaviors.map((behavior: ProceduralTerrainBehaviorInterface) =>
                    behavior.id === updatedBehavior.id ? updatedBehavior : behavior
                );
                selected.userData.behaviors = updatedBehaviors;

                setBehaviorState(updatedBehavior);

                app.call(`objectChanged`, app.editor, app.editor.selected);
                app.call(`objectUpdated`, app.editor, app.editor.selected);
            }
        }

    }, [selected]); 

    const proceduralTerrainOptions = Object.keys(PROCEDURAL_TERRAIN_TYPES).map((key) => ({
        key,
        value: PROCEDURAL_TERRAIN_TYPES[key as keyof typeof PROCEDURAL_TERRAIN_TYPES],
    }));

    return (
        <>
            <ContentItem style={{ marginBottom: "12px" }} $flexDirection="row" $justifyContent="space-between">
                <PanelSectionTitleSecondary>Terrain Type</PanelSectionTitleSecondary>
                <BasicCombobox
                    data={proceduralTerrainOptions}
                    value={proceduralTerrainOptions.find((option) => option.value === behaviorState.terrainType) || proceduralTerrainOptions[0]}
                    onChange={(option) => handleInputChange(option?.value, "terrainType")}
                />
            </ContentItem>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <div style={{ marginTop: "10px", alignSelf: "flex-start" }}>
                    <ImageSection
                        text="Height Map Texture"
                        uploadedFile={behaviorState.perlinNoiseImage || null}
                        setUploadedFile={(value) => handleInputChange(value, "perlinNoiseImage")}
                    />
                </div>
            </div>
            <NumericInputRow
                width="75px"
                label="Noise Scale"
                value={behaviorState.perlinNoiseScale}
                setValue={(value) => handleInputChange(value, "perlinNoiseScale")}
            />
            <NumericInputRow
                width="75px"
                label="Width"
                value={behaviorState.terrainWidth}
                setValue={(value) => handleInputChange(value, "terrainWidth")}
            />
            <NumericInputRow
                width="75px"
                label="Length"
                value={behaviorState.terrainLength}
                setValue={(value) => handleInputChange(value, "terrainLength")}
            />
            <NumericInputRow
                width="75px"
                label="Segments"
                value={behaviorState.terrainSegments}
                setValue={(value) => handleInputChange(value, "terrainSegments")}
            />
        </>
    );
};
