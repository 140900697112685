import {IconComponent} from "../../../common/HUDIcon";
import playcoinIcon from "./assets/playcoin.svg";

export const Playcoin = () => {
    return (
        <IconComponent $top $playcoin $playcoinValue={"1,000"} $active={false}>
            <img src={playcoinIcon} alt="" />
        </IconComponent>
    );
};
