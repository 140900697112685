import {useEffect, useRef, useState} from "react";
import I18n from "i18next";
import JSZip from "jszip";
import {AssetsList} from "../../../../common/AssetsList";
import {FileData} from "../../../../types/file";

import RemoveObjectCommand from "../../../../../../../command/RemoveObjectCommand";
import AddObjectCommand from "../../../../../../../command/AddObjectCommand";
import ModelLoader from "../../../../../../../assets/js/loaders/ModelLoader";
import Ajax from "../../../../../../../utils/Ajax";
import {ModelPreview} from "../../../../common/ModelPreview";
import global from "../../../../../../../global";
import {TitleContainer, TopContainer} from "../AssetsTab.style";
import {SearchInput} from "../../../../common/SearchInput";
import {UploadButton} from "../../../../common/UploadButton";
import {backendUrlFromPath} from "../../../../../../../utils/UrlUtils";
import {fetchModels, generateUniqueName, getSceneUniqueModels} from "../../../../../../../v2/pages/services";
import {useAuthorizationContext} from "../../../../../../../context";
import {Object3D} from "three";
import {BodyShapeType} from "../../../../../../../physics/common/types";
import MaterialUtils from "../../../../../../../utils/MaterialUtils";

const NEW_MODELS_KEY = "newModels";

export const ModelsTab = () => {
    const {dbUser} = useAuthorizationContext();
    const [search, setSearch] = useState("");
    const [data, setData] = useState<FileData[]>();
    const [sceneModelsData, setSceneModelsData] = useState<FileData[]>();
    const [filteredData, setFilteredData] = useState<FileData[]>();
    const [newlyAddedModelsIds, setNewlyAddedModelsIds] = useState<string[]>(
        JSON.parse(sessionStorage.getItem(NEW_MODELS_KEY) || "[]"),
    );
    const [selectedItemId, setSelectedItemId] = useState("");
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const app = (global as any).app as any;

    const handleDelete = async (id: string) => {
        const model = data?.find(item => item.ID === id);
        const isOwner = model?.UserID === dbUser?.id;

        const content = isOwner
            ? `${I18n.t("Delete")} ${model?.Name}?`
            : `${I18n.t("Delete")} ${model?.Name}? You are not the owner of this model.`;

        if (model) {
            app.confirm({
                title: I18n.t("Confirm"),
                content: content,
                onOK: async () => {
                    try {
                        const response = await Ajax.post({
                            url: backendUrlFromPath(`/api/Mesh/Delete?ID=${model.ID}`),
                        });

                        const obj = response?.data;
                        if (obj.Code !== 200) {
                            app.toast(I18n.t(obj.Msg), "warn");
                            return;
                        }

                        handleDeleteFromScene(model.ID);
                        handleFetchingModels();
                    } catch (error) {
                        app.toast(I18n.t("An error occurred"), "error");
                    }
                },
            });
        }
    };

    const handleFileUpload = (file: File | null) => {
        setUploadedFile(file);
    };

    const addMesh = async (thumbnailUrl: string, zippedFile: File | null, isPublic: boolean) => {
        try {
            const addResponse = await Ajax.post({
                url: backendUrlFromPath(`/api/Mesh/Add`),
                data: {
                    file: zippedFile,
                },
                msgBodyType: "multipart",
            });
            if (addResponse?.data.Code === 200) {
                const data = addResponse.data.Data;
                const payload = {
                    ID: data.ID,
                    Name: data.Name,
                    Image: thumbnailUrl,
                    IsPublic: isPublic,
                };

                const newModels = [data.ID, ...newlyAddedModelsIds];
                setNewlyAddedModelsIds(newModels);
                sessionStorage.setItem(NEW_MODELS_KEY, JSON.stringify(newModels));
                app.editModel(payload, () => {
                    app.call("fetchModels");
                });
            } else {
                app.toast("This file type is not supported.", "error");
            }
        } catch (error) {
            handleModelPreviewClose();
            app.toast("Failed to save model", "error");
        }
    };

    const zipAndUploadModel = async (
        thumbnailUrl: string,
        isPublic: boolean,
        file?: File | null,
        data?: Blob,
        filename?: string,
    ) => {
        if (data && filename) {
            file = new File([data], filename);
        }

        if (file) {
            const zipper = new JSZip();

            zipper.file(file.name, file);

            zipper.generateAsync({type: "blob", compressionOptions: {level: 1}}).then(async zip => {
                const zippedFile = new File([zip], `${file?.name}.zip`);

                try {
                    await addMesh(thumbnailUrl, zippedFile, isPublic);
                } catch (error) {
                    app.toast("Failed to save model", "error");
                    handleModelPreviewClose();
                }
            });
        }
    };

    const handleUploadClicked = async (thumbnailUrl: string, isPublic: boolean, data?: Blob, filename?: string) => {
        if ((uploadedFile && uploadedFile?.type !== "application/zip") || (data && filename)) {
            zipAndUploadModel(thumbnailUrl, isPublic, uploadedFile, data, filename);
        } else {
            try {
                await addMesh(thumbnailUrl, uploadedFile, isPublic);
            } catch (error) {
                app.toast("Failed to save model", "error");
                handleModelPreviewClose();
            }
        }
    };

    const handleClick = (id: string, callback?: any) => {
        //@ts-ignore
        let loader = new (ModelLoader as any)(app);
        if (data) {
            const model = data.find(item => item.ID === id);
            if (model) {
                let url = backendUrlFromPath(model.Url);
                loader
                    .load(url, model, {
                        camera: app.editor.camera,
                        renderer: app.editor.renderer,
                        audioListener: app.editor.audioListener,
                        clearChildren: true,
                    })
                    .then((obj: any) => {
                        if (!obj) {
                            return;
                        }

                        obj.traverse(function (child: Object3D) {
                            if ((child as any).isMesh) {
                                child.castShadow = true;
                                child.receiveShadow = true;
                            }
                        });

                        const existingNames = new Set<string>();
                        app.editor.scene.children.forEach((child: any) => {
                            if (child.name) {
                                existingNames.add(child.name);
                            }
                        });

                        obj.name = generateUniqueName(model.Name, existingNames);

                        Object.assign(obj.userData, model, {
                            Server: true,
                            reflection: false,
                        });

                        MaterialUtils.toggleReflection(obj, false);

                        if (app.storage.addMode === "click") {
                            clickSceneToAdd(obj);
                        } else {
                            setDefaultPhysicsData(obj);
                            app.editor.moveObjectToCameraClosestPoint(obj);
                            addToCenter(obj);
                        }

                        callback && callback(obj);
                    })
                    .catch((e: any) => {
                        app.toast(I18n.t("Could not load model"), "error");
                    });
            }
        }
    };

    const addToCenter = (obj: any) => {
        app.editor.execute(new (AddObjectCommand as any)(obj));

        if (obj.userData.scripts) {
            obj.userData.scripts.forEach((n: any) => {
                app.editor.scripts.push(n);
            });
            app.call("scriptChanged", obj);
        }
    };

    const setDefaultPhysicsData = (obj: Object3D) => {
        let isCharacter = false;

        obj.traverse((child: Object3D) => {
            if ((child as any).isBone) {
                isCharacter = true;
            }
        });

        obj.userData.physics = {
            enabled: true,
            type: "rigidBody",
            shape: isCharacter ? BodyShapeType.CAPSULE : BodyShapeType.CONCAVE_HULL,
            mass: isCharacter ? 1 : 0,
            inertia: {
                x: 0,
                y: 0,
                z: 0,
            },
            restitution: 0,
            ctype: isCharacter ? "Kinematic" : "Static",
        };
    };

    const clickSceneToAdd = (obj: any) => {
        let added = false;
        app.editor.gpuPickNum += 1;
        app.on(`gpuPick.ModelPanel`, (intersect: {point: any}) => {
            // 鼠标移动出现预览效果
            if (!intersect.point) {
                return;
            }
            if (!added) {
                added = true;
                app.editor.sceneHelpers.add(obj);
            }
            obj.position.copy(intersect.point);
        });
        app.on(`raycast.ModelPanel`, (intersect: {point: any}) => {
            // 点击鼠标放置模型
            app.on(`gpuPick.ModelPanel`, null);
            app.on(`raycast.ModelPanel`, null);
            obj.position.copy(intersect.point);
            addToCenter(obj);
            app.editor.gpuPickNum -= 1;
        });
    };

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>, id: string) => {
        e.dataTransfer.setData("asset-id", id);
        e.dataTransfer.setData("asset-type", "model");
    };

    const handleObjectSelected = (object: any) => {
        // 展开选中节点的所有父节点，并设置选中
        if (!object) {
            setSelectedItemId("");
            return;
        }

        let selected = object.userData.ID;
        setSelectedItemId(selected);
    };

    const handleModelPreviewClose = () => {
        setUploadedFile(null);
    };

    useEffect(() => {
        if (!search) {
            setFilteredData(data);
            return;
        } else {
            setFilteredData(
                data?.filter(n => {
                    return n.Name.toLowerCase().indexOf(search.toLowerCase()) > -1;
                }),
            );
        }
    }, [search, data]);

    const getSceneModels = () => {
        const sceneModels = getSceneUniqueModels(app.editor.scene);

        setSceneModelsData(sceneModels);
        return sceneModels || [];
    };

    const handleDeleteFromScene = (id: any) => {
        if (!app?.editor) return;

        const deleteInstance = (id: any) => {
            const object = app.editor.modelByID(id);

            if (!object || !object.parent) {
                return;
            }

            app.editor.execute(new (RemoveObjectCommand as any)(object));

            app.editor.scene.userData.lastSaveTime = new Date().toISOString();

            deleteInstance(id);
        };

        deleteInstance(id);

        app.saveScene(true);
    };

    const handleDataUpdate = (data: FileData[], sceneModels: FileData[]) => {
        const newModels =
            data
                ?.filter(model => newlyAddedModelsIds.includes(model.ID))
                ?.sort((a, b) => newlyAddedModelsIds.indexOf(a.ID) - newlyAddedModelsIds.indexOf(b.ID)) || [];
        const filteredSceneModels = sceneModels?.filter(model => !newlyAddedModelsIds.includes(model.ID)) || [];

        const restModels =
            data?.filter(model => {
                return (
                    !sceneModels.find(sceneModel => sceneModel.ID === model.ID) &&
                    !newModels.find(newModel => newModel.ID === model.ID)
                );
            }) || [];

        setData([...newModels, ...filteredSceneModels, ...restModels]);
    };

    const handleFetchingModels = async () => {
        const res = await fetchModels();
        const sceneModels = getSceneModels();
        handleDataUpdate(res || [], sceneModels);

        app.call("modelsFetched");
    };

    useEffect(() => {
        handleFetchingModels();

        app.on(`objectSelected.ModelsTab`, handleObjectSelected);
        app.on(`fetchModels.ModelsTab`, handleFetchingModels);
        app.on(`modelsFetched.ModelsTab`, handleModelPreviewClose);
        app.on(`sceneLoaded.ModelsTab`, getSceneModels);
        app.on(`objectAdded.ModelsTab`, getSceneModels);
        app.on(`objectRemoved.ModelsTab`, handleFetchingModels);
        return () => {
            app.on(`objectSelected.ModelsTab`, null);
            app.on(`fetchModels.ModelsTab`, null);
            app.on(`modelsFetched.ModelsTab`, null);
            app.on(`sceneLoaded.ModelsTab`, null);
            app.on(`objectAdded.ModelsTab`, null);
            app.on(`objectRemoved.ModelsTab`, null);
        };
    }, []);

    useEffect(() => {
        app.on(`dragEnd.ModelsTab`, (type: string, id: string, position: any) => {
            if (type === "model") {
                handleClick(id, (obj: any) => {
                    obj.position.copy(position);
                });
            }
        });
        return () => {
            app.on(`dragEnd.ModelsTab`, null);
        };
    }, [data]);

    useEffect(() => {
        if (sceneModelsData) {
            handleDataUpdate(data || [], sceneModelsData);
        }
    }, [sceneModelsData, newlyAddedModelsIds]);

    return (
        <>
            <TopContainer>
                <SearchInput width="224px" placeholder="Search 3D models" onChange={setSearch} value={search} />
            </TopContainer>
            <TitleContainer>
                Models
                <UploadButton onClick={() => inputRef.current?.click()} />
            </TitleContainer>

            {!uploadedFile && (
                <input
                    type="file"
                    ref={inputRef}
                    style={{display: "none"}}
                    onChange={e => handleFileUpload(e.target.files ? e.target.files[0] : null)}
                />
            )}
            {filteredData && (
                <AssetsList
                    data={filteredData}
                    selectedItemsIds={[selectedItemId]}
                    onClick={handleClick}
                    onDelete={handleDelete}
                    draggable
                    onDragStart={handleDragStart}
                />
            )}

            {uploadedFile && (
                <ModelPreview onClose={handleModelPreviewClose} file={uploadedFile} onApprove={handleUploadClicked} />
            )}
        </>
    );
};
