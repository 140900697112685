import {useCallback, useEffect} from "react";
import {debounce} from "lodash";
import styled from "styled-components";
import {ITemplate} from "./constants/templates";
import {getThumbnail} from "../../../../services";
import gamePlaceholder from "../../../../v2/assets/game-controller.svg";
import {flexCenter} from "../../../../assets/style";

interface Props {
    item: ITemplate;
    selectedItemsIds?: string[];
    onClick: (id: string) => void;
    onDoubleClick?: (id: string, newTab?: boolean) => void;
}

export const SingleTemplate = ({onClick, onDoubleClick, selectedItemsIds, item}: Props) => {
    const thumbnail = getThumbnail(item.Thumbnail || "null");
    const isSelected = selectedItemsIds?.includes(item.ID);

    const debouncedOnClick = useCallback(
        debounce((id: string) => {
            if (onClick) onClick(id);
        }, 200),
        [onClick],
    );

    useEffect(() => {
        return () => {
            debouncedOnClick.cancel();
        };
    }, [debouncedOnClick]);

    const handleOpen = (e: any) => {
        if (e.target.name === "options") {
            return;
        }

        onDoubleClick && onDoubleClick(item.ID);
    };

    return (
        <ListItem onClick={() => debouncedOnClick(item.ID)} onDoubleClick={handleOpen}>
            <SceneImage $bgImage={thumbnail}>
                {!thumbnail && <img className="default-img thumbnail" src={gamePlaceholder} alt="" />}
                {isSelected && <SelectedBorder className="selectedBorder" />}
            </SceneImage>
            <SceneName>{item.Name}</SceneName>
        </ListItem>
    );
};

const ListItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: center;
    font-family: "Inter";
    line-height: 120%;
    color: white;
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-regular);
    position: relative;
    cursor: pointer;
    margin: 0 auto;
    &:hover {
        color: white;
    }
    img,
    .selectedBorder {
        border-radius: 8px;
        width: 100%;
        aspect-ratio: 16 / 9;
    }
`;

const SceneImage = styled.div<{$bgImage?: string}>`
    border-radius: 8px;
    width: 100%;
    aspect-ratio: 16 / 9;
    ${flexCenter};

    .default-img {
        width: 40%;
        max-width: 104px;
    }

    ${({$bgImage}) =>
        $bgImage
            ? `
                background-image: url('${$bgImage}');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;

              `
            : `background-color: var(--theme-grey-bg)`}
`;
const SelectedBorder = styled.div`
    border: 1px solid #ffffff;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
`;
const SceneName = styled.span`
    display: inline-block;
    text-align: left;
    line-clamp: 1;
    font-size: var(--theme-font-size-xs);
    font-weight: var(--theme-font-medium);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`;
