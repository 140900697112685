import {FileData} from "../editor/assets/v2/types/file";
import global from "../global";
import Application from "../Application";
import Player from "../player/Player";
import ApplicationProps from "../ApplicationProps";

export const backendUrlFromPath = (url: string | FileData | null): string | undefined => {
    const app = global.app as Player | Application;
    const options = app?.options as ApplicationProps;
    if (url) {
        if (typeof url !== "string") {
            url = url.Url;
        }
        url = url.trim();
        if (url.indexOf("http") === -1) {
            url = `${options?.server}${url}`;
        }
    }
    return url ?? undefined;
};

export const backendWebSocketUrlFromPath = (url: string): string => {
    const app = global.app as Player | Application;
    const options = app.options as ApplicationProps;
    if (url.indexOf("wss") === -1) {
        if (url.indexOf("http") > -1) {
            url = `${options?.server.replace("http", "wss")}${url}`;
        } else if (url.indexOf("https") > -1) {
            url = `${options?.server.replace("https", "wss")}${url}`;
        }
    }
    return url;
};
