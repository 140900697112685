import styled from "styled-components";
import {Bold, List, Paragraph, SectionTitle} from "./TermsAndPolicy.style";

export const TOS = () => {
    return (
        <>
            <Paragraph>
                Welcome! Please take the time to carefully read these Terms of Service (these <Bold>“Terms”</Bold>) of
                Erth.AI Inc. (<Bold>“Company”, “we” or “us”</Bold>), as they include important information about your
                legal rights.
            </Paragraph>

            <Paragraph>
                These Terms govern your access to and use of the Company’s websites (including the one at www.erth.ai)
                (collectively, the <Bold>“Websites”</Bold>) and the applications (collectively, the <Bold>“Apps”</Bold>)
                that we may make available from time to time through third-party platforms or services, such as Discord
                (collectively, the <Bold>“Third-Party Game Platforms”</Bold>). The Websites, the Apps, the games
                comprising a part of the Websites or Apps (Including Poker Party) (collectively, the{" "}
                <Bold>“Games”</Bold>), however accessed and wherever played, and any other game experiences,
                applications, services, content, tools, features, and functionality made available on or through our
                Websites, Apps, and Games are collectively referred to as the <Bold>“Services”</Bold>.
            </Paragraph>

            <Paragraph>
                By accessing and/or using the Services, you are agreeing to these Terms. If you do not understand or
                agree to these Terms, please do not use the Services.
                <br />
                <br />
                For purposes of these Terms, <Bold>“you”</Bold> and <Bold>“your”</Bold> means you as the user of the
                Services. If you use the Services on behalf of a company or other entity then “you” includes you and
                that entity, and you represent and warrant that (a) you are an authorized representative of the entity
                with the authority to bind the entity to these Terms, and (b) you agree to these Terms on the entity’s
                behalf.
                <br />
                <br />
                THE SERVICES, INCLUDING THE WEBSITES, THE APPS, AND THE GAMES, ARE STRICTLY FOR AMUSEMENT PURPOSES ONLY.
                THE SERVICES DO NOT OFFER REAL MONEY GAMBLING OR AN OPPORTUNITY TO WIN REAL MONEY OR REAL-WORLD PRIZES.
                NO ACTUAL MONEY OR ANYTHING OF VALUE CAN BE WON PLAYING THE GAMES OFFERED THROUGH THE SERVICES, AND NO
                ACTUAL MONEY IS REQUIRED TO PLAY, EVEN THOUGH THE SERVICES MAY OFFER THE OPPORTUNITY TO PURCHASE CERTAIN
                PRODUCTS OR SERVICES, INCLUDING VIRTUAL ITEMS.
            </Paragraph>

            <Paragraph>
                <Bold $uppercase>
                    Section 9 contains an arbitration clause and class action waiver. By agreeing to these Terms, you
                    agree (a) to resolve all disputes (with limited exception) related to the Company’s Services AND/or
                    products through binding individual arbitration, which means that you waive any right to have those
                    disputes decided by a judge or jury, and (b) to waive your right to participate in class actions,
                    class arbitrations, or representative actions, as set forth below. You have the right to opt-out of
                    the arbitration clause and the class action waiver as explained in Section 9
                </Bold>
            </Paragraph>

            <Paragraph style={{fontWeight: "bold", marginTop: "64px"}}>TABLE OF CONTENTS</Paragraph>
            <TOC>
                <TOCItem>
                    <a href="#section1">Who May Use the Services</a>
                </TOCItem>
                <TOCItem>
                    <a href="#section2">User Accounts</a>
                </TOCItem>
                <TOCItem>
                    <a href="#section3">Purchases</a>
                </TOCItem>
                <TOCItem>
                    <a href="#section4">Data Collection and Usage</a>
                </TOCItem>
                <TOCItem>
                    <a href="#section5">Rights We Grant You</a>
                </TOCItem>
                <TOCItem>
                    <a href="#section6">Intellectual Property</a>
                </TOCItem>
                <TOCItem>
                    <a href="#section7">Third-Party Services</a>
                </TOCItem>
                <TOCItem>
                    <a href="#section8">Disclaimers, Limitations of Liability and Indemnification</a>
                </TOCItem>
                <TOCItem>
                    <a href="#section9">ARBITRATION AND CLASS ACTION WAIVER</a>
                </TOCItem>
                <TOCItem>
                    <a href="#section10">DISCORD APPs</a>
                </TOCItem>
                <TOCItem>
                    <a href="#section11">TERMINATION</a>
                </TOCItem>
                <TOCItem>
                    <a href="#section12">Additional Provisions</a>
                </TOCItem>
            </TOC>

            <SectionTitle id="section1">1. WHO MAY USE THE SERVICES</SectionTitle>
            <Paragraph>
                You must be 13 years of age or older to access and use the Services. However, if you are 13 years of age
                or older, but less than 18 years of age, or the age of majority in your jurisdiction, whichever is
                higher, you may only access and use the Services with the express consent of your parent or legal
                guardian. By using the Services, you represent and warrant that you are either 18 years of age, or the
                age of majority in your jurisdiction, whichever is higher, or older, or 13 years of age or older, but
                less than 18 years of age, or the age of majority in your jurisdiction, whichever is higher, and have
                the express consent of your parent or legal guardian to access and use the Services. You also represent
                and warrant that you are not using the Services on behalf of any other person, especially including any
                person that is not 18 years of age, or the age of majority in your jurisdiction, whichever is higher, or
                older, unless you are the parent or legal guardian of a person 13 years of age or older, but less than
                18 years of age, or the age of majority in your jurisdiction, whichever is higher, in which case you
                represent and warrant that you are such person’s parent or legal guardian and expressly consent to such
                person’s access to and use of the Services. If you are a parent or legal guardian of a user under 18, by
                allowing your child to use the Services, you are subject to these Terms and responsible for your child's
                activity.
            </Paragraph>

            <SectionTitle id="section2">2. USER ACCOUNTS</SectionTitle>
            <Paragraph>
                To use the Services, you may be required to create an account or link another account, such as your
                account on an applicable Third-Party Game Platform (<Bold>“Account”</Bold>). You agree to provide us
                with accurate, complete and updated information for your Account. You can access, edit and update your
                Account via the account settings page on the Websites or Apps or the applicable Third-Party Game
                Platform. You are solely responsible for any activity on your Account and for maintaining the
                confidentiality and security of your password. Neither we nor any Third-Party Game Platform are liable
                for any acts or omissions by you in connection with your Account. You must immediately notify us at
                hello@erth.ai or the applicable Third-Party Game Platform if you know or have any reason to suspect that
                your Account or password have been stolen, misappropriated or otherwise compromised, or in case of any
                actual or suspected unauthorized use of your Account. You agree not to create any Account if we or any
                applicable Third-Party Game Platform have previously removed your Account, or we or any applicable
                Third-Party Game Platform previously banned you from any of our Services, unless we provide written
                consent otherwise.
            </Paragraph>

            <SectionTitle id="section3">3. PURCHASES</SectionTitle>
            <Paragraph>
                3.1 <u>Offerings</u>. We may in our discretion choose to provide many aspects of the Services to you
                without charge. We may also in our discretion, however, require you to pay a fee to purchase certain
                products or services (including Virtual Items, as defined in Section 2.5 (—Virtual Items and Virtual
                Currency) below) through the Services, including products or services of third parties that are offered
                through the Services (<Bold>“Offerings”</Bold>). We reserve (on our own behalf and on behalf of each
                Third-Party Game Platforms) the right, with or without prior notice and in our sole and complete
                discretion, to (a) discontinue, modify, or limit the available quantity of, any Offerings, and (b)
                refuse to allow any user to purchase any Offering or deliver such Offerings to a user or a user
                designated address.
                <br />
                <br />
                3.2 <u>Payments</u>. You acknowledge and agree that all information you provide with regards to a
                purchase of Offerings, including, without limitation, credit card or other payment information, is
                accurate, current and complete. You represent and warrant that you have the legal right to use the
                payment method you provide to us or any applicable Third-Party Game Platform or our or their payment
                processor, including, without limitation, any credit card you provide when completing a transaction.
                When you purchase Offerings, you (a) agree to pay the price for such Offerings as set forth in the
                applicable Service, and all shipping and handling charges and all applicable taxes in connection with
                your purchase (the <Bold>“Full Purchase Amount”</Bold>), and (b) authorize us or any applicable
                Third-Party Game Platform to charge your credit card or other payment method for the Full Purchase
                Amount. Unless otherwise noted, all currency references are in U.S. Dollars. All fees and charges are
                payable in accordance with payment terms in effect at the time the fee or the charge becomes payable.
                Payment can be made by credit card, debit card, or through PayPal or other means that we or any
                applicable Third-Party Game Platform may make available. Orders will not be processed until payment has
                been received in full, and any holds on your account by PayPal or any other payment processor are solely
                your responsibility.
                <br />
                <br />
                3.3 <u>Promotional Codes</u>. We may offer certain promotional codes, referral codes, discount codes,
                coupon codes or similar offers (<Bold>“Promotional Codes”</Bold>) that may be redeemed for discounts on
                future Offerings, or other features or benefits related to the Services, subject to any additional terms
                that the Company establishes. You agree that Promotional Codes: (a) must be used in a lawful manner; (b)
                must be used for the intended audience and purpose; (c) may not be duplicated, sold or transferred in
                any manner, or made available by you to the general public (whether posted to a public forum, coupon
                collecting service, or otherwise), unless expressly permitted by the Company; (d) may be disabled or
                have additional conditions applied to them by the Company at any time for any reason without liability
                to the Company; (e) may only be used pursuant to the specific terms that the Company establishes for
                such Promotional Code; (f) are not valid for cash or other credits or points; and (g) may expire prior
                to your use.
                <br />
                <br />
                3.4 <u>Changes and Pricing</u>. The Company may, at any time, revise or change the pricing,
                availability, specifications, content, descriptions or features of any Offerings. While we attempt to be
                as accurate as we can in our descriptions for the Offerings, we do not warrant that Offering
                descriptions are accurate, complete, reliable, current, or error-free. If an Offering itself is not as
                described on the Services, your sole remedy is to return it (for physical products, in unused
                condition). The inclusion of any Offerings for purchase through the Services at a particular time does
                not imply or warrant that the Offerings will be available at any other time. We reserve the right to
                change prices for Offerings displayed on the Services at any time, and to correct pricing errors that
                may inadvertently occur (and to cancel any orders in our sole discretion that were purchased with
                pricing errors). All such changes shall be effective immediately upon posting of such new Offering
                prices to the Services and/or upon making the customer aware of the pricing error.
                <br />
                <br />
                3.5 <u>Return Policy</u>. Except as the refund policy of any applicable Third-Party Game Platform may
                otherwise expressly provide, there are no refunds available for any Offerings on the Services, except as
                we may choose to offer in our sole discretion on a case-by-case basis.
                <br />
                <br />
                3.6 <u>Virtual Items and Virtual Currency</u>. The Services may include an opportunity to earn,
                purchase, or otherwise obtain virtual currency (<Bold>“Virtual Currency”</Bold>) or other virtual items
                (e.g., tokens, cards, trophies, rewards, badges, or other in-game virtual assets) (
                <Bold>“Virtual Items”</Bold>
                ). Some Virtual Currency or other Virtual Items may require you to pay a fee using “real world” money to
                obtain them. Virtual Items (including Virtual Currency) can never be redeemed for real money, goods, or
                any other item of monetary value from us or any other party. You understand that you have no right or
                title in Virtual Currency or other Virtual Items you may obtain. Your purchase of Virtual Currency or
                other Virtual Items is final and is not refundable, exchangeable, transferable, except in our sole
                discretion. You may not purchase, sell, or exchange Virtual Currency or other Virtual Items outside the
                Services. Doing so is a violation of the Terms and may result in termination of your Account with the
                Services and/or legal action. We retain the right to modify, manage, control, delete, move, remove,
                and/or suspend any or all Virtual Currency and other Virtual Items at our sole discretion at any time
                with or without notice to you and with no liability of any kind to you. We may limit the total amount of
                Virtual Items that may be held for any one game or that may be held in your Account in the aggregate. We
                may limit the period of time during which you may hold or use Virtual Items related to any particular
                game or other aspect of the Services. Additionally, the price and availability of Virtual Items to
                purchase or acquire are subject to change without notice. You agree that you have no ownership or other
                rights in or to any Virtual Items or your Account.
            </Paragraph>

            <SectionTitle id="section4">4. DATA COLLECTION AND USAGE</SectionTitle>
            <Paragraph>
                4.1 <u>Data Collection</u>. You acknowledge and agree that we have the right to collect, use, store, and
                process data about you and your use of the Services. This includes, but is not limited to, personal
                information, usage data, device information, and content you provide or create while using the Services.
                <br />
                <br />
                4.2 <u>Usage of Collected Data</u>. We may use the data we collect for various purposes, including but
                not limited to:
                <List $lowerAlpha>
                    <li>Providing, maintaining, and improving the Services; and Complying with legal obligations.</li>
                    <li>Developing new products, services, features, and functionality;</li>
                    <li>Understanding and analyzing how you use our Services;</li>
                    <li>
                        Communicating with you, including for customer service, marketing, and promotional purposes;
                    </li>
                    <li>Protecting our legal rights and preventing potentially illegal activities; and</li>
                    <li>Complying with legal obligations;</li>
                </List>
                <br />
                4.3 <u>Aggregated and Anonymized Data</u>. You agree that we have the right to aggregate and anonymize
                the data we collect about you and other users. We may use this aggregated and anonymized data for any
                business purpose, including but not limited to analyzing trends, improving our Services, and sharing
                with third parties.
                <br />
                <br />
                4.4 <u>Third-Party Sharing</u>. We may share aggregated, anonymized data with third parties for various
                purposes, including business analytics, research, and marketing. This shared data will not include any
                personally identifiable information.
                <br />
                <br />
                4.5 <u>Privacy Policy</u>. For more detailed information about our data collection and usage practices,
                please refer to our Privacy Policy located at https://www.erth.ai/legal/privacy-policy. The Privacy
                Policy is incorporated into these Terms by reference.
                <br />
                <br />
                4.6 <u>Consent</u>. By using the Services, you consent to our collection, use, and sharing of data as
                described in this section and in our Privacy Policy.
                <br />
                <br />
                4.7 <u>Parental Rights</u>. If you are a parent or legal guardian of a user under 18, you have the right
                to:
                <List $lowerAlpha>
                    <li>Review your child’s personal information.</li>
                    <li>Request deletion of your child’s personal information.</li>
                    <li>Restrict further collection or use of your child’s personal information.</li>
                    <li>
                        Revoke at any time by written notice to us your consent to your child’s access to and use of the
                        Services.
                    </li>
                </List>
            </Paragraph>

            <SectionTitle id="section5">5. RIGHTS WE GRANT YOU</SectionTitle>
            <Paragraph>
                5.1 <u>Right to Use Services</u>. We hereby permit you to use the Services for your personal
                non-commercial use only, provided that you comply with these Terms in connection with all such use. If
                any software, content or other materials owned or controlled by us are distributed to you as part of
                your use of the Services, we hereby grant you, a personal, non-assignable, non-sublicensable,
                non-transferrable, and non-exclusive right and license to access and display such software, content and
                materials provided to you as part of the Services, in each case for the sole purpose of enabling you to
                use the Services as permitted by these Terms. Your access and use of the Services may be interrupted
                from time to time for any of several reasons, including, without limitation, the malfunction of
                equipment, periodic updating, maintenance or repair of the Service or other actions that Company, in its
                sole discretion, may elect to take.
                <br />
                <br />
                5.2 <u>Social Free-to-Play Games</u>. Except as these Terms otherwise expressly provide, the Games are
                social free-to-play games made available strictly for amusement purposes only. Even Games that simulate
                card games (such as poker) or other casino games or games associated with gambling are just that:
                simulations. They do not purport to and do not involve real money gambling or an opportunity to win real
                money or real-world prizes. No actual money or anything of value can be won playing the Games through
                the Services, and no actual money is required to play, even though the Services may offer the
                opportunity to purchase certain products or services, including Virtual Items. Accordingly, we are not
                licensed and regulated by any gambling authority. All references in the Games to “pots,” “limits”,
                “betting” or the like are solely for instructional or illustrative purposes and do not involve wagering
                real money. You are strictly prohibited from associating the results of any Game to winning real money
                or real-world prizes or otherwise gambling, or facilitating gambling, on the results of any Game.
                <br />
                <br />
                5.3 <u>Restrictions On Your Use of the Services</u>. You may not do any of the following in connection
                with your use of the Services, unless applicable laws or regulations prohibit these restrictions or you
                have our written permission to do so:
                <List $lowerAlpha>
                    <li>
                        download, modify, copy, distribute, transmit, display, perform, reproduce, duplicate, publish,
                        license, create derivative works from, or offer for sale any proprietary software owned by the
                        Company and deployed for the Services, except for temporary files that are automatically cached
                        by your web browser for display purposes, or as otherwise expressly permitted in these Terms;
                    </li>
                    <li>
                        duplicate, decompile, reverse engineer, disassemble or decode the Services (including any
                        underlying idea or algorithm), or attempt to do any of the same;
                    </li>
                    <li>
                        use, reproduce or remove any copyright, trademark, service mark, trade name, slogan, logo,
                        image, or other proprietary notation displayed on or through the Services;
                    </li>
                    <li>
                        use automation software (bots), hacks, modifications (mods) or any other unauthorized
                        third-party software designed to modify the Services;
                    </li>
                    <li>
                        exploit the Services for any commercial purpose, except as specifically permitted by the Company
                        in connection with your permitted use of the Services, including without limitation
                        communicating or facilitating any commercial advertisement or solicitation;
                    </li>
                    <li>
                        use the Services to create or develop any competing products or services or to power, enable, or
                        train other artificial intelligence and machine learning models, tools or technologies;
                    </li>
                    <li>
                        access or use the Services in any manner that could disable, overburden, damage, disrupt or
                        impair the Services or interfere with any other party’s access to or use of the Services or use
                        any device, software or routine that causes the same;
                    </li>
                    <li>
                        attempt to gain unauthorized access to, interfere with, damage or disrupt the Services, accounts
                        registered to other users, or the computer systems or networks connected to the Services;
                    </li>
                    <li>
                        circumvent, remove, alter, deactivate, degrade or thwart any technological measure or content
                        protections of the Services;
                    </li>
                    <li>
                        use any robot, spider, crawlers, scraper, or other automatic device, process, software or
                        queries that intercepts, “mines,” scrapes, extracts, or otherwise accesses the Services to
                        monitor, extract, copy or collect information or data from or through the Services, or engage in
                        any manual process to do the same; 
                    </li>
                    <li>
                        introduce any viruses, trojan horses, worms, logic bombs or other materials that are malicious
                        or technologically harmful into our systems;
                    </li>
                    <li>
                        submit, transmit, display, generate, create, perform, post or store any content that is
                        inaccurate, unlawful, defamatory, infringing, obscene, lewd, lascivious, filthy, excessively
                        violent, pornographic, invasive of privacy or publicity rights, harassing, threatening, abusive,
                        inflammatory, harmful, hateful, cruel or insensitive, deceptive, or otherwise objectionable, use
                        the Services for illegal, harassing, bullying, unethical or disruptive purposes, or otherwise
                        use the Services in a manner that is infringing, obscene, lewd, lascivious, filthy, excessively
                        violent, harassing, harmful, hateful, cruel or insensitive, deceptive, threatening, abusive,
                        inflammatory, pornographic, inciting, organizing, promoting or facilitating violence or criminal
                        or harmful activities, defamatory, obscene or otherwise objectionable;
                    </li>
                    <li>
                        submit any personal information through the Services of any person under the age of 18, or the
                        age of majority in your jurisdiction, whichever is higher, except, in the case of any person
                        that is 13 years of age or older, but less than 18 years of age, that has such person’s parents’
                        or legal guardians’ express consent to access and use the Service, such person or such person’s
                        parents or legal guardians may submit personal information with respect to such person through
                        the Services;
                    </li>
                    <li>use the Services in violation of any Third-Party Software License Terms (as defined below);</li>
                    <li>
                        use the Services in connection with any wager of any money or other thing of value unless
                        subject to separate, express written terms provided by us and the applicable Third-Party
                        Platform permitting such conduct;
                    </li>
                    <li>
                        encourage other users to gamble beyond their means or otherwise in an irresponsible way, or to
                        engage in illegal gambling activities of any nature;
                    </li>
                    <li>
                        violate any applicable law or regulation in connection with your access to or use of the
                        Services; or
                    </li>
                    <li>access or use the Services in any way not expressly permitted by these Terms.</li>
                </List>
                <br />
                5.4 <u>Use of the Services</u>. You are responsible for providing the computer, tablet, mobile device,
                wireless service plan, software, Internet connections and/or other equipment or services that you need
                to access and use the Services. We do not guarantee that the Services can be accessed and used on any
                particular device or with any particular service plan. We do not guarantee that the Services are or will
                be available in, or that orders for Offerings can be placed from, any particular geographic location. As
                part of the Services and to update you regarding the status of deliveries, you may receive push
                notifications, local client notifications, text messages, picture messages, alerts, emails or other
                types of messages directly sent to you in connection with the Services (<Bold>“Push Messages”</Bold>).
                You acknowledge that, when you use the Services, your wireless service provider may charge you fees for
                data, text messaging and/or other wireless access, including in connection with Push Messages. You have
                control over the Push Messages settings, and can opt in or out of these Push Messages through the
                Services or through your mobile device’s operating system (with the possible exception of infrequent,
                important service announcements and administrative messages). Please check with your wireless service
                provider to determine what fees apply to your access to and use of the Services, including your receipt
                of Push Messages from the Company. You are solely responsible for any fee, cost or expense that you
                incur to access or use the Services on your mobile device, including for your receipt of push messages
                from the Company.
                <br />
                <br />
                5.5 <u>Beta Offerings</u>. From time to time, we may, in our sole discretion, include certain test or
                beta features or products in the Services (<Bold>“Beta Offerings”</Bold>) as we may designate from time
                to time. Your use of any Beta Offering is completely voluntary and at your own risk. The Beta Offerings
                are provided on an “as is” and ‘as available” basis, without any warranties of any kind, and may contain
                errors, defects, bugs, or inaccuracies that could cause failures, corruption or loss of data and
                information from any connected device. You acknowledge and agree that:
                <List $lowerAlpha>
                    <li>all use of any Beta Offering is at your sole risk;</li>
                    <li>
                        Beta Offerings may be unstable and may significantly change or be discontinued at any time
                        without any notice to you;
                    </li>
                    <li>we do not guarantee the availability, completeness, or performance of Beta Offerings;</li>
                    <li>
                        once you use a Beta Offering, your content or data may be affected such that you may be unable
                        to revert back to a prior non-beta version of the same or similar feature;
                    </li>
                    <li>
                        if reversion is possible, you may not be able to return or restore data created within the Beta
                        Offering back to the prior non-beta version;
                    </li>
                    <li>
                        we may terminate your access to and use of Beta Offerings at any time, for any or no reason, and
                        without notice to you.
                    </li>
                </List>
                <Paragraph>
                    If we provide you any Beta Offerings on a closed beta or confidential basis, we will notify you of
                    such as part of your use of the Beta Offerings. For any such confidential Beta Offerings, you agree
                    to not disclose, divulge, display, or otherwise make available any of the Beta Offerings or any
                    information about the Beta Offerings without our prior written consent. We reserve the right to
                    impose additional terms, conditions, or requirements on your participation in any Beta Offering.
                </Paragraph>
                <br />
                5.6 <u>Third-Party Software</u> We use open-source software and/or certain other third-party software (
                <Bold>“Third-Party Software”</Bold>) in connection with providing the Services, including, but not
                limited to:
                <List>
                    <li>
                        <a href="https://unity.com/legal/terms-of-service" target="_blank" rel="noopener noreferrer">
                            Unity
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://cloud-prod.colyseus.io/terms-of-service"
                            target="_blank"
                            rel="noopener noreferrer">
                            Colyseus
                        </a>
                    </li>
                    <li>
                        <a href="https://github.com/Furnyr/Dissonity" target="_blank" rel="noopener noreferrer">
                            Dissonity
                        </a>
                    </li>
                </List>
                <Paragraph>
                    Your access and use of such Third-Party Software, including through your use of the Services, may be
                    governed by additional terms and conditions of the provider of such Third-Party Software (
                    <Bold>“Third-Party Software License Terms”</Bold>). We will endeavour to notify you of such
                    additional terms and conditions. The Third-Party Software License Terms of which we are aware are
                    accessible through the links provided above. You agree to comply with all applicable Third-Party
                    Software License Terms.
                </Paragraph>
                <br />
                5.7 <u>Accuracy of Materials</u>. While we strive to provide accurate information through our Services,
                we do not warrant or guarantee the accuracy, completeness, or usefulness of this information. Any
                reliance you place on such information is strictly at your own risk.
                <br />
                <br />
                5.8 <u>Disconnection Policy</u>. In the event of a disconnection during gameplay, we will make
                reasonable efforts to restore your game state. However, we cannot guarantee that all progress will be
                saved. In some cases, the game may continue without you, and you may forfeit any potential winnings from
                that game session.
                <br />
                <br />
                5.9 <u>Malfunction Policy</u>. In the event of a malfunction in the Services, we reserve the right to
                void all plays and refund all affected wagers or entries. We are not liable for any damages or losses
                resulting from such malfunctions.
                <br />
                <br />
                5.10 <u>Alternative Entry Method</u>. For any contests or tournaments that may be offered through or
                involving the Services, we may provide a free alternative method of entry. Please contact us at
                hello@erth.ai for details on how to enter without purchase, if available.
                <br />
                <br />
                5.11 <u>Prize Claim Process</u>. If you win a prize through any contests or tournaments offered through
                or involving the Services, you may be required to provide us or third-party contest or tournament
                sponsors certain information or documentation to claim your prize. This may include, but is not limited
                to, proof of identity, tax information, and a signed affidavit of eligibility and liability/publicity
                release. Failure to provide required information or documentation within the specified timeframe may
                result in forfeiture of the prize.
            </Paragraph>

            <SectionTitle id="section6">6. INTELLECTUAL PROPERTY</SectionTitle>
            <Paragraph>
                6.1 <u>Ownership of the Services</u>. The Services, including their “look and feel” (e.g., text,
                graphics, images, logos), proprietary content, information and other materials, are protected under
                copyright, trademark and other intellectual property laws. You agree that the Company and/or its
                licensors own all right, title and interest in and to the Services (including any and all intellectual
                property rights therein) and you agree not to take any action(s) inconsistent with such ownership
                interests. We and our licensors reserve all rights in connection with the Services and its content
                (other than Your Content), including, without limitation, the exclusive right to create derivative
                works.
                <br />
                <br />
                6.2 <u>Ownership of Trademarks</u>. The Company’s name, trademarks, logo and all related names, logos,
                product and service names, designs and slogans (including those of or comprising a part of the Games)
                are trademarks of the Company or its affiliates or licensors. Other names, logos, product and service
                names, designs and slogans that appear on the Services are the property of their respective owners, who
                may or may not be affiliated with, connected to, or sponsored by us.
                <br />
                <br />
                6.3 <u>Ownership of Modifications and Derivatives</u>. You acknowledge and agree that any modifications,
                improvements, or derivatives of the Services that you create or suggest, whether intentionally or
                inadvertently, shall be the sole and exclusive property of the Company. You hereby assign to the Company
                all right, title, and interest in any such modifications, improvements, or derivatives, without any
                right to compensation.
                <br />
                <br />
                6.4 <u>Ownership of Feedback and Ideas</u>. We welcome feedback, comments, suggestions, ideas,
                documents, and/or proposals for improvements to the Services (collectively, “Feedback”). By submitting
                any Feedback to the Company through any means, including but not limited to suggestion or feedback
                webpages, you acknowledge and agree that:
                <List $lowerAlpha>
                    <li>Your Feedback does not contain confidential or proprietary information;</li>
                    <li>
                        The Company is not under any obligation of confidentiality, express or implied, with respect to
                        the Feedback;
                    </li>
                    <li>
                        Any contribution of Feedback does not and will not give or grant you any right, title or
                        interest in the Services or in any such Feedback;
                    </li>
                    <li>All Feedback becomes the sole and exclusive property of the Company;</li>
                    <li>
                        The Company may use and disclose Feedback in any manner and for any purpose whatsoever without
                        further notice or compensation to you and without retention by you of any proprietary or other
                        right or claim;
                    </li>
                    <li>
                        The Company shall be entitled to use or disclose (or choose not to use or disclose) such
                        Feedback for any purpose, in any way, in any media worldwide;
                    </li>
                    <li>
                        The Company may have something similar to the Feedback already under consideration or in
                        development;
                    </li>
                    <li>
                        You are not entitled to any compensation or reimbursement of any kind from the Company under any
                        circumstances.
                    </li>
                </List>
                <br />
                6.5 <u>No Implied Rights</u>. Nothing in these Terms shall be construed as conferring any license to
                intellectual property rights, whether by estoppel, implication, or otherwise, except as expressly set
                forth herein.
                <br />
                <br />
                6.6 <u>Your Content License Grant</u>. In connection with your use of the Services, you may be able to
                post, upload, or submit content to be made available through the Services (<Bold>“Your Content”</Bold>).
                In order to operate the Service, we must obtain from you certain license rights in Your Content so that
                actions we take in operating the Service are not considered legal violations. Accordingly, by using the
                Service and uploading Your Content, you grant us a license to access, use, host, cache, store,
                reproduce, transmit, display, publish, distribute, and modify (for technical purposes, e.g., making sure
                content is viewable on smartphones as well as computers and other devices) Your Content to be able to
                operate, improve and provide the Services. You agree that these rights and licenses are royalty free,
                transferable, sub-licensable, worldwide and irrevocable (for so long as Your Content is stored with us),
                and include a right for us to make Your Content available to, and pass these rights along to, others
                with whom we have contractual relationships related to the provision of the Services, solely for the
                purpose of operating, improving and providing such Services, and to otherwise permit access to or
                disclose Your Content to third parties if we determine such access is necessary to comply with our legal
                obligations. As between the Company and you, the Company does not claim any ownership in Your Content.
                To the fullest extent permitted by applicable law, the Company reserves the right, and has absolute
                discretion, to remove, screen, edit, or delete any of Your Content at any time, for any reason, and
                without notice. By posting or submitting Your Content through the Services, you represent and warrant
                that you have, or have obtained, all rights, licenses, consents, permissions, power and/or authority
                necessary to grant the rights granted herein for Your Content. You agree that Your Content will not
                contain material subject to copyright or other proprietary rights, unless you have the necessary
                permission or are otherwise legally entitled to post the material and to grant us the license described
                above.
                <br />
                <br />
                6.7 <u>Notice of Infringement – DMCA (Copyright) Policy</u>
                <br />
                <br />
                If you believe that any text, graphics, photos, audio, videos or other materials or works uploaded,
                downloaded or appearing on the Services have been copied in a way that constitutes copyright
                infringement, you may submit a notification to our copyright agent in accordance with 17 USC 512(c) of
                the Digital Millennium Copyright Act (the <Bold>“DMCA”</Bold>), by providing the following information
                in writing:
                <List $lowerAlpha>
                    <li>identification of the copyrighted work that is claimed to be infringed;</li>
                    <li>
                        identification of the allegedly infringing material that is requested to be removed, including a
                        description of where it is located on the Service;
                    </li>
                    <li>
                        information for our copyright agent to contact you, such as an address, telephone number and
                        e-mail address;
                    </li>
                    <li>
                        a statement that you have a good faith belief that the identified, allegedly infringing use is
                        not authorized by the copyright owners, its agent or the law;
                    </li>
                    <li>
                        a statement that the information above is accurate, and under penalty of perjury, that you are
                        the copyright owner or the authorized person to act on behalf of the copyright owner; and
                    </li>
                    <li>
                        the physical or electronic signature of a person authorized to act on behalf of the owner of the
                        copyright or of an exclusive right that is allegedly infringed.
                    </li>
                </List>
                Notices of copyright infringement claims should be sent by mail to: Erth.AI Inc., Attn: Erth.AI
                Copyright Agent, 3450 Sacramento St., #122, San Francisco, CA 94118; or by e-mail to legal@erth.ai. It
                is our policy, in appropriate circumstances and at our discretion, to disable or terminate the accounts
                of users who repeatedly infringe copyrights or intellectual property rights of others.
                <br />
                <br />A user of the Services who has uploaded or posted materials identified as infringing as described
                above may supply a counter-notification pursuant to sections 512(g)(2) and (3) of the DMCA. When we
                receive a counter-notification, we may reinstate the posts or material in question, in our sole
                discretion. To file a counter-notification with us, you must provide a written communication (by fax or
                regular mail or by email) that sets forth all of the items required by sections 512(g)(2) and (3) of the
                DMCA. Please note that you will be liable for damages if you materially misrepresent that content or an
                activity is not infringing the copyrights of others.
            </Paragraph>

            <SectionTitle id="section7">7. THIRD-PARTY SERVICES</SectionTitle>
            <Paragraph>
                7.1 <u> Use of Third-Party Materials in the Services</u>. Certain Services may display, include or make
                available content, data, information, applications or materials from third parties (
                <Bold>“Third-Party Materials”</Bold>) or provide links to certain third-party websites. By using the
                Services, you acknowledge and agree that the Company is not responsible for examining or evaluating the
                content, accuracy, completeness, availability, timeliness, validity, copyright compliance, legality,
                decency, quality or any other aspect of such Third-Party Materials or websites or Third-Party Software.
                We do not warrant or endorse and do not assume and will not have any liability or responsibility to you
                or any other person for any third-party services, Third-Party Software, Third-Party Materials or
                third-party websites, or for any other materials, products, or services of third parties. Third-Party
                Materials and links to other websites are provided solely as a convenience to you.
                <br />
                <br />
                7.2 <u>Purchases of Third-Party Products</u>. You may be able to access certain third-party products or
                services through the Services. If you choose to access such third-party products or services, your use
                of those products or services is subject to the terms and conditions provided by the third party. The
                Company is not responsible for, and does not endorse, any features, content, advertising, products or
                other materials available through such third-party products or services. You assume sole responsibility
                for and assume all risk arising from your use of any such products or services
            </Paragraph>

            <SectionTitle id="section8">8. Disclaimers, Limitations of Liability and Indemnification</SectionTitle>
            <Paragraph>
                8.1 <u>Disclaimers</u>.
                <List $lowerAlpha>
                    <li>
                        Your access to and use of the Services are at your own risk. You understand and agree that the
                        Services are provided to you on an “AS IS” and “AS AVAILABLE” basis. Without limiting the
                        foregoing, to the maximum extent permitted under applicable law, the Company, its parents,
                        affiliates, related companies, officers, directors, employees, agents, representatives, partners
                        and licensors (the <Bold>“the Company Entities”</Bold>) DISCLAIM ALL WARRANTIES AND CONDITIONS,
                        WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                        NON-INFRINGEMENT. The Company Entities make no warranty or representation and disclaim all
                        responsibility and liability for: (a) the completeness, accuracy, availability, timeliness,
                        security or reliability of the Services; (b) any harm to your computer system, loss of data, or
                        other harm that results from your access to or use of the Services; (c) the operation or
                        compatibility with any other application or any particular system or device; and (d) whether the
                        Services will meet your requirements or be available on an uninterrupted, secure or error-free
                        basis; and (e) the deletion of, or the failure to store or transmit, Your Content and other
                        communications maintained by the Services. No advice or information, whether oral or written,
                        obtained from the Company Entities or through the Services, will create any warranty or
                        representation not expressly made herein.
                    </li>
                    <li>
                        THE LAWS OF CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE
                        EXCLUSION OR LIMITATION OF CERTAIN DAMAGES AS SET FORTH IN SECTION 8.2 BELOW. IF THESE LAWS
                        APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO
                        YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                    </li>
                    <li>
                        THE COMPANY ENTITIES TAKE NO RESPONSIBILITY AND ASSUME NO LIABILITY FOR ANY CONTENT THAT YOU,
                        ANOTHER USER, OR A THIRD PARTY CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH
                        OUR SERVICES.
                    </li>
                    <li>
                        YOU UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL,
                        MISLEADING, OR OTHERWISE INAPPROPRIATE, NONE OF WHICH THE COMPANY ENTITIES WILL BE RESPONSIBLE
                        FOR.
                    </li>
                </List>
                <br />
                8.2 <u>Limitations of Liability</u>. O THE EXTENT NOT PROHIBITED BY LAW, YOU AGREE THAT IN NO EVENT WILL
                THE COMPANY ENTITIES BE LIABLE (A) FOR DAMAGES OF ANY KIND, INCLUDING DIRECT, INDIRECT, SPECIAL,
                EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF
                SUBSTITUTE GOODS OR SERVICES, LOSS OF USE, DATA OR PROFITS, BUSINESS INTERRUPTION OR ANY OTHER DAMAGES
                OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE SERVICES), HOWEVER CAUSED AND
                UNDER ANY THEORY OF LIABILITY, WHETHER UNDER THESE TERMS OR OTHERWISE ARISING IN ANY WAY IN CONNECTION
                WITH THE SERVICES OR THESE TERMS AND WHETHER IN CONTRACT, STRICT LIABILITY OR TORT (INCLUDING NEGLIGENCE
                OR OTHERWISE) EVEN IF THE COMPANY ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, OR (B)
                FOR ANY OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER RESULTING FROM OR ARISING OUT OF OR IN CONNECTION WITH
                THESE TERMS OR THE DELIVERY, USE OR PERFORMANCE OF THE SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE
                EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION OR LIMITATION MAY
                NOT APPLY TO YOU. THE COMPANY ENTITIES’ TOTAL LIABILITY TO YOU FOR ANY DAMAGES FINALLY AWARDED SHALL NOT
                EXCEED THE GREATER OF ONE HUNDRED DOLLARS ($100.00), OR THE AMOUNT YOU PAID THE COMPANY ENTITIES, IF
                ANY, IN THE PAST SIX (6) MONTHS FOR THE SERVICES (OR OFFERINGS PURCHASED ON OR THROUGH THE SERVICES)
                GIVING RISE TO THE CLAIM. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF
                ITS ESSENTIAL PURPOSE.
                <br />
                <br />
                8.3 <u>Indemnification</u>. By entering into these Terms and accessing or using the Services, you agree
                that you shall defend, indemnify and hold the Company Entities harmless from and against any and all
                claims, costs, damages, losses, liabilities and expenses (including attorneys’ fees and costs) incurred
                by the Company Entities arising out of or in connection with:
                <List $lowerAlpha>
                    <li>your violation or breach of any term of these Terms or any applicable law or regulation;</li>
                    <li>your violation of any rights of any third party;</li>
                    <li>your access to or use or misuse of the Services;</li>
                    <li>any content you submit, post, or transmit through the Services, including Your Content;</li>
                    <li>your interaction with any other user of the Services; or</li>
                    <li>your negligence or wilful misconduct.</li>
                </List>
                <br />
                If you are obligated to indemnify any Company Entity hereunder, then you agree that Company (or, at its
                discretion, the applicable Company Entity) will have the right, in its sole discretion, to control any
                action or proceeding and to determine whether Company wishes to settle, and if so, on what terms. You
                agree to promptly notify the Company of any third-party claims, cooperate with the Company in defending
                such claims, and pay all fees, costs, and expenses associated with defending such claims (including, but
                not limited to, attorneys’ fees). You agree not to settle any matter with respect to which you are
                obligated to indemnity any Company Entity without the prior written consent of the Company. This
                indemnification obligation will survive the termination of these Terms and your use of the Services.
                <br />
                <br />
                8.4 <u>Basis of the Bargain</u>. The limitations and exclusions of liability and indemnification set out
                in this Section 8 form an essential basis of the bargain between you and the Company and shall survive
                termination.
            </Paragraph>

            <SectionTitle id="section9">9. ARBITRATION AND CLASS ACTION WAIVER</SectionTitle>
            <SectionTitle>
                9.1. PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR
                RIGHT TO FILE A LAWSUIT IN COURT AND TO HAVE A JURY HEAR YOUR CLAIMS. IT CONTAINS PROCEDURES FOR
                MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.
            </SectionTitle>
            <Paragraph>
                9.2 <u>Informal Process First</u>. You and the Company agree that in the event of any dispute, either
                party will first contact the other party and make a good faith sustained effort to resolve the dispute
                before resorting to more formal means of resolution, including without limitation, any court action,
                after first allowing the receiving party 30 days in which to respond. Both you and the Company agree
                that this dispute resolution procedure is a condition precedent which must be satisfied before
                initiating any arbitration against the other party.
                <br />
                <br />
                9.3 <u>Arbitration Agreement and Class Action Waiver</u>. After the informal dispute resolution process,
                any remaining dispute, controversy, or claim (collectively, <Bold>“Claim”</Bold>) relating in any way to
                the Company’s services and/or products, including the Services, and any use or access or lack of access
                thereto, will be resolved by arbitration, including threshold questions of arbitrability of the Claim.
                You and the Company agree that any Claim will be settled by final and binding arbitration, using the
                English language, administered by JAMS under its Comprehensive Arbitration Rules and Procedures (the{" "}
                <Bold>“JAMS Rules”</Bold>) then in effect (those rules are deemed to be incorporated by reference into
                this section, and as of the date of these Terms). Because your contract with the Company, these Terms,
                and this Arbitration Agreement concern interstate commerce, the Federal Arbitration Act (“FAA”) governs
                the arbitrability of all disputes. However, the arbitrator will apply applicable substantive law
                consistent with the FAA and the applicable statute of limitations or condition precedent to suit.{" "}
                <Bold>
                    Arbitration will be handled by a sole arbitrator in accordance with the JAMS Rules. Judgment on the
                    arbitration award may be entered in any court that has jurisdiction. Any arbitration under these
                    Terms will take place on an individual basis – class arbitrations and class actions are not
                    permitted. You understand that by agreeing to these Terms, you and the Company are each waiving the
                    right to trial by jury or to participate in a class action or class arbitration.
                </Bold>
                <br />
                <br />
                9.4 <u>Exceptions</u>. Notwithstanding the foregoing, you and the Company agree that the following types
                of disputes will be resolved in a court designated in Section 10.8 (Additional
                Provisions—Miscellaneous):
                <List $lowerAlpha>
                    <li>
                        disputes or claims within the jurisdiction of a small claims court in San Francisco, California
                        consistent with the jurisdictional and dollar limits that may apply, as long as it is brought
                        and maintained as an individual dispute and not as a class, representative, or consolidated
                        action or proceeding;
                    </li>
                    <li>
                        disputes or claims where the sole form of relief sought is injunctive relief (including public
                        injunctive relief); or
                    </li>
                    <li>intellectual property disputes.</li>
                </List>
                <br />
                9.5 <u>Costs of Arbitration</u>. Payment of all filing, administration, and arbitrator costs and
                expenses will be governed by the JAMS Rules, except that if you demonstrate that any such costs and
                expenses owed by you under those rules would be prohibitively more expensive than a court proceeding,
                the Company will pay the amount of any such costs and expenses that the arbitrator determines are
                necessary to prevent the arbitration from being prohibitively more expensive than a court proceeding
                (subject to possible reimbursement as set forth below).
                <br />
                <br />
                Fees and costs may be awarded as provided pursuant to applicable law. If the arbitrator finds that
                either the substance of your claim or the relief sought in the demand is frivolous or brought for an
                improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then
                the payment of all fees will be governed by the JAMS rules. In that case, you agree to reimburse the
                Company for all monies previously disbursed by it that are otherwise your obligation to pay under the
                applicable rules. If you prevail in the arbitration and are awarded an amount that is less than the last
                written settlement amount offered by the Company before the arbitrator was appointed, the Company will
                pay you the amount it offered in settlement. The arbitrator may make rulings and resolve disputes as to
                the payment and reimbursement of fees or expenses at any time during the proceeding and upon request
                from either party made within 14 days of the arbitrator’s ruling on the merits.
                <br />
                <br />
                <Bold>
                    9.6 <u>Opt-Out</u>. You have the right to opt-out and not be bound by the arbitration provisions set
                    forth in these Terms by sending written notice of your decision to opt-out to hello@erth.ai. The
                    notice must be sent to the Company within thirty (30) days of your first registering to use the
                    Services or agreeing to these Terms; otherwise you shall be bound to arbitrate disputes on a
                    non-class basis in accordance with these Terms. If you opt out of only the arbitration provisions,
                    and not also the class action waiver, the class action waiver still applies. You may not opt out of
                    only the class action waiver and not also the arbitration provisions. If you opt-out of these
                    arbitration provisions, the Company also will not be bound by them.
                </Bold>
                <br />
                <br />
                9.7{" "}
                <Bold>
                    <u>WAIVER OF RIGHT TO BRING CLASS ACTION AND REPRESENTATIVE CLAIMS</u>.
                </Bold>{" "}
                To the fullest extent permitted by applicable law, you and the Company each agree that any proceeding to
                resolve any dispute, claim, or controversy will be brought and conducted ONLY IN THE RESPECTIVE PARTY’S
                INDIVIDUAL CAPACITY AND NOT AS PART OF ANY CLASS (OR PURPORTED CLASS), CONSOLIDATED, MULTIPLE-PLAINTIFF,
                OR REPRESENTATIVE ACTION OR PROCEEDING (“CLASS ACTION”). You and the Company AGREE TO WAIVE THE RIGHT TO
                PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS ACTION. You and the Company EXPRESSLY WAIVE ANY
                ABILITY TO MAINTAIN A CLASS ACTION IN ANY FORUM. If the dispute is subject to arbitration, THE
                ARBITRATOR WILL NOT HAVE THE AUTHORITY TO COMBINE OR AGGREGATE CLAIMS, CONDUCT A CLASS ACTION, OR MAKE
                AN AWARD TO ANY PERSON OR ENTITY NOT A PARTY TO THE ARBITRATION. Further, you and the Company agree that
                the ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS FOR MORE THAN ONE PERSON’S CLAIMS, AND IT MAY NOT
                OTHERWISE PRESIDE OVER ANY FORM OF A CLASS ACTION. For the avoidance of doubt, however, you can seek
                public injunctive relief to the extent authorized by law and consistent with the Exceptions clause
                above.
                <br />
                <br />
                IF THIS CLASS ACTION WAIVER IS LIMITED, VOIDED, OR FOUND UNENFORCEABLE, THEN, UNLESS THE PARTIES
                MUTUALLY AGREE OTHERWISE, THE PARTIES’ AGREEMENT TO ARBITRATE SHALL BE NULL AND VOID WITH RESPECT TO
                SUCH PROCEEDING SO LONG AS THE PROCEEDING IS PERMITTED TO PROCEED AS A CLASS ACTION. If a court decides
                that the limitations of this paragraph are deemed invalid or unenforceable, any putative class, private
                attorney general, or consolidated or representative action must be brought in a court of proper
                jurisdiction and not in arbitration.
            </Paragraph>

            <SectionTitle id="section10">10. DISCORD APPs</SectionTitle>
            <Paragraph>
                10.1 <u>Incorporation of Discord Terms of Service and Policies</u>. Discord’s
                <a href="https://discord.com/terms" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                </a>
                ,
                <a href="https://discord.com/guidelines" target="_blank" rel="noopener noreferrer">
                    Community Guidelines
                </a>
                ,
                <a href="https://discord.com/privacy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </a>
                ,
                <a
                    href="https://support-dev.discord.com/hc/en-us/articles/8563934450327-Discord-Developer-Policy"
                    target="_blank"
                    rel="noopener noreferrer">
                    Developer Policy
                </a>
                , and{" "}
                <a
                    href="https://support-dev.discord.com/hc/en-us/articles/8562894815383-Discord-Developer-Terms-of-Service"
                    target="_blank"
                    rel="noopener noreferrer">
                    Developer Terms of Service
                </a>{" "}
                (and all other Discord terms and conditions that constitute “Terms” thereunder) (collectively, the
                <Bold>“Discord Terms”</Bold>) are incorporated into these Terms in their entirety to the same extent as
                if set forth in full in these Terms. Your access to and use of Apps made available through the Discord
                platform (collectively, the <Bold>“Discord Apps”</Bold>) are subject in all respects to the Discord
                Terms. In connection with your access to and use of the Discord Apps, you will abide in all respects
                with the Discord Terms and all applicable laws and regulations. Without limiting the generality of the
                immediately preceding sentence, in connection with your access to and use of the Discord Apps, you will
                not infringe or violate any third-party rights (including intellectual or other proprietary rights or
                rights of privacy or publicity) or act in a manner that is deceptive, unethical, false, misleading, or
                encourages or promotes illegal activity or infringement or violation of third-party rights.
                <br />
                <br />
                10.2 <u>No Discord Responsibility for Discord Apps</u>. Our relationship with respect to the Discord
                Apps and your access to and use thereof, including these Terms, is solely between you and us. Discord
                has no responsibility for the Discord Apps, or your access to and use thereof, of any kind whatsoever.
                Neither Discord nor any of affiliates, directors, officers, employees, or other users will have any
                liability to you of any kind whatsoever based upon, arising out of, or relating to the Discord Apps or
                your access to and use thereof.
                <br />
                <br />
                10.3 <u>Discord Terms Take Precedence</u>. These Terms do not supersede, modify, or replace the Discord
                Terms or your obligations thereunder in any respect. In the event of any conflict or inconsistency
                between these Terms and the Discord Terms, the applicable term or condition of the Discord Terms will
                supersede and take precedence over the conflicting or inconsistent term or condition of these Terms.
                <br />
                10.4 <u>Licenses, Permissions, and Other Rights</u>. You grant Discord and us all licenses, permissions,
                and other rights necessary for us and you to comply in full with our respective obligations under the
                Discord Terms (including those in Sections 3(a) (Use Of App Content And Your Application—License) and
                8(d) (Brand Features; Attribution—Promotional And Marketing Use) of{" "}
                <a
                    href="https://support-dev.discord.com/hc/en-us/articles/8562894815383-Discord-Developer-Terms-of-Service"
                    target="_blank"
                    rel="noopener noreferrer">
                    Discord’s Developer Terms of Service
                </a>
                ) and for Discord and us to operate the Discord Apps and any Games comprising a part thereof
                (collectively, the <Bold>“Discord Games”</Bold>) (including to display, distribute, and provide all
                information, data, and other content therein and to access, use, and otherwise process your API Data and
                other data (including Your Content)). Without limiting the generality of the immediately preceding
                sentence, you grant Discord and us a non-exclusive, transferable, sublicensable, royalty-free, worldwide
                licenses to, among other things:
                <List $lowerAlpha>
                    <li>
                        publicly display and perform, distribute, reproduce, modify, host, translate, store, and
                        otherwise use Your Content in connection with Discord operating, developing, and improving the
                        APIs and other Discord services and us operating, developing, and improving the Discord Apps and
                        the Discord Games; and
                    </li>
                    <li>
                        produce and distribute Your Content and incidental depictions (including screenshots, videos, or
                        other content) from Your Content, in the course of Discord promoting, marketing, or
                        demonstrating the APIs or other Discord services or the Discord Apps or the Discord Games
                        (including in Discord’s discovery surfaces).
                    </li>
                </List>
                <br />
                10.5 <u>Indemnification of Discord and Related Persons</u>. Unless prohibited by applicable law, you
                will defend (at Discord’s or our option), indemnify, and hold harmless Discord and us and our respective
                affiliates, directors, officers, employees, and other users, from and against all claims, liabilities,
                damages, losses, costs, fees (including legal and accounting fees), and expenses relating to:
                <List $lowerAlpha>
                    <li>
                        access to or use of Discord’s APIs or API Data or the Discord Apps or the Discord Games by you
                        (including any incidents of unauthorized access to or use of API Data);
                    </li>
                    <li>actual or alleged violation the Terms by you;</li>
                    <li>
                        actual or alleged infringement of any third-party right (including intellectual property or
                        other proprietary rights or rights to privacy or publicity) by you; or
                    </li>
                    <li>
                        any content, data, technology, information, or materials provided or contributed by or through
                        you.
                    </li>
                </List>
            </Paragraph>

            <SectionTitle id="section11">11. TERMINATION</SectionTitle>
            <Paragraph>
                11.1 <u>Termination by You</u>. You may terminate your Account and your right to access and use the
                Services at any time and for any reason by contacting us at hello@erth.ai.
                <br />
                <br />
                11.2 <u>Termination by Us</u>. We reserve the right to suspend, disable, or terminate your Account and
                your right to access and use the Services at any time, for any reason, and without prior notice. Reasons
                for Account termination may include, but are not limited to:
                <List $lowerAlpha>
                    <li>Violation of these Terms or any other policies or guidelines referenced herein;</li>
                    <li>Engaging in fraudulent, illegal, or abusive activities;</li>
                    <li>Failure to pay any fees or charges associated with your use of the Services;</li>
                    <li>Extended periods of Account inactivity;</li>
                    <li>Upon receipt of requests from law enforcement or other government agencies;</li>
                    <li>Unexpected technical or security issues;</li>
                    <li>Participation by you in suspicious or fraudulent activities.</li>
                </List>
                <br />
                11.3 <u>Automatic Termination</u>. If you breach any of the provisions of these Terms, all licenses
                granted by the Company will terminate automatically.
                <br />
                <br />
                11.4 <u>Effects of Termination</u>. Upon termination of your Account:
                <List $lowerAlpha>
                    <li>Your right to access and use the Services will immediately cease;</li>
                    <li>
                        We may delete or deactivate your Account and all related information and files in your Account;
                    </li>
                    <li>We may bar any further access to such files or the Services;</li>
                    <li>
                        You will lose access to any Virtual Items or Virtual Currency associated with your Account,
                        without any right to compensation;
                    </li>
                </List>
                We reserve the right to maintain information as required for legal or operational purposes.
                <br />
                <br />
                11.5 <u>Prohibition of Re-registration</u>. If we delete your Account for any suspected breach of these
                Terms, you are prohibited from re-registering for the Services under a different name.
                <br />
                <br />
                11.6 <u>Survival</u>. All provisions of these Terms which by their nature should survive termination
                shall survive termination, including, without limitation, ownership provisions, warranty disclaimers,
                indemnity and limitations of liability.
                <br />
                <br />
                11.7 <u>No Liability</u>. We will not be liable to you or any third party for any termination of your
                access to the Services or deletion of your Account or content within your Account.
                <br />
                <br />
                11.8 <u>Other Rights and Remedies</u>. Termination will not limit any of the Company’s other rights or
                remedies at law or in equity.
                <br />
                <br />
            </Paragraph>

            <SectionTitle id="section12">12. Additional Provisions</SectionTitle>
            <Paragraph>
                12.1 <u>SMS Messaging and Phone Calls</u>. Certain portions of the Services may allow us to contact you
                via telephone or text messages. You agree that the Company may contact you via telephone or text
                messages (including by an automatic telephone dialling system) at any of the phone numbers provided by
                you or on your behalf in connection with your use of the Services, including for marketing purposes. You
                understand that you are not required to provide this consent as a condition of purchasing any Offerings.
                You also understand that you may opt out of receiving text messages from us at any time, either by
                texting the word “STOP” using the mobile device that is receiving the messages, or by contacting
                hello@erth.ai. If you do not choose to opt out, we may contact you as outlined in our Privacy Policy.
                <br />
                <br />
                12.2 <u>Updating These Terms</u>. We may modify these Terms from time to time in which case we will
                update the “Last Revised” date at the top of these Terms. If we make changes that are material, we will
                use reasonable efforts to attempt to notify you, such as by placing a prominent notice on the Websites
                or Apps and/or by sending you an e-mail. However, it is your sole responsibility to review these Terms
                from time to time to view any such changes. The updated Terms will be effective as of the time of
                posting, or such later date as may be specified in the updated Terms. Your continued access or use of
                the Services after the modifications have become effective will be deemed your acceptance of the
                modified Terms.
                <br />
                <br />
                12.3 <u>Additional Terms</u>. Our Services are diverse, so sometimes additional terms or product
                requirements (including age requirements) may apply. These additional terms will be available with the
                relevant Services, and those additional terms become part of your agreement with us if you use those
                Services.
                <br />
                <br />
                12.4 <u>Modifications to the Services</u>. The Company has the right, at any time and without liability,
                to modify, alter, update or eliminate the features, navigation, appearance, functionality and other
                elements of the Services, and any aspect, portion or feature thereof, including to modify the
                Third-Party Software you are able to access via the Services.
                <br />
                <br />
                12.5 <u>Injunctive Relief</u>. You agree that a breach of these Terms will cause irreparable injury to
                the Company for which monetary damages would not be an adequate remedy and the Company shall be entitled
                to equitable relief in addition to any remedies it may have hereunder or at law without a bond, other
                security or proof of damages.
                <br />
                <br />
                12.6 <u>California Residents</u>. If you are a California resident, in accordance with Cal. Civ. Code §
                1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services
                of the California Department of Consumer Affairs by contacting them in writing at 1625 North Market
                Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800) 952-5210.
                <br />
                <br />
                12.7 <u>Export Laws</u>. You agree that you will not export or re-export, directly or indirectly, the
                Services and/or other information or materials provided by the Company hereunder, to any country for
                which the United States or any other relevant jurisdiction requires any export license or other
                governmental approval at the time of export without first obtaining such license or approval. In
                particular, but without limitation, the Services may not be exported or re-exported (a) into any U.S.
                embargoed countries or any country that has been designated by the U.S. Government as a “terrorist
                supporting” country, or (b) to anyone listed on any U.S. Government list of prohibited or restricted
                parties, including the U.S. Treasury Department’s list of Specially Designated Nationals or the U.S.
                Department of Commerce Denied Person’s List or Entity List. By using the Services, you represent and
                warrant that you are not located in any such country or on any such list. You are responsible for and
                hereby agree to comply at your sole expense with all applicable United States export laws and
                regulations.
                <br />
                <br />
                12.8 <u>Miscellaneous</u>. If any provision of these Terms shall be unlawful, void or for any reason
                unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the
                validity and enforceability of any remaining provisions. These Terms and the licenses granted hereunder
                may be assigned by the Company but may not be assigned by you without the prior express written consent
                of the Company. No waiver by either party of any breach or default hereunder shall be deemed to be a
                waiver of any preceding or subsequent breach or default. The section headings used herein are for
                reference only and shall not be read to have any legal effect. The Services are operated by us in the
                United States. Those who choose to access the Services from locations outside the United States do so at
                their own initiative and are responsible for compliance with applicable local laws. These Terms are
                governed by the laws of the State of Delaware, without regard to conflict of laws rules, and the proper
                venue for any disputes arising out of or relating to any of the same will be the arbitration venue set
                forth in Section 9, or if arbitration does not apply, then the state and federal courts located in San
                Francisco County, California.
                <br />
                <br />
                12.9 <u>How to Contact Us</u>. You may contact us regarding the Services or these Terms by e-mail at
                hello@erth.ai.
                <br />
                <br />
            </Paragraph>
        </>
    );
};

const TOC = styled.ul`
    list-style: decimal;
    padding: 0;
    margin-bottom: 40px;
    padding-left: 30px;

    * {
        color: white !important;
        text-transform: uppercase;
    }
`;

const TOCItem = styled.li`
    margin-bottom: 5px;
    font-weight: 500;
    cursor: pointer;
    color: #007bff;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;
