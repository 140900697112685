import {
    BoxData,
    CapsuleData,
    COLLISION_MAP,
    CollisionFlag,
    CollisionRegistration,
    ConcaveHullData,
    ConvexHullData,
    IDispatcher,
    IPhysics,
    IPlayerOptions,
    ModelData,
    SphereData,
    TerrainData,
} from "../physics/common/types";
import {IPhysics as PhysicsConfig} from "../editor/assets/v2/types/physics";
import {
    AnimationClip,
    AnimationMixer,
    Clock,
    MathUtils,
    Object3D,
    Quaternion,
    QuaternionLike,
    Scene,
    Vector3,
    Vector3Like,
} from "three";
import * as Colyseus from "colyseus.js";
import {RoomAvailable} from "colyseus.js/lib/Room";
import {GameObject, GameRoomState, Player} from "./GameRoomState";
import PhysicsBase from "../physics/PhysicsBase";
import {PHYSICS_EVENTS} from "../physics/common/events";
import ServerObject from "../serialization/core/ServerObject";
import global from "../global";
import {PhysicsUtil} from "../physics/PhysicsUtil";
import {AnimationAction} from "three/src/animation/AnimationAction";
import {REACT_APP_MULTIPLAYER_SERVER_URL} from "../v2/pages/constants";
import Application from "../Application";
import {IFRAME_MESSAGES} from "../types/editor";
import PlayerQueueView from "../player/component/PlayerQueueView";
import {createLiveKitRoom} from "../api/livekit";
import {Room} from "colyseus.js";
import EventBus from "../../src/behaviors/event/EventBus";

type UpdateData = {
    uuid: string;
    position: {x: number; y: number; z: number};
    rotation: {x: number; y: number; z: number; w: number};
};

export default class MultiplayerProxy extends PhysicsBase implements IPhysics {
    id: string = MathUtils.generateUUID();
    localPlayerUuid = "";
    sceneId: string;
    scene: Scene;
    client?: Colyseus.Client;
    room?: Colyseus.Room<GameRoomState>;
    dispatcher: IDispatcher;
    isReady = false;
    objectUpdates: Map<string, UpdateData> = new Map<string, UpdateData>();
    playerObjects = new Map<string, Object3D>();
    queueView: PlayerQueueView | null = null;

    //animations
    playerAnimations = new Map<string, Map<string, AnimationAction>>();
    playerMixers = new Map<string, AnimationMixer>();

    clock = new Clock();

    constructor(sceneId: string, scene: Scene, dispatcher: IDispatcher) {
        super(true, false, false);
        this.sceneId = sceneId;
        this.scene = scene;
        this.dispatcher = dispatcher;
        this.queueView = new PlayerQueueView(global.app);
    }

    start(): Promise<IPhysics> {
        this.clock.start();
        console.log("MP: Colyseus server: " + REACT_APP_MULTIPLAYER_SERVER_URL);
        this.client = new Colyseus.Client(REACT_APP_MULTIPLAYER_SERVER_URL); //FIXME: read from config
        return new Promise<IPhysics>((resolve, reject) => {
            this.client
                ?.getAvailableRooms(this.sceneId)
                .then((rooms: RoomAvailable[]) => {
                    console.log("MP: rooms available: ", rooms);

                    const hasRoom = rooms.length > 0;
                    const roomWithSpace = rooms.find(room => room.clients < room.maxClients);

                    if (!hasRoom) {
                        console.log("MP: No rooms available. Creating a new room.");
                        this.createRoom(resolve, reject);
                    } else if (!roomWithSpace) {
                        console.log("MP: All rooms are full. Adding to waiting list.");
                        this.addToWaitingList();
                    } else if (roomWithSpace) {
                        console.log("MP: Joining room with space.");
                        this.joinRoom(roomWithSpace.roomId, resolve, reject);
                    }
                })
                .catch(err => {
                    console.error("MP: ERROR", err);
                    window.parent.postMessage(IFRAME_MESSAGES.GAME_MULTIPLAYER_ERROR, "*");
                    reject("Connection to multiplayer server failed: " + err);
                });
        });
    }

    createRoom(resolve: (value: IPhysics | PromiseLike<IPhysics>) => void, reject: (reason?: any) => void) {
        this.client
            ?.create<GameRoomState>(this.sceneId, {
                id: this.id,
                name: "stem-studio-player",
            })
            .then(async room => {
                this.setupRoom(room, resolve, reject);
                this.queueView?.dispose();
                if (global?.app?.editor) {
                    await createLiveKitRoom(room.roomId, global.app.editor.username);
                    global.app.editor.roomId = room.roomId;
                }
                this.addPhysicsObjectsToServer();
            })
            .catch(e => {
                console.log("MP: CREATE ERROR", e);
                reject(e);
            });
    }

    joinRoom(
        roomId: string,
        resolve: (value: IPhysics | PromiseLike<IPhysics>) => void,
        reject: (reason?: any) => void,
    ) {
        this.client
            ?.joinById<GameRoomState>(roomId, {
                id: this.id,
                name: "stem-studio-player",
            })
            .then(room => {
                this.setupRoom(room, resolve, reject);
                if (global?.app?.editor) {
                    global.app.editor.roomId = roomId;
                }
            })
            .catch(e => {
                console.log("MP: JOIN ERROR", e);
                reject(e);
            });
    }

    private addPhysicsObjectsToServer() {
        this.scene.traverse(obj => {
            if (!obj.userData.isStemObject || 
                !obj.userData.physics ||
                 obj.userData.physics.enabled === false || 
                 obj.userData.player
            ) {
                return;
            }

            PhysicsUtil.addObjectShapeToPhysics(obj, this);
        });
    }

    setupRoom(
        room: Room<GameRoomState>,
        resolve: (value: IPhysics | PromiseLike<IPhysics>) => void,
        reject: (reason?: any) => void,
    ) {
        console.log("MP: joined room: ", room.sessionId, room.name);

        //OBJECT UPDATES
        room.state.objects.onAdd((object, key) => {
            //instantiate object from template if needed
            if (object.template) {
                this.cloneObject(object);
            }
            //FIXME: switch to primitive types to avoid double update
            object.position.onChange(() => {
                this.addUpdateData(object.uuid, object.position, object.quaternion);
            });
            object.quaternion.onChange(() => {
                this.addUpdateData(object.uuid, object.position, object.quaternion);
            });
        });
        room.state.objects.onRemove((object, key) => {
            //FIXME: optimize object lookup
            console.log("MP.start.objects.onRemove: " + object.uuid);
            let sceneObject = this.scene.getObjectByProperty("uuid", object.uuid);
            if (sceneObject) {
                console.log("this.scene.remove(sceneObject)", sceneObject);
                this.scene.remove(sceneObject);
            } else {
                console.warn("MP.start.objects.onRemove: object not found in the scene: " + object.uuid, this.scene);
            }
        });

        //PLAYER UPDATES
        room.state.players.onAdd((player, id) => {
            console.log("MP: players.onAdd", player);
            if (!player) return;
            if (player.uuid) {
                this.addPlayer(player);
            } else {
                //wait for uuid to be assigned
                player.listen("uuid", (val, pval) => {
                    console.log("MP: players.onAdd.listenUuid", player);
                    this.addPlayer(player);
                });
            }
            //listen for animation changes
            player.listen("animation", (val, prevVal) => {
                console.log("MP: players.onAdd.listenAnimation: " + val + " <= " + prevVal);
                this.onAnimationChanged(val, prevVal, player);
            });
        });
        room.state.players.onRemove((player, id) => {
            console.log("MP: players.onRemove: " + player.uuid);
            //scene object is removed in objects.onRemove
            this.playerObjects.delete(player.uuid);
            this.playerAnimations.delete(player.uuid);
            this.playerMixers.delete(player.uuid);
        });

        //READY FLAG
        room.state.listen("ready", (val, prevVal) => {
            if (val) {
                this.isReady = true;
                this.dispatcher.onReady();
                resolve(this);
            }
        });
        this.room = room;
        if (global.app) {
            global.app.room = this.room;
        }
    }

    private addToWaitingList() {
        console.log("Client added to waiting list.");
        this.queueView?.show();
    }

    private addUpdateData(uuid: string, position: Vector3Like, rotation: QuaternionLike) {
        const updateData = {
            uuid: uuid,
            position: {
                x: position.x,
                y: position.y,
                z: position.z,
            },
            rotation: {
                x: rotation.x,
                y: rotation.y,
                z: rotation.z,
                w: rotation.w,
            },
        };

        this.objectUpdates.set(uuid, updateData);
    }

    initializeAnimations(playerObject: Object3D) {
        let animations = (
            playerObject._obj && playerObject._obj.animations ? playerObject._obj.animations : []
        ) as AnimationClip[];
        if (!animations.length) return;

        let mixer = new AnimationMixer(playerObject);
        this.playerMixers.set(playerObject.uuid, mixer);

        const actions = new Map<string, AnimationAction>();
        for (let animation of animations) {
            actions.set(animation.name, mixer.clipAction(animation));
        }
        this.playerAnimations.set(playerObject.uuid, actions);
    }

    onAnimationChanged(currentAnimation: string, previousAnimation: string, player: Player) {
        if (!player || player.id === this.id) return; //local player
        let playerAnimations = this.playerAnimations.get(player.uuid);
        if (!playerAnimations) {
            console.log("onAnimationChanged: no animations", player);
            return;
        }
        let currentAction = playerAnimations.get(currentAnimation);
        let previousAction = playerAnimations.get(previousAnimation);
        if (previousAction) {
            previousAction.fadeOut(0.5); //TODO add to props
            if (currentAction) {
                currentAction.reset().fadeIn(0.5).play(); //TODO add to props
            }
        } else if (currentAction) {
            currentAction.play();
        } else {
            console.log("No action for current animation: " + currentAnimation);
        }
    }

    cloneObject(objectState: GameObject) {
        //check if object is already added to the scene
        if (this.scene.getObjectByProperty("uuid", objectState.uuid)) {
            return;
        }

        //create object from template and add it to the scene
        let templateObject = this.scene.getObjectByProperty("uuid", objectState.template);
        if (!templateObject) {
            console.warn("Template object not found on the scene", objectState);
            return;
        }
        let object = templateObject.clone(true);
        object.uuid = objectState.uuid;
        object.position.set(objectState.position.x, objectState.position.y, objectState.position.z);
        object.quaternion.set(
            objectState.quaternion.x,
            objectState.quaternion.y,
            objectState.quaternion.z,
            objectState.quaternion.w,
        );
        this.scene.add(object);

        //add object to the update cache
        let physicsConfig = templateObject.userData.physics as PhysicsConfig;
        this.addObject(object.uuid, physicsConfig.mass, COLLISION_MAP.get(physicsConfig.ctype)!, object);
    }

    //FIXME: re-use clone object
    clonePlayerObject(originUuid: string, playerUuid: string | null = null): Promise<Object3D> {
        return new Promise((resolve, reject) => {
            let origin = this.scene.getObjectByProperty("uuid", originUuid);
            if (origin) {
                //if prefab is removed from the world via regular remove call, then we also remove it from the scene in objects.onRemove
                this.removePrefab(origin.uuid); //remove player prefab from physics world
                //hide character prefab
                origin.traverse(child => {
                    child.visible = false;
                });
                
                let converter = new ServerObject();
                let json = converter.toJSON(origin);
                //FIXME: clone template instead
                converter
                    .fromJSON(json, {server: (global.app! as Application).options.server}, {}, false)
                    .then(playerObject => {
                        if (playerUuid) playerObject.uuid = playerUuid; //set new object uuid to the remote player uuid
                        playerObject.visible = true;
                        playerObject.removeFromParent();
                        //TODO: use spawn points and set new position
                        this.scene.add(playerObject);
                        if (playerUuid) {
                            //remote player - already added to the world, just add it to the local update cache
                            const physicsConfig = playerObject.userData.physics;
                            this.addObject(playerObject.uuid, physicsConfig.mass, CollisionFlag.DYNAMIC, playerObject);
                        } else {
                            //local player - new object, add to the world
                            PhysicsUtil.addObjectShapeToPhysics(playerObject, this);
                            // set target for character updater behavior
                            EventBus.instance.send("CharacterBehavior:setTarget", {
                                uuid: origin.uuid,
                                target: playerObject,
                            });
                        }
                        this.playerObjects.set(playerObject.uuid, playerObject);
                        resolve(playerObject);
                    });
            } else {
                console.error("MP.addPlayer: player origin is not in the scene: " + originUuid);
                reject("player origin is not in the scene: " + originUuid);
            }
        });
    }

    addPlayer(player: Player) {
        console.log(
            "MP.addPlayer: processing player state change: self=" +
                (player.id === this.id) +
                " uuid=" +
                player.uuid +
                " origin=" +
                player.origin,
        );
        if (!player || player.id === this.id) return; //local player
        if (player.origin) {
            let sceneObject = this.scene.getObjectByProperty("uuid", player.uuid);
            if (sceneObject) {
                console.warn("MP.addPlayer: player object is already added to the scene: " + player.uuid);
                return;
            }
            //clone player object
            this.clonePlayerObject(player.origin, player.uuid)
                .then(playerObject => {
                    playerObject.name = playerObject.name + "-mp-" + player.name;
                    //TODO: use spawn points to set the position
                    this.initializeAnimations(playerObject);
                })
                .catch(err => {
                    console.error("Failed to clone player object: " + err, player);
                });
        }
    }

    //iPhysics impl
    simulate(): void {
        let delta = this.clock.getDelta();

        this.dispatchUpdateData(delta);

        for (let mixer of this.playerMixers.values()) {
            mixer.update(delta);
        }
    }

    private dispatchUpdateData(delta: number) {
        this.objectUpdates.forEach((data, uuid) => {
            this.dispatcher.onBodyUpdate(
                uuid,
                {
                    x: data.position.x,
                    y: data.position.y,
                    z: data.position.z,
                } as Vector3,
                {
                    x: data.rotation.x,
                    y: data.rotation.y,
                    z: data.rotation.z,
                    w: data.rotation.w,
                } as Quaternion,
                delta,
                {},
            );
        });
        this.objectUpdates.clear();
    }

    setCurrentAnimation(uuid: string, animation: string): void {
        this.room?.send(PHYSICS_EVENTS.ANIMATION.SET, {
            uuid: uuid,
            animation: animation,
        });
    }

    terminate(): void {
        //client can't be disconnected
        this.room?.leave().then(num => {
            console.log("MP: client left the room: " + num);
        });
        this.clock.stop();
    }

    addBox(object: Object3D, data: BoxData): void {
        this.room?.send(PHYSICS_EVENTS.ADD.BOX, {
            uuid: object.uuid,
            data: data,
        });
        super.addObject(object.uuid, data.mass, CollisionFlag.DYNAMIC, object);
    }

    addConcaveHull(object: Object3D, data: ConcaveHullData): void {
        this.room?.send(PHYSICS_EVENTS.ADD.CONCAVEHULL, {
            uuid: object.uuid,
            data: data,
        });
        super.addObject(object.uuid, data.mass, CollisionFlag.DYNAMIC, object);
    }

    addConvexHull(object: Object3D, data: ConvexHullData): void {
        this.room?.send(PHYSICS_EVENTS.ADD.CONVEXHULL, {
            uuid: object.uuid,
            data: data,
        });
        super.addObject(object.uuid, data.mass, CollisionFlag.DYNAMIC, object);
    }

    addModel(object: Object3D, data: ModelData): void {
        //TODO: send event to the room
        super.addObject(object.uuid, data.mass, CollisionFlag.DYNAMIC, object);
    }

    addSphere(object: Object3D, data: SphereData): void {
        this.room?.send(PHYSICS_EVENTS.ADD.SPHERE, {
            uuid: object.uuid,
            data: data,
        });
        super.addObject(object.uuid, data.mass, CollisionFlag.DYNAMIC, object);
    }

    addCapsuleShape(object: Object3D, data: CapsuleData): void {
        this.room?.send(PHYSICS_EVENTS.ADD.CAPSULE, {
            uuid: object.uuid,
            data: data,
        });
        super.addObject(object.uuid, data.mass, CollisionFlag.DYNAMIC, object);
    }

    setOrigin(uuid: string, position: Vector3): void {
        this.room?.send(PHYSICS_EVENTS.SET.ORIGIN, {
            uuid: uuid,
            position: {x: position.x, y: position.y, z: position.z},
        });
    }

    setRotation(uuid: string, quaternion: Quaternion): void {
        this.room?.send(PHYSICS_EVENTS.SET.ROTATION, {
            uuid: uuid,
            rotation: {
                x: quaternion.x,
                y: quaternion.y,
                z: quaternion.z,
                w: quaternion.w,
            },
        });
    }

    addPlayerObject(modelUuid: string, useController: boolean, options?: IPlayerOptions): Promise<Object3D> {
        return new Promise((resolve, reject) => {
            return this.clonePlayerObject(modelUuid).then(player => {
                this.room?.send(PHYSICS_EVENTS.PLAYER.ADD, {
                    uuid: player.uuid,
                    controller: useController,
                    origin: modelUuid,
                });
                resolve(player);
            });
        });
    }

    removePlayerObject(uuid: string): void {
        this.room?.send(PHYSICS_EVENTS.PLAYER.REMOVE, {uuid: uuid});
    }

    movePlayerObject(uuid: string, walkDirection: Vector3, jump: boolean): void {
        this.room?.send(PHYSICS_EVENTS.PLAYER.MOVE, {
            uuid: uuid,
            direction: walkDirection,
            jump: jump,
        });
    }

    setPlayerPosition(uuid: string, position: Vector3): void {
        this.room?.send(PHYSICS_EVENTS.PLAYER.SET_POSITION, {
            uuid: uuid,
            position: position,
        });
    }

    remove(uuid: string): void {
        this.room?.send(PHYSICS_EVENTS.REMOVE.RIGID_BODY, {
            uuid: uuid,
            physics_only: false,
        });
    }

    removePrefab(uuid: string) {
        this.room?.send(PHYSICS_EVENTS.REMOVE.RIGID_BODY, {
            uuid: uuid,
            physics_only: true,
        });
    }

    setLinearVelocity(uuid: string, velocity: Vector3): void {
        this.room?.send(PHYSICS_EVENTS.SET.LINEAR_VELOCITY, {
            uuid: uuid,
            velocity: {x: velocity.x, y: velocity.y, z: velocity.z},
        });
    }

    //the rest is not needed

    addCollidableObject(uuid: string): void {}

    addTerrain(object: Object3D, data: TerrainData): void {}

    applyCentralImpulse(uuid: string, impulse: Vector3): void {}

    detectCollisionsForObject(uuid: string, registration: CollisionRegistration, enable: boolean): void {}

    initDebug(): Object3D {
        return null;
    }

    removeCollidableObject(uuid: string): void {}

    applyImpulseToPlayer(uuid: string, impulse: Vector3): void {}

    addotsShiftVector(otsShiftVector: Vector3): void {}
}
