import styled from "styled-components";
import {regularFont} from "../../../assets/style";

export const Container = styled.div`
    position: relative;
    background-color: var(--theme-container-main-dark);
`;

export const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    overflow: hidden auto;
    color: #fff;
    padding: 20px 0 0;

    .react-multi-carousel-list {
        z-index: 2;
    }

    .react-multi-carousel-item:last-child {
        padding-right: 20px;
    }

    .react-multi-carousel-item {
        opacity: 0.5;
        transition: opacity 0.5s ease;
    }

    .react-multi-carousel-item--active {
        opacity: 1;
    }
`;

export const AllGamesWrapper = styled.div`
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 40px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 4px;

    .loadeMoreBtn {
        margin: 0 auto;
    }
`;

export const GamesSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 16px;
    width: 100%;
    margin-top: 20px;
`;

export const StyledGamesList = styled.div<{$allGames?: boolean}>`
    width: 100%;
    margin: 0 auto;

    .carousel-item {
        &:first-of-type {
            margin-left: 16px;
        }
    }

    .carousel-padding {
        padding-right: 20px;
    }

    .react-multiple-carousel__arrow {
        background: var(--theme-grey-bg-secondary);
        min-width: 40px;
        min-height: 40px;
        top: 50%;
        transform: translateY(-100%);
    }
    .react-multiple-carousel__arrow::before {
        font-size: 13px;
    }

    .react-multiple-carousel__arrow--right {
        right: calc(2% + 1px);
    }
    .react-multiple-carousel__arrow--left {
        left: calc(2% + 1px);
    }

    .textContainer {
        padding-right: 0;
    }

    ${({$allGames}) =>
        $allGames &&
        `
    display: grid;
    grid-template-columns: repeat(4,1fr);
    align-items: start;
    justify-items: start;
    gap: 20px;
    padding: 0 20px;
    @media only screen and (max-width: 1023px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
    `}
`;

export const SectionTitle = styled.div`
    ${regularFont("l")};
    font-weight: var(--theme-font-bold);
    width: 100%;
    margin-left: 20px;

    .more {
        margin-left: 8px;
        ${regularFont("xs")};
        font-weight: var(--theme-font-medium);
        color: var(--theme-homepage-link-color);
        cursor: pointer;
    }
`;
