import styled from "styled-components";
import {buttonReset, flexCenter, regularFont} from "../../../../assets/style";

export const Container = styled.div`
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
    width: 224px;
    height: 48px;
    padding: 8px;
    ${flexCenter};
    column-gap: 8px;
    border-radius: 16px;
    border: 1px solid #ffffff1a;
    background: var(--theme-container-minor-dark);
`;

export const ActionButton = styled.button<{$isSelected?: boolean; $isBlue?: boolean; $isPink?: boolean}>`
    ${buttonReset};
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: transparent;

    ${({$isPink}) => $isPink && "background: #C702C7;"}
    ${({$isBlue}) => $isBlue && "background: var(--theme-container-main-blue);"}
    ${({$isSelected}) => $isSelected && "background: var(--theme-grey-bg-secondary-button);"}

    &:disabled {
        cursor: not-allowed !important;
    }

    ${({$isPink}) => $isPink && "border-top: 1px solid #E90EDE;"}
    ${({$isBlue}) => $isBlue && "border-bottom: 1px solid var(--theme-container-main-blue-border);"}
    img {
        width: 100%;
        height: 100%;
    }
`;

export const InputWrapper = styled.div`
    position: relative;
    width: 69px;
    height: 32px;
    .zoomInput {
        background-color: var(--theme-grey-bg);
        color: #fff;
        width: 100%;
        padding-left: 30px;
        height: 32px;
    }

    .zoomIcon {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        z-index: 1;
    }

    .percentage {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        ${regularFont("xxs")};
    }
`;

export const Separator = styled.div`
    width: 1px;
    height: 48px;
    background: var(--theme-container-divider);
`;
