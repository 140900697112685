import React, {useRef} from "react";
import {useOnClickOutside} from "usehooks-ts";
import {Oval} from "react-loader-spinner";
import {Container, MenuItem, Overlay} from "./UserMenu.style";
import {ROUTES} from "../../../../../AppRouter";

export enum USER_MENU_ITEM {
    SETTINGS = "Account Settings",
    AVATAR_CREATOR = "Avatar Creator",
}

interface Props {
    close: () => void;
    userMenuButtonRef: React.MutableRefObject<HTMLButtonElement | SVGSVGElement | null>;
    showAvatarCreator: () => void;
    isLoading: boolean;
}

export const UserMenu = ({close, userMenuButtonRef, showAvatarCreator, isLoading}: Props) => {
    const userMenuRef = useRef<HTMLDivElement | null>(null);
    const menuListItems = Object.values(USER_MENU_ITEM);
    const checkboxRef = useRef<any>(null);

    useOnClickOutside(
        [userMenuRef as React.MutableRefObject<HTMLElement>, userMenuButtonRef as React.MutableRefObject<HTMLElement>],
        close,
    );

    const handleUserMenuItemClick = (item: USER_MENU_ITEM) => {
        switch (item) {
            case USER_MENU_ITEM.SETTINGS:
                window.open(ROUTES.SETTINGS, "_blank");
                break;
            case USER_MENU_ITEM.AVATAR_CREATOR:
                showAvatarCreator();
                close();
                break;

            default:
                break;
        }
    };

    const handleMenuItemClick = (event: any, item: USER_MENU_ITEM) => {
        if (checkboxRef.current && checkboxRef.current.contains(event.target)) {
            return;
        }

        handleUserMenuItemClick(item as USER_MENU_ITEM);
    };

    return (
        <Container ref={userMenuRef}>
            {menuListItems.map((item, index) => {
                return (
                    <MenuItem key={index} onClick={e => handleMenuItemClick(e, item)}>
                        {item}
                    </MenuItem>
                );
            })}
            {isLoading && (
                <Overlay>
                    <Oval
                        visible
                        height="40"
                        width="40"
                        color="#0284c7"
                        secondaryColor="#333"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass="loaderWrapper"
                    />
                </Overlay>
            )}
        </Container>
    );
};
