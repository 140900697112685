import {useEffect, useState} from "react";
import {
    HarvestBehaviorInterface,
    RESPAWN_TYPES,
    HarvestInitiatorType,
    RespawnType,
    HARVEST_TYPES,
} from "../../../../../types/editor";
import global from "../../../../../global";
import HarvestBehaviorConverter from "../../../../../serialization/behaviours/HarvestBehaviorConverter";
import {Separator} from "../common/Separator";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {NumericInputRow} from "../common/NumericInputRow";
import {StyledButton} from "../../common/StyledButton";
import {PanelSectionTitleSecondary} from "../RightPanel.style";
import {ContentItem} from "../panels/GameSettings/GameSettings.style";
import {UploadField} from "../../common/UploadField/UploadField";
import {ResourcesUtils} from "../../../../../utils/ResourcesUtils";
import {BasicCombobox} from "../../common/BasicCombobox";

type Props = {
    behavior: HarvestBehaviorInterface;
};

export const HarvestBehavior = ({behavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behaviorState, setBehaviorState] = useState(behavior);
    const [availableObjects, setAvailableObjects] = useState<any[]>([]);
    const targetBehavior = HarvestBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    const handleInputChange = (value: any, name: keyof HarvestBehaviorInterface) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehaviorState({...targetBehavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleharvestInitiatorTypeChange = (value: HarvestInitiatorType) => {
        if (selected) {
            if (targetBehavior) {
                targetBehavior.harvestInitiatorType = value;
                setBehaviorState({...targetBehavior, harvestInitiatorType: value});
            }

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleRespawnTypeChange = (value: RespawnType) => {
        if (selected) {
            if (targetBehavior) {
                targetBehavior.respawnType = value;
                setBehaviorState({...targetBehavior, respawnType: value});
            }

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    useEffect(() => {
        (async () => {
            const objects = await ResourcesUtils.getBiomeObjects("Forest", "");
            setAvailableObjects(objects || []);
        })();
    }, []);

    const harvestTab = behaviorState.harvestInitiatorType || HARVEST_TYPES.HIT;
    const respawnTab = behaviorState.respawnType || RESPAWN_TYPES.ONCE;
    const objectTypeOptions = availableObjects.map(object => {
        return {key: object.Name, value: object.Name};
    });

    return (
        <>
            <SelectionOfButtons margin="8px auto 0">
                <StyledButton
                    width="calc(50% - 3px)"
                    isBlue={harvestTab === HARVEST_TYPES.HIT}
                    isActive={harvestTab !== HARVEST_TYPES.HIT}
                    onClick={() => handleharvestInitiatorTypeChange(HARVEST_TYPES.HIT)}>
                    <span>Hit</span>
                </StyledButton>
                <StyledButton
                    width="calc(50% - 3px)"
                    isBlue={harvestTab === HARVEST_TYPES.PRESS_E_KEY}
                    isActive={harvestTab !== HARVEST_TYPES.PRESS_E_KEY}
                    onClick={() => handleharvestInitiatorTypeChange(HARVEST_TYPES.PRESS_E_KEY)}>
                    <span>Press E Key</span>
                </StyledButton>
                <Separator />
            </SelectionOfButtons>
            <ContentItem style={{marginBottom: "12px"}} $flexDirection="row" $justifyContent="space-between">
                <PanelSectionTitleSecondary>Object Type</PanelSectionTitleSecondary>
                <BasicCombobox
                    data={objectTypeOptions}
                    value={objectTypeOptions.find(option => option.value === behavior.objectType)}
                    onChange={option => handleInputChange(option?.value, "objectType")}
                />
            </ContentItem>
            <NumericInputRow
                width="75px"
                label="Material Per Intersection"
                value={behavior.materialPerInteraction}
                setValue={value => handleInputChange(value, "materialPerInteraction")}
            />
            <NumericInputRow
                width="75px"
                label="Times Before Destroyed"
                value={behavior.timesBeforeDestroyed}
                setValue={value => handleInputChange(value, "timesBeforeDestroyed")}
            />

            <SelectionOfButtons margin="8px auto 0">
                <StyledButton
                    width="calc(50% - 3px)"
                    isBlue={respawnTab === RESPAWN_TYPES.ONCE}
                    isActive={respawnTab !== RESPAWN_TYPES.ONCE}
                    onClick={() => handleRespawnTypeChange(RESPAWN_TYPES.ONCE)}>
                    <span>Once</span>
                </StyledButton>
                <StyledButton
                    width="calc(50% - 3px)"
                    isBlue={respawnTab === RESPAWN_TYPES.CAN_RESPAWN}
                    isActive={respawnTab !== RESPAWN_TYPES.CAN_RESPAWN}
                    onClick={() => handleRespawnTypeChange(RESPAWN_TYPES.CAN_RESPAWN)}>
                    <span>Can Respawn</span>
                </StyledButton>
            </SelectionOfButtons>
        </>
    );
};
