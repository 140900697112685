import {Object3D, InstancedMesh, Mesh, BufferGeometry, Material, Scene} from "three";

type ModelUrl = string;

type InstancedMeshMap = Map<string, InstancedMesh>;
const MAX_INSTANCES_PER_MESH = 100;

class Instancer {

    private instancedMeshes: Map<ModelUrl, InstancedMeshMap> = new Map<ModelUrl, InstancedMeshMap>();
    
    constructor() {
        console.log('Instancer created');
    }

    public convertMeshesToInstancedMeshes(scene: Scene) {
        scene.traverse((object) => {
            if (!object.userData?.isStemObject || object.userData?.behaviors?.length > 0) {
                return;
            }
            this.convertToInstancedMesh(object, scene);
        });
    }

    public convertToInstancedMesh(object: Object3D, scene: Scene) {
        if (object.userData.isInstancedMesh || object.userData.instanceData) {
            return;
        }

        const modelUrl = object.userData.Url;

        if (!modelUrl) {
            return;
        }

        // get model object from children
        const meshObjects: Mesh[] = [];
        const stack: Object3D[] = [object];

        // traverse object children to find mesh objects
        while (stack.length > 0) {
            const currentObject = stack.pop()!;
            for (let i = 0; i < currentObject.children.length; i++) {
                const child = currentObject.children[i];
                if (child.type === 'Bone') {
                    // disable instancing for objects with bones
                    meshObjects.length = 0;
                    return;
                }
        
                if (child.type === 'Mesh') {
                    meshObjects.push(child as Mesh);
                }
                stack.push(child);
            }
        }

        if (meshObjects.length === 0) {
            return;
        }

        // get instanced mesh map
        let instancedMeshMap: InstancedMeshMap | undefined = this.instancedMeshes.get(modelUrl);

        if (!instancedMeshMap) {
            instancedMeshMap = new Map<string, InstancedMesh>();
            this.instancedMeshes.set(modelUrl, instancedMeshMap);
        }

        // create instanced mesh for each mesh object
        for (let i = 0; i < meshObjects.length; i++) {
            const meshObject = meshObjects[i];
            const name = meshObject.userData.name;

            let instancedMesh = instancedMeshMap.get(name);
            if (!instancedMesh) {
                instancedMesh = this.createInstancedMesh(meshObject.geometry, meshObject.material);
                instancedMesh.count = 0;
                instancedMesh.userData = {
                    isInstancedMesh: true,
                    Url: modelUrl
                }

                instancedMeshMap.set(name, instancedMesh);
                scene.add(instancedMesh);
            }

            if (instancedMesh.count >= MAX_INSTANCES_PER_MESH) {
                // TODO: create new instanced mesh
                // console.log('Max instances per mesh exceeded for', name, ' in ', modelUrl);
                return;
            }

            const newObject = this.swapMeshToInstance(meshObject, instancedMesh);
            
            this.updateInstancePosition(newObject);
        }
    }

    private swapMeshToInstance(mesh: Mesh, instancedMesh: InstancedMesh): Object3D {
        // create a new object based on mesh
        const newObject = new Object3D();
        newObject.position.copy(mesh.position);
        newObject.rotation.copy(mesh.rotation);
        newObject.scale.copy(mesh.scale);

        newObject.userData = mesh.userData;
        newObject.userData.isInstance = true;
        newObject.userData.instanceData = {
            modelUrl: instancedMesh.userData.Url,
            id: instancedMesh.count,
            matrix: newObject.matrix.clone(),
            instancedMesh: instancedMesh
        }

        instancedMesh.count++;

        const meshObjectParent = mesh.parent!;
        meshObjectParent.remove(mesh);
        meshObjectParent.add(newObject);

        // move children to new object
        while (mesh.children.length) {
            const child = mesh.children[0];
            mesh.remove(child);
            newObject.add(child);
        }

        return newObject;
    }

    private updateInstancePosition(object: Object3D) {
        object.updateWorldMatrix(true, true);
        const instancedMesh = object.userData.instanceData.instancedMesh;
        const id = object.userData.instanceData.id;
        const matrix = object.matrixWorld;

        // instance matrix should be set using object world matrix
        instancedMesh.setMatrixAt(id, matrix);
    }

    private createInstancedMesh(geometry: BufferGeometry, material: Material | Material[]): InstancedMesh {
        const instancedMesh = new InstancedMesh(geometry, material, MAX_INSTANCES_PER_MESH);
        return instancedMesh;
    }

}

export default Instancer;
