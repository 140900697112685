import React, { useEffect, useState } from "react";
import { ButtonsColumn, Grid } from "../../HUDEditView/GameStartMenu/GameStartMenu.style";
import {
    HUD_TABS,
    IGameButtonInterface,
    START_MENU_BUTTON_TYPES,
    START_MENU_IDS,
    StartGameMenuDataType,
} from "../../HUDEditView/types";
import { CustomGameButton } from "../../HUDEditView/CustomGameButton/CustomGameButton";
import { Logo } from "../Logo";
import EventBus from "../../../../../../behaviors/event/EventBus";
import global from "../../../../../../global";
import { StopButton } from "../StopButton";
import { getSoundsFromUI } from "../getGameSounds";
import { DetectDevice } from '../../../../../../utils/DetectDevice';
import { DEVICE_TYPES } from "../../../../../../types/editor";

interface Props {
    setView: React.Dispatch<React.SetStateAction<HUD_TABS | null>>;
    setBgImage: React.Dispatch<React.SetStateAction<string | undefined>>;
    emptyHUD: boolean;
    renderStopButton: boolean;
}

export const GameStartView = ({ setView, setBgImage, emptyHUD, renderStopButton }: Props) => {
    const app = (global as any).app;
    const [startUI, setStartUI] = useState<StartGameMenuDataType>();
    const [sounds, setSounds] = useState<any[]>();

    const scene = app.scene || app.editor?.scene;

    const logo = startUI?.["start-menu-game-logo-left"];

    useEffect(() => {
        if (scene?.userData?.gameUI?.gameStartMenu) {
            const data = scene.userData.gameUI.gameStartMenu as StartGameMenuDataType;
            setStartUI(data);
        } else {
            setStartUI(undefined);
        }
    }, [app]);

    useEffect(() => {
        if (startUI) {
            const img = startUI[START_MENU_IDS.MENU_BG] as string;
            const soundsToLoad = getSoundsFromUI(startUI, true);
            img && setBgImage(img);
            setSounds(soundsToLoad);
        } else {
            setBgImage(undefined);
        }
    }, [startUI]);

    useEffect(() => {
        if (sounds) {
            EventBus.instance.send("game.loadSounds", sounds);
        }
    }, [sounds]);

    useEffect(() => {
        return () => {
            sounds && EventBus.instance.send("game.clear_sounds");
        };
    }, []);

    const handleGameButtonClick = (customStyle: IGameButtonInterface) => {
        const clickSound = customStyle.clickSound;
        clickSound && EventBus.instance.send("game.playSound", clickSound.ID);
        if (checkIfStartBtn(customStyle.UIButtonType)) {
            EventBus.instance.send("game.start");
            EventBus.instance.send("game.clear_sounds");
            setView(null);
        }
        const deviceType = DetectDevice.getDeviceType();
        if (deviceType === DEVICE_TYPES.MOBILE) {
            openFullScreen();
        }
    };

    const openFullScreen = () => {
        const elem = document.documentElement;
        if (!elem) return;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
            // @ts-ignore
        } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            // @ts-ignore
            elem.webkitRequestFullscreen();
            // @ts-ignore
        } else if (elem.msRequestFullscreen) {
            /* IE11 */
            // @ts-ignore
            elem.msRequestFullscreen();
        }
    };

    const handleHover = (customStyle: IGameButtonInterface) => {
        const hoverSound = customStyle.hoverSound;
        hoverSound && EventBus.instance.send("game.playSound", hoverSound.ID);
    };

    const checkIfStartBtn = (buttonType: string) => {
        return buttonType?.toLowerCase() === START_MENU_BUTTON_TYPES.START_GAME.toLowerCase();
    };

    return startUI ? (
        <Grid>
            {!emptyHUD && (
                <>
                    <ButtonsColumn $panelBg={(startUI?.[START_MENU_IDS.PANEL_BG] as string) || undefined}>
                        {logo && (
                            <Logo
                                width="100%"
                                height="161px"
                                bgImage={(startUI[START_MENU_IDS.LOGO_LEFT] as string) || null}
                            />
                        )}
                        {Array(5)
                            .fill(5)
                            .map((_, index) => {
                                const id = `start-menu-game-button-column-left-${index + 1}` as START_MENU_IDS;
                                const customStyleData = startUI[id] as IGameButtonInterface;

                                return (
                                    <CustomGameButton
                                        key={index}
                                        customStyle={customStyleData ? customStyleData : null}
                                        width="81%"
                                        height="50px"
                                        onClick={() => handleGameButtonClick(customStyleData)}
                                        onHover={() => handleHover(customStyleData)}
                                        pointerEvent
                                        id={
                                            customStyleData
                                                ? checkIfStartBtn(customStyleData.UIButtonType)
                                                    ? "startGameBtn"
                                                    : undefined
                                                : undefined
                                        }
                                    />
                                );
                            })}
                    </ButtonsColumn>
                    {renderStopButton && (
                        <ButtonsColumn $isCenter>
                            <StopButton />
                        </ButtonsColumn>
                    )}
                </>
            )}
        </Grid>
    ) : null;
};
