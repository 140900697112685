import * as THREE from "three";
import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import { COLLISION_TYPE } from "../../types/editor";
import GameManager from "../../behaviors/game/GameManager";
import { IPhysics } from "src/physics/common/types";
import { PhysicsUtil } from "../../physics/PhysicsUtil";
import CameraUtils from "../../utils/CameraUtils";
import { ProceduralTerrainBehaviorInterface, OBJECT_TYPES } from "../../types/editor";
import BufferGeometryUtils from "../../assets/js/utils/BufferGeometryUtils";

class ProceduralTerrainBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    game?: GameManager;
    physics?: IPhysics;
    behavior: ProceduralTerrainBehaviorInterface;
    scene: THREE.Scene | null = null;
    perlinNoiseTexture: THREE.Texture | null = null;
    perlinNoiseYValues: { x: number, y: number, z: number }[] = [];

    constructor(target: THREE.Object3D, behavior: ProceduralTerrainBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        CameraUtils.disableFromCameraCollision(this.target);
        this.target.visible = false;
        this.perlinNoiseYValues = this.perlinNoiseYValues || [];
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        //this.physics = this.game?.behaviorManager?.collisionDetector.physics; //not needed ( yet )
        //this.addCollisionListener(); //not needed ( yet )
        this.target.userData.isTerrain = true;

        this.scene = this.game!.scene!;
        const textureLoader = new THREE.TextureLoader();

        this.perlinNoiseTexture = textureLoader.load(this.behavior.perlinNoiseImage, () => {
            const terrain = this.generateTerrainWithPerlinNoise();
            if (terrain && this.perlinNoiseTexture) {
                terrain.position.copy(this.target.position);
                terrain.rotation.copy(this.target.rotation);
                this.game!.scene!.add(terrain);
            }
        }, undefined, (err) => {

        });

    }

    generateTerrainWithPerlinNoise(sampleRange: number = 100): THREE.Mesh | null {
        const textureWidth = this.perlinNoiseTexture!.image.width;
        const textureHeight = this.perlinNoiseTexture!.image.height;

        const canvas = document.createElement('canvas');
        canvas.width = textureWidth;
        canvas.height = textureHeight;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            console.warn('Could not get 2D context from canvas.');
            return null;
        }

        ctx.drawImage(this.perlinNoiseTexture!.image, 0, 0);
        const imageData = ctx.getImageData(0, 0, textureWidth, textureHeight).data;

        const width = this.behavior.terrainWidth;
        const height = this.behavior.terrainLength;
        const segments = this.behavior.terrainSegments;

        const terrainGeometry = new THREE.PlaneGeometry(width, height, segments, segments);
        terrainGeometry.rotateX(-Math.PI / 2);

        terrainGeometry.computeBoundingBox();
        const boundingBox = terrainGeometry.boundingBox!;
        const offsetX = (boundingBox.max.x + boundingBox.min.x) / 2;
        const offsetZ = (boundingBox.max.z + boundingBox.min.z) / 2;

        const positionArray = terrainGeometry.attributes.position.array;
        const colors = new Float32Array(positionArray.length);

        let minY = Infinity, maxY = -Infinity;

        const perlinNoiseScaleX = textureWidth / width;
        const perlinNoiseScaleZ = textureHeight / height;

        for (let i = 0; i < positionArray.length; i += 3) {
            const posX = positionArray[i] - offsetX;
            const posZ = positionArray[i + 2] - offsetZ;

            const scaledX = (posX + width / 2) * perlinNoiseScaleX;
            const scaledZ = (posZ + height / 2) * perlinNoiseScaleZ;

            const pixelX = Math.floor(scaledX % textureWidth);
            const pixelZ = Math.floor(scaledZ % textureHeight);

            const pixelIndex = (pixelZ * textureWidth + pixelX) * 4;
            const noiseValue = imageData[pixelIndex] / 255;

            const y = noiseValue * this.behavior.perlinNoiseScale;
            positionArray[i + 1] = y;

            minY = Math.min(minY, y);
            maxY = Math.max(maxY, y);
        }

        //TODO Work on this may a color picker ?? adjust color based on height factor
        const colorArray: number[] = [];
        for (let i = 0; i < positionArray.length; i += 3) {
            const heightFactor = (positionArray[i + 1] - minY) / (maxY - minY);

            // High elevation - white
            const r = heightFactor > 0.7 ? 1 : (heightFactor > 0.4 ? 0.5 : 0); // white for high elevations
            const g = heightFactor > 0.7 ? 1 : (heightFactor > 0.4 ? heightFactor : (0.3 + heightFactor * 0.7)); // lighter green to darker green for low
            const b = heightFactor > 0.7 ? 1 : (heightFactor > 0.4 ? heightFactor : 0); // white to gray transition

            colors[i] = r;
            colors[i + 1] = g;
            colors[i + 2] = b;

            colorArray.push(r, g, b);
        }

        terrainGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

        const mergedGeometry = BufferGeometryUtils.mergeVertices(terrainGeometry);

        mergedGeometry.computeVertexNormals();

        mergedGeometry.attributes.position.needsUpdate = true;

        const terrainMaterial = new THREE.MeshStandardMaterial({
            vertexColors: true,
            color: "#22311d",
            roughness: 1,
            metalness: 0,
        });

        const terrainMesh = new THREE.Mesh(mergedGeometry, terrainMaterial);

        const yOffset = this.target.position.y - minY;
        terrainMesh.position.copy(this.target.position);
        terrainMesh.position.y = this.target.position.y + yOffset;

        if (!this.target.userData) {
            this.target.userData = {};
        }

        if (this.target && this.target.userData) {
            this.target.userData.terrainMeshData = {
                positionArray: Array.from(positionArray), 
                boundingBox: boundingBox, 
                colors: colorArray,  
            };
            this.target.userData.terrainMesh = terrainMesh;
        } else {
            console.warn('target or target.userData is not defined');
        }

        return terrainMesh;
    }



    onCollision() { }

    addCollisionListener() {
        this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollision.bind(this),
                useBoundingBoxes: true,
            },
            PhysicsUtil.isDynamicObject(this.target),
        );
    }

    update(clock: THREE.Clock, delta: number): void {
        if (!this.game?.player?.position || !this.target) return;
    }

    reset() { }
}

export default ProceduralTerrainBehaviorUpdater;
