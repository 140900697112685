import * as THREE from "three";
import EventBus from "../behaviors/event/EventBus";
import global from "../global";
import {AnimationMixer, Box3, Camera, Object3D, Quaternion, Scene, Vector3} from "three";
import {
    CharacterOptionsInterface,
    OBJECT_TYPES,
    WEAPON_TYPES,
    CHARACTER_VERTICAL_STATUS_TYPES,
    MOBILE_JOYSTICK_MOVEMENT_STATE,
    MOBILE_JOYSTICK_DIRECTION_STATE,
    GAMEPAD_JOYSTICK_MOVEMENT_STATE,
    GAMEPAD_JOYSTICK_DIRECTION_STATE,
    MOVEMENT_STATES,
    CAMERA_TYPES,
} from "../types/editor";

import {CollisionFlag, IPhysics, SphereData} from "../physics/common/types";
import {CameraControl} from "./CameraControl";
import {CharacterBehaviorInterface} from "../types/editor";
import {PhysicsUtil} from "../physics/PhysicsUtil";
import {AnimationAction} from "three/src/animation/AnimationAction";
import {EffectsManager} from "../editor/effects/EffectsManager";
import {PlayerBones} from "../player/Skeleton/PlayerBones";
import {GunMuzzleFlashEffect} from "../editor/effects/GunMuzzleFlashEffect";
import {InputProvider} from "./input/InputProvider";
import {PlayerActions} from "./input/ActionTypes";
import Player from "../player/Player";

//TODO this is an ongoing refactor and most of this will be
//removed or moved to character behavior

const keysMapping: Record<number, string> = {
    87: "W",
    65: "A",
    83: "S",
    68: "D",
    16: "Shift",
    32: "Space",
    70: "F",
    80: "P",
    82: "R",
    48: "0",
    81: "Q",
};

export default class CharacterControls {
    app: Player = global.app as Player;
    private inputProvider: InputProvider<PlayerActions>;
    private physics: IPhysics;
    private scene: Scene;
    private camera: Camera;
    private scenePlayer: any;
    private domElement: HTMLElement;
    player: Object3D;
    private animations: any[] = [];
    private mixer: AnimationMixer | null = null;
    private actions: any;
    private currentAction: string | null = null;
    private renderer: any;
    walkDirection = new THREE.Vector3();
    rotateAngle = new THREE.Vector3(0, 1, 0);
    rotateQuarternion = new THREE.Quaternion();
    isPhysicsEnabled = false;
    public velocity: number = 0;
    public walkSpeed: number = 0;
    public runSpeed: number = 0;
    public lookSpeed: number = 0;
    private initialXRotation: string = "";
    private hideCurrentWeapon: boolean = true;
    private isSpaceBarReleased: boolean = true;
    public clock = new THREE.Clock();
    public delta = this.clock.getDelta();
    public mousePos: THREE.Vector3 | null = null;
    public intersectPointX: number = 0;
    public intersectPointY: number = 0;
    public intersectPointZ: number = 0;
    private raycasterFromCamera: THREE.Raycaster | null = null;
    private selectedHitObject: THREE.Object3D | null = null;
    private touchCount: number = 0;

    keysPressed: Record<string, boolean> = {
        W: false,
        A: false,
        S: false,
        D: false,
        Shift: false,
        Space: false,
        F: false,
        R: false,
        Q: false,
    };

    dropWeapon: boolean;
    gamePaused = true;
    CameraControl: CameraControl | null;
    jumpCount = 0;
    lastJumpTime = 0;
    jumpDuration: number = 0;
    playerGravity: number = -75;
    jumpHeight: number = 4;
    cameraMinDistance: number = 10;
    cameraMaxDistance: number = 10;
    cameraFov: number = 50;
    newAction: string | null;
    isJumping: boolean = false;
    wasJumping: boolean = false;
    isStopped: boolean = true;
    playerAttacking: boolean = false;
    weaponName: string;
    weaponSelectedThrowable: Object3D | undefined;
    weaponType: string | null = null;
    weaponStarting: boolean | undefined;
    uiImage: string | undefined;
    weaponDamage: number | undefined;
    weapon_clip_ammount: number | undefined;
    weaponFireSpeed: number | undefined;
    weaponReloadSpeed: number | undefined;
    weaponScopeZoom: number | undefined;
    aimerUIImage: string | null = null;
    weaponPreviewHUDAimer: boolean | undefined;
    weaponShowHUDAimerInGame: boolean | undefined;
    weaponHUDAimerSize: number | undefined;
    weaponVFXSmallEffect = null;
    weaponVFXMediumEffect = null;
    weaponVFXBigEffect = null;
    weaponVFXLaserEffect = null;
    weaponVFXCartoonyEffect = null;
    weaponAutoPickUp: boolean = false;
    weaponAutoReload: boolean = false;
    weaponPositionX: number = 0;
    weaponPositionY: number = 0;
    weaponPositionZ: number = 0;
    weaponRotationX: number = 0;
    weaponRotationY: number = 0;
    weaponPotationZ: number = 0;
    currentHeldObjectName: string | null = null;
    weaponSelectedCharacterBone: string | undefined;
    weaponAttachedBone: Object3D | undefined;
    characterHeadBone: Object3D | undefined;
    characterHeadTopEndBone: Object3D | undefined;
    characterLeftShoulderBone: Object3D | undefined;
    weaponThrowableMass: number;
    weaponThrowableSpeed: number;
    weaponThrowableLife: number;
    weaponThrowableVisible: boolean;
    weaponThrowableName: undefined;
    currentHeldObjectMass: number = 0;
    currentHeldObjectSpeed: number = 0;
    currentHeldObjectLife: number = 0;
    currentHeldObjectVisible: boolean;
    currentHeldObjectMuzzleFlash: boolean;
    currentHeldObjectLaserEffect: boolean;
    currentHeldObjectFriction: number | undefined;
    currentHeldObjectRestitution: number | undefined;
    currentHeldObjectInertia: number | undefined;
    weaponClipAmount: number;
    weaponRapidFiree: boolean;
    currentWeapon: any;
    currentHeldObject: any;
    projectiles: THREE.Object3D[] = [];
    playerFallingBack: boolean;
    chatActivated: boolean;
    playerIsDead: boolean;
    characterOptions: CharacterOptionsInterface | any;
    bbox = new Box3();
    vec = new Vector3();
    private effectsManager: any;
    playerHipBone: THREE.Object3D | null = null;
    initialRootPosition: THREE.Vector3 | null = null;
    overTheShoulder: boolean = false;
    otsShiftVector: THREE.Vector3;
    otsBoundingBox: THREE.Box3;
    otsPlayerWidth: number;
    otsPlayerHeight: number;
    otsShiftAmount: number;
    otsRightShoulderCamera: boolean = false;
    private readonly boundHandleKeyDown: (event: KeyboardEvent) => void;
    private readonly boundHandleKeyUp: (event: KeyboardEvent) => void;
    private readonly boundHandleMouseDown: (event: MouseEvent) => void;
    private readonly boundHandleMouseUp: (event: MouseEvent) => void;
    pickedUpObject: boolean;
    isDebug: boolean;
    private previousPlayerRotationY: number = 0;
    private cameraRotating: boolean;
    playerRotation: number;
    fpsEnabled: boolean;
    showPlayerDustParticles: boolean;
    playerDirection: THREE.Vector3 | null = null;
    private originalPositions: Map<THREE.Mesh, Float32Array>;
    private GunMuzzleFlashEffect: GunMuzzleFlashEffect | null = null;
    private weaponsData: any[] = [];
    private isPlayerReloading: boolean = false;
    private ammoFireIntervalId: number | null = null;
    private allowWeaponAmmoReload: boolean = false;
    private selectedWeaponIndex: number;
    private selectedWeapon: THREE.Object3D | null = null;
    private targetQuaternion: THREE.Quaternion;

    private weaponMuzzleFlashBrightness: number = 0;
    private weaponMuzzleSmokeDensity: number = 0;
    private weaponMuzzleSmokeSize: number = 0;
    private weaponMuzzleSmokeLife: number = 0;
    private weaponMuzzleSmokeOpacity: number = 0;

    private controlType: CAMERA_TYPES;
    private playerBox: THREE.Box3;
    private playerHeight: number = 0;

    constructor(
        inputProvider: InputProvider<PlayerActions>,
        physics: IPhysics,
        scene: Scene,
        camera: Camera,
        renderer: any,
        scenePlayer: any,
        domElement: HTMLElement,
        model: Object3D,
        animations: any[],
    ) {
        this.inputProvider = inputProvider;
        this.physics = physics;
        this.scene = scene;
        this.camera = camera;
        this.renderer = renderer;
        this.scenePlayer = scenePlayer;
        this.domElement = domElement;
        this.player = model;
        this.animations = animations;
        this.controlType = CAMERA_TYPES.THIRD_PERSON;
        this.setOptions(this.camera.userData.characterOptions);
        this.actions = {};
        this.isPhysicsEnabled = PhysicsUtil.isPhysicsEnabled(this.player);

        this.weaponThrowableMass = 0;
        this.weaponThrowableSpeed = 0;
        this.weaponThrowableLife = 0;
        this.weaponThrowableVisible = false;
        this.weaponThrowableName = undefined;
        this.playerFallingBack = false;
        this.chatActivated = false;
        this.playerIsDead = false;
        this.weaponClipAmount = 0;
        this.weaponRapidFiree = false;
        this.currentWeapon = new THREE.Object3D();
        this.currentHeldObject = new THREE.Object3D();
        this.currentHeldObjectVisible = true;
        this.CameraControl = null;
        this.dropWeapon = false;
        this.weaponName = "";
        global.app!.on("gameStarted.3PC", this.handleGameStarted);
        global.app!.on("pauseGame.3PC", this.handleGamePaused);
        global.app!.on("gameEnded.3PC", this.handleGameEnded);
        global.app!.on("gameResumed.3PC", this.handleGameResumed);
        global.app!.on("unlockEvent.3PC", this.handleControlUnlock);
        global.app!.on("chatActivated.3PC", this.handleActiveChat);
        global.app!.on("chatDeactivated.3PC", this.handleDeactivatedChat);
        this.effectsManager = EffectsManager.reset(this.scene, this.camera);
        this.currentHeldObjectMuzzleFlash = false;
        this.currentHeldObjectLaserEffect = false;

        this.currentHeldObjectMass = 0;
        this.currentHeldObjectSpeed = 0;
        this.currentHeldObjectLife = 0;

        this.fpsEnabled = false;

        this.currentWeapon = null;
        this.boundHandleKeyDown = this.handleKeyDown.bind(this);
        this.boundHandleKeyUp = this.handleKeyUp.bind(this);
        this.boundHandleMouseDown = this.handleMouseDown.bind(this);
        this.boundHandleMouseUp = this.handleMouseUp.bind(this);
        this.pickedUpObject = false;
        this.isDebug = (global.app as any)?.storage?.debug;
        this.cameraRotating = false;
        this.playerRotation = 0;
        this.newAction = null;
        this.showPlayerDustParticles = false;
        this.playerDirection = new THREE.Vector3(0, 0, 0);
        this.originalPositions = new Map();
        this.isPlayerReloading = false;
        this.selectedWeaponIndex = 1;
        this.targetQuaternion = new THREE.Quaternion();
        if (global && global.app) {
            global.app.on("playerFallBack", this.setPlayerFallBack.bind(this));
            global.app.on("playerDead", this.setPlayerIsDead.bind(this));
        }

        this.otsShiftVector = new THREE.Vector3(0, 0, 0);
        this.otsBoundingBox = new THREE.Box3();
        this.otsPlayerWidth = 0;
        this.otsPlayerHeight = 0;
        this.otsShiftAmount = 0;
        this.renderer.clear(true, true, true);
        this.scene.userData.physics = this.physics;

        this.playerBox = new THREE.Box3().setFromObject(this.player);
        this.playerHeight = this.playerBox.max.y - this.playerBox.min.y;
    }

    //TODO refactor this to character behavior
    private setOptions(options: CharacterOptionsInterface) {
        this.cameraFov = options.cameraFov;
        this.jumpHeight = options.jumpHeight;
        this.playerGravity = this.scene.userData.gravity;
        this.otsRightShoulderCamera = options.otsRightShoulderCamera;
        this.overTheShoulder = options.overTheShoulder;
        this.lookSpeed = options.lookSpeed;
        this.player.userData.slopeTolerance = options.slopeTolerance;
    }

    create(): Promise<CharacterControls> {
        return new Promise((resolve, reject) => {
            if (this.isPhysicsEnabled) {
                this.physics
                    ?.addPlayerObject(this.player.uuid, true, {
                        playerGravity: this.playerGravity,
                        jumpHeight: this.jumpHeight,
                    })
                    .then(playerObject => {
                        this.player = playerObject ? (playerObject as Object3D) : this.player;
                        this.animations =
                            this.player._obj && this.player._obj.animations ? this.player._obj.animations : [];
                        this.init()
                            .then(() => {
                                resolve(this);
                            })
                            .catch(err => {
                                reject(err);
                            });
                    });
            } else {
                reject("Physics is not enabled for the character object");
            }
        });
    }

    init(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.mixer = this.animations.length > 0 ? new THREE.AnimationMixer(this.player) : null;
            this.actions = this.initializeActions();
            this.currentAction = this.camera.userData.characterOptions.idleAnimation;
            if (this.currentAction && this.actions[this.currentAction]) {
                this.playCurrentAnimation();
            }

            if (this.player.userData && this.player.userData.behaviors) {
                const characterBehavior = this.player.userData.behaviors.find(
                    (behavior: any) => behavior.type === OBJECT_TYPES.CHARACTER,
                ) as CharacterBehaviorInterface;

                if (
                    characterBehavior?.control !== CAMERA_TYPES.THIRD_PERSON &&
                    characterBehavior?.control !== CAMERA_TYPES.FIRST_PERSON &&
                    characterBehavior?.control !== CAMERA_TYPES.FORTNITE
                ) {
                    return;
                }

                if (characterBehavior) {
                    this.controlType = characterBehavior.control;

                    this.weaponAutoPickUp = characterBehavior.pickUpWeapons;

                    this.CameraControl = CameraControl.reset(
                        this.scene,
                        this.camera as THREE.PerspectiveCamera,
                        this.player,
                        false,
                        false,
                        this.cameraMinDistance,
                        this.cameraMaxDistance,
                        this.cameraFov,
                    );

                    const playerBones = new PlayerBones(this.player);
                    const {bones, hipsBone} = playerBones.getPlayerBones();

                    if (hipsBone) {
                        this.playerHipBone = hipsBone as THREE.Object3D;
                        this.initialRootPosition = new THREE.Vector3();
                        this.initialRootPosition.copy(this.playerHipBone.position);
                        if (this.isDebug) {
                            console.log("Bone Names:", bones);
                            console.log("Hips Bone:", hipsBone);
                        }
                    } else {
                        console.log("Hips bone not found");
                    }

                    let weaponIndex = 0;

                    /*this.scene!.traverse((object: THREE.Object3D) => {
                        if (object.userData && object.userData.behaviors) {
                            const weaponBehavior = object.userData.behaviors.find(
                                (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON,
                            );
                            if (weaponBehavior && weaponBehavior.weaponName === object.name) {
                                this.weaponName = weaponBehavior.weaponName;
                                this.weaponType = weaponBehavior.weaponType;
                                this.weaponStarting = weaponBehavior.weaponStarting;
                                this.weaponDamage = weaponBehavior.weaponDamage;
                                this.weaponClipAmount = weaponBehavior.weaponClipAmount;
                                this.weaponFireSpeed = weaponBehavior.weaponFireSpeed;
                                this.weaponReloadSpeed = weaponBehavior.weaponReloadSpeed;
                                this.weaponScopeZoom = weaponBehavior.weaponScopeZoom;
                                this.aimerUIImage = weaponBehavior.aimerUIImage;
                                this.weaponPreviewHUDAimer = weaponBehavior.weaponPreviewHUDAimer;
                                this.weaponShowHUDAimerInGame = weaponBehavior.weaponShowHUDAimerInGame;
                                this.weaponHUDAimerSize = weaponBehavior.weaponHUDAimerSize;
                                this.weaponVFXSmallEffect = weaponBehavior.weaponVFXSmallEffect;
                                this.weaponVFXMediumEffect = weaponBehavior.weaponVFXMediumEffect;
                                this.weaponVFXBigEffect = weaponBehavior.weaponVFXBigEffect;
                                this.weaponVFXLaserEffect + weaponBehavior.weaponVFXLaserEffect;
                                this.weaponVFXCartoonyEffect = weaponBehavior.weaponVFXCartoonyEffect;
                                this.currentHeldObjectName = weaponBehavior.selectedWeaponThrowableName;
                                this.currentHeldObjectVisible = weaponBehavior.currentHeldObjectVisible;
                                this.weaponSelectedCharacterBone = weaponBehavior.weaponSelectedCharacterBone;
                                this.weaponClipAmount = weaponBehavior.weaponClipAmount;
                                this.weaponAutoReload = weaponBehavior.weaponAutoReload;
                                this.currentWeapon = this.scene!.getObjectByName(this.weaponName);
                                this.weaponMuzzleFlashBrightness = weaponBehavior.weaponMuzzleFlashBrightness;
                                this.weaponMuzzleSmokeDensity = weaponBehavior.weaponMuzzleSmokeDensity;
                                this.weaponMuzzleSmokeSize = weaponBehavior.weaponMuzzleSmokeSize;
                                this.weaponMuzzleSmokeLife = weaponBehavior.weaponMuzzleSmokeLife;
                                this.weaponMuzzleSmokeOpacity = weaponBehavior.weaponMuzzleSmokeOpacity;

                                if (this.isDebug) {
                                    console.log("Weapon Behavior Details:");
                                    for (const key in weaponBehavior) {
                                        console.log(`${key}:`, weaponBehavior[key]);
                                    }
                                }
                            }

                            if (weaponBehavior) {
                                if (!this.scene.userData.gameWeapons) {
                                    this.scene.userData.gameWeapons = [];
                                }
                                this.scene.userData.gameWeapons.push(object);

                                const weaponData = {
                                    name: this.weaponName,
                                    type: this.weaponType,
                                    image: weaponBehavior.uiImage,
                                };

                                this.weaponsData.push(weaponData);

                                object.userData.weaponIndex = weaponIndex;
                                object.userData.aimerImage = this.aimerUIImage;

                                weaponIndex++;
                            }
                        }
                    });*/

                    /*this.scene!.traverse((object: any) => {
                        if (object.userData?.behaviors) {
                            const throwableBehavior = object.userData.behaviors.find(
                                (behavior: any) => behavior.type === OBJECT_TYPES.THROWABLE,
                            );
                            if (throwableBehavior && throwableBehavior.throwableVisible) {
                                object.visible = true;
                                object.userData.allowPickUp = true;
                            }
                        }
                    });*/

                    //TODO update this as character testing progresses
                    if (this.weaponSelectedCharacterBone!) {
                        this.player.traverse(object => {
                            if (object.type === "Bone") {
                                const boneName = object.name.replace("mixamorig", "");
                                if (this.isDebug) {
                                    console.log("Auto select hand bone" + boneName);
                                }
                                if (boneName.toLowerCase().includes(this.weaponSelectedCharacterBone!.toLowerCase())) {
                                    this.weaponAttachedBone = object;
                                }
                                if (boneName.toLowerCase() === "head") {
                                    this.characterHeadBone = object;
                                }
                                if (boneName.toLowerCase() === "headtop_end") {
                                    this.characterHeadTopEndBone = object;
                                }
                                if (boneName.toLowerCase() === "leftarm") {
                                    this.characterLeftShoulderBone = object;
                                }
                            }
                        });
                    }

                    if (this.weaponsData && this.weaponsData.length > 0) {
                        this.GunMuzzleFlashEffect = new GunMuzzleFlashEffect(this.currentWeapon);
                    } else {
                        if (this.isDebug) {
                            console.warn("No weapons data available.");
                        }
                    }

                    if (this.scene.userData.gameWeapons && this.scene.userData.gameWeapons.length > 0) {
                        this.weaponsData.forEach((weapon: THREE.Object3D, index) => {
                            let weaponObject = this.scene.getObjectByName(weapon.name) as THREE.Object3D;
                            this.GunMuzzleFlashEffect?.checkForFlashEffectObject(weaponObject);
                        });
                        if (!this.weaponAutoPickUp) {
                            this.selectWeapon(1);
                            this.fpsEnabled = true;
                        } else {
                            this.selectWeapon(1);
                            this.fpsEnabled = false;
                        }
                    }
                } else {
                    if (this.isDebug) {
                        console.log("Character type not found");
                    }
                    reject("Character type not found");
                }
            } else {
                if (this.isDebug) {
                    console.log("Player userData or behaviors not defined");
                }
                reject("Player userData or behaviors not defined");
            }

            this.movePlayerToRandomSpawnPoint();

            resolve();
        });
    }

    private movePlayerToRandomSpawnPoint() {
        const spawnPoints = this.getSpawnPointObjects();

        if (spawnPoints.length > 0) {
            // select random spawn points
            const spawnPoint = spawnPoints[Math.floor(Math.random() * spawnPoints.length)];

            this.player.position.copy(spawnPoint.position);
            this.player.quaternion.copy(spawnPoint.quaternion);

            this.playerDirection = new THREE.Vector3(0, 0, -1);
            this.playerDirection.applyQuaternion(this.player.quaternion);

            if (this.isPhysicsEnabled && this.physics) {
                this.physics.setPlayerPosition(this.player.uuid, spawnPoint.position);
            }
        }
    }

    private getSpawnPointObjects(): Object3D[] {
        const spawnPoints: Object3D[] = [];

        this.scene.traverse(child => {
            if (child.userData.isSpawnPoint) {
                spawnPoints.push(child);
            }
        });

        return spawnPoints;
    }

    // IControl
    getPlayerObject(): Object3D {
        return this.player;
    }

    attack() {
        if (!this.physics) {
            return;
        }

        if (
            //TODO set these to be melee weapons
            this.weaponType !== WEAPON_TYPES.SWORD &&
            this.weaponType !== WEAPON_TYPES.STAFF &&
            this.weaponType !== WEAPON_TYPES.KNIFE
        ) {
            let projectile = this.currentHeldObject;
            let projectileMass = this.currentHeldObjectMass;
            let projectileSpeed = this.currentHeldObjectSpeed;
            let projectileLife = this.currentHeldObjectLife;

            let projectileSpawnPosition;

            if (this.currentWeapon) {
                const weaponDirection = new THREE.Vector3(0, 0, -1).applyQuaternion(
                    this.currentWeapon.getWorldQuaternion(new THREE.Quaternion()),
                );
                const weaponBoundingBox = new THREE.Box3().setFromObject(this.currentWeapon);
                const weaponFrontOffset = weaponDirection
                    .clone()
                    .multiplyScalar(weaponBoundingBox.getSize(new THREE.Vector3()).z / 2);
                projectileSpawnPosition = this.currentWeapon.position.clone().add(weaponFrontOffset);
                projectile.userData.firedAmmo = true;
            } else if (projectile) {
                projectileSpawnPosition = projectile.position.clone();
            }

            if (projectileSpawnPosition == null) {
                return;
            }

            let playerRotation = this.player.quaternion.clone();
            let localForward;
            let direction;

            //handle throwing or shooting in over the shoulder
            if (!this.overTheShoulder) {
                direction = new THREE.Vector3(0, 0, 1).applyQuaternion(this.player.quaternion).normalize();
            } else {
                localForward = new THREE.Vector3(0, 0, -1);
                direction = localForward.applyQuaternion(this.camera.quaternion);
                playerRotation = new THREE.Quaternion().setFromEuler(this.player.rotation);
            }

            let projectileMesh = this.createProjectileAndAddToPhysics(
                projectileSpawnPosition,
                playerRotation,
                direction,
                projectileMass,
                projectileSpeed,
            );

            if (projectileMesh) {
                if (!projectileMesh.userData) {
                    projectileMesh.userData = {};
                }
            }

            projectileMesh!.userData.direction = direction;
            projectileMesh!.userData.speed = projectileSpeed;
            projectileMesh!.userData.life = projectileLife;
            this.projectiles.push(projectileMesh!);

            setTimeout(() => {
                this.disposeProjectile(projectileMesh);
            }, projectileLife! * 1000);
        }
    }

    //TODO this will be re factored
    addVingeWeaponSpecialEffects() {
        if (this.playerAttacking && this.hideCurrentWeapon && this.currentHeldObject.userData.isAmmoForWeapon) {
            this.GunMuzzleFlashEffect?.addGunPrimaryMuzzleFlash(
                this.scene,
                this.camera,
                this.currentWeapon,
                this.weaponMuzzleFlashBrightness,
                this.weaponMuzzleSmokeSize,
                this.weaponMuzzleSmokeLife,
                this.weaponMuzzleSmokeDensity,
                this.weaponMuzzleSmokeOpacity,
                this.CameraControl!.isAimingDownSites,
            );
        } else {
            this.GunMuzzleFlashEffect?.checkForFlashEffectObject(this.currentWeapon);
        }
    }

    createProjectileAndAddToPhysics(
        //TODO work on throwing....
        position: Vector3,
        rotation: Quaternion,
        direction: Vector3,
        mass: number,
        ammoSpeed: number,
    ): Object3D | null {
        const offsetDistance = 1 + 1 / 2;
        let startPosition = new THREE.Vector3().copy(position);
        startPosition.addScaledVector(direction, offsetDistance);

        if (this.currentHeldObjectMuzzleFlash) {
            this.effectsManager.createMuzzleFlash(startPosition, this.camera);
        }

        //create projectile and add to scene
        let projectileObject = null;
        projectileObject = this.currentHeldObject;

        if (projectileObject) {
            let clonedProjectile: any;
            if (this.currentHeldObject) {
                if (!this.currentWeapon) {
                    // Handle when there's no weapon
                    clonedProjectile = this.currentHeldObject;
                    let projectileObject = this.currentHeldObject;

                    // Disable pick-up and projectile status temporarily
                    projectileObject.userData.isThrowable = false;
                    projectileObject.userData.allowPickUp = false;
                    this.currentHeldObject = null;

                    // Re-enable pick-up after 5 seconds
                    setTimeout(() => {
                        projectileObject.userData.allowPickUp = true;
                    }, 5000);

                    // Position the projectile at the player's location
                    clonedProjectile.position.copy(this.player.position);
                } else {
                    // Handle when there is a weapon
                    clonedProjectile = projectileObject.clone();
                    clonedProjectile.position.copy(this.currentWeapon.position);
                }
            }

            let angle = Math.atan2(direction.x, direction.z);
            clonedProjectile.rotation.set(0, angle, 0);
            if (this.currentWeapon) {
                clonedProjectile.userData.isAmmoForWeapon = true;
                clonedProjectile.visible = false;
            }
            this.scene!.add(clonedProjectile);

            switch (this.currentWeapon.userData.weaponType) {
                case WEAPON_TYPES.SNIPER_RIFLE:
                    this.addVingeWeaponSpecialEffects();
                    break;
                case WEAPON_TYPES.SCIFI_SNIPER_RIFLE:
                    this.addVingeWeaponSpecialEffects();
                    break;
                case WEAPON_TYPES.RIFLE:
                    this.addVingeWeaponSpecialEffects();
                    break;
                    break;
                case WEAPON_TYPES.MACHINE_GUN:
                    this.addVingeWeaponSpecialEffects();
                    break;
                    break;
                case WEAPON_TYPES.SUB_MACHINE_GUN:
                    this.addVingeWeaponSpecialEffects();
                    break;
                    break;
                case WEAPON_TYPES.SHOT_GUN:
                    this.addVingeWeaponSpecialEffects();
                    break;
                    break;
            }

            clonedProjectile.visible = false;
            const weaponLength = this.currentWeapon ? this.currentWeapon.length : 1;
            const targetPosition = new THREE.Vector3(
                clonedProjectile.position.x + direction.x * weaponLength,
                clonedProjectile.position.y,
                clonedProjectile.position.z + direction.z * weaponLength,
            );
            let startTime = Date.now();
            const duration = 25;
            const interval = setInterval(() => {
                const elapsed = Date.now() - startTime;
                const progress = elapsed / duration;

                if (progress >= 1) {
                    clearInterval(interval);
                } else {
                    clonedProjectile.position.lerpVectors(clonedProjectile.position, targetPosition, progress);
                }
            }, 16);

            this.bbox.setFromObject(clonedProjectile);
            let radius = this.bbox.getSize(this.vec).length() * 0.2;
            let sphereData: SphereData = {
                uuid: clonedProjectile.uuid,
                radius: radius,
                position: {
                    x: startPosition.x,
                    y: startPosition.y,
                    z: startPosition.z,
                },
                quaternion: {
                    x: rotation.x,
                    y: rotation.y,
                    z: rotation.z,
                    w: rotation.w,
                },
                mass: mass,
                friction: this.currentHeldObjectFriction,
                restitution: this.currentHeldObjectRestitution,
                collision_flag: CollisionFlag.DYNAMIC,
                template: projectileObject.uuid,
            } as SphereData;

            this.physics!.addSphere(clonedProjectile, sphereData);
            this.physics!.addCollidableObject(clonedProjectile.uuid);
            this.physics!.setLinearVelocity(clonedProjectile.uuid, {
                x: direction.x * ammoSpeed,
                y: direction.y * ammoSpeed,
                z: direction.z * ammoSpeed,
            } as Vector3);

            return clonedProjectile;
        } else {
            if (this.isDebug) {
                console.error("Object selected object not found in the scene.");
            }
        }

        return null;
    }

    disposeProjectile(projectileMesh: any) {
        if (this.scene && projectileMesh && this.physics) {
            if (projectileMesh.userData.isAmmoForWeapon) {
                projectileMesh.userData.firedAmmo = false;
                this.scene!.remove(projectileMesh);
            }
            try {
                this.physics!.remove(projectileMesh.uuid);
            } catch {}
            this.projectiles = this.projectiles.filter(o => o.uuid !== projectileMesh.uuid);
        }
    }

    initializeActions(): {} {
        const actions: any = {};
        if (!this.mixer) return actions;

        this.characterOptions = this.camera.userData.characterOptions;

        const actionNames = this.camera.userData.characterOptions.animationNames || {};

        for (const actionName in actionNames) {
            const clip =
                THREE.AnimationClip.findByName(this.animations, actionNames[actionName]) ||
                THREE.AnimationClip.findByName(this.animations, actionNames[actionName.toLowerCase()]);
            if (clip) {
                actions[actionName] = this.mixer.clipAction(clip);
                actions[actionName].clampWhenFinished = true;
            }
        }
        return actions;
    }

    private bindEventListeners(): void {
        document.addEventListener("keydown", this.boundHandleKeyDown);
        document.addEventListener("keyup", this.boundHandleKeyUp);
        document.addEventListener("mousedown", this.boundHandleMouseDown);
        document.addEventListener("mouseup", this.boundHandleMouseUp);
    }

    private unbindEventListeners(): void {
        document.removeEventListener("keydown", this.boundHandleKeyDown);
        document.removeEventListener("keyup", this.boundHandleKeyUp);
        document.removeEventListener("mousedown", this.boundHandleMouseDown);
        document.removeEventListener("mouseup", this.boundHandleMouseUp);
    }

    private handleActiveChat = (): void => {
        this.chatActivated = true;
    };
    private handleDeactivatedChat = (): void => {
        this.chatActivated = false;
    };

    private handleKeyDown(event: KeyboardEvent): void {
        this.velocity = 0;

        if (this.playerFallingBack) {
            return;
        }

        const key = keysMapping[event.keyCode];
        if (key && this.keysPressed.hasOwnProperty(key)) {
            this.keysPressed[key] = true;
        }

        //TODO Temp toggle FPS on the fly need design
        if (event.code === "Digit0") {
            if (this.currentWeapon) this.fpsEnabled = !this.fpsEnabled;
        }

        const weaponNumber = parseInt(event.code.replace("Digit", ""), 10);
        if (!isNaN(weaponNumber) && weaponNumber > 0 && weaponNumber <= this.weaponsData.length) {
            this.selectWeapon(weaponNumber);
            this.addVingeWeaponSpecialEffects();
        }

        //TODO Temp toggle player dust particles need design
        //TODO also need to fix dust y position
        if (event.code === "KeyP") {
            this.showPlayerDustParticles = !this.showPlayerDustParticles;
        }

        if (this.keysPressed.Q) {
            if (!this.gamePaused && this.currentWeapon) {
                this.hideCurrentWeapon = !this.hideCurrentWeapon;
            }
        }
    }

    private handleKeyUp(event: KeyboardEvent): void {
        this.velocity = 0;
        if (this.playerFallingBack) {
            return;
        }

        const key = keysMapping[event.keyCode];
        if (key && this.keysPressed.hasOwnProperty(key)) {
            this.keysPressed[key] = false;
        }
    }
    private canJump(): boolean {
        return this.player.userData.verticalStatus === CHARACTER_VERTICAL_STATUS_TYPES.STATIONARY;
    }

    private selectWeapon(index: number) {
        this.selectedWeaponIndex = index - 1;
        this.selectedWeapon = this.scene.userData.gameWeapons[this.selectedWeaponIndex];
        this.selectedWeapon!.userData.isCurrentWeapon = true;
        const weaponBehavior = this.selectedWeapon!.userData.behaviors?.find(
            (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON,
        );
        if (weaponBehavior && this.currentWeapon != this.selectedWeapon) {
            this.currentWeapon = this.selectedWeapon;
            this.weaponType = weaponBehavior.weaponType;
            this.currentHeldObjectName = weaponBehavior.selectedWeaponAmmoName;
            this.aimerUIImage = weaponBehavior.aimerUIImage;
            this.weaponHUDAimerSize = weaponBehavior.weaponHUDAimerSize;
            this.weaponClipAmount = weaponBehavior.weaponClipAmount;
            this.currentHeldObjectVisible = weaponBehavior.currentHeldObjectVisib;
        }
    }

    private handleMouseDown(event: MouseEvent): void {
        if (event.button === 0) {
            if (!this.gamePaused && !this.isPlayerReloading) {
                this.playerAttacking = true;
                if (this.currentHeldObject && this.currentHeldObject.userData.isThrowableobject) {
                    this.attack();
                }
                if (this.currentWeapon && this.weaponType) {
                    if (
                        this.weaponType === WEAPON_TYPES.MACHINE_GUN ||
                        this.weaponType === WEAPON_TYPES.SUB_MACHINE_GUN
                    ) {
                        this.attack();
                        if (this.ammoFireIntervalId === null) {
                            this.ammoFireIntervalId = window.setInterval(() => {
                                this.attack();
                            }, this.weaponFireSpeed! * 1000);
                        }
                    } else if (
                        this.weaponType === WEAPON_TYPES.SHOT_GUN ||
                        this.weaponType === WEAPON_TYPES.SNIPER_RIFLE ||
                        this.weaponType === WEAPON_TYPES.SCIFI_SNIPER_RIFLE ||
                        this.weaponType === WEAPON_TYPES.RIFLE
                    ) {
                        this.attack();
                    }
                }
            }
        }
    }

    private handleMouseUp(event: MouseEvent): void {
        if (event.button === 0) {
            if (!this.gamePaused) {
                this.playerAttacking = false;
                if (this.ammoFireIntervalId !== null) {
                    clearInterval(this.ammoFireIntervalId);
                    this.ammoFireIntervalId = null;
                }
            }
        }
    }

    private handleControlUnlock = () => {
        if (!this.gamePaused) {
            global.app!.call("pauseGame");
        }
    };

    private handleGamePaused = (): void => {
        this.scenePlayer.stopAnimationLoop();
        this.resetKeysPressed();
        this.gamePaused = true;
        EventBus.instance.send("game.pause");
        this.CameraControl?.update(this.gamePaused);
        this.player.userData.movingToTouchPoint = false;
    };

    private handleGameEnded = (): void => {
        this.scenePlayer.stopAnimationLoop();
        this.gamePaused = true;
        if (!this.app.isSpriteCharacter) {
            this.CameraControl?.unlockPointerLock();
        }
        this.CameraControl?.update(this.gamePaused);
        this.resetKeysPressed();
        this.unbindEventListeners();
    };

    private handleGameStarted = (): void => {
        this.scenePlayer.startAnimationLoop();
        this.gamePaused = false;
        this.bindEventListeners();
        if (!this.app.isSpriteCharacter) {
            this.CameraControl?.requestPointerLock();
        }
        this.CameraControl?.update(this.gamePaused);
        this.player.userData.movingToTouchPoint = false;
    };

    private handleGameResumed = (): void => {
        this.scenePlayer.startAnimationLoop();
        this.gamePaused = false;
        this.bindEventListeners();
        this.CameraControl?.update(this.gamePaused);
        this.player.userData.movingToTouchPoint = false;
    };

    updatePosition(mouseX: number, mouseY: number) {
        if (!this.player.userData.isSpriteCharacter) {
            return;
        }

        const selectedPoint = new THREE.Vector2();
        selectedPoint.x = (mouseX / window.innerWidth) * 2 - 1;
        selectedPoint.y = -(mouseY / window.innerHeight) * 2 + 1;

        this.raycasterFromCamera = new THREE.Raycaster();
        this.raycasterFromCamera.setFromCamera(selectedPoint, this.camera);

        const selectedObject = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
        const cameraIntersection = new THREE.Vector3();
        this.raycasterFromCamera.ray.intersectPlane(selectedObject, cameraIntersection);
        if (!cameraIntersection) {
            return;
        } else {
            const leftMouseClick = this.inputProvider.getAction("primary");
            if (this.player && leftMouseClick) {
                this.touchCount++;
                const intersects = this.raycasterFromCamera.intersectObjects(this.scene.children, true);

                if (intersects.length > 0) {
                    if (this.selectedHitObject) {
                        this.selectedHitObject.userData.stopAtLocation = false;
                    }
                    this.selectedHitObject = intersects[0].object;
                    let intersectionPoint = intersects[0].point;
                    for (let i = 0; i < intersects.length; i++) {
                        const hitObject = intersects[i].object;
                        if (hitObject.userData && hitObject.userData.behaviors) {
                            const harvestBehavior = hitObject.userData.behaviors.find(
                                (behavior: any) => behavior.type === OBJECT_TYPES.HARVEST,
                            );
                            if (harvestBehavior) {
                                this.selectedHitObject = hitObject;
                                intersectionPoint = intersects[i].point;
                                this.selectedHitObject.userData.stopAtLocation = true;
                                break;
                            }
                        } else {
                            if (this.selectedHitObject != this.player || !this.selectedHitObject.visible) {
                                intersectionPoint = intersects[i].point;
                                this.scene.userData.freeMovement = true;
                                break;
                            }
                        }
                    }

                    this.player.userData.movingToTouchPoint = true;

                    if (intersectionPoint) {
                        const targetPosition = intersectionPoint.clone();
                        const directionToHarvest = targetPosition.sub(this.player.position).normalize();
                        this.player.userData.directionToHarvest = directionToHarvest;

                        const playerDirection = this.player.getWorldDirection(new THREE.Vector3()).normalize();
                        const crossProduct = new THREE.Vector3().crossVectors(playerDirection, directionToHarvest);
                        let movementDirection = MOVEMENT_STATES.STOPPED;
                        if (crossProduct.y > 0) {
                            movementDirection = MOVEMENT_STATES.LEFT;
                        } else if (crossProduct.y < 0) {
                            movementDirection = MOVEMENT_STATES.RIGHT;
                        }

                        this.player.userData.movementDirection = movementDirection;
                    }

                    this.intersectPointX = intersectionPoint.x;
                    this.intersectPointY = intersectionPoint.y;
                    this.intersectPointZ = intersectionPoint.z;
                }
            }
        }
    }

    update = () => {
        if (this.scene.userData.isGameMenuOpen || (this.gamePaused && !this.player) || this.chatActivated) {
            this.intersectPointX = this.player.position.x;
            this.intersectPointY = this.player.position.y;
            this.intersectPointZ = this.player.position.z;
            this.player.userData.movingToTouchPoint = false;
            this.scene.userData.freeMovement = false;
            return;
        }

        const mouseToucnPoint = this.inputProvider.getMouseTouchPosition();

        if (mouseToucnPoint && this.player.userData.isSpriteCharacter && !this.player.userData.isGameMenuOpen) {
            this.scene.userData.touchCount = this.touchCount;
            this.updatePosition(mouseToucnPoint.x, mouseToucnPoint.y);
            const stopPoint = new THREE.Vector3(this.intersectPointX, 0, this.intersectPointZ);
            const distance = this.player.position.distanceTo(stopPoint);
            let threshold = distance * 0.1;
            if (this.scene.userData.freeMovement) {
                threshold = 0.15;
                const deltaX = Math.abs(this.player.position.x - this.intersectPointX);
                const deltaZ = Math.abs(this.player.position.z - this.intersectPointZ);
                if (deltaX <= threshold && deltaZ <= threshold) {
                    this.player.userData.movingToTouchPoint = false;
                    this.scene.userData.freeMovement = false;
                }
            }
            if (distance <= threshold) {
                this.player.userData.movingToTouchPoint = false;
                this.scene.userData.freeMovement = false;
            }
        } else {
            this.player.userData.movingToTouchPoint = false;
        }

        this.delta = this.clock.getDelta();

        //Enable FPS mode
        if (this.controlType == CAMERA_TYPES.FIRST_PERSON) {
            this.fpsEnabled = true;
            this.overTheShoulder = true;
            this.otsRightShoulderCamera = true;
        }

        //Enable 3rd Person only
        if (this.controlType == CAMERA_TYPES.THIRD_PERSON) {
            this.fpsEnabled = true;
            this.overTheShoulder = false;
            this.otsRightShoulderCamera = false;
        }

        ////Enable Fortnite Over The Shoulder mode
        if (this.controlType == CAMERA_TYPES.FORTNITE) {
            this.fpsEnabled = false;
            this.overTheShoulder = true;
            this.otsRightShoulderCamera = true;
        }

        const lateralSpeed = this.inputProvider.getMotion("lateral");
        const forwardSpeed = this.inputProvider.getMotion("forward");
        const runState = this.inputProvider.getAction("run");
        const jumpState = this.inputProvider.getAction("jump");
        this.scene.userData.pressE = this.inputProvider.getAction("use");

        if (this.canJump() && jumpState) {
            this.isJumping = true;
        } else {
            this.isJumping = false;
        }

        let isMoving = lateralSpeed !== 0 || forwardSpeed !== 0;
        let isRunning = isMoving && runState;
        this.player.userData.isMoving = isMoving;
        this.player.userData.currentCamera = this.camera;

        // TODO move this to weapons behavior - drop weapon
        if (this.inputProvider.getAction("drop")) {
            if (!this.fpsEnabled) {
                this.dropWeapon = true;
                global.app!.call("removeGunAimer", this);
            }
        } else {
            this.dropWeapon = false;
        }

        if (this.player.userData.movingToTouchPoint && !this.player.userData.isGameMenuOpen) {
            if (this.player.userData.isOnMobileDevice) {
                this.velocity = this.walkSpeed * 1.5;
            } else {
                this.velocity = this.walkSpeed;
            }
        } else {
            this.velocity = 0;
        }

        //this handles character touch movement
        if (this.player.userData.touchJoystickIsRunning) {
            isRunning = this.player.userData.touchJoystickIsRunning;
            this.runSpeed = this.player.userData.currentSpeed;
        }

        if (this.player.userData.touchMovementState) {
            this.isJumping = this.player.userData.touchJump;
            if (isMoving) {
                this.walkSpeed = this.player.userData.currentSpeed;
                this.runSpeed = isRunning ? this.player.userData.currentSpeed : this.runSpeed;
            }
            if (this.isJumping) {
                this.velocity = this.player.userData.currentSpeed;
            }
        }

        if (this.characterOptions) {
            this.newAction = this.characterOptions.idleAnimation || null;
        } else {
            this.newAction = null;
        }

        if (this.currentHeldObject) {
            this.weaponSelectedThrowable = this.currentHeldObject;
        }

        if (!this.scene.userData.isGameMenuOpen) {
            this.movePlayer(isMoving, isRunning, this.velocity, this.delta);
        } else {
            this.velocity = 0;
        }

        if (this.mixer) {
            this.mixer.update(this.delta);
        }

        if (this.currentAction !== this.newAction && this.currentAction != null) {
            const previousAction = this.actions[this.currentAction];
            this.currentAction = this.newAction;
            this.playCurrentAnimation(previousAction);
        }

        this.pickedUpObject = false;

        if (!this.currentWeapon) {
            /*this.scene!.traverse((object: THREE.Object3D) => {
                if (this.pickedUpObject && this.currentHeldObject) {
                    return;
                }

                if (object.userData?.behaviors) {
                    const throwableBehavior = object.userData.behaviors.find(
                        (behavior: any) => behavior.type === OBJECT_TYPES.THROWABLE,
                    );

                    if (
                        throwableBehavior &&
                        object.userData.isThrowable &&
                        !this.currentHeldObject?.userData?.isAmmoForWeapon
                    ) {
                        this.pickedUpObject = true;

                        const {
                            weight,
                            powerLevel,
                            bounceEffect,
                            aimer,
                            aimerGuide,
                            throwable_visible,
                            throwableVisible,
                            throwableMass,
                            throwableSpeed,
                            throwableLife,
                            throwableFriction,
                            throwableRestitution,
                            throwableInertia,
                        } = throwableBehavior;

                        this.currentHeldObjectMass = throwableMass;
                        this.currentHeldObjectSpeed = throwableSpeed;
                        this.currentHeldObjectLife = throwableLife;

                        this.currentHeldObjectVisible = throwableVisible;
                        this.currentHeldObjectFriction = throwableFriction;
                        this.currentHeldObjectRestitution = throwableRestitution;
                        this.currentHeldObjectInertia = throwableInertia;

                        this.currentHeldObject = object;
                        this.currentHeldObject.userData.isThrowableobject = true;

                        const boneWorldPosition = new THREE.Vector3();
                        this.weaponAttachedBone?.getWorldPosition(boneWorldPosition);

                        const boneWorldQuaternion = new THREE.Quaternion();
                        this.weaponAttachedBone?.getWorldQuaternion(boneWorldQuaternion);

                        object.position.copy(boneWorldPosition);
                        object.quaternion.copy(boneWorldQuaternion);

                        const additionalRotation = new THREE.Quaternion();
                        additionalRotation.setFromEuler(
                            new THREE.Euler(
                                THREE.MathUtils.degToRad(90),
                                THREE.MathUtils.degToRad(0),
                                THREE.MathUtils.degToRad(-90),
                            ),
                        );

                        object.quaternion.multiplyQuaternions(boneWorldQuaternion, additionalRotation);

                        if (!this.otsRightShoulderCamera) {
                            const offset = new THREE.Vector3(0, 0, 0);
                            offset.applyQuaternion(boneWorldQuaternion);
                        } else {
                            object.position.add(this.otsShiftVector);
                        }
                        if (this.isDebug) {
                            console.log("Throwable Picked Up" + object.name);
                        }
                        return;
                    }
                }
            });*/
            return;
        }

        if (this.weaponAttachedBone) {
            if (this.dropWeapon) {
                // If weapon drop is triggered (e.g., by pressing 'F')

                //Handle Drop Weapon
                if (this.currentWeapon) {
                    let dropped_weapon = this.currentWeapon;
                    this.currentWeapon.userData.allowPickUp = false;
                    setTimeout(() => {
                        dropped_weapon.userData.allowPickUp = true;
                    }, 5000);

                    //TODO add remaining props
                    this.currentWeapon = null;
                    this.weaponType = null;
                    this.currentHeldObjectName = null;
                    this.pickedUpObject = false;
                    this.currentHeldObject = null;
                    if (this.isDebug) {
                        console.log("Weapon Dropped");
                    }
                    return;
                }

                //handle dropping a throwable object after dropping a weapon
                if (!this.currentWeapon) {
                    /*this.scene!.traverse((object: THREE.Object3D) => {
                        if (object.userData?.behaviors) {
                            const throwableBehavior = object.userData.behaviors.find(
                                (behavior: any) => behavior.type === OBJECT_TYPES.THROWABLE,
                            );
                            if (
                                throwableBehavior &&
                                object.userData.isThrowable &&
                                !this.currentHeldObject?.userData?.isAmmoForWeapon
                            ) {
                                if (this.player) {
                                    object.userData.isThrowable = false;
                                    object.userData.allowPickUp = false;
                                    this.currentHeldObject = null;

                                    if (this.isDebug) {
                                        console.log("Throwable Dropped");
                                    }
                                    setTimeout(() => {
                                        object.userData.allowPickUp = true;
                                    }, 5000);
                                }
                                return;
                            }
                            return;
                        }
                    });*/
                    return;
                }
            } else {
                // Handle weapon pickup or switching with ammo
                /*this.scene!.traverse((object: THREE.Object3D) => {
                    if (object.userData?.behaviors) {
                        const weaponBehavior = object.userData.behaviors.find(
                            (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON,
                        );
                        if (weaponBehavior && !this.weaponAutoPickUp) {
                            object.visible = false;
                        }
                        if (
                            (weaponBehavior?.weaponStarting &&
                                this.weaponAutoPickUp &&
                                !this.isPlayerReloading &&
                                !this.playerAttacking &&
                                !this.CameraControl?.isAimingDownSites) ||
                            (!this.weaponAutoPickUp &&
                                object == this.selectedWeapon! &&
                                object.userData.weaponIndex == this.selectedWeaponIndex)
                        ) {
                            this.currentWeapon = object;
                            // if (this.controlType == CAMERA_TYPES.FIRST_PERSON) {
                            //     object.visible = this.hideCurrentWeapon;
                            // } else {
                            object.visible = true;
                            // }
                            this.player.userData.hideCurrentWeapon = this.hideCurrentWeapon;
                            weaponBehavior.weaponStarting = false;
                            this.weaponType = weaponBehavior.weaponType;
                            this.currentHeldObjectName = weaponBehavior.selectedWeaponAmmoName;
                            this.weaponHUDAimerSize = weaponBehavior.weaponHUDAimerSize;
                            this.weaponFireSpeed = weaponBehavior.weaponFireSpeed;
                            this.aimerUIImage = weaponBehavior.aimerUIImage;
                            this.weaponClipAmount = weaponBehavior.weaponClipAmount;
                            this.currentHeldObjectVisible = weaponBehavior.currentHeldObjectVisible;
                            this.currentWeapon.userData.weaponHUDAimerSize = weaponBehavior.weaponHUDAimerSize;
                            this.CameraControl!.weaponScopeZoom = weaponBehavior.weaponScopeZoom;
                            this.currentWeapon.userData.weaponType = this.weaponType;
                            this.currentWeapon.userData.isCurrentWeapon = true;
                            this.currentWeapon.userData.clipAmount = this.weaponClipAmount;

                            object.userData.weaponDropped = true;
                            this.weaponMuzzleFlashBrightness = weaponBehavior.weaponMuzzleFlashBrightness;
                            this.weaponMuzzleSmokeDensity = weaponBehavior.weaponMuzzleSmokeDensity;
                            this.weaponMuzzleSmokeSize = weaponBehavior.weaponMuzzleSmokeSize;
                            this.weaponMuzzleSmokeLife = weaponBehavior.weaponMuzzleSmokeLife;
                            this.weaponMuzzleSmokeOpacity = weaponBehavior.weaponMuzzleSmokeOpacity;

                            setTimeout(() => {
                                object.userData.weaponDropped = false;
                            }, 3000);

                            if (this.isDebug) {
                                console.log("Switched Weapon: " + object.name);
                            }

                            return;
                        }

                        if (this.currentWeapon && object.name === this.currentHeldObjectName) {
                            const currentHeldObjectBehavior = object.userData.behaviors.find(
                                (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON_AMMO,
                            );

                            if (currentHeldObjectBehavior && this.currentHeldObjectName == object.name) {
                                this.currentHeldObject = object;
                                this.currentHeldObject.userData.isAmmoForWeapon = true;

                                const { ammoMass, ammoSpeed, ammoLife, ammoFriction, ammoRestitution, ammoInertia } =
                                    currentHeldObjectBehavior;

                                this.currentHeldObjectMass = ammoMass;
                                this.currentHeldObjectSpeed = ammoSpeed;
                                this.currentHeldObjectLife = ammoLife;
                                this.currentHeldObjectFriction = ammoFriction;
                                this.currentHeldObjectRestitution = ammoRestitution;
                                this.currentHeldObjectInertia = ammoInertia;

                                if (this.isDebug) {
                                    console.log("Current Ammo: " + this.currentHeldObject?.name);
                                }

                                return;
                            }
                        }
                        return;
                    }
                });*/
            }

            if (this.currentWeapon) {
                //sync bones to weapon

                const boneWorldPosition = new THREE.Vector3();
                this.weaponAttachedBone.getWorldPosition(boneWorldPosition);

                const boneWorldQuaternion = new THREE.Quaternion();
                this.weaponAttachedBone.getWorldQuaternion(boneWorldQuaternion);

                if (this.isDebug) {
                    console.log(`Bone Name:`, this.weaponAttachedBone.name);
                    console.log(`Bone World Position:`, boneWorldPosition);
                    console.log(`Bone World Rotation:`, boneWorldQuaternion);
                }

                const playerWorldQuaternion = new THREE.Quaternion();
                this.player.getWorldQuaternion(playerWorldQuaternion);

                this.currentWeapon.position.copy(boneWorldPosition);
                this.currentWeapon.quaternion.copy(playerWorldQuaternion);

                const correctionQuaternion = new THREE.Quaternion();
                correctionQuaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI);
                this.currentWeapon.quaternion.multiply(correctionQuaternion);

                if (!this.otsRightShoulderCamera) {
                    const offset = new THREE.Vector3(0, 0, 0);
                    offset.applyQuaternion(playerWorldQuaternion);
                } else {
                    this.currentWeapon.position.add(this.otsShiftVector);
                }

                //handle weapon auto reloading
                // if (this.fpsEnabled && this.controlType === CAMERA_TYPES.FIRST_PERSON) {
                //     this.allowWeaponAmmoReload = this.weaponAutoReload;
                // }
            }
        }

        if (this.overTheShoulder) {
            this.handleOverTheShoulder();
            this.currentWeapon.userData.overTheShoulder = true;
        } else {
            this.currentWeapon.userData.overTheShoulder = false;
        }

        if (this.showPlayerDustParticles) {
            this.createDustEffect();
        }

        if (this.player) {
            const boundingBox = new THREE.Box3().setFromObject(this.player);
            this.otsPlayerWidth = boundingBox.max.x - boundingBox.min.x;
            this.otsPlayerHeight = boundingBox.max.y - boundingBox.min.y;
            this.otsShiftAmount = this.player.position.x + this.otsPlayerWidth / 2;
        }
    };

    createDustEffect() {
        // TODO: Make dust and other effects a Stem
        if (this.overTheShoulder) {
            if (this.velocity !== 0 || this.isJumping || this.cameraRotating) {
                const pixelSize = 15;
                const relativeParticleSize = pixelSize * this.otsPlayerHeight;
                const particleCount = 3;

                this.effectsManager.createFootDustEffect(
                    this.player.position, // Player's position
                    particleCount, // Number of particles based on player's width
                    0.5, // Fade out duration
                    new THREE.Color(0.8, 0.8, 0.8), // Slightly darker than white (light gray)
                    relativeParticleSize, // Size of the particles
                    0.35, // Starting opacity of the particle
                    this.otsPlayerWidth,
                );
            }
        }
    }

    setPlayerFallBack() {
        this.playerFallingBack = true;
    }

    setPlayerIsDead() {
        this.playerIsDead = true;
    }

    private playCurrentAnimation(previousAction: AnimationAction | null = null) {
        if (!this.currentAction || !this.actions[this.currentAction]) {
            /*console.warn(
                "3pc.playCurrentAnimation: current action is not set correctly: ",
                this.currentAction,
                this.actions[this.currentAction!],
                this.actions,
            );*/
            return;
        }

        if (previousAction && previousAction.getClip().name === this.actions[this.currentAction!].getClip().name) {
            return;
        }

        this.physics.setCurrentAnimation(this.player.uuid, this.actions[this.currentAction!].getClip().name);

        if (this.isPlayerReloading) {
            const reloadAction = this.actions[this.currentAction!];
            const reloadDuration = reloadAction.getClip().duration / reloadAction.timeScale;
            reloadAction.setLoop(THREE.LoopOnce, 1);
            reloadAction.reset().play();
            this.mixer!.addEventListener("finished", (event: any) => {
                if (event.action === reloadAction) {
                    this.isPlayerReloading = false;
                    this.currentAction = this.characterOptions?.idleAnimation;
                    if (this.currentAction) {
                        const nextAction = this.actions[this.currentAction!];
                        nextAction.reset().play();
                    }
                }
            });
            return;
        }

        if (previousAction) {
            if (this.cameraRotating && this.velocity === 0 && this.overTheShoulder) {
                previousAction.fadeOut(0.25);
                this.actions[this.currentAction!].reset().fadeIn(0.25).play();
            } else {
                previousAction.fadeOut(0.25);
                this.actions[this.currentAction!].reset().fadeIn(0.25).play();
            }
        } else {
            this.actions[this.currentAction!].play();
        }
    }

    movePlayer(isMoving: boolean, isRunning: boolean, velocity: number, delta: number) {
        const friction = 0.1;
        this.isStopped = !isMoving && !this.isJumping;

        const lateralSpeed = this.inputProvider.getMotion("lateral");
        const forwardSpeed = this.inputProvider.getMotion("forward");

        // TODO: motion should use normalized number values for variable speed, not booleans
        const moveLeft = lateralSpeed < 0;
        const moveRight = lateralSpeed > 0;
        const moveForward = forwardSpeed > 0;
        const moveBackward = forwardSpeed < 0;

        this.adjustMovementDirectionBasedOnInput();

        switch (true) {
            case this.isJumping:
                if (this.characterOptions?.jumpAnimation) {
                    this.newAction = this.characterOptions.jumpAnimation;
                }
                if (isMoving) {
                    this.velocity = isRunning ? this.runSpeed : this.walkSpeed;
                }
                break;

            case this.playerFallingBack:
                if (this.characterOptions?.fallAnimation) {
                    this.newAction = this.characterOptions.fallAnimation;
                }
                setTimeout(
                    () => {
                        this.playerFallingBack = false;
                        this.velocity = 0;
                    },
                    this.characterOptions?.fallDelay ? this.characterOptions.fallDelay * 1000 : 1000,
                );
                break;

            case this.playerIsDead:
                if (this.characterOptions?.dieAnimation) {
                    this.newAction = this.characterOptions.dieAnimation;
                }
                setTimeout(() => {
                    this.playerIsDead = false;
                    this.velocity = 0;
                }, 1000);
                break;

            case this.playerAttacking &&
                isMoving &&
                (this.weaponType === WEAPON_TYPES.SHOT_GUN ||
                    this.weaponType === WEAPON_TYPES.MACHINE_GUN ||
                    this.weaponType === WEAPON_TYPES.RIFLE ||
                    this.weaponType === WEAPON_TYPES.SNIPER_RIFLE ||
                    this.weaponType === WEAPON_TYPES.SCIFI_SNIPER_RIFLE):
                if (this.characterOptions?.shootAnimation) {
                    this.newAction = this.characterOptions.shootAnimation;
                }
                this.velocity = this.walkSpeed;
                break;

            case this.playerAttacking &&
                this.isStopped &&
                (this.weaponType === WEAPON_TYPES.SHOT_GUN ||
                    this.weaponType === WEAPON_TYPES.MACHINE_GUN ||
                    this.weaponType === WEAPON_TYPES.RIFLE ||
                    this.weaponType === WEAPON_TYPES.SNIPER_RIFLE ||
                    this.weaponType === WEAPON_TYPES.SCIFI_SNIPER_RIFLE):
                if (this.characterOptions?.shootAnimation) {
                    this.newAction = this.characterOptions.shootAnimation;
                }
                break;

            case this.playerAttacking &&
                (this.weaponType === WEAPON_TYPES.STAFF ||
                    this.weaponType === WEAPON_TYPES.KNIFE ||
                    this.weaponType === WEAPON_TYPES.SWORD):
                if (this.characterOptions?.swordSimpleAnimation && !this.fpsEnabled) {
                    this.newAction = this.characterOptions.swordSimpleAnimation;
                    this.velocity = this.walkSpeed / 2;
                }
                break;

            case isRunning:
                if (this.characterOptions?.runAnimation) {
                    this.newAction = this.characterOptions.runAnimation;
                }
                this.velocity = this.runSpeed;
                break;

            case moveBackward:
                if (this.characterOptions?.reverseDirectionAnimation) {
                    this.newAction = this.characterOptions.reverseDirectionAnimation;
                }
                if (this.currentWeapon && this.playerAttacking && this.fpsEnabled) {
                    this.newAction = this.characterOptions?.shootAnimation;
                }
                this.velocity = this.walkSpeed;
                break;

            case moveLeft:
                if (this.characterOptions?.leftDirectionAnimation) {
                    this.newAction = this.characterOptions.leftDirectionAnimation;
                }
                if (
                    this.characterOptions?.rightDirectionAnimation &&
                    this.currentWeapon &&
                    this.playerAttacking &&
                    this.fpsEnabled
                ) {
                    this.newAction = this.characterOptions.shootAnimation;
                }
                this.velocity = this.walkSpeed;
                break;

            case moveRight:
                if (this.characterOptions?.rightDirectionAnimation) {
                    this.newAction = this.characterOptions.rightDirectionAnimation;
                }
                if (
                    this.characterOptions?.rightDirectionAnimation &&
                    this.currentWeapon &&
                    this.playerAttacking &&
                    this.fpsEnabled
                ) {
                    this.newAction = this.characterOptions.shootAnimation;
                }
                this.velocity = this.walkSpeed;
                break;

            case (isMoving && moveForward) ||
                this.player.userData.touchMovementState === MOBILE_JOYSTICK_MOVEMENT_STATE.FORWARD ||
                this.player.userData.touchMovementState === MOBILE_JOYSTICK_MOVEMENT_STATE.BACKWARD ||
                this.player.userData.touchMovementState === MOBILE_JOYSTICK_MOVEMENT_STATE.STRAIGHT_FORWARD ||
                this.player.userData.touchMovementState === MOBILE_JOYSTICK_MOVEMENT_STATE.STRAIGHT_BACKWARD:
                if (this.characterOptions?.walkAnimation && !this.currentWeapon) {
                    this.newAction = this.characterOptions.walkAnimation;
                }
                if (this.characterOptions?.walkAnimation && this.currentWeapon) {
                    this.newAction = this.characterOptions.walkAnimation;
                }
                this.velocity = this.walkSpeed;
                break;

            case isMoving && moveForward && this.playerAttacking:
                if (this.characterOptions?.shootAnimation && this.currentWeapon) {
                    this.newAction = this.characterOptions.shootAnimation;
                }
                this.velocity = this.walkSpeed;
                break;

            //FIXME: looks like weapon will not reload if the player is not moving
            case this.inputProvider.getAction("reload") && this.allowWeaponAmmoReload:
                this.isPlayerReloading = true;
                this.newAction = this.characterOptions.reloadAnimation;
                break;

            case isMoving && moveBackward:
                if (this.characterOptions?.walkAnimation && !this.currentWeapon) {
                    this.newAction = this.characterOptions.walkAnimation;
                }
                this.velocity = this.walkSpeed;
                break;

            default:
                if (this.characterOptions?.idleAnimation) {
                    this.newAction = this.characterOptions.idleAnimation;
                }

                if (this.velocity > 0) {
                    this.velocity -= friction;
                    if (this.velocity < 0) this.velocity = 0;
                }
                if (this.isStopped && !this.overTheShoulder) {
                    this.maintainPlayerDirection();
                }
                break;
        }

        switch (this.player.userData.verticalStatus) {
            case CHARACTER_VERTICAL_STATUS_TYPES.FALLING_DOWN:
                this.newAction = this.characterOptions?.fallAnimation;
                break;
            case CHARACTER_VERTICAL_STATUS_TYPES.JUMPING_UP:
                this.newAction = this.characterOptions?.jumpAnimation;
                break;
            case CHARACTER_VERTICAL_STATUS_TYPES.LANDED:
                this.newAction = this.characterOptions?.idleAnimation;
                this.velocity = 0;
                break;
        }

        const isReversed = this.initialXRotation === "-x";
        let angleYCameraDirection = Math.atan2(
            this.camera.position.x - this.player.position.x,
            this.camera.position.z - this.player.position.z,
        );

        if (isReversed) {
            angleYCameraDirection += Math.PI;
        }

        this.playerDirection = new THREE.Vector3(0, 0, -1);
        this.playerDirection.applyQuaternion(this.player.quaternion);

        if (!this.overTheShoulder && !this.player.userData.movingToTouchPoint) {
            if (isMoving || !this.isJumping) {
                let directionOffset = this.directionOffset();
                this.rotateQuarternion.setFromAxisAngle(this.rotateAngle, angleYCameraDirection + directionOffset);
                const lerpFactor = this.lookSpeed;
                this.player.quaternion.slerp(this.rotateQuarternion, lerpFactor);
            }
        } else {
            this.handleOverTheShoulder();
        }

        const fps = 1 / delta;
        const targetFPS = 60;
        const fpsRatio = Math.min(Math.max(fps / targetFPS, 0.5), 2);

        if (this.isPhysicsEnabled) {
            const movementSpeed = this.velocity * delta * fpsRatio;

            this.physics.movePlayerObject(
                this.player.uuid,
                {
                    x: this.walkDirection.x * movementSpeed,
                    y: 0,
                    z: this.walkDirection.z * movementSpeed,
                } as Vector3,
                this.isJumping,
            );

            if (!this.overTheShoulder) {
                let newPlayerRotation = this.player.quaternion
                    .clone()
                    .rotateTowards(this.rotateQuarternion, this.lookSpeed);
                this.physics.setRotation(this.player.uuid, newPlayerRotation);
                this.maintainPlayerDirection();
            }
        }

        this.CameraControl?.update(this.gamePaused);
    }

    maintainPlayerDirection() {
        if (this.playerDirection) {
            this.targetQuaternion = new THREE.Quaternion();
            this.targetQuaternion.setFromUnitVectors(
                new THREE.Vector3(0, 0, -1),
                this.playerDirection.clone().normalize(),
            );
            this.player.quaternion.copy(this.targetQuaternion);
        }
    }

    adjustMovementDirectionBasedOnInput() {
        if (this.player.userData.movingToTouchPoint) {
            this.walkDirection = this.player.userData.directionToHarvest;
        } else {
            this.camera.getWorldDirection(this.walkDirection);
            this.walkDirection.y = 0; // Ignore vertical direction
            this.walkDirection.normalize();

            const currentYAngle = Math.atan2(this.walkDirection.x, this.walkDirection.z);

            let directionOffset = this.directionOffset();

            const targetAngle = currentYAngle + directionOffset;

            this.walkDirection.set(Math.sin(targetAngle), 0, Math.cos(targetAngle));

            this.walkDirection.normalize();
        }
    }

    directionOffset() {
        let directionOffset = 0;

        const moveLeft = this.inputProvider.getMotion("lateral") < 0;
        const moveRight = this.inputProvider.getMotion("lateral") > 0;
        const moveForward = this.inputProvider.getMotion("forward") > 0;
        const moveBackward = this.inputProvider.getMotion("forward") < 0;

        if (moveLeft || moveRight || moveForward || moveBackward) {
            this.player.userData.inputActiveMovement = true;
        }

        const forward =
            moveForward ||
            this.player.userData.touchMovementState === MOBILE_JOYSTICK_MOVEMENT_STATE.FORWARD ||
            this.player.userData.gamepadMovementState === GAMEPAD_JOYSTICK_MOVEMENT_STATE.FORWARD;

        const straightForward =
            this.player.userData.touchMovementState === MOBILE_JOYSTICK_MOVEMENT_STATE.STRAIGHT_FORWARD;
        const straightBackward =
            this.player.userData.touchMovementState === MOBILE_JOYSTICK_MOVEMENT_STATE.STRAIGHT_BACKWARD;

        const backward =
            moveBackward ||
            this.player.userData.touchMovementState === MOBILE_JOYSTICK_MOVEMENT_STATE.BACKWARD ||
            this.player.userData.gamepadMovementState === GAMEPAD_JOYSTICK_MOVEMENT_STATE.BACKWARD;

        const left =
            moveLeft ||
            this.player.userData.touchDirectionState === MOBILE_JOYSTICK_DIRECTION_STATE.LEFT ||
            this.player.userData.gamepadDirectionState === GAMEPAD_JOYSTICK_DIRECTION_STATE.LEFT;

        const right =
            moveRight ||
            this.player.userData.touchDirectionState === MOBILE_JOYSTICK_DIRECTION_STATE.RIGHT ||
            this.player.userData.gamepadDirectionState === GAMEPAD_JOYSTICK_DIRECTION_STATE.RIGHT;

        let movementDirection = MOVEMENT_STATES.STOPPED;

        if (forward && left) {
            movementDirection = MOVEMENT_STATES.FORWARD_LEFT;
            directionOffset = Math.PI / 4;
        } else if (forward && right) {
            movementDirection = MOVEMENT_STATES.FORWARD_RIGHT;
            directionOffset = -Math.PI / 4;
        } else if (backward && left) {
            movementDirection = MOVEMENT_STATES.BACKWARD_LEFT;
            directionOffset = (3 * Math.PI) / 4;
        } else if (backward && right) {
            movementDirection = MOVEMENT_STATES.BACKWARD_RIGHT;
            directionOffset = -(3 * Math.PI) / 4;
        } else if (forward) {
            movementDirection = MOVEMENT_STATES.FORWARD;
            directionOffset = 0;
        } else if (backward) {
            movementDirection = MOVEMENT_STATES.BACKWARD;
            directionOffset = Math.PI;
        } else if (left) {
            movementDirection = MOVEMENT_STATES.LEFT;
            directionOffset = Math.PI / 2;
        } else if (right) {
            movementDirection = MOVEMENT_STATES.RIGHT;
            directionOffset = -Math.PI / 2;
        }

        this.player.userData.movementDirection = movementDirection;

        if (straightBackward) {
            directionOffset = Math.PI;
        }
        if (straightForward) {
            directionOffset = 0;
        }

        return directionOffset;
    }

    handleOverTheShoulder() {
        let cameraDirection = new THREE.Vector3();
        this.camera.getWorldDirection(cameraDirection);

        let playerRotationY = Math.atan2(cameraDirection.x, cameraDirection.z);
        this.player.rotation.y = playerRotationY;

        this.otsShiftVector = new THREE.Vector3();

        if (this.weaponAutoPickUp || !this.fpsEnabled) {
            this.otsShiftAmount = (2 / 3) * this.otsPlayerWidth;
            this.otsPlayerWidth;
            this.otsShiftVector.setFromMatrixColumn(this.camera.matrixWorld, 0);
            this.otsShiftVector.normalize().multiplyScalar(-this.otsShiftAmount);
        }

        if (this.otsShiftVector && this.otsRightShoulderCamera) {
            this.physics?.addotsShiftVector(this.otsShiftVector);
            this.player.position.add(this.otsShiftVector);
            this.player.userData.otsOffset = this.otsShiftVector.clone();
        }

        let lastCheckTime = 0;
        const checkInterval = 1000;
        const currentPlayerRotationY = this.player.rotation.y;
        const rotationDifference = currentPlayerRotationY - this.previousPlayerRotationY;
        const currentTime = Date.now();
        this.cameraRotating = false;

        if (currentTime - lastCheckTime > checkInterval && !this.fpsEnabled) {
            if (rotationDifference > 0.01 && this.velocity == 0) {
                // Rotating left
                this.cameraRotating = true;
                if (this.characterOptions?.rightDirectionAnimation) {
                    this.newAction = this.characterOptions?.leftDirectionAnimation;
                }
            } else if (rotationDifference < -0.01 && this.velocity == 0) {
                // Rotating right
                this.cameraRotating = true;
                if (this.characterOptions?.leftDirectionAnimation) {
                    this.newAction = this.characterOptions?.rightDirectionAnimation;
                }
            }

            this.previousPlayerRotationY = currentPlayerRotationY;
            lastCheckTime = currentTime;
        }

        //handle fps on the fly with weapon
        if (
            this.currentWeapon &&
            this.currentWeapon?.userData?.aimerID &&
            this.fpsEnabled &&
            this.otsShiftVector &&
            this.otsRightShoulderCamera
        ) {
            this.currentWeapon.userData.isCurrentWeapon = true;
            this.currentWeapon.userData.weaponAimerSize = this.weaponHUDAimerSize;
            this.currentWeapon.userData.fpsEnabled = this.fpsEnabled;
            this.currentWeapon.userData.weaponType = this.weaponType;

            const direction = new THREE.Vector3();
            this.camera.getWorldDirection(direction);
            this.player.lookAt(this.player.position.clone().add(direction));
            this.player.rotation.y = -this.camera.rotation.y;

            //TODO keep testing this on other character models it will need upgrading
            //as we find out more on character weapon bone binding.
            const headBoneWorldPosition = new THREE.Vector3();
            const headTopEndWorldPosition = new THREE.Vector3();
            const leftShoulderWorldPosition = new THREE.Vector3();
            this.characterHeadBone?.getWorldPosition(headBoneWorldPosition);
            this.characterHeadTopEndBone?.getWorldPosition(headTopEndWorldPosition);
            this.characterLeftShoulderBone?.getWorldPosition(leftShoulderWorldPosition);
            if (this.CameraControl?.isAimingDownSites) {
                if (this.characterHeadTopEndBone) {
                    this.camera.position.set(
                        this.currentWeapon.position.x,
                        headTopEndWorldPosition.y,
                        this.currentWeapon.position.z,
                    );
                } else {
                    this.camera.position.set(
                        this.currentWeapon.position.x,
                        headBoneWorldPosition.y + this.otsPlayerHeight * 0.1,
                        this.currentWeapon.position.z,
                    );
                }
                switch (this.currentWeapon.userData.weaponType) {
                    case WEAPON_TYPES.SNIPER_RIFLE:
                        this.player.visible = false;
                        this.currentWeapon.visible = false;
                        break;
                    case WEAPON_TYPES.SCIFI_SNIPER_RIFLE:
                        this.player.visible = false;
                        this.currentWeapon.visible = false;
                        break;
                }
            } else {
                this.camera.position.set(
                    leftShoulderWorldPosition.x,
                    headBoneWorldPosition.y,
                    leftShoulderWorldPosition.z,
                );
                // if (this.controlType == CAMERA_TYPES.FIRST_PERSON) {
                //     this.player.visible = this.hideCurrentWeapon;
                // }
            }
        }
    }

    resetKeysPressed() {
        for (const key in this.keysPressed) {
            if (this.keysPressed.hasOwnProperty(key)) {
                this.keysPressed[key] = false;
            }
        }
    }

    public dispose() {
        this.unbindEventListeners();

        if (global.app) {
            global.app.on("playerFallBack.3PC", null);
            global.app.on("playerDead.3PC", null);
            global.app!.on("gameStarted.3PC", null);
            global.app!.on("pauseGame.3PC", null);
            global.app!.on("gameEnded.3PC", null);
            global.app!.on("unlockEvent.3PC", null);
            global.app!.on("chatActivated.3PC", null);
            global.app!.on("chatDeactivated.3PC", null);
        }

        if (this.mixer) {
            this.mixer.stopAllAction();
        }

        for (let i = 0; i < this.projectiles.length; i++) {
            const projectile = this.projectiles[i];
            this.disposeProjectile(projectile);
        }
        this.projectiles = [];

        if (this.CameraControl) {
            this.CameraControl.dispose();
        }

        if (this.GunMuzzleFlashEffect) {
            this.GunMuzzleFlashEffect.dispose();
        }

        this.effectsManager = null;
    }

    private setInitalPlayerState() {}

    private getCollisionObjects() {}
}

export {CharacterControls};
