import styled from "styled-components";
import {flexCenter} from "../../../../../../assets/style";

export const Modal = styled.div`
    position: fixed;
    bottom: 138px;
    left: 10px;

    pointer-events: all;
    overflow: hidden;
    z-index: 99999;

    width: 536px;
    max-width: 95vw;
    height: 536px;
    max-height: 70vh;

    background: #d4cbb8;
    border: 1px solid #8b6653;
    border-radius: 16px;

    ${flexCenter};
    flex-direction: column;
    justify-content: flex-start;
`;

export const Top = styled.div`
    height: 28.36%;
    height: 152px;
    width: 100%;
    ${flexCenter};
    column-gap: 8px;
    background: #8b6653;
    padding: 16px;

    @media only screen and (max-width: 767px) {
        height: auto;
        padding: 8px;
    }
`;

export const MenuItem = styled.div<{$active: boolean}>`
    width: 120px;
    height: 120px;
    cursor: pointer;
    background: ${({$active}) => ($active ? "#B58368" : "transparent")};
    ${flexCenter};
    flex-direction: column;
    gap: 4px;
    border-radius: 16px;

    img {
        width: ${({$active}) => ($active ? "80px" : "96px")};
        height: ${({$active}) => ($active ? "80px" : "96px")};
    }

    .label {
        font-family: Lilita One;
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.011em;
        text-align: center;
        color: #fff;
    }

    @media only screen and (max-width: 767px) {
        width: 80px;
        height: 80px;
        img {
            width: 50px;
            height: 50px;
            width: ${({$active}) => ($active ? "55px" : "75px")};
            height: ${({$active}) => ($active ? "55px" : "75px")};
        }

        .label {
            font-size: 13px;
            margin-bottom: 2px;
        }
    }
`;

export const Middle = styled.div`
    flex-grow: 1;
    width: 100%;
    margin: 16px;
    position: relative;
    ${flexCenter};
    padding: 16px;
`;

export const StateWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexCenter};
    flex-direction: column;
    gap: 8px;
`;

export const EmptyStateText = styled.div`
    width: 100%;
    font-family: Lilita One;
    font-family: Lilita One;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.011em;
    text-align: center;
    color: #00000066;
    margin-top: -10px;
`;
